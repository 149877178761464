import get from 'lodash/get'

export function getBodyMutation(data: Record<string, string> = {}) {
  return {
    id: get(data, 'id', ''),
    uuid: get(data, 'uuid', undefined),
    text: get(data, 'text', ''),
    sectionID: get(data, 'sectionID'),
    topic: get(data, 'topic'),
    reasonForMA: get(data, 'reasonForMA'),
    reasonForProspect: get(data, 'reasonForProspect'),
    status: get(data, 'status', false),
    createdBy: get(data, 'createdBy', ''),
    updatedBy: get(data, 'updatedBy', ''),
    createdAt: get(data, 'createdAt', ''),
    updatedAt: get(data, 'updatedAt', ''),
  }
}
