import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  FilterType,
  initialState,
  NotificationItem,
  NotificationState,
  ResponseMeta,
} from './model'

const NotificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    setNotificationIsOpen: (
      state: NotificationState,
      action: PayloadAction<boolean>
    ) => {
      state.isOpen = action.payload
    },
    setNotificationIsLoading: (
      state: NotificationState,
      action: PayloadAction<boolean>
    ) => {
      state.isLoading = action.payload
    },
    setNotificationItems: (
      state: NotificationState,
      action: PayloadAction<NotificationItem[]>
    ) => {
      state.items = action.payload
    },
    setNotificationAppendItems: (
      state: NotificationState,
      action: PayloadAction<NotificationItem[]>
    ) => {
      state.items = [...state.items, ...action.payload]
    },
    setNotificationFilterType: (
      state: NotificationState,
      action: PayloadAction<FilterType>
    ) => {
      state.filterType = action.payload
    },
    setNotificationMeta: (
      state: NotificationState,
      action: PayloadAction<ResponseMeta>
    ) => {
      state.meta = action.payload
    },
    setIsReadToItems: (
      state: NotificationState,
      action: PayloadAction<string>
    ) => {
      const currentUuid = action.payload

      state.items = state.items.map((item) =>
        item.uuid === currentUuid ? { ...item, isRead: true } : item
      )
    },
    setUnReadTotal: (
      state: NotificationState,
      action: PayloadAction<number>
    ) => {
      state.meta.unReadTotal = action.payload
    },
  },
})

export const {
  setNotificationIsOpen,
  setNotificationIsLoading,
  setNotificationFilterType,
  setNotificationItems,
  setNotificationAppendItems,
  setNotificationMeta,
  setIsReadToItems,
  setUnReadTotal,
} = NotificationSlice.actions

export default NotificationSlice.reducer
