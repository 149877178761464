import _ from 'lodash'
import { setInitialTable, startLoading, stopLoading } from '../../../../../../components/Table/redux'
import { store } from '../../../../../../redux/store'
import { dcAdminDashboard } from '../../../../../../utils/apiPath'
import { headerCells } from '../model/headerCellsList'
import { callAPI } from '../../../../../../utils/callAPI'
import { setDashboard, setDashboardIsLoading } from '../slices'
import { ROW_PAGE_PER_LIST } from '../../../../../../constants/table/rowPerPageList'

export const fetchData = async () => {
  const { table } = store.getState().table
  const { page } = table
  const { filterProp } = store.getState().table.filter

  const realPage = page <= 0 ? 1 : +page
  const tableProps = table

  const body = {
    limit: _.get(tableProps, 'limit', ROW_PAGE_PER_LIST[0].value),
    page: realPage,
    startDate: _.get(filterProp, 'startDate', ''),
    endDate: _.get(filterProp, 'endDate', ''),
  }

  store.dispatch(setDashboardIsLoading(true))
  store.dispatch(startLoading())
  const isCancel = await callAPI({
    method: 'post',
    url: dcAdminDashboard,
    body: body,
    onSuccess: (res) => {
      store.dispatch(setDashboard(res))
      store.dispatch(
        setInitialTable({
          rows: _.get(res, 'dcAdminTable.result', []),
          allCount: _.get(res, 'dcAdminTable.totalCount', 0),
          headCells: headerCells,
          isCheckBox: false,
        })
      )
    },
    onError: (e) => {
      store.dispatch(
        setInitialTable({
          rows: [],
          allCount: 0,
          headCells: headerCells,
        })
      )
      return e.message?.includes('method')
    },
  })
  store.dispatch(setDashboardIsLoading(false))

  if (isCancel) store.dispatch(stopLoading())
}
