/* eslint-disable @typescript-eslint/no-explicit-any */
import { memo } from 'react'
import get from 'lodash/get'
import isUndefined from 'lodash/isUndefined'
import isEmpty from 'lodash/isEmpty'
// mui
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import Autocomplete, {
  type AutocompleteProps,
} from '@mui/material/Autocomplete'
import type { AutocompleteChangeReason, SxProps, Theme } from '@mui/material'
// redux
import { useDispatch } from '../../redux/store'
import { setFieldValue } from '../CRUD/slices'
// hook
import { useInputField } from '../CRUD/hooks/useInputField'
// types
import type { OptionItem } from '../../types/input'

export const convertAnyItemToOption = (
  items: any[],
  field: { label: string; value: string }
): OptionItem[] => {
  const options: OptionItem[] = []
  for (const item of items) {
    options.push({
      label: get(item, field.label, ''),
      value: get(item, field.value, ''),
    })
  }
  return options
}
export type CustomDropdownProps = {
  label: string
  id?: string
  crud?: string // save value only
  isValue?: boolean
  name?: string
  required?: boolean
  boxSx?: SxProps<Theme>
  placeholder?: string
  fullWidth?: boolean
  disableClearable?: boolean
  onChange?: (value: any, reason?: AutocompleteChangeReason) => void
  handleExtraChange?: (
    event: React.SyntheticEvent,
    value: any,
    reason?: AutocompleteChangeReason
  ) => void
} & Omit<AutocompleteProps<any, any, any, any>, 'renderInput' | 'onChange'>

const CustomDropdown: React.FC<CustomDropdownProps> = memo(
  ({
    label,
    id,
    crud,
    isValue,
    name = '',
    required,
    disabled,
    boxSx,
    placeholder,
    disableClearable,
    fullWidth,
    onChange,
    handleExtraChange,
    ...other
  }) => {
    const dispatch = useDispatch()

    const isCrud = !!(!isEmpty(crud) && !isUndefined(crud))
    const _name = isCrud ? crud : name
    const _id = !isEmpty(id) && !isUndefined(id) ? id : name

    const { message, isError } = useInputField(_name)

    const handleValueChange = (
      event: React.SyntheticEvent,
      data: OptionItem,
      reason: AutocompleteChangeReason
    ) => {
      const _value = isValue ? data.value : data
      if (handleExtraChange) {
        dispatch(setFieldValue({ key: `formData.${name}`, value: _value }))
        handleExtraChange(event, data, reason)
      } else if (isCrud) {
        dispatch(setFieldValue({ key: `formData.${crud}`, value: _value }))
        if (crud === 'reasonTopic') {
          dispatch(
            setFieldValue({ key: `formData.reasonRequestMemo`, value: null })
          )
        }
      } else if (onChange) {
        onChange(_value, reason)
      }
    }

    return (
      <Box sx={{ mb: 2, ...boxSx }}>
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <Typography
            variant="body2"
            color={disabled ? 'text.gray' : 'text.secondary'}
          >
            {label}
          </Typography>
          {required && (
            <Typography
              sx={{ lineHeight: 1, ml: 0.5 }}
              color={disabled ? 'text.gray' : 'error'}
            >
              *
            </Typography>
          )}
        </Box>

        <Autocomplete
          id={_id}
          disableClearable={disableClearable}
          isOptionEqualToValue={(option, selected) =>
            option.value === selected.value
          }
          renderInput={(params) => (
            <TextField placeholder={placeholder} {...params} />
          )}
          onChange={handleValueChange}
          fullWidth={fullWidth}
          disabled={disabled}
          {...other}
        />
        {isError && message !== '' && (
          <Typography variant="body2" color="error">
            {message}
          </Typography>
        )}
      </Box>
    )
  }
)

CustomDropdown.displayName = 'CustomDropdownComponent'

export default CustomDropdown
