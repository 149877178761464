/* eslint-disable @typescript-eslint/no-explicit-any */
import { memo } from 'react'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import Autocomplete, {
  type AutocompleteProps,
} from '@mui/material/Autocomplete'
import { useDispatch } from 'react-redux'
import { setFieldValue } from '../../CRUD/slices'
import { OptionItem } from '../types/propsInputs'
import { AppDispatch } from '../../../redux/store'
import _ from 'lodash'
import { SxProps } from '@mui/material'
import { useInputField } from '../../CRUD/handler/useInputField'
import { getValueStr } from '../../../utils/getValue'

export const convertAnyItemToOption = (
  items: any[],
  field: { label: string; value: string }
): OptionItem[] => {
  const options: OptionItem[] = []
  for (const item of items) {
    options.push({
      label: _.get(item, field.label, ''),
      value: _.get(item, field.value, ''),
    })
  }
  return options
}

export type CustomDropdownProps = {
  label: string
  id?: string
  crud?: string // save value only
  isValue?: boolean
  name?: string
  required?: boolean
  boxSx?: SxProps
  onChange?: (value: any) => void
  onClear?: () => void
  handleExtraChange?: (event: React.SyntheticEvent, value: any) => void
  clearable?: boolean
  value?: any
  placeholder?: string
  defaultValue?: string
} & Omit<AutocompleteProps<any, any, any, any>, 'renderInput' | 'onChange'>

const CustomDropdown: React.FC<CustomDropdownProps> = memo(
  ({
    label,
    id,
    crud,
    isValue,
    name = '',
    required,
    disabled,
    boxSx,
    onChange,
    handleExtraChange,
    placeholder,
    options,
    defaultValue,
    ...other
  }) => {
    const dispatch: AppDispatch = useDispatch()

    const isCrud = !!(!_.isEmpty(crud) && !_.isUndefined(crud))
    const _name = isCrud ? crud : name
    const _id = !_.isEmpty(id) && !_.isUndefined(id) ? id : name

    const { message, isError, value } = useInputField(_name)

    const handleValueChange = (
      event: React.SyntheticEvent,
      data: OptionItem | null
    ) => {
      const _value = isValue ? data?.value : data
      if (handleExtraChange) {
        dispatch(setFieldValue({ key: `formData.${name}`, value: _value }))
        handleExtraChange(event, data)
      } else if (isCrud) {
        dispatch(setFieldValue({ key: `formData.${crud}`, value: _value }))
      } else if (onChange) {
        onChange(_value)
      }
    }
    const findLabel = () => {
      const data = _.find(options, { value: value })
      return getValueStr(data, 'label')
    }
    return (
      <Box sx={{ mb: 2, ...boxSx }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', mb: 0.6 }}>
          <Typography
            variant="body2"
            color={disabled ? 'text.gray' : 'text.secondary'}
          >
            {label}
          </Typography>
          {required && (
            <Typography
              sx={{ lineHeight: 1, ml: 1 }}
              color={disabled ? 'text.gray' : 'error'}
            >
              *
            </Typography>
          )}
        </Box>

        <Autocomplete
          id={_id}
          options={options}
          sx={{
            backgroundColor: 'white.main',
            '.MuiOutlinedInput-notchedOutline': {
              borderColor: isError ? 'error.main' : '',
            },
          }}
          value={defaultValue ?? findLabel()}
          isOptionEqualToValue={(option, selected) =>
            isValue
              ? option.label === findLabel()
              : option.value === selected.value
          }
          renderInput={(params) => (
            <TextField placeholder={placeholder} {...params} />
          )}
          onChange={handleValueChange}
          clearOnEscape
          disabled={disabled}
          {...other}
        />
        {isError && message !== '' && (
          <Typography variant="body2" color="error">
            {message}
          </Typography>
        )}
      </Box>
    )
  }
)

CustomDropdown.displayName = 'CustomDropdownComponent'

export default CustomDropdown
