import type { SxProps, Theme } from '@mui/material'

export interface DialogState {
  isOpen: boolean
  isConfirmation: boolean
  isLoading: boolean
  title: React.ReactNode
  icon?: string
  colorDisagreeText?: string
  colorAgreeText?: string
  disagreeText?: string
  message: React.ReactNode
  type: string
  variantDisagreeText?: string
  content: React.ReactNode | null
  maxWidth: string | null
  disableButton: boolean
  handleConfirm: (() => void) | null
  handleError: (() => void) | null
  handleCancel: (() => void) | null
  agreeText?: string
  isCloseDialog: boolean
  fullWidth: boolean
  sx?: SxProps<Theme>
  dataField: string
  showButton: boolean
  sxActions?: SxProps<Theme>
}

export const initialState: DialogState = {
  isOpen: false,
  isConfirmation: false,
  isLoading: false,
  title: '',
  icon: undefined,
  colorDisagreeText: undefined,
  colorAgreeText: undefined,
  disagreeText: undefined,
  message: '',
  type: '',
  variantDisagreeText: undefined,
  content: null,
  maxWidth: null,
  disableButton: false,
  handleConfirm: null,
  handleError: null,
  handleCancel: null,
  agreeText: undefined,
  isCloseDialog: true,
  fullWidth: true,
  sx: undefined,
  dataField: '',
  showButton: false,
  sxActions: null,
}

export interface OpenErrorDialogPayload {
  message: string
  title?: React.ReactNode
  name?: string
  isCloseDialog: boolean
  type?: string
  icon?: string
  handleError?: () => void
  maxWidth?: string | null
  sx?: SxProps<Theme>
  content?: React.ReactNode
  showButton?: boolean
  agreeText?: string
  handleConfirm?: (() => void) | null
  sxActions?: SxProps
}

export interface OpenDialogPayload {
  type?: string
  title: React.ReactNode
  message?: React.ReactNode
  handleConfirm?: (() => void) | null
  handleCancel?: (() => void) | null
  handleOK?: (() => void) | null
  isCloseDialog: boolean
  icon?: string
  handleError?: () => void
  maxWidth?: string | null
  sx?: SxProps<Theme>
  content?: React.ReactNode
  showButton?: boolean
  agreeText?: string
}
