import _ from 'lodash'
import {
  setIsLoadingDrawer,
  setDrawerTable,
} from '../../../../components/CustomDrawer/slices'
import { AppDispatch } from '../../../../redux/store'
import { getValueNumber } from '../../../../utils/getValue'
import { callProspectAssignHistory } from '../services/thunk'

export const loadProspectAssignHistory =
  (uuid: string) => async (dispatch: AppDispatch) => {
    dispatch(setIsLoadingDrawer(true))

    const { payload } = await dispatch(callProspectAssignHistory(uuid))

    const items = _.get(payload, 'result', [])
    dispatch(
      setDrawerTable({
        items: items,
        total: getValueNumber(payload, 'meta.total'),
        page: getValueNumber(payload, 'meta.page'),
        limit: getValueNumber(payload, 'meta.limit'),
      })
    )
    dispatch(setIsLoadingDrawer(false))
  }
