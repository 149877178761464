export const considerationOptions = [
  {
    label: 'ผ่าน',
    value: true,
  },
  {
    label: 'ไม่ผ่าน',
    value: false,
  },
]
