import { Box, Typography } from '@mui/material'
import { closeCustomDialog } from '../../../../components/CustomDialog/slices'
import { dispatch, useSelector } from '../../../../redux/store'
import CustomButton, {
  CustomLoadingButton,
} from '../../../../components/Input/CustomButton'
import { HistoryDecisionForm } from './HistoryDecisionForm'
import { HistoryDecisionInfo } from './HistoryDecisionInfo'
import {
  selectCrudFormData,
  selectCrudLoading,
  selectInitialProfile,
} from '../../../../redux/selector'
import _ from 'lodash'
import { useEffect } from 'react'
import {
  setFieldValue,
  setFormIsLoading,
} from '../../../../components/CRUD/slices'
import { callResultConsideration } from './services/thunk'
import { ApiStatus } from '../../../../constants/status'
import { getProfileDetails } from '../../slices/applicant-details'
import { getTitleNotOwner } from '../handler/getTitleNotOwner'

export const HistoryDecision = (props: { isEdit?: boolean }) => {
  const { isEdit = false } = props
  const initProfile = useSelector(selectInitialProfile)
  const fullFormUuid = String(initProfile?.fullformUuid)
  const formData = useSelector(selectCrudFormData)
  const isLoading = useSelector(selectCrudLoading)
  const { actionForm, actionBy, decisionResult } = formData
  const metaIds = _.map(decisionResult?.attachments ?? [], 'id')
  const title = getTitleNotOwner(initProfile, 'ยืนยันผลพิจารณา')
  const title2 = getTitleNotOwner(initProfile, 'ผลพิจารณา')
  useEffect(() => {
    dispatch(
      setFieldValue({
        key: `formData.actionForm`,
        value: '',
      })
    )
  }, [])

  const getHeaderTitle = () => {
    if (actionBy === 'Confirm') return `${title}`
    else if (actionBy === 'Request') return `${title2}`
    else return `รอพิจารณา / ติดตามผล`
  }
  const handleSubmitResult = async () => {
    dispatch(setFormIsLoading(true))
    const { payload } = await dispatch(
      callResultConsideration({
        fullFormUuid: fullFormUuid,
        actionBy: actionForm,
        action: 'Request',
        metaId: metaIds,
        result: decisionResult.result === 'YES' ? true : false,
      })
    )
    if (payload?.status !== ApiStatus.ERROR) {
      dispatch(
        setFieldValue({
          key: `formData.actionForm`,
          value: '',
        })
      )
    }

    dispatch(setFormIsLoading(false))
  }
  const handleSubmitConfirm = async () => {
    dispatch(setFormIsLoading(true))
    const { payload } = await dispatch(
      callResultConsideration({
        fullFormUuid: fullFormUuid,
        actionBy: actionForm,
        action: 'Confirm',
      })
    )
    if (payload?.status !== ApiStatus.ERROR) {
      dispatch(
        setFieldValue({
          key: `formData.actionForm`,
          value: '',
        })
      )
    }

    dispatch(setFormIsLoading(false))
  }
  const disabledButton = () => {
    if (actionBy !== 'Confirm') {
      return (
        _.isEmpty(decisionResult?.result) ||
        _.isEmpty(decisionResult?.attachments)
      )
    }
    return false
  }
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          mb: 2,
          justifyContent: 'space-between',
          alignItems: 'center',
          pr: 2,
        }}
      >
        {isEdit ? (
          <Typography variant="h6" sx={{ width: '100%' }}>
            {getHeaderTitle()}
          </Typography>
        ) : (
          <Typography variant="h6" sx={{ width: '100%' }}>
            ประวัติการส่งพิจารณาเพิ่มเติม
          </Typography>
        )}
      </Box>

      <Box
        sx={{
          maxHeight: '65vh',
          overflow: 'auto',
          scrollbarWidth: 'thin',
          pr: 3,
        }}
      >
        {!_.isEmpty(actionForm) ? (
          <HistoryDecisionForm />
        ) : (
          <HistoryDecisionInfo isEdit={isEdit} />
        )}
      </Box>

      <Box
        sx={{ display: 'flex', justifyContent: 'end', gap: 1, mt: 3, pr: 3 }}
      >
        {!_.isEmpty(actionForm) ? (
          <>
            <CustomButton
              sx={{ borderRadius: 1 }}
              variant={'outlined'}
              color="info"
              onClick={() =>
                dispatch(
                  setFieldValue({
                    key: `formData.actionForm`,
                    value: '',
                  })
                )
              }
            >
              ยกเลิก
            </CustomButton>
            <CustomLoadingButton
              loading={isLoading}
              disabled={disabledButton()}
              onClick={async () => {
                if (actionBy === 'Confirm') handleSubmitConfirm()
                else handleSubmitResult()
              }}
            >
              ยืนยัน
            </CustomLoadingButton>
          </>
        ) : (
          <CustomButton
            sx={{ borderRadius: 1 }}
            variant="contained"
            color="info"
            onClick={() => {
              dispatch(
                getProfileDetails({ prospectId: String(initProfile?.uuid) })
              )
              dispatch(closeCustomDialog())
            }}
          >
            ปิด
          </CustomButton>
        )}
      </Box>
    </>
  )
}
