import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CustomDateRangeState, initialState } from './model'

const CustomDateRangeSlice = createSlice({
  name: 'custom-date-range',
  initialState,
  reducers: {
    setMinDate: (
      state: CustomDateRangeState,
      action: PayloadAction<Date | undefined>
    ) => {
      state.minDate = action.payload
    },
    setMaxDate: (
      state: CustomDateRangeState,
      action: PayloadAction<Date | undefined>
    ) => {
      state.maxDate = action.payload
    },
  },
})

export const { setMinDate, setMaxDate } = CustomDateRangeSlice.actions

export default CustomDateRangeSlice.reducer
