import Box from '@mui/material/Box'
import { themeColor } from '../../../styles/themes/themeData'
import Avatar from '@mui/material/Avatar'
import _ from 'lodash'
import CancelIcon from '@mui/icons-material/Cancel'
import IconButton from '@mui/material/IconButton'
import { dispatch } from '../../../redux/store'
import { setClearFixedFileBox, setShowFile } from '../slices/applicant-details'
import { useSelector } from 'react-redux'
import { selectFixedFileBox } from '../../../redux/selector'
import { getFileUrl } from '../../../utils/getFileUrl'
import PictureAsPdfRoundedIcon from '@mui/icons-material/PictureAsPdfRounded'
export const ShowFixedFileBox = () => {
  const fixedFileBox = useSelector(selectFixedFileBox)

  return _.size(fixedFileBox) > 0 ? (
    <>
      <Box
        sx={{
          position: 'fixed',
          display: 'flex',
          justifyContent: 'center',
          bottom: '100px',
          right: '20px',
          width: '64px',
          height: '64px',
          textAlign: 'center',
          alignItems: 'center',
          borderRadius: '50%',
          backgroundColor: '#FFFFFF',
          boxShadow: themeColor.boxShadow.main,
          zIndex: 9999,
        }}
      >
        <IconButton
          sx={{
            position: 'absolute',
            top: '-10px',
            right: '-10px',
            display: 'inline-block',
            zIndex: 99,
          }}
          onClick={() => dispatch(setClearFixedFileBox())}
        >
          <CancelIcon />
        </IconButton>
        <Box>
          {_.get(fixedFileBox[_.size(fixedFileBox) - 1], `type`, '') ===
            'image/png' ||
          _.get(fixedFileBox[_.size(fixedFileBox) - 1], `type`, '') ===
            'image/jpeg' ||
          _.get(fixedFileBox[_.size(fixedFileBox) - 1], `type`, '') ===
            'image/jpg' ? (
            <Avatar
              variant={'square'}
              sx={{ borderRadius: 1, cursor: 'pointer' }}
              src={getFileUrl(
                _.get(fixedFileBox[_.size(fixedFileBox) - 1], `url`, '')
              )}
              onClick={() => {
                dispatch(
                  setShowFile({
                    isOpen: true,
                    file: fixedFileBox[_.size(fixedFileBox) - 1],
                  })
                )
              }}
            />
          ) : (
            <PictureAsPdfRoundedIcon
              fontSize={'large'}
              color="error"
              sx={{ cursor: 'pointer' }}
              onClick={() => {
                dispatch(
                  setShowFile({
                    isOpen: true,
                    file: fixedFileBox[_.size(fixedFileBox) - 1],
                  })
                )
              }}
            />
          )}
        </Box>
      </Box>
    </>
  ) : null
}
