import Dialog from '@mui/material/Dialog'
import { AttachmentMeta } from '../../types/full-form'
import Box from '@mui/material/Box'
import { IconButton, Typography } from '@mui/material'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import CloseIcon from '@mui/icons-material/Close'
import Image from '../Image/Image'
import {
  setFixedFileBox,
  setShowFile,
} from '../../modules/DCAdmin/slices/applicant-details'
import { dispatch, useSelector } from '../../redux/store'
import PDFViewer from '../PdfViewer'
import { getStorageUrl } from '../FullUpload/handler/getStorageUrl'
import { CustomLoadingButton } from '../Input/CustomButton'
import ImageIcon from '@mui/icons-material/Image'
import CustomSliderWithBox from '../CropperDialog/CustomSliderWithBox'
import { useEffect, useState } from 'react'
import Cropper from 'react-easy-crop'
import { getValueStr } from '../../utils/getValue'
import { selectProfileDetails } from '../../redux/selector'
import { handlePreviewImage } from '../../utils/image'
import {
  downloadImageFromFile,
  downloadPdf,
} from '../../services/utils/downloadFile'
type Props = {
  isOpen: boolean
  fileData?: AttachmentMeta | null
}

export default function FileViewer({ isOpen, fileData }: Readonly<Props>) {
  const [zoom, setZoom] = useState<number>(1)
  const profileDetails = useSelector(selectProfileDetails)
  const [loading, setLoading] = useState(false)
  const [_, setFile] = useState<
    | {
        srcFile: string | ArrayBuffer
        name: string
      }
    | File
    | null
  >(null)

  const handleMinimize = () => {
    setZoom(1)
    dispatch(setShowFile({ isOpen: false, file: fileData }))
    if (fileData) dispatch(setFixedFileBox(fileData))
  }
  const createFileName = () => {
    let newFileName = ''
    if (fileData?.fieldName === 'photo') {
      newFileName = getValueStr(profileDetails, 'personal.idCard')
    } else {
      newFileName =
        getValueStr(profileDetails, 'personal.idCard') +
        '_' +
        fileData?.fieldName
    }

    return newFileName
  }

  const loadFile = async (fileImage: AttachmentMeta) => {
    const previewImage = await handlePreviewImage({
      imageUrl: fileImage.url,
      fileName: fileImage.fileName,
      fileType: fileImage.type,
      cb: (file: { srcFile: string | ArrayBuffer; name: string }) => {
        setFile(file)
      },
    })
    return previewImage
  }

  const openNewTab = (fileUrl: string) => {
    const link = document.createElement('a')
    link.href = fileUrl
    link.target = '_blank'
    link.rel = 'noopener noreferrer'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  return (
    <>
      <Dialog
        fullScreen
        sx={{
          width: 'calc(100%)',
          maxHeight: '100%',
          height: '100%',
          p: 0,
          m: 0,
          borderRadius: '0px',
        }}
        open={isOpen}
      >
        <Box
          sx={{
            top: 0,
            left: 0,
            width: '100%',
            position: 'fixed',
            p: 1,
            px: 5,
            backgroundColor: '#000000',
            color: '#FFFFFF',
            textAlign: 'center',
            justifyContent: 'space-between',
            display: 'flex',
            alignItems: 'center',
            zIndex: 900,
          }}
        >
          <Box display={'flex'} sx={{ width: '20%' }}></Box>
          <Typography variant="body1">{fileData?.originalFileName}</Typography>
          <Box display={'flex'} sx={{ width: '20%', justifyContent: 'end' }}>
            <IconButton onClick={handleMinimize}>
              <Image
                src="/icons/minimized-icon.svg"
                alt="Minimized previewed image"
                effect="blur"
                sizes="20"
                sx={{ lineHeight: 0 }}
              />
            </IconButton>
            <IconButton
              onClick={() =>
                fileData && openNewTab(getStorageUrl(fileData?.url))
              }
            >
              <OpenInNewIcon sx={{ color: '#FFFFFF' }} />
            </IconButton>
            <IconButton
              onClick={() => {
                setZoom(1)
                dispatch(setShowFile({ isOpen: false, file: null }))
              }}
            >
              <CloseIcon sx={{ color: '#FFFFFF' }} />
            </IconButton>
          </Box>
        </Box>
        <Box sx={{ height: '100vh', maxHeight: '100%', p: 2, borderRadius: 0 }}>
          {fileData && fileData.type === 'application/pdf' ? (
            <Box sx={{ pt: '50px', maxWidth: '1000px', margin: 'auto' }}>
              <PDFViewer
                disabledDownload
                height={`calc(100vh - 130px)`}
                fileUrl={getStorageUrl(fileData?.url)}
              />
            </Box>
          ) : (
            <>
              {fileData && (
                <Box
                  sx={{
                    pt: '50px',
                    maxWidth: '1000px',
                    pb: '50px',
                    margin: 'auto',
                  }}
                >
                  <EasyCropper src={getStorageUrl(fileData?.url)} zoom={zoom} />
                </Box>
              )}
            </>
          )}
        </Box>
        <Box
          sx={{
            bottom: 0,
            left: 0,
            width: '100%',
            position: 'fixed',
            p: 1,
            px: 5,
            backgroundColor: 'rgba(0,0,0,.2)',
            color: '#FFFFFF',
            textAlign: 'center',
            justifyContent: 'space-between',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Box display={'flex'} sx={{ width: '20%' }}></Box>

          <Box display={'flex'} alignItems={'center'} gap={1}>
            {fileData && fileData.type !== 'application/pdf' && (
              <>
                <ImageIcon fontSize={'small'} />
                <CustomSliderWithBox
                  min={1}
                  max={10}
                  step={0.01}
                  defaultValue={1}
                  size="small"
                  onChange={(value) => setZoom(value)}
                />
                <ImageIcon fontSize={'large'} />
              </>
            )}
          </Box>
          <Box display={'flex'} sx={{ width: '20%', justifyContent: 'end' }}>
            <CustomLoadingButton
              loading={loading}
              onClick={async () => {
                if (fileData) {
                  setLoading(true)

                  if (fileData.type === 'application/pdf') {
                    await downloadPdf(
                      getStorageUrl(fileData?.url),
                      createFileName()
                    )
                  } else {
                    const _file = await loadFile(fileData)
                    await downloadImageFromFile(_file as File, createFileName())
                  }

                  setLoading(false)
                }
              }}
            >
              Download
            </CustomLoadingButton>
          </Box>
        </Box>
      </Dialog>
    </>
  )
}

const EasyCropper: React.FC<{ src: string; zoom: number }> = ({
  src,
  zoom = 1,
}) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [aspect, setAspect] = useState(1)

  useEffect(() => {
    const img = document.createElement('img') as HTMLImageElement
    img.src = src

    setAspect(img.width / img.height)
    return () => {
      img.onload = null
    }
  }, [src])

  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        height: 'calc(100vh - 130px)',
        margin: 'auto',
        overflow: 'auto',
      }}
    >
      {src && (
        <Cropper
          image={src}
          crop={crop}
          zoom={zoom}
          objectFit={'contain'}
          showGrid={false}
          aspect={aspect}
          onCropChange={setCrop}
        />
      )}
    </Box>
  )
}
