import Modal from 'react-modal'
import { useMemo } from 'react'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
// components
import Image from '../../../components/Image/Image'
// redux
import { useSelector } from '../../../redux/store'
import {
  selectInitialProfile,
  selectProfileDetails,
  selectProgressStatus,
} from '../../../redux/selector'
// constants
import { FULLFORM, getLabelFullFormArrText } from '../../../constants/dc-admin'
// utils
import { handleCalAge } from '../../../utils/getFormatDate'
//
import {
  PendingStatus,
  CheckingApplicationStatus,
  AwaitingCorrectionStatus,
  CheckCompletedStatus,
  AwaitingReviewOrFollowUpStatus,
  ReviewApprovedStatus,
  AwaitingLicenseNoStatus,
  AwaitingRCMSCodeStatus,
  AwaitingAgentCodeStatus,
  CodeIssuedStatus,
  ReviewRejectedStatus,
  AwaitingCCMCheckStatus,
  RejectedByOICStatus,
  AwaitingCCMSendStatus,
} from './progress-bar-status'
import { CardContentStyle, CardStyle } from './styled'
import { displayDateTime } from '../../../utils/convertFormatDateTime'

Modal.setAppElement('#root')

export default function ProfileHeader() {
  const initProfile = useSelector(selectInitialProfile)
  const profileDetails = useSelector(selectProfileDetails)
  const applicationStatus = useSelector(selectProgressStatus)

  const lookupChecklistStatus = useMemo(() => {
    return {
      DS_REVIEWED: <PendingStatus />,
      DCV_ACCEPTED_REVIEW: <CheckingApplicationStatus />,
      DCV_REQUESTED_MEMO: <AwaitingCorrectionStatus />,
      DS_SAVED_FULL_FORM_MEMO: <AwaitingCorrectionStatus />,
      DS_SUBMITTED_FULL_FORM_MEMO: <AwaitingCorrectionStatus />,
      DS_REVIEWED_MEMO: <CheckingApplicationStatus />,
      DCV_PASSED_PROSPECT_HISTORY: <CheckCompletedStatus />,
      DCV_REQUESTED_DESCISION: <AwaitingReviewOrFollowUpStatus />,
      DCV_UPDATED_DESCISION: <ReviewApprovedStatus />,
      DCV_APPROVED_TO_BE_AGENT: <ReviewApprovedStatus />,
      DCV_REQUESTED_E_LICENCING: <AwaitingLicenseNoStatus />,
      DCV_UPDATED_E_LICENCING_PASSED: <AwaitingRCMSCodeStatus />,
      DCV_REQUESTED_RCMS: <AwaitingAgentCodeStatus />,
      DCV_UPDATED_AGENT_CODE: <CodeIssuedStatus />,
      DCV_DROPPED: <ReviewRejectedStatus />,
      DCV_SENT_MAIL_DESCISION_CCM: <AwaitingCCMCheckStatus />,
      DCV_UPDATED_E_LICENCING_FAILED: <RejectedByOICStatus />,
      DCV_REQUESTED_DESCISION_CCM: <AwaitingCCMSendStatus />,
    }
  }, [])

  if (!initProfile || !profileDetails || !applicationStatus) {
    return null
  }

  return (
    <Stack direction="row" gap={3} alignItems="center">
      {/* DMS Candidate Profile card */}
      <CardStyle>
        <CardContentStyle>
          <Stack direction="row" alignItems="center" gap={2}>
            <Image
              src="/images/fallback-profile-female.svg"
              alt="Prospect profile details"
              effect="opacity"
              sizes="56"
            />
            <Stack flex={1} gap={1}>
              <Typography variant="body1b" color="primary.main">
                {`${getLabelFullFormArrText(FULLFORM.title, initProfile?.prefix)} ${initProfile.name}`}
              </Typography>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography variant="body2">
                  {`อายุ ${handleCalAge(initProfile.dateOfBirth)} ปี`}
                </Typography>
                <Typography variant="body2">
                  {`วันที่สมัคร: ${displayDateTime(initProfile.applicationDate)}`}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </CardContentStyle>
      </CardStyle>

      {/* LOOK UP TO RENDER CARD BASED ON STATUS */}
      {lookupChecklistStatus[applicationStatus]}
    </Stack>
  )
}
