import { createAsyncThunk } from '@reduxjs/toolkit'
import { API_URL } from '../../../../constants/envConfig'
import { callAPI } from '../../../../utils/callAPI'

export const callProspectDetail = createAsyncThunk(
  'appointment/call-prospect-detail',
  async (payload: { prospectUuid: string }, { rejectWithValue }) => {
    try {
      const response = await callAPI({
        url: `${API_URL}/appointment/detail`,
        method: 'post',
        body: payload,
      })
      return response
    } catch (err) {
      console.log('err ->>', err)
      return rejectWithValue(null)
    }
  }
)

export const callProspectFullFormData = createAsyncThunk(
  'appointment/call-prospect-fullform-data',
  async (fullFormUuid: string, { rejectWithValue }) => {
    try {
      const response = await callAPI({
        url: `${API_URL}/appointment/fullform/data/${fullFormUuid}`,
        method: 'get',
      })
      return response
    } catch (err) {
      console.log('err ->>', err)
      return rejectWithValue(null)
    }
  }
)

export const callProspectAssignHistory = createAsyncThunk(
  'simple-rule/call-prospect-assign-history',
  async (uuid: string, { rejectWithValue }) => {
    try {
      const response = await callAPI({
        url: `${API_URL}/simple-rule/filter/prospect-assign/history`,
        method: 'post',
        body: {
          uuid: uuid,
          limit: 64,
          page: 1,
          order: 'DESC',
          sort: 'createdAt',
        },
      })
      return response
    } catch (err) {
      console.log('err ->>', err)
      return rejectWithValue(null)
    }
  }
)
