import Typography from '@mui/material/Typography'
import CustomDropdown, {
  convertAnyItemToOption,
} from '../../../../components/Input/CustomDropdown'
import { Box } from '@mui/material'
import _ from 'lodash'
import { dispatch, useSelector } from '../../../../redux/store'
import { handleGetOptions } from './handler/handleGetSRAM'
import { selectCrudFormData } from '../../../../redux/selector'

export const FormCADTO = () => {
  const formData = useSelector(selectCrudFormData)
  const { options } = formData
  return (
    <>
      <Typography sx={{ mb: 1 }}>เลือกผู้ตรวจ</Typography>
      <Box
        onClick={() =>
          _.isEmpty(options?.CADTO_PROFILE) &&
          dispatch(handleGetOptions('CADTO_PROFILE'))
        }
      >
        <CustomDropdown
          label={'CADTO'}
          crud={'decision.CADTOUuid'}
          placeholder="กรุณาเลือกผู้ตรวจ"
          isValue
          loading
          options={convertAnyItemToOption(options?.CADTO_PROFILE ?? [], {
            label: 'name',
            value: 'uuid',
          })}
          fullWidth
        />
      </Box>
      <Box
        onClick={() =>
          _.isEmpty(options?.CDO_PROFILE) &&
          dispatch(handleGetOptions('CDO_PROFILE'))
        }
      >
        <CustomDropdown
          label={'CDO'}
          crud={'decision.CDOUuid'}
          placeholder="กรุณาเลือกผู้ตรวจ"
          isValue
          loading
          options={convertAnyItemToOption(options?.CDO_PROFILE ?? [], {
            label: 'name',
            value: 'uuid',
          })}
          fullWidth
        />
      </Box>
    </>
  )
}
