import Badge from '@mui/material/Badge'
import Menu from '@mui/material/Menu'
import { useEffect, useState } from 'react'
import NotificationsIcon from '@mui/icons-material/Notifications'
import { Box, IconButton } from '@mui/material'
import { shallowEqual, useSelector } from 'react-redux'
import { SliceModel } from '../../redux/models'
import { dispatch } from '../../redux/store'
import { loadNotification } from './handler/loadNotification'
import _ from 'lodash'
import CustomNotificationMobile from './MobileView'
import CloseIcon from '@mui/icons-material/Close'
import NotiContainer from './NotiContainer'

export default function CustomNotification() {
  const { meta } = useSelector(
    (state: SliceModel) => ({
      meta: state.notification.meta,
    }),
    shallowEqual
  )

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [isNotiMobileOpen, setIsNotiMobileOpen] = useState(false)
  const open = Boolean(anchorEl)
  const pathname = _.get(window.location, 'pathname', '')

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
    handleOpenNotification()
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleOpenNotification = () => {
    dispatch(
      loadNotification({
        page: 1,
        tab: 'unread',
        limit: pathname.includes('/notifications') ? 10 : 5,
      })
    )
  }

  useEffect(() => {
    if (!_.isEmpty(window.localStorage.getItem('user'))) {
      handleOpenNotification()
    }
  }, [])

  useEffect(() => {
    if (isNotiMobileOpen) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
  }, [isNotiMobileOpen])

  return (
    <>
      {!pathname.includes('/notifications') && (
        <>
          <IconButton
            sx={{
              ':hover': { background: 'none' },
              mr: 1,
              display: {
                xs: 'none',
                sm: 'block',
              },
            }}
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
          >
            <Badge badgeContent={meta?.unReadTotal} color="error">
              <NotificationsIcon sx={{ color: 'primary.dark' }} />
            </Badge>
          </IconButton>
          {!isNotiMobileOpen ? (
            <IconButton
              sx={{
                ':hover': { background: 'none' },
                mr: 1,
                display: {
                  xs: 'block',
                  sm: 'none',
                },
              }}
              onClick={() => {
                handleOpenNotification()
                setIsNotiMobileOpen(true)
              }}
            >
              <Badge badgeContent={meta?.unReadTotal} color="error">
                <NotificationsIcon sx={{ color: 'primary.dark' }} />
              </Badge>
            </IconButton>
          ) : (
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                right: 0,
                backgroundColor: '#FFFFFF',
                width: '40px',
                height: '80px',
                alignItems: 'center',
                display: {
                  xs: 'flex',
                  sm: 'none',
                },
                justifyContent: 'center',
              }}
            >
              <CloseIcon
                fontSize={'large'}
                color="primary"
                onClick={() => {
                  setIsNotiMobileOpen(false)
                }}
              />
            </Box>
          )}
        </>
      )}
      {isNotiMobileOpen && (
        <CustomNotificationMobile>
          <NotiContainer menuListSx={{ width: '100%' }} />
        </CustomNotificationMobile>
      )}

      <Menu
        data-testid="img-profile-menu"
        id="basic-menu"
        sx={{
          p: 0,
          display: { xs: 'none', sm: 'block' },
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <NotiContainer />
      </Menu>
    </>
  )
}
