import { enumStatus } from '../../../../../../components/CRUD/enum/status'
import { handleFetchError } from '../../../../../../components/CRUD/handler/handlerFetchError'
import { setFieldValue } from '../../../../../../components/CRUD/slices'
import {
  openDialog,
  loadingDialog,
  stopLoadingDialog,
  closeDialog,
} from '../../../../../../components/dialog/slices'
import { store } from '../../../../../../redux/store'
import { prospectUpdateStatus } from '../../../../../../utils/apiPath'
import { callAPI } from '../../../../../../utils/callAPI'
import { fetchDataList } from './fetchDataList'

const DIALOG_TITLE = 'ยืนยันลบข้อมูล'
const DIALOG_MESSAGE = 'คุณต้องการลบรายการนี้ใช่หรือไม่'

function confirmDialog(uuid: string, mutatePropToBody: any): void {
  store.dispatch(
    openDialog({
      type: 'delete',
      title: DIALOG_TITLE,
      message: DIALOG_MESSAGE,
      handleConfirm: () => {
        handleProcessDelete(uuid, mutatePropToBody)
      },
      isCloseDialog: false,
    })
  )
}

export const handleProcessDelete = async (
  uuid: string,
  mutatePropToBody: any
) => {
  startDialogLoading()

  try {
    await fetchData(uuid)
    handleSuccessfulDelete(mutatePropToBody)
  } catch (error) {
    handleFetchError(error)
  } finally {
    stopDialogLoading()
  }
}

const startDialogLoading = () => {
  store.dispatch(loadingDialog())
}

const stopDialogLoading = () => {
  store.dispatch(stopLoadingDialog())
}

const fetchData = async (uuid = '') => {
  const response = await callAPI({
    method: 'post',
    baseURL: window.__env__.REACT_APP_API_URL,
    url: prospectUpdateStatus,
    body: {
      prospectUuid: uuid,
      status: enumStatus.DELETED,
    },
  })
  store.dispatch(setFieldValue({ key: 'formData', value: response }))
}

const handleSuccessfulDelete = (mutatePropToBody: any) => {
  store.dispatch(
    openDialog({
      type: 'success',
      title: 'สำเร็จ',
      message: 'ข้อมูลถูกบันทึกเรียบร้อยแล้ว',
      agreeText: 'ตกลง',
      isCloseDialog: false,
      handleConfirm: () => {
        store.dispatch(closeDialog())
        fetchDataList(mutatePropToBody).then()
      },
    })
  )
}

export const handleDeleteClick = async (
  uuid: string,
  mutatePropToBody: any
): Promise<void> => {
  confirmDialog(uuid, mutatePropToBody)
}
