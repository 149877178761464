import Box, { BoxProps } from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import styled from '@mui/system/styled'
import { shouldForwardProp } from '../../../../../components/Styled'

export const BoxButton = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  gap: theme.spacing(2),
  [theme.breakpoints.down('md')]: {
    position: 'relative',
    width: '100%',
    '& > .MuiIconButton-root': {
      position: 'absolute',
      right: 0,
      top: 0,
    },
  },
  [theme.breakpoints.down('sm')]: {
    gap: theme.spacing(2),
  },
}))

export const BoxHeader = styled(
  Box,
  shouldForwardProp
)(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
}))

export const BoxCard = styled(Box)<any>(({
  theme, isFullWidth
}) => ({
  position: 'relative',
  width: isFullWidth ? 'calc(66% - 4px)' : 'calc(33% - 13px)',
  height: 196,
  display: 'flex',
  padding: theme.spacing(3),
  borderRadius: theme.spacing(2),
  boxShadow: '0px 12px 40px 1px rgba(73, 118, 186, 0.20);',
  overflow: 'hidden',
}))
