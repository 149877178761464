export enum DIALOG_TYPE {
  'DEFAULT',
  'SUCCESS',
  'ERROR',
  'CONFIRM',
  'WARNING',
  'CLOSE',
  'CUSTOM',
  'COMPLETE',
}
export const DIALOG_INFO = {
  DEFAULT: { title: '', description: '' },
  CONFIRM: { title: 'CONFIRM', description: '' },
  SUCCESS: { title: 'SUCCESS', description: 'Action Complete !' },
  ERROR: { title: 'ERROR', description: 'Action Failed !' },
  WARNING: { title: 'WARNING', description: '' },
  CLOSE: { title: 'CLOSE', description: '' },
  COMPLETE: { title: 'COMPLETE', description: '' },
}
export const DIALOG_TYPE_STRING = {
  DEFAULT: 'DEFAULT',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
  CONFIRM: 'CONFIRM',
  WARNING: 'WARNING',
  CLOSE: 'CLOSE',
  CUSTOM: 'CUSTOM',
  COMPLETE: 'COMPLETE',
}
