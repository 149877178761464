import {
  setProspectDetailIsLoading,
  setProspectDetail,
  setProspectFullForm,
} from '../slices'
import { AppDispatch } from '../../../../redux/store'
import { callProspectDetail, callProspectFullFormData } from '../services/thunk'
import { ApiStatus } from '../../../../constants/status'

export const initProspectDetail =
  (uuid: string) => async (dispatch: AppDispatch) => {
    dispatch(setProspectDetailIsLoading(true))
    const { payload } = await dispatch(
      callProspectDetail({ prospectUuid: uuid })
    )
    if (payload?.status === ApiStatus.SUCCESS) {
      payload.result.expireType = payload.result?.expireType ?? 'EXPIRE'
      dispatch(setProspectDetail(payload.result))

      if (payload.result.fullFormUuid) {
        const fullform = await dispatch(
          callProspectFullFormData(payload.result.fullFormUuid)
        )
        if (fullform.payload.status === ApiStatus.SUCCESS) {
          dispatch(setProspectFullForm(fullform.payload.result))
        } else {
          dispatch(setProspectFullForm(null))
        }
      }
    } else {
      window.location.href = '/prospect'
    }
    dispatch(setProspectDetailIsLoading(false))
  }
