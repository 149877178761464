import { combineReducers } from '@reduxjs/toolkit'
import dashboard from '../application-verification/features/Dashboard/slices'
import listing from '../application-verification/features/Listing/slices'
import history from '../applicant-details/slices'
import applicantDetails from './applicant-details'
import receivePayment from './receive-payment'

const dcAdminReducer = combineReducers({
  dashboard,
  listing,
  applicantDetails,
  history,
  receivePayment,
})
export default dcAdminReducer
