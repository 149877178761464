import { CustomInput } from '../../../../../../components/CRUD/components/CustomInput'
import CustomSwitchInLine from '../../../../../../components/CRUD/components/CustomSwitchInLine'
import Divider from '@mui/material/Divider'

export const BasicInfo = () => {
  return (
    <>
      <CustomInput
        required
        labelName="ชื่อ Ram"
        fieldName="name"
        placeholder="ระบุชื่อ Ram"
      />
      <CustomInput
        required
        labelName="อีเมล"
        fieldName="email"
        placeholder="อีเมล"
      />
      <CustomInput
        required
        labelName="เบอร์โทรศัพท์"
        fieldName="phoneNumber"
        placeholder="เบอร์โทรศัพท์"
      />
      <CustomInput
        required
        labelName="เลขาชื่อไทย"
        fieldName="secretaryNameTh"
        placeholder="เลขาชื่อไทย"
      />
      <CustomInput
        required
        labelName="เลขาชื่ออังกฤษ"
        fieldName="secretaryNameEn"
        placeholder="เลขชื่ออังกฤษ"
      />
      <CustomInput
        required
        labelName="เบอร์โทรศัพท์เลขา"
        fieldName="secretaryPhoneNumber"
        placeholder="เบอร์โทรศัพท์เลขา"
      />
      <CustomInput
        required
        labelName="อีเมลเลขา"
        fieldName="secretaryEmail"
        placeholder="อีเมลเลขา"
      />
      <CustomInput
        required
        labelName="รหัส"
        fieldName="code"
        placeholder="รหัส"
      />
      <CustomSwitchInLine fieldName="status" title="สถานะ" />
      <Divider />
    </>
  )
}
