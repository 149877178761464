import CustomRadioGroup from '../../../../components/Input/CustomRadioGroup'
import { useSelector } from 'react-redux'
import { selectCrudFormData } from '../../../../redux/selector'

export const FormCCM = () => {
  const formData = useSelector(selectCrudFormData)
  const { decision } = formData
  return (
    <>
      <CustomRadioGroup
        label={'ต้องการส่งอีเมลไปยัง CCM หรือไม่ *'}
        name={'decision.hasSendCCM'}
        defaultValue={decision?.hasSendCCM}
        options={[
          {
            label: 'ส่ง',
            value: 'YES',
          },
          {
            label: 'ไม่ส่ง',
            value: 'NO',
          },
        ]}
      />
    </>
  )
}
