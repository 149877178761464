import type { Breadcrumb } from '../../../../components/CRUD/components/Header'

export const breadcrumb: Breadcrumb[] = [
  {
    title: 'ตรวจสอบใบสมัคร',
    link: '/',
    pointer: false,
  },
  {
    title: 'รายละเอียดผู้สมัคร',
    link: '/dc-admin/applicant-details',
    pointer: false,
  },
]
