import { store } from '../../../../../../redux/store'
import get from 'lodash/get'

export function mutateFilterPropsToBody() {
  const { filterProp } = store.getState().table.filter

  return {
    name: get(filterProp, 'name', ''),
    managerCode: get(filterProp, 'managerCode', ''),
    managerName: get(filterProp, 'managerName', ''),
    channel: get(filterProp, 'channel', []),
    createdAt: get(filterProp, 'createdAt', ''),
    startDate: get(filterProp, 'startDate', ''),
    endDate: get(filterProp, 'endDate', ''),
    status: get(filterProp, 'status', []),
    loginStatus: get(filterProp, 'loginStatus', []),
    userStatus: get(filterProp, 'userStatus', []),
  }
}
