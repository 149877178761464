import type { ChangeEvent } from 'react'
import set from 'lodash/set'

import { dispatch } from '../redux/store'
import { openErrorDialog, closeDialog } from '../components/dialog/slices'
import { themeColor } from '../styles/themes/themeData'

export async function handleUploadFile(
  files: File[] | ChangeEvent<HTMLInputElement>,
  acceptFiles: string[],
  cb?: (file?: File) => void
) {
  const file = 'target' in files ? files.target.files?.[0] : files?.[0]

  let isWrongType = true
  const sizeFile = file?.size ?? 0
  const getNameImage = file?.name ?? ''
  const splitName = getNameImage.split('.')
  const checkType = acceptFiles.includes(
    splitName[splitName.length - 1].toLowerCase()
  )

  if (checkType) isWrongType = false

  if (sizeFile > 2000000) {
    isWrongType = true
  }

  if (isWrongType) {
    const message =
      'กรุณาตรวจสอบว่าขนาดไฟล์, จำนวนไฟล์, \nหรือประเภทไฟล์ของคุณว่าเป็นไปตามที่ระบบกำหนดหรือไม่'
    const elem = document.getElementById('input-file') ?? {}

    set(elem, 'value', null)
    dispatch(
      openErrorDialog({
        title: 'ไม่สามารถดำเนินการได้',
        message,
        handleConfirm: () => {
          dispatch(closeDialog())
        },
        isCloseDialog: false,
        agreeText: 'ตกลง',
        sxActions: {
          '& .MuiButtonBase-root': {
            bgcolor: themeColor.error.main,
            boxShadow: `0px 4px 12px 1px ${themeColor.text.error}59`,

            ':hover': {
              background: themeColor.error.containedHoverBackground,
            },
          },
        },
      })
    )

    return
  }

  cb?.(file)
}

export function handleDispatchException() {
  const message = 'ระบบขัดข้อง\nกรุณาลองใหม่อีกครั้ง'
  dispatch(
    openErrorDialog({
      title: 'ไม่สามารถดำเนินการได้',
      message,
      handleConfirm() {
        dispatch(closeDialog())
      },
      isCloseDialog: false,
      agreeText: 'ตกลง',
      sxActions: {
        '& .MuiButtonBase-root': {
          bgcolor: themeColor.error.main,
          boxShadow: `0px 4px 12px 1px ${themeColor.text.error}59`,

          ':hover': {
            background: themeColor.error.containedHoverBackground,
          },
        },
      },
    })
  )
}

export function handleDispatchExceptionForceLogout() {
  const message = 'ระบบขัดข้อง\nกรุณาลองใหม่อีกครั้ง'

  dispatch(
    openErrorDialog({
      title: 'ไม่สามารถดำเนินการได้',
      message,
      handleConfirm() {},
      isCloseDialog: false,
      agreeText: 'ตกลง',
      sxActions: {
        '& .MuiButtonBase-root': {
          bgcolor: themeColor.error.main,
          boxShadow: `0px 4px 12px 1px ${themeColor.text.error}59`,

          ':hover': {
            background: themeColor.error.containedHoverBackground,
          },
        },
      },
    })
  )
}
