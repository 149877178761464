export function filterMenuByPermissions(
  menu: any[],
  userPermissions: any[]
): any[] {
  return menu
    .map((item) => {
      const hasPermission = userPermissions.some((perm) =>
        Array.isArray(item.module)
          ? item.module.includes(perm.module)
          : perm.module === item.module
      )

      const filteredList = item.list
        ? item.list.filter((subItem: { permission: any }) =>
            userPermissions.some(
              (perm) =>
                (Array.isArray(subItem.permission)
                  ? subItem.permission.includes(perm.permission)
                  : perm.permission === subItem.permission) &&
                (Array.isArray(item.module)
                  ? item.module.includes(perm.module)
                  : perm.module === item.module)
            )
          )
        : []

      if (hasPermission || filteredList.length > 0) {
        return { ...item, list: filteredList }
      }

      return null
    })
    .filter((item) => item !== null)
}
