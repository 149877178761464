import { setClearForm } from '../../../../../../../components/CRUD/slices'
import { AppDispatch } from '../../../../../../../redux/store'
import { FormData } from '../../../../../../../components/CRUD/types/CrudState'
import { ManagerGrid } from '../../../../../../../components/Lookup/ManagerLookup/slices/model'

import { handleSubmitFormAssign } from './submitFormAssign'
import { Manager } from '../../../../type/types'
import { openCustomDialog } from '../../../../../../../components/CustomDialog/slices'
import { DIALOG_TYPE_STRING } from '../../../../../../../components/dialog/constants'

export const handleConfirmAssign =
  (row: ManagerGrid | undefined, form: FormData) =>
  async (dispatch: AppDispatch) => {
    if (!row) return
    const param: Manager = {
      ...form,
      ...row,
      prospectUuid: form.prospectUuid,
      managerUuid: row.uuid,
    }

    dispatch(
      openCustomDialog({
        title: 'ยืนยันบันทึก',
        message: 'คุณต้องการบันทึกนี้หรือไม่',
        handleConfirm: () => {
          dispatch(handleSubmitFormAssign(param))
        },
        type: DIALOG_TYPE_STRING.CONFIRM,
      })
    )
    dispatch(setClearForm())
  }
