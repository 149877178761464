import { useEffect } from 'react'
import { CPageContainer } from '../../components/CRUD/components/pageContainer'
import { setFormInit } from '../../components/CRUD/slices'
import { dispatch } from '../../redux/store'
import { init } from './handler/init'
import Header from './Header'
import ConfigurationForm from './Form'
import ConfigurationFormInterview from './FormInterview'
import LoadingPageCircular from '../../components/CRUD/components/LoadingPageCircular'
import { getFormIsLoading } from '../../components/CRUD/handler/useInputField'

export default function ConfigurationModule() {
  const isLoading = getFormIsLoading()
  useEffect(() => {
    dispatch(setFormInit({}))
    dispatch(init())
  }, [])
  return (
    <>
      <CPageContainer header={<Header />} content={<ConfigurationForm />} />
      <CPageContainer content={<ConfigurationFormInterview />} />
      <LoadingPageCircular isLoading={isLoading} />
    </>
  )
}
