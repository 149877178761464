import { useEffect, useState } from 'react'
import Modal from 'react-modal'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import useTheme from '@mui/material/styles/useTheme'
import { useDispatch, useSelector } from '../../../../redux/store'
import { getDataLicense } from '../../slices/applicant-details'
import {
  selectDataLicense,
  selectInitialProfile,
} from '../../../../redux/selector'
import { CardContentStyle, CardStyle } from '../styled'
import { formatAgentCode } from '../../../../utils/formatAgentCode'
import { displayDateTime } from '../../../../utils/convertFormatDateTime'
import EditLicenseDialog from './EditLicenseDialog'
import IconButton from '@mui/material/IconButton'
import EditIcon from '@mui/icons-material/Edit'

Modal.setAppElement('#root')

type Props = {
  isOpenModal: boolean
  closeModal: VoidFunction
}

export default function AdditionalInfoDialog({
  isOpenModal,
  closeModal,
}: Readonly<Props>) {
  const theme = useTheme()

  const dispatch = useDispatch()
  const dataLicense = useSelector(selectDataLicense)
  const initProfile = useSelector(selectInitialProfile)
  const fullFormUuid = String(initProfile?.fullformUuid)

  const [isEditOpen, setEditOpen] = useState(false)

  useEffect(() => {
    if (!dataLicense && initProfile) {
      dispatch(getDataLicense(initProfile.fullformUuid))
    }
  }, [dataLicense, dispatch, initProfile])

  const handleOpenEdit = () => {
    closeModal()
    setEditOpen(true)
  }

  const handleCloseEdit = () => {
    setEditOpen(false)
  }

  return (
    <>
      <Modal
        isOpen={isOpenModal}
        onRequestClose={closeModal}
        contentLabel="Awaiting license number modal"
        style={{
          overlay: {
            backgroundColor: `${theme.palette.common.black}BF`,
            zIndex: 1200, // For ensuring overlay is above other elements
          },
          content: {
            maxWidth: '678px',
            width: '100%',
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            padding: 0,
            backgroundColor: theme.palette.background.paper,
            borderRadius: '8px',
            boxShadow: `0px 9px 46px 8px ${theme.palette.common.black}1F,
                          0px 24px 38px 3px ${theme.palette.common.black}24,
                          0px 11px 15px -7px ${theme.palette.common.black}33`,
          },
        }}
      >
        <>
          <Box
            padding={2}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h6">เลขที่ใบอนุญาต และ รหัสตัวแทน</Typography>
            <IconButton onClick={handleOpenEdit} size="small" color="primary">
              <EditIcon />
            </IconButton>
          </Box>

          <Box paddingX={2} pb={2}>
            <CardStyle sx={{ marginBottom: theme.spacing(2) }}>
              <CardContentStyle>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  gap={2}
                >
                  <Box>
                    <Typography variant="body2" color="text.gray">
                      เลขที่ใบอนุญาต
                    </Typography>
                    <Typography variant="h6">
                      {dataLicense?.licenseNumber ?? '-'}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="body2" color="text.gray">
                      วันออกใบอนุญาต
                    </Typography>
                    <Typography variant="h6">
                      {displayDateTime(dataLicense?.startLicenseDate)}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="body2" color="text.gray">
                      วันใบอนุญาตหมดอายุ
                    </Typography>
                    <Typography variant="h6">
                      {displayDateTime(dataLicense?.expireLicenseDate)}
                    </Typography>
                  </Box>
                </Stack>
              </CardContentStyle>
            </CardStyle>
            <CardStyle>
              <CardContentStyle>
                <Box>
                  <Typography variant="body2" color="text.gray">
                    รหัสตัวแทน
                  </Typography>
                  <Typography variant="h6">
                    {dataLicense?.agentCode
                      ? formatAgentCode(dataLicense?.agentCode)
                      : '-'}
                  </Typography>
                </Box>
              </CardContentStyle>
            </CardStyle>
          </Box>
          <Box width={1} padding={2} paddingTop={1} textAlign="right">
            <Button variant="contained" onClick={closeModal}>
              <Typography variant="buttonm">รับทราบ</Typography>
            </Button>
          </Box>
        </>
      </Modal>

      {/* Edit Dialog */}
      <EditLicenseDialog
        isOpenModal={isEditOpen}
        closeModal={handleCloseEdit}
        fullFormUuid={fullFormUuid}
        dataLicense={{
          licenseNumber: dataLicense?.licenseNumber || '',
          startLicenseDate: dataLicense?.startLicenseDate || '',
          expireLicenseDate: dataLicense?.expireLicenseDate || '',
          agentCode: dataLicense?.agentCode || '',
        }} // กำหนด fallback เป็น string ว่าง
      />
    </>
  )
}
