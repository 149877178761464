import _ from "lodash"

export const checkPermissionOwner = ({ owner, user, creator, assignees }: any) => {
  if (owner === 'ALL') return true

  let hasPermission = false

  if (creator) {
    hasPermission = creator.uuid === user.uuid
  }

  if (!hasPermission) {
    if (assignees) {
      hasPermission = assignees.some(
        (assignee: any) => _.get(assignee, 'uuid', assignee.value) === user.uuid,
      )
    }
  }

  return hasPermission
}