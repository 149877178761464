// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const arrRequired: any[] = []
arrRequired[1] = [
  'main.invitedCode',
  'personal.title',
  'personal.firstName',
  'personal.lastName',
  'personal.firstNameEn',
  'personal.lastNameEn',
  'personal.gender',
  'personal.maritalStatus',

  'personal.mobileNo',
  'personal.email',

  'personal.idCard',
  'personal.nationality',
  'personal.bookbankKT',

  'personalAddress.isAddressCurrent',
  'personalAddress.houseNo',
  // 'personalAddress.village',
  // 'personalAddress.soi',
  // 'personalAddress.road',
  'personalAddress.provinceUuid',
  'personalAddress.amphurUuid',
  'personalAddress.tambonUuid',
  'personalAddress.zipcode',

  'personalAddress.addressType',
]
arrRequired[2] = [
  // 'work.companyName',
  // 'work.startYear',
  // 'work.endYear',
  // 'work.occupation',
  // 'work.salary',
  'work.experienceSaleInsurance',
  'personal.channel',
]
arrRequired[3] = ['tax.taxMaritalStatus']

arrRequired[4] = [
  'policy.conflictOfInterest',
  'policy.bankrupt',
  'policy.criminalCase',
  'policy.authorization',
  'policy.insuranceIntermediary',
  'policy.majorBusinessPartners',
]
export const arrRequiredAttachment = [
  'photo',
  'copyOfIdCard',
  'copyOfHouseRegistration',
  'copyOfSavingsBook',
]
export const subRequired = [
  {
    field: 'personal.maritalStatus',
    value: 'M',
    arrRequired: [
      'personalSpouse.title',
      'personalSpouse.fullName',
      'personalSpouse.dateOfBirth',
      'personalSpouse.idCard',
      'personalSpouse.occupation',
    ],
  },
  {
    field: 'personal.nationality',
    value: 'N',
    arrRequired: ['personal.nationalityOther'],
  },
  {
    field: 'personalAddress.isAddressCurrent',
    value: 'NO',
    arrRequired: [
      'personalAddress.regHouseNo',
      // 'personalAddress.regVillage',
      // 'personalAddress.regSoi',
      // 'personalAddress.regRoad',
      'personalAddress.regProvinceUuid',
      'personalAddress.regAmphurUuid',
      'personalAddress.regTambonUuid',
      'personalAddress.regZipcode',
    ],
  },
  // {
  //   field: 'work.occupation',
  //   value: 'O',
  //   arrRequired: ['work.occupationOther'],
  // },
  {
    field: 'policy.conflictOfInterest',
    value: 'YES',
    arrRequired: ['policy.conflictOfInterestInfo'],
  },
  {
    field: 'policy.bankrupt',
    value: 'YES',
    arrRequired: ['policy.bankruptInfo'],
  },
  {
    field: 'policy.criminalCase',
    value: 'YES',
    arrRequired: ['policy.criminalCaseInfo'],
  },
]
