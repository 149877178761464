import _ from 'lodash'
import { InitProfile } from '../../../../types/full-form'

export const getTitleNotOwner = (
  initialProfile: InitProfile | null,
  title = 'ยืนยัน'
) => {
  const user = JSON.parse(window?.localStorage?.getItem('user') as any)
  if (initialProfile?.contractorUuid !== user?.uuid) {
    return `งานนี้เป็นของ “${_.get(initialProfile, 'contractor', '-')}” คุณยืนยันที่จะดำเนินการต่อหรือไม่`
  } else {
    return title
  }
}
