import { Box, Typography, Avatar, IconButton } from '@mui/material'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { CustomCard } from '../../../../../../components/Layout/CustomCard'
import { SliceModel } from '../../../../../../redux/models'
import { AppDispatch } from '../../../../../../redux/store'
import EditIcon from '@mui/icons-material/Edit'
import ManagerLookup from '../../../../../../components/Lookup/ManagerLookup'
import { ManagerGrid } from '../../../../../../components/Lookup/ManagerLookup/slices/model'
import { handleConfirmAssign } from '../../Form/Assign/handler/confirmAssign'
import { setIsModalOpen } from '../../../../../../components/Lookup/ManagerLookup/slices'
import * as _ from 'lodash'
import { reAssignAccessStatus } from '../../../handler/validationStatus'

export default function AssignInfo() {
  const dispatch: AppDispatch = useDispatch()
  const { prospect, form, isLoading } = useSelector(
    (state: SliceModel) => ({
      prospect: state.prospect.detail.data,
      form: state.crud.formData,
      isLoading: state.prospect.detail.isLoading,
    }),
    shallowEqual
  )

  const canReAssignManager = _.includes(reAssignAccessStatus, prospect?.status)

  return (
    <CustomCard
      isLoading={isLoading}
      sx={{ height: '145px', alignContent: 'center' }}
    >
      <Box display="flex" gap={2}>
        <Box>
          <Avatar
            sx={{ width: '64px', height: '64px' }}
            src={'icons/profile-def.png'}
          />
        </Box>

        <Box display="flex" flexDirection="column" flexGrow={1}>
          <Typography variant="body1b">ผู้จัดการ</Typography>
          <Typography variant="body2" sx={{ color: 'text.gray' }}>
            {prospect?.managerName}
          </Typography>
          <Typography variant="body1b">
            รหัส {prospect?.managerCode}{' '}
            {canReAssignManager && (
              <IconButton
                size="small"
                onClick={() => dispatch(dispatch(setIsModalOpen(true)))}
              >
                <Typography variant="body2" color={'primary'} sx={{ mr: 1 }}>
                  เปลี่ยนผู้จัดการ{' '}
                </Typography>{' '}
                <EditIcon color={'primary'} />
              </IconButton>
            )}
          </Typography>
        </Box>
      </Box>
      <ManagerLookup
        uuid={prospect?.uuid ? prospect?.uuid : null}
        onConfirmClick={(row: ManagerGrid | undefined) =>
          dispatch(handleConfirmAssign(row, form))
        }
      />
    </CustomCard>
  )
}
