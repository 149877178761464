import CustomNotificationView from '../../components/CustomNotification/PageView'
import { StyledContainer } from '../../components/Styled'

const NotificationModule = () => {
  return (
    <StyledContainer>
      <CustomNotificationView />
    </StyledContainer>
  )
}

export default NotificationModule
