import Box from '@mui/material/Box'
import CircleIcon from '@mui/icons-material/Circle'
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded'
import { displayDateTime } from '../../utils/convertFormatDateTime'
import { DateFormat } from '../../constants/dateFormat'
import Typography from '@mui/material/Typography'
import { NotificationItem } from './slices/model'
import { dispatch } from '../../redux/store'
import { submitIsRead } from './handler/submitIsRead'
import _ from 'lodash'
import { getActionLink } from './handler/getActionLink'

export default function ItemView(props: { item: NotificationItem }) {
  const { item } = props
  const actionLink = getActionLink(item)

  const handleLink = async () => {
    if (!item.isRead) {
      await dispatch(submitIsRead(item.uuid))
    }
    window.location.href = actionLink
  }
  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        gap: 2,
        py: 2,
        px: 3,
        borderBottom: 1,
        borderColor: 'grey.grey200',
        ':hover': {
          backgroundColor: '#FAFAFA',
        },
      }}
      onClick={() => !item.isRead && dispatch(submitIsRead(item.uuid))}
    >
      <CircleIcon
        sx={{
          fontSize: '10px',
          mt: 0.5,
          visibility: item.isRead ? 'hidden' : 'visible',
        }}
        color={'warning'}
      />
      <Box sx={{ width: '100%' }}>
        <Typography variant={'body2'} color={'black'} mb={1}>
          {item.title}
        </Typography>
        <Typography variant={'body2'} color={'grey.grey600'}>
          {displayDateTime(item.createdAt, DateFormat.DDMMYYYYHHmm) + ` น.`}
        </Typography>
        {!_.isEmpty(actionLink) && (
          <Box
            display={'flex'}
            mt={1}
            gap={1}
            sx={{ cursor: 'pointer' }}
            alignItems={'center'}
            onClick={handleLink}
          >
            <Typography variant={'body2'} color={'primary'}>
              ดูรายละเอียด
            </Typography>
            <KeyboardArrowRightRoundedIcon color={'primary'} />
          </Box>
        )}
      </Box>
    </Box>
  )
}
