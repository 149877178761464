import { AppDispatch } from '../../../redux/store'
import { callUploadFile, callUploadFileBase64 } from '../services/thunk'
import { UploadBody } from '../types/upload'

export const handleUploadFile =
  (body: UploadBody) => async (dispatch: AppDispatch) => {
    let response
    if (
      (body.data.mimeType === 'image/jpeg' ||
        body.data.mimeType === 'image/png') &&
      body.data.base64
    ) {
      response = await dispatch(callUploadFileBase64(body))
    } else {
      response = await dispatch(callUploadFile(body))
    }
    const { payload } = response

    return payload
  }
