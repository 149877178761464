import { useSelector, shallowEqual } from 'react-redux'
import { SliceModel } from '../../../../../redux/models'
import { CompareState } from '../slices/model'

export const useCompare = () => {
  const { compare } = useSelector(
    (state: SliceModel) => ({
      compare: state.compare,
    }),
    shallowEqual
  )
  const entryData: CompareState = compare
  return entryData
}
