import { useCallback, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useAuth } from 'react-oidc-context'
import { useLocalStorage } from '@rehooks/local-storage'

import type { UserFromStorage } from '../types/user'
import { MenuItemType } from '../components/LeftMenu/LeftMenuData'
import * as _ from 'lodash'

export const useAuthGuard = (leftMenu: MenuItemType[]) => {
  const navigate = useNavigate()

  const [userFromStorage] = useLocalStorage<UserFromStorage>('authUser')

  const { pathname } = useLocation()

  const { isAuthenticated } = useAuth()

  const handleRedirect = useCallback(() => {
    if (!isAuthenticated && !userFromStorage?.access_token) {
      navigate('/')

      return
    }

    if (pathname === '/') {
      const redirectToPath = _.get(leftMenu, '[0].path', '/home')

      const reditctLink =
        leftMenu
          .find((menu) =>
            menu.list.some(
              (subMenu) => subMenu.path === '/application-verification/list'
            )
          )
          ?.list.find(
            (subMenu) => subMenu.path === '/application-verification/list'
          )?.path || redirectToPath

      navigate(reditctLink)
    }
  }, [
    isAuthenticated,
    navigate,
    pathname,
    userFromStorage?.access_token,
    leftMenu,
  ])

  useEffect(() => {
    handleRedirect()
  }, [handleRedirect])
}
