export interface ListingData {
  prospectUuid: string
}
export interface ListingState {
  isLoading: boolean
  items: ListingData[]
}

export const initialState: ListingState = {
  isLoading: false,
  items: [],
}
