import { APPLICATION_STATUS } from '../../../../../../constants/dc-admin'

export const statusIcon = {
  [APPLICATION_STATUS.PENDING]: 'icon-calendar',
  [APPLICATION_STATUS.PROCESS]: 'icon-report',
  [APPLICATION_STATUS.MEMO]: 'icon-warning-document',
  [APPLICATION_STATUS.REQUEST_DESCISION]: 'icon-certificate',
  [APPLICATION_STATUS.OIC]: 'icon-mail-pending',
  [APPLICATION_STATUS.RCMS]: 'icon-license',
  [APPLICATION_STATUS.SUCCESS]: 'icon-receive-success',
}
