import { ProcessResultApprovalStatus } from '../../../../constants/status'

export const reAssignAccessStatus = [
  // ProcessResultApprovalStatus.DL_SUBMITTED_SHORT_FORM,
  ProcessResultApprovalStatus.DL_SUCCESSFULLY_SENT_ASSIGN_RULE,
  ProcessResultApprovalStatus.DL_ASSIGNED_MA,
  ProcessResultApprovalStatus.DL_DROPPED,
  ProcessResultApprovalStatus.DL_CHANGED,
  ProcessResultApprovalStatus.I_ACCEPTED_INTERVIEW,
  ProcessResultApprovalStatus.I_DECLINED_INTERVIEW,
  ProcessResultApprovalStatus.I_FAILED_INTERVIEW,
  ProcessResultApprovalStatus.I_FAILED_OUT_OF_LIMIT,
  ProcessResultApprovalStatus.I_TIME_OUT_OF_LIMIT,
]
