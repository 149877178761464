import { useSelector, shallowEqual } from 'react-redux'
import { SliceModel } from '../../../../redux/models'
import { HistoryAudit } from '../slices/model'

export const useHistoryAudit = () => {
  const { data } = useSelector(
    (state: SliceModel) => ({
      data: state.dcAdmin.history.audit,
    }),
    shallowEqual
  )
  const entryData: HistoryAudit = data
  return entryData
}
