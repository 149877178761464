import React from 'react'
import {
  StyledCard,
  StyledContainer,
  StyledContent,
  StyledHeader,
} from '../../../Styled'
import Table from '../../../Table'
import { CContainerProps } from './types/cContainerProps'

export const CContainer: React.FC<CContainerProps> = ({
  header,
  drawer,
  content,
  headerContent,
}) => {
  return (
    <StyledContainer>
      <StyledContent isLoading={false}>
        <StyledHeader>{header}</StyledHeader>
        {headerContent}
        <StyledCard id="list-table" isLoading={false}>
          {content}

          {/* TABLE SECTION */}
          <Table />
        </StyledCard>
        {drawer}
      </StyledContent>
    </StyledContainer>
  )
}
