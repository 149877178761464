import { useEffect, useMemo, useState, useCallback } from 'react'
import Modal from 'react-modal'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import useTheme from '@mui/material/styles/useTheme'
import { ApiStatus } from '../../../../constants/status'
import { DIALOG_TYPE_STRING } from '../../../../components/dialog/constants'
import { openCustomDialog } from '../../../../components/CustomDialog/slices'
import { useDispatch, useSelector } from 'react-redux'
import { selectCrudFormData } from '../../../../redux/selector'
import { callEditLicenseAgent } from '../services/thunk'
import { AppDispatch } from '../../../../redux/store'
import { setFormInit } from '../../../../components/CRUD/slices'
import dayjs from 'dayjs'
import { CustomDate } from '../../../../components/Input/CustomDate'
import CustomTextfield from '../../../../components/Input/CustomTextfield'
import { CustomLoadingButton } from '../../../../components/Input/CustomButton'

Modal.setAppElement('#root')

type Props = {
  isOpenModal: boolean
  closeModal: VoidFunction
  fullFormUuid: string
  dataLicense: {
    licenseNumber: string
    startLicenseDate: string
    expireLicenseDate: string
    agentCode: string | null
  }
}

export default function EditLicenseDialog({
  isOpenModal,
  closeModal,
  fullFormUuid,
  dataLicense,
}: Readonly<Props>) {
  const theme = useTheme()
  const dispatch: AppDispatch = useDispatch()
  const formData = useSelector(selectCrudFormData)
  const [isLoading, setIsLoading] = useState(false)

  // ใช้ dataLicense ถ้า formData ไม่มีค่า
  const fallbackData = useMemo(
    () => ({
      licenseNumber: dataLicense.licenseNumber || '',
      startLicenseDate: dataLicense.startLicenseDate || '',
      expireLicenseDate: dataLicense.expireLicenseDate || '',
      agentCode: dataLicense.agentCode || '',
    }),
    [dataLicense]
  )

  const initializeFormData = useCallback(() => {
    dispatch(
      setFormInit({
        formData: fallbackData,
      })
    )
  }, [dispatch, fallbackData])

  useEffect(() => {
    if (!formData || Object.keys(formData).length === 0) {
      initializeFormData()
    }
  }, [formData, initializeFormData])

  const handleCancel = () => {
    dispatch(setFormInit({ formData: fallbackData })) // รีเซ็ตค่า
    closeModal() // ปิด Modal
  }

  const handleSave = async () => {
    setIsLoading(true)
    const { licenseNumber, startLicenseDate, expireLicenseDate, agentCode } =
      formData

    const { payload } = await dispatch(
      callEditLicenseAgent({
        fullFormUuid,
        licenseNumber,
        startLicenseDate,
        expireLicenseDate,
        agentCode,
      })
    )

    setIsLoading(false)

    if (payload?.status && payload?.status !== ApiStatus.ERROR) {
      dispatch(
        openCustomDialog({
          type: DIALOG_TYPE_STRING.SUCCESS,
          title: 'สำเร็จ',
          message: 'บันทึกข้อมูลเรียบร้อยแล้ว',
          handleOK: () => {
            window.location.reload()
          },
        })
      )
      closeModal()
    } else {
      dispatch(
        openCustomDialog({
          type: DIALOG_TYPE_STRING.ERROR,
          title: 'เกิดข้อผิดพลาด',
          message: 'ไม่สามารถบันทึกข้อมูลได้ กรุณาลองใหม่อีกครั้ง',
        })
      )
    }
  }

  return (
    <Modal
      isOpen={isOpenModal}
      onRequestClose={closeModal}
      contentLabel="Edit license and agent code"
      style={{
        overlay: {
          backgroundColor: `${theme.palette.common.black}BF`,
          zIndex: 1200,
        },
        content: {
          maxWidth: '678px',
          width: '100%',
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          padding: 0,
          backgroundColor: theme.palette.background.paper,
          borderRadius: '8px',
          boxShadow: `0px 9px 46px 8px ${theme.palette.common.black}1F,
                          0px 24px 38px 3px ${theme.palette.common.black}24,
                          0px 11px 15px -7px ${theme.palette.common.black}33`,
        },
      }}
    >
      <>
        <Box padding={3}>
          <Typography variant="h6" mb={2}>
            แก้ไขเลขที่ใบอนุญาต และ รหัสตัวแทน
          </Typography>
          <Stack direction="column" spacing={2}>
            {/* License Number */}
            <CustomTextfield
              required
              label="เลขที่ใบอนุญาต"
              crud="licenseNumber"
              placeholder="เลขที่ใบอนุญาต"
              maxLength={10}
              valueType="number"
              fullWidth
              disabled={isLoading}
            />

            {/* Start License Date and Expire License Date */}
            <Stack direction="row" alignItems="center" gap={2}>
              <Box flex={1}>
                <CustomDate
                  label="วันออกใบอนุญาต"
                  crud="startLicenseDate"
                  placeholder="วว/ดด/ปปปป"
                  maxDate={
                    dayjs(formData.expireLicenseDate) || dayjs().add(10, 'year')
                  } // จำกัดวันที่มากกว่า expire
                  required
                  disabled={isLoading}
                />
              </Box>
              <Box flex={1}>
                <CustomDate
                  label="วันใบอนุญาตหมดอายุ"
                  crud="expireLicenseDate"
                  placeholder="วว/ดด/ปปปป"
                  minDate={dayjs(formData.startLicenseDate)}
                  maxDate={dayjs().add(10, 'year')}
                  required
                  disabled={isLoading}
                />
              </Box>
            </Stack>

            {/* Agent Code */}
            {dataLicense.agentCode && (
              <CustomTextfield
                required
                label="รหัสตัวแทน"
                crud="agentCode"
                maxLength={14}
                valueType="number"
                fullWidth
                disabled={isLoading}
              />
            )}
          </Stack>
        </Box>
        <Box width={1} padding={2} paddingTop={1} textAlign="right">
          <Button
            variant="outlined"
            onClick={handleCancel}
            sx={{ marginRight: theme.spacing(2) }}
            disabled={isLoading}
          >
            ยกเลิก
          </Button>
          <CustomLoadingButton
            loading={isLoading}
            onClick={handleSave}
            disabled={isLoading}
          >
            ยืนยัน
          </CustomLoadingButton>
        </Box>
      </>
    </Modal>
  )
}
