import _ from 'lodash'
import { META } from '../../../constants/webMeta'
import { CSSProperties } from 'react'
import Box from '@mui/material/Box'
import { SxProps } from '@mui/material'
const CustomImage = (props: {
  src: string
  srcXs?: string
  sx?: SxProps
  alt?: string
  width?: string
  height?: string
  style?: CSSProperties
  align?: 'center' | 'left' | 'right'
}) => {
  const alt = _.isEmpty(props.alt) ? META.ALT : props.alt

  /*
    let style = {}
    if (props.align === 'center') {
      style = {
        display: 'block',
        margin: 'auto',
      }
    } else if (props.align === 'right') {
      style = {
        display: 'block',
        float: 'right',
      }
    } else {
      style = {
        display: 'block',
        float: 'left',
      }
    }
    <img
      style={{ ...style, ...props.style }}
      src={props.src}
      alt={alt}
      width={props?.width || '100%'}
      height={props?.height || 'auto'}
    />
    */
  return (
    <>
      <Box
        alt={alt}
        component="img"
        sx={{
          display: {
            xs: _.get(props, 'srcXs', null) !== null ? 'none' : 'block',
            sm: 'block',
          },
          width: props?.width || '100%',
          height: props?.height || 'auto',
          m: 'auto',
          ...props?.sx,
        }}
        src={props?.src}
      />
      {_.get(props, 'srcXs', null) !== null && (
        <Box
          alt={alt}
          component="img"
          sx={{
            display: { xs: 'block', sm: 'none' },
            width: props?.width || '100%',
            height: props?.height || 'auto',
            m: 'auto',
            ...props?.sx,
          }}
          src={props?.srcXs}
        />
      )}
    </>
  )
}
export default CustomImage
