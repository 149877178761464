import { ProspectDetail } from '../../type/types'
import { FormData } from '../../../../../components/CRUD/types/CrudState'

export interface ProspectDetailState {
  isLoading: boolean
  data: ProspectDetail | null
  fullForm: FormData | null
}
export const initialState: ProspectDetailState = {
  isLoading: false,
  data: null,
  fullForm: null,
}

export interface EntryFullFormData {
  per: string
}

export interface FullFormMemo {
  uuid: string
  managerUuid: string
  prospectUuid: string
  status: string
  personalInfo: {
    uuid: string
    title: string
    firstName: string
    lastName: string
    gender: string
    dateOfBirth: string
    mobileNo: string
  }
  memo: MemoData[]
}

export interface MemoData {
  createdAt: string
  updatedAt: string
  uuid: string
  status: string
  reason: string
  reasonSection: string
  reasonForMA: string
  reasonForProspect: string
  detail: string
}
