import dayjs, { Dayjs } from 'dayjs'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import 'dayjs/locale/th'
import { SxProps } from '@mui/material'
import InputAdornment from '@mui/material/InputAdornment'
import { useState } from 'react'
import InsertInvitationIcon from '@mui/icons-material/InsertInvitation'
import { dispatch } from '../../../redux/store'
import { setFieldValue } from '../../CRUD/slices'
import _ from 'lodash'
import { useInputField } from '../../CRUD/hooks/useInputField'

class AdapterDayjsBuddhist extends AdapterDayjs {
  formatByString = (date: Dayjs, formatString: string): string => {
    const buddhistYear = date.year() + 543
    return date.format(formatString.replace('YYYY', buddhistYear.toString()))
  }
}
export type DayjsTZ = string | Dayjs | null
export type DateView = 'year' | 'month' | 'day'
export type TimeView = 'hours' | 'minutes' | 'seconds'
export type DateOrTimeView = DateView | TimeView
export interface CustomDateProps {
  id?: string
  crud?: string
  value?: string | null
  boxSx?: SxProps
  disabled?: boolean
  name?: string
  label: string
  handleExtraChange?: (event: DayjsTZ) => void
  handleChange?: (event: DayjsTZ) => void
  placeholder?: string
  required?: boolean
  hideTextError?: boolean
  labelVariant?: any
  format?: string
  openTo?: DateView
  views?: DateView[]
  valueType?: string
  minDate?: Dayjs
  maxDate?: Dayjs
  readOnly?: boolean
}
export const CustomDate = (props: CustomDateProps) => {
  const {
    crud,
    label,
    disabled,
    required,
    format,
    openTo,
    views,
    minDate = dayjs('1900-01-01'),
    maxDate = dayjs(),
    readOnly = true,
  } = props
  const [open, setOpen] = useState(false)
  const isError = false

  const handleChangeTZ = (e: Dayjs | null) => {
    const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
    const dateTime = dayjs(e).tz(localTimeZone).format()

    if (crud) {
      dispatch(
        setFieldValue({
          key: `formData.${crud}`,
          value: e,
        })
      )
    } else {
      props.handleChange && props.handleChange(dateTime)
    }
  }

  const isCrud = !!(!_.isEmpty(props.crud) && !_.isUndefined(props.crud))
  const name = isCrud ? crud : _.get(props, 'name', 'def')

  const nameValue = isCrud ? _.get(props, 'crud', '') : _.get(props, 'name', '')
  const { value } = useInputField(nameValue)

  const _inputValue = value ?? ''

  const xValue = isCrud ? _inputValue : ''

  const parsedValue = xValue ? dayjs(xValue) : null

  return (
    <Box id={`Box-${name}`}>
      <Box sx={{ display: 'flex', flexDirection: 'row', mb: 1 }}>
        <Typography
          variant={'body2'}
          display={'flex'}
          color={disabled ? 'text.gray' : 'text.black'}
        >
          {label}{' '}
          {required && (
            <Typography sx={{ ml: 1 }} variant="body2" color={'error'}>
              *
            </Typography>
          )}
        </Typography>
      </Box>
      <LocalizationProvider
        dateAdapter={AdapterDayjsBuddhist}
        adapterLocale={'th'}
      >
        <DatePicker
          open={open}
          format={format || 'DD/MM/YYYY'}
          value={parsedValue}
          onClose={() => setOpen(false)}
          minDate={minDate}
          maxDate={maxDate}
          openTo={openTo}
          views={views}
          disabled={disabled}
          onChange={(e) => handleChangeTZ(e)}
          sx={{
            width: '100%',
            '.MuiOutlinedInput-root': {
              backgroundColor: '#FFFFFF !important',
            },
            '.MuiOutlinedInput-notchedOutline': {
              borderColor: isError ? 'error.main' : '',
            },
            '.Mui-disabled': {
              backgroundColor: readOnly ? '#FFFFFF !important' : '',
            },
          }}
          slotProps={{
            textField: {
              onClick: () => readOnly && setOpen(true),
              InputProps: {
                endAdornment: (
                  <InputAdornment
                    position="end"
                    onClick={() => setOpen(true)}
                    sx={{ cursor: 'pointer' }}
                  >
                    <InsertInvitationIcon color="primary" />
                  </InputAdornment>
                ),
              },
            },
          }}
        />
      </LocalizationProvider>
    </Box>
  )
}
