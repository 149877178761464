export const arrRequiredAttachment: Record<string, string> = {
  photo: 'รูปถ่ายสีขนาด 1 นิ้ว',
  copyOfIdCard: 'สำเนาบัตรประจำตัว ประชาชน หรือ บัตรข้าราชการ',
  copyOfHouseRegistration: 'สําเนาทะเบียนบ้าน',
  copyOfSavingsBook: 'สําเนาหน้าบัญชีสมุดคู่ฝากออมทรัพย์ธนาคารกรุงไทยทุกสาขา',
  copyOfMarriageCertificate: 'ทะเบียนสมรสกรณีผู้สมัครอายุต่ำกว่า 20 ปีบริบูรณ์',
  copyOfNameChange:
    'ในกรณีที่มีการเปลี่ยน ชื่อ-นามสกุล ใช้สําเนาหนังสือสําคัญการเปลี่ยนชื่อ-นามสกุล',
  copyOfExaminationIdCard:
    'บัตรประจําตัวสอบ หรือ แจ้งวันเดือนปีที่สอบ, สถานที่สอบ, เลขที่สอบที่ตัวแทนสอบผ่าน',
  copyOfTrainingCertificate:
    'สําเนาหนังสือรับรองการอบรมหลักสูตรการขอรับใบอนุญาตเป็นตัวแทนประกันชีวิต',
  slipPayment: 'ใบเสร็จชำระเงิน',
}

export const attachmentTooltip: Record<string, string> = {
  photo: 'รูปหน้าตรง ไม่สวมแว่นตาดำ ฉากหลังภาพสีพื้น ถ่ายไว้ไม่เกิน 6 เดือน',
  copyOfIdCard:
    'ตัวแทนเซ็นชื่อรับรองสําเนาถูกต้อง หรือถ่ายรูปจากบัตรตัวจริงที่ยังมีผลบังคับ, เห็นรูปหน้าชัดเจน หน้าไม่ดํา',
  copyOfHouseRegistration:
    'ทะเบียนบ้านแบบใหม่ให้ส่งสําเนาที่มีเลขที่บ้านและหน้าที่มีชื่อตัวแทนควบคู่เสมอ,ทะเบียนบ้านแบบเก่าให้ถ่ายสําเนาชัดเจน/เต็มแผ่น, ไม่มีการย้ายออกกรณีมีการขีดฆ่าแก้ไขชื่อ/นามสกุลของตัวแทน และไม่มีใบเปลี่ยนชื่อ/นามสกุล ให้ถ่ายสําเนาด้านหลังทะเบียนบ้านที่ระบุว่ามีการแก้ไขชื่อ/นามสกุล, ตัวแทนเซ็นชื่อรับรองสําเนาถูกต้อง หรือถ่ายรูปจากทะเบียนบ้านตัวจริง',
  copyOfSavingsBook:
    'ตัวแทนเซ็นชื่อรับรองสําเนาถูกต้อง หรือถ่ายรูปจากเอกสารตัวจริง',
  copyOfMarriageCertificate: 'ทะเบียนสมรสกรณีผู้สมัครอายุต่ำกว่า 20 ปีบริบูรณ์',
  copyOfNameChange:
    'สําเนาการเปลี่ยนชื่อที่ได้รับการรับรองจากตัวแทน หรือถ่ายรูปจากเอกสารตัวจริง',
  copyOfExaminationIdCard:
    'บัตรประจําตัวสอบ หรือ แจ้งวันเดือนปีที่สอบ, สถานที่สอบ, เลขที่สอบที่ตัวแทนสอบผ่าน',
  copyOfTrainingCertificate:
    'สําเนาใบรับรองการฝึกอบรมที่ได้รับการรับรองจากตัวแทน หรือถ่ายรูปจากเอกสารตัวจริง',
}
