import { Box, Typography } from '@mui/material'
import CompareTableItem, { CompareTableCol } from './CompareTableItem'
import HeaderActions from '../step/HeaderActions'

export const TableEducationWork = () => {
  const educationRows: CompareTableCol[] = [
    {
      fieldTitle: 'การศึกษาสูงสุด',
      fieldName: 'personal.highestEducation',
    },
  ]
  const arrEducationRows = []
  for (let i = 0; i < 3; i++) {
    const educationList: CompareTableCol[] = [
      {
        fieldTitle: 'ประวัติการศึกษา',
        fieldName: `educations[${i}].educationHistory`,
      },
      {
        fieldTitle: 'จากปี',
        fieldName: `educations[${i}].startYear`,
        other: 'year',
      },
      {
        fieldTitle: 'ถึงปี',
        fieldName: `educations[${i}].endYear`,
        other: 'year',
      },
      {
        fieldTitle: 'สำเร็จขั้นสูงสุด',
        fieldName: `educations[${i}].graduation`,
      },
      {
        fieldTitle: 'ประกาศนียบัตร',
        fieldName: `educations[${i}].diploma`,
      },
    ]
    arrEducationRows.push(educationList)
  }
  const languageRows: CompareTableCol[] = [
    {
      fieldTitle: 'ท่านพูดภาษาใดได้บ้าง',
      fieldName: 'personal.language1',
    },
    {
      fieldTitle: '',
      fieldName: 'personal.language2',
    },
    {
      fieldTitle: '',
      fieldName: 'personal.language3',
    },
  ]
  const workRows: CompareTableCol[] = [
    {
      fieldTitle: 'ประวัติการทำงานปัจจุบัน ชื่อนายจ้าง',
      fieldName: 'work.companyName',
    },
    {
      fieldTitle: 'จากปี',
      fieldName: 'work.startYear',
      other: 'year',
    },
    {
      fieldTitle: 'ถึงปี',
      fieldName: 'work.endYear',
      other: 'year',
    },
    {
      fieldTitle: 'อาชีพ',
      fieldName: 'work.occupation',
    },
    {
      fieldTitle: '- อาชีพ อื่น ๆ',
      fieldName: 'work.occupationOther',
    },
    {
      fieldTitle: 'รายได้เฉลี่ย',
      fieldName: 'work.salary',
    },
  ]
  const chanalRows: CompareTableCol[] = [
    {
      fieldTitle: 'ช่องทางการสมัครเข้ามาเป็นตัวแทน',
      fieldName: 'personal.channel',
    },
    {
      fieldTitle: 'ประสบการณ์ด้านการทำงานหรือการขาย',
      fieldName: 'work.experienceSaleInsurance',
    },
    {
      fieldTitle: 'ท่านคิดว่าจะยึดอาชีพขายประกันชีวิตเป็นงาน',
      fieldName: 'work.workTypeInsurance',
    },
  ]
  const otherRows: CompareTableCol[] = [
    {
      fieldTitle: 'บุคคลที่จะติดต่อด้วยกรณีจำเป็น',
      fieldName: 'personal.contactPersonName',
    },
    {
      fieldTitle: 'เบอร์โทรศัพท์',
      fieldName: 'personal.contactPersonMobile',
    },
    {
      fieldTitle: 'ที่อยู่',
      fieldName: 'personal.contactPersonAddress',
    },
  ]
  const agentRelativeRows: CompareTableCol[] = [
    {
      fieldTitle:
        'ท่านมีบุคคลในครอบครัวและ/หรือญาติเป็นตัวแทนประกันชีวิตหรือพนักงานบริษัท กรุงไทย-แอกซ่า ประกันชีวิต จํากัด (มหาชน) หรือบริษัทประกันชีวิตอื่นหรือไม่ถ้ามีโปรดให้รายละเอียด',
      fieldName: 'personalAssets.agentRelative',
    },
  ]
  const arrAgentRelativRows = []
  for (let i = 0; i < 2; i++) {
    const agentRelativeList: CompareTableCol[] = [
      {
        fieldTitle: 'ชื่อ นามสกุล',
        fieldName: `agentRelatives[${i}].fullName`,
      },
      {
        fieldTitle: 'ความสัมพันธ์',
        fieldName: `agentRelatives[${i}].relation`,
      },
      {
        fieldTitle: 'บริษัท',
        fieldName: `agentRelatives[${i}].company`,
      },
      {
        fieldTitle: 'ตำแหน่งหน้าที่',
        fieldName: `agentRelatives[${i}].position`,
      },
      {
        fieldTitle: 'ชื่อหน่วย',
        fieldName: `agentRelatives[${i}].department`,
      },
      {
        fieldTitle: 'รหัสตัวแทน',
        fieldName: `agentRelatives[${i}].agentCode`,
      },
    ]
    arrAgentRelativRows.push(agentRelativeList)
  }
  const insuranceRows: CompareTableCol[] = [
    {
      fieldTitle:
        'ท่านมีประกันชีวิตกับบริษัท กรุงไทย-แอกซ่า ประกันชีวิต จำกัดมหาชน (มีหรือไม่)',
      fieldName: 'personalAssets.insuranceKTAXA',
    },
    {
      fieldTitle: '- เบื้ยประกัน',
      fieldName: 'personalAssets.insurancePremium',
    },
    {
      fieldTitle: 'ท่านมีประกันชีวิตกับบริษัทประกันชีวิตอื่น ๆ หรือไม่',
      fieldName: 'personalAssets.insuranceOtherName',
    },
    {
      fieldTitle: '- ชื่อบริษัท',
      fieldName: 'personalAssets.insuranceOtherName',
    },
    {
      fieldTitle: '- เบื้ยประกัน',
      fieldName: 'personalAssets.insuranceOtherPremium',
    },
  ]
  const refPersonRows: CompareTableCol[] = [
    {
      fieldTitle: 'ชื่อ นามสกุล',
      fieldName: 'personContact.fullName',
    },
    {
      fieldTitle: 'โทรศัพท์',
      fieldName: 'personContact.mobileNo',
    },
    {
      fieldTitle: 'ที่อยู่',
      fieldName: 'personContact.address',
    },
    {
      fieldTitle: 'อาชีพ',
      fieldName: 'personContact.occupation',
    },
    {
      fieldTitle: 'รู้จักมากี่ปี',
      fieldName: 'personContact.knownForYear',
    },
  ]
  const assetOtherRows: CompareTableCol[] = [
    {
      fieldTitle: 'ท่านมีทรัพย์สินอื่นหรือไม่',
      fieldName: 'personalAssets.assetEtc',
    },
    {
      fieldTitle: '- ทรัพย์สินอื่น ๆ',
      fieldName: 'personalAssets.assetEtcInfo',
    },
    {
      fieldTitle: 'ท่านมียานพาหนะหรือไม่',
      fieldName: 'personalAssets.vehicle',
    },
    {
      fieldTitle: '- ยานพาหนะ',
      fieldName: 'personalAssets.vehicleInfo',
    },
    {
      fieldTitle: 'ท่านมีประกันยานพาหนะหรือไม่',
      fieldName: 'personalAssets.insuranceVehicle',
    },
    {
      fieldTitle: '- ประกันยานพาหนะ',
      fieldName: 'personalAssets.insuranceVehicleInfo',
    },
  ]
  return (
    <>
      <HeaderActions titleStep="Education-Work" />
      <Typography variant="h6" mb={2}>
        ประวัติการศึกษา
      </Typography>
      <CompareTableItem sx={{ mb: 4 }} rows={educationRows} showCompare />
      {arrEducationRows.map((rows, key) => (
        <Box key={key}>
          <Typography variant="body2b" mb={2}>
            ลำดับที่ {key + 1}
          </Typography>
          <CompareTableItem sx={{ mb: 8 }} rows={rows} hideHeader />
        </Box>
      ))}
      <CompareTableItem sx={{ mb: 4 }} rows={languageRows} hideHeader />
      <Typography variant="h6" mb={2}>
        ประสบการณ์การทำงาน
      </Typography>
      <CompareTableItem sx={{ mb: 4 }} rows={workRows} hideHeader />
      <Typography variant="h6" mb={2}>
        ช่องทางการสมัครเข้ามาเป็นตัวแทน
      </Typography>
      <CompareTableItem sx={{ mb: 4 }} rows={chanalRows} hideHeader />
      <Typography variant="h6" mb={2}>
        รายละเอียดอื่น ๆ
      </Typography>
      <CompareTableItem sx={{ mb: 4 }} rows={otherRows} hideHeader />
      <Typography variant="h6" mb={2}>
        ประกันชีวิต
      </Typography>
      <CompareTableItem sx={{ mb: 4 }} rows={agentRelativeRows} hideHeader />
      {arrAgentRelativRows.map((rows, key) => (
        <Box key={key}>
          <Typography variant="body2b" mb={2}>
            ลำดับที่ {key + 1}
          </Typography>
          <CompareTableItem sx={{ mb: 8 }} rows={rows} hideHeader />
        </Box>
      ))}
      <CompareTableItem sx={{ mb: 4 }} rows={insuranceRows} hideHeader />
      <Typography variant="h6">บุคคลอ้างอิง</Typography>
      <Typography variant="body2" mb={2}>
        โปรดบอกชื่อผู้ที่รู้จักท่านดี มา 1 ท่าน (ไม่ใช่ญาติ)
        ที่บริษัทสามารถสอบถามได้
      </Typography>
      <CompareTableItem sx={{ mb: 4 }} rows={refPersonRows} hideHeader />

      <Typography variant="h6">อื่น ๆ</Typography>
      <CompareTableItem sx={{ mb: 4 }} rows={assetOtherRows} hideHeader />
    </>
  )
}
