import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { setReceiveBank } from '../../../slices/receive-payment'
import { useDispatch } from 'react-redux'

interface Bank {
  bank_code: string
  bank_name: string
  short_name: string
}

const banks: Bank[] = [
  { bank_code: '002', bank_name: 'ธนาคารกรุงเทพ', short_name: 'BBL' },
  { bank_code: '004', bank_name: 'ธนาคารกสิกรไทย', short_name: 'KBank' },
  { bank_code: '006', bank_name: 'ธนาคารกรุงไทย', short_name: 'KTB' },
  { bank_code: '011', bank_name: 'ธนาคารทหารไทยธนชาต', short_name: 'TTB' },
  { bank_code: '014', bank_name: 'ธนาคารไทยพาณิชย์', short_name: 'SCB' },
  { bank_code: '025', bank_name: 'ธนาคารกรุงศรีอยุธยา', short_name: 'BAY' },
  { bank_code: '022', bank_name: 'ธนาคารซีไอเอ็มบีไทย', short_name: 'CIMB' },
  { bank_code: '069', bank_name: 'ธนาคารเกียรตินาคินภัทร', short_name: 'KKP' },
  { bank_code: '065', bank_name: 'ธนาคารทิสโก้', short_name: 'TISCO' },
  { bank_code: '071', bank_name: 'ธนาคารยูโอบี', short_name: 'UOB' },
]

export default function ReceiveBank() {
  const dispatch = useDispatch()

  return (
    <div>
      <Box sx={{ display: 'flex', flexDirection: 'row', mb: 1 }}>
        <Typography variant={'body2'} color={'text.secondary'}>
          ธนาคาร
        </Typography>

        <Typography sx={{ lineHeight: 1, ml: 0.5 }} color={'error'}>
          *
        </Typography>
      </Box>

      <Autocomplete
        options={banks}
        getOptionLabel={(option: Bank) => option.bank_name}
        onChange={(_, newValue: Bank | null) => {
          dispatch(setReceiveBank(newValue!.bank_name))
        }}
        renderInput={(params) => (
          <TextField {...params} label="" placeholder="เลือกธนาคาร" />
        )}
      />
    </div>
  )
}
