export interface HistoryAuditData {
  uuid: string
  sectionName: string
  reason: string
  detail: string
  currectData: string
  operator: string
  status: string
}
export interface HistoryProcessData {
  uuid: string
  sectionName: string
  reason: string
  detail: string
  currectData: string
  operator: string
  status: string
}
export interface HistoryState {
  audit: HistoryAudit
  process: HistoryProcess
}
export interface HistoryAudit {
  isLoading: boolean
  items: HistoryAuditData[]
}
export interface HistoryProcess {
  isLoading: boolean
  items: HistoryProcessData[]
}
export const initialState: HistoryState = {
  audit: {
    isLoading: false,
    items: [],
  },
  process: {
    isLoading: false,
    items: [],
  },
}
