import { createAsyncThunk } from '@reduxjs/toolkit'
import { callAPI } from '../../../utils/callAPI'
import { BodyIsRead, BodyNotification } from '../types'
import { API_URL } from '../../../constants/envConfig'

export const callInit = createAsyncThunk(
  'admin/get-notification',
  async (body: BodyNotification, { rejectWithValue }) => {
    try {
      const response = await callAPI({
        url: `${API_URL}/notification`,
        method: 'post',
        body: body,
      })
      return response
    } catch (err) {
      return rejectWithValue(null)
    }
  }
)
export const callIsRead = createAsyncThunk(
  'admin/post-notification-is-read',
  async (body: BodyIsRead, { rejectWithValue }) => {
    try {
      const response = await callAPI({
        url: `${API_URL}/notification/is-read`,
        method: 'put',
        body: body,
      })
      return response
    } catch (err) {
      return rejectWithValue(null)
    }
  }
)
