import { TextField } from '@mui/material'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { useCallback, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import debounce from 'lodash/debounce'
import { setReceiveRemark } from '../../../slices/receive-payment'

type DebouncedFunction = {
  (value: string): void
  cancel: () => void
}

export default function ReceiveRemark() {
  const dispatch = useDispatch()
  const debounceRef = useRef<DebouncedFunction>()
  useEffect(() => {
    debounceRef.current = debounce((value: string) => {
      dispatch(setReceiveRemark(value))
    }, 300) as DebouncedFunction

    return () => {
      const currentDebounce = debounceRef.current
      currentDebounce?.cancel()
    }
  }, [dispatch])

  const handleRemarkChange = useCallback((value: string) => {
    debounceRef.current?.(value)
  }, [])

  return (
    <div>
      <Box sx={{ display: 'flex', flexDirection: 'row', mb: 1 }}>
        <Typography variant={'body2'} color={'text.secondary'}>
          หมายเหตุ
        </Typography>
      </Box>

      <TextField
        placeholder="หมายเหตุ"
        InputProps={{
          style: {
            padding: 1,
          },
        }}
        sx={{
          '& .MuiInputBase-input': {
            padding: 1,
          },
          width: '100%',
        }}
        multiline
        rows={2}
        onChange={(e) => handleRemarkChange(e.target.value)}
      />
    </div>
  )
}
