import Modal from 'react-modal'
import { useEffect, useState } from 'react'
import isEmpty from 'lodash/isEmpty'
// mui
import useTheme from '@mui/material/styles/useTheme'
import styled from '@mui/material/styles/styled'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import LoadingButton from '@mui/lab/LoadingButton'
import Button from '@mui/material/Button'
import { ListProps } from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import { Typography } from '@mui/material'
import SaveIcon from '@mui/icons-material/Save'
// redux
import { useDispatch, useSelector } from '../../../redux/store'
import {
  selectCrudFormData,
  selectBackgroundReview,
  selectInitialProfile,
  selectProfileDetails,
} from '../../../redux/selector'
import {
  getCheckHistory,
  startLoading,
  endLoading,
  getProfileDetails,
} from '../slices/applicant-details'
import {
  replaceFieldError,
  setAddFormErrors,
  setFieldValue,
} from '../../../components/CRUD/slices'
// components
import Dropdown, {
  convertAnyItemToOption,
} from '../../../components/Input/Dropdown'
import { CustomInput } from '../../../components/CRUD/components/CustomInput'
import CustomRadioGroup from '../../../components/Input/CustomRadioGroup'
import { CustomDateInput } from '../../../components/CRUD/components/CustomDateInput'
//
import { ListStyle } from './styled'
import {
  backgroundReviewOptions,
  formerAgentAXAOptions,
  hasLicenseOptions,
  levelOptions,
  roleOptions,
  variousHistoryLabel,
  type AuditInfoForm,
} from './model/background-review-options'

import _ from 'lodash'
import {
  closeCustomDialog,
  openCustomDialog,
  setCustomDialogIsLoading,
} from '../../../components/CustomDialog/slices'
import { DIALOG_TYPE_STRING } from '../../../components/dialog/constants'
import { FormRequestDecision } from './FormRequestDecision'
import {
  ApiStatus,
  ProcessResultApprovalStatus,
} from '../../../constants/status'
import {
  callUpdateAuditInfo,
  callUpdateVariousHistoryStatus,
  callVerifyBackgroundByDC,
} from './services/thunk'
import { CustomLoadingButton } from '../../../components/Input/CustomButton'
import CustomDropdown from '../../../components/Input/CustomDropdown'
import CustomTextfield from '../../../components/Input/CustomTextfield'
import { handleGetOptions } from './FormRequestDecision/handler/handleGetSRAM'
import { getValueStr } from '../../../utils/getValue'
import { getTitleNotOwner } from './handler/getTitleNotOwner'
import { formatAgentCode } from '../../../utils/formatAgentCode'
import useLocalStorage from '@rehooks/local-storage'
import { permissionItem } from '../../../constants/permission'

const WrapperStyle = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(3),
  borderRadius: '8px',
  backgroundColor: theme.palette.common.white,
  padding: theme.spacing(3),
  border: `1px solid ${theme.palette.blue.blueBorder}`,
}))

const ListItemStyle = styled(ListItem)(({ theme }) => ({
  justifyContent: 'space-between',
  paddingRight: 0,

  '&.MuiListItem-root:first-of-type': {
    marginTop: theme.spacing(2),
  },

  '& .MuiListItemSecondaryAction-root': {
    flex: 1,
    position: 'relative',
    transform: 'none',

    '& .MuiBox-root': {
      marginBottom: 0,
    },
  },
}))

Modal.setAppElement('#root')

export default function BackgroundReview() {
  const theme = useTheme()

  const dispatch = useDispatch()

  const [user] = useLocalStorage('user')
  const uniqPermissions = _.get(user, 'uniqPermissions', [])
  const isPermission = _.includes(uniqPermissions, permissionItem.DC_ADMIN)

  const [isOpenModal, setIsOpenModal] = useState(false)

  const initProfile = useSelector(selectInitialProfile)
  const auditForm = useSelector(selectCrudFormData) as AuditInfoForm
  const { isLoading } = useSelector((state) => state.applicantDetails)
  const bgReview = useSelector(selectBackgroundReview)
  const profileDetails = useSelector(selectProfileDetails)

  const closeModal = () => {
    setIsOpenModal(false)

    document.body.style.overflow = 'auto'
  }

  const isAlreadySave = () => {
    dispatch(replaceFieldError(null))
    const arrRequest = ['area', 'level', 'ga', 'role', 'tUnit', 'eUnit']
    const arrErr = []
    if (_.isEmpty(_.get(auditForm, `ram.value`))) {
      arrErr.push('ram')
      dispatch(setAddFormErrors({ path: 'ram', message: 'กรุณาระบุข้อมูล' }))
    }

    for (const field of arrRequest) {
      if (_.isEmpty(_.get(auditForm, `${field}`))) {
        arrErr.push(field)
        dispatch(setAddFormErrors({ path: field, message: 'กรุณาระบุข้อมูล' }))
      }
    }
    if (getValueStr(auditForm, `hasLicense`) === 'true') {
      const subValidate = [
        'licenseNumber',
        'licenseStartDate',
        'licenseExpireDate',
      ]
      for (const field of subValidate) {
        if (_.isEmpty(_.get(auditForm, `${field}`))) {
          arrErr.push(field)
          dispatch(
            setAddFormErrors({ path: field, message: 'กรุณาระบุข้อมูล' })
          )
        }
      }
      if (getValueStr(auditForm, `formerAgentAXA`) === 'true') {
        if (_.isEmpty(_.get(auditForm, `licenseNumberAXA`))) {
          arrErr.push('licenseNumberAXA')
          dispatch(
            setAddFormErrors({
              path: 'licenseNumberAXA',
              message: 'กรุณาระบุข้อมูล',
            })
          )
        }
      } else {
        if (_.isEmpty(_.get(auditForm, `insuranceCompany`))) {
          arrErr.push('insuranceCompany')
          dispatch(
            setAddFormErrors({
              path: 'insuranceCompany',
              message: 'กรุณาระบุข้อมูล',
            })
          )
        }
      }
    }

    if (_.size(arrErr) > 0) {
      return false
    } else return true
  }

  const handleUpdateAuditInfo = async () => {
    const {
      ram,
      area,
      level,
      ga,
      role,
      tUnit,
      eUnit,
      hasLicense,
      licenseNumber,
      licenseStartDate,
      licenseExpireDate,
      formerAgentAXA,
      licenseNumberAXA,
      insuranceCompany,
    } = auditForm

    if (!isAlreadySave()) return false
    dispatch(startLoading())
    const { payload } = await dispatch(
      callUpdateAuditInfo({
        fullFormUuid: String(initProfile?.fullformUuid),
        ramUuid: ram?.value ?? '',
        ramName: ram?.label ?? '',
        area,
        level,
        ga,
        role,
        tUnit,
        eUnit,
        hasLicense: _.toString(hasLicense) === 'true',
        licenseNumber,
        licenseStartDate,
        licenseExpireDate,
        formerAgentAXA: _.toString(formerAgentAXA) === 'true',
        licenseNumberAXA,
        insuranceCompany,
      })
    )
    if (payload?.status && payload?.status !== ApiStatus.ERROR) {
      dispatch(
        openCustomDialog({
          type: DIALOG_TYPE_STRING.SUCCESS,
          title: 'สำเร็จ',
          message: 'บันทึกรายการสำเร็จแล้ว',
          handleOK: () => {
            dispatch(
              getProfileDetails({ prospectId: String(initProfile?.uuid) })
            )
            dispatch(closeCustomDialog())
          },
        })
      )
    }
    dispatch(endLoading())
  }
  const title = getTitleNotOwner(initProfile, 'ยืนยัน')
  const handleSubmitVerifyBackground = () => {
    dispatch(
      openCustomDialog({
        type: DIALOG_TYPE_STRING.CONFIRM,
        title: title,
        message: 'คุณยืนยันตรวจสอบประวัติผ่านแล้วใช่หรือไม่',
        handleConfirm: async () => {
          dispatch(setCustomDialogIsLoading(true))
          const { payload } = await dispatch(
            callVerifyBackgroundByDC(String(initProfile?.fullformUuid))
          )
          dispatch(setCustomDialogIsLoading(false))
          if (payload?.status && payload?.status !== ApiStatus.ERROR) {
            dispatch(
              openCustomDialog({
                type: DIALOG_TYPE_STRING.SUCCESS,
                title: 'สำเร็จ',
                message: 'คุณยืนยันตรวจสอบประวัติผ่านเรียบร้อยแล้ว',
                handleOK: () => {
                  window.location.reload()
                },
              })
            )
          } else {
            dispatch(closeCustomDialog())
          }
        },
      })
    )
  }

  useEffect(() => {
    if (initProfile) {
      dispatch(getCheckHistory(String(initProfile.fullformUuid)))
    }
  }, [dispatch, initProfile])

  // Initial form data when component mounted
  useEffect(() => {
    const { auditInfo } = bgReview

    dispatch(
      setFieldValue({
        key: 'formData',
        value: {
          ram: {
            value: auditInfo?.ramUuid ?? null,
            label: auditInfo?.ramName ?? null,
          },
          area: auditInfo?.area ?? null,
          level: _.isEmpty(auditInfo?.level) ? 'IC' : auditInfo?.level,
          ga: auditInfo?.ga ?? null,
          role: _.isEmpty(auditInfo?.role) ? 'Agent' : auditInfo?.role,
          tUnit: auditInfo?.tUnit ?? null,
          eUnit: auditInfo?.eUnit ?? null,
          hasLicense: auditInfo?.hasLicense ?? null,
          licenseNumber: auditInfo?.licenseNumber ?? null,
          licenseStartDate: auditInfo?.licenseStartDate ?? null,
          licenseExpireDate: auditInfo?.licenseExpireDate ?? null,
          formerAgentAXA: auditInfo?.formerAgentAXA ?? null,
          licenseNumberAXA: auditInfo?.licenseNumberAXA ?? null,
          insuranceCompany: auditInfo?.insuranceCompany ?? null,
        },
      })
    )

    dispatch(
      setFieldValue({
        key: 'formData.variousHistory',
        value: bgReview.variousHistory.map((item: any) => ({
          label: backgroundReviewOptions.find(
            (option) => option.value === item.status
          )?.label,
          value: item.status,
        })),
      })
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bgReview])

  const isNoAction = () => {
    const noEditStatuses = [
      ProcessResultApprovalStatus.DCV_REQUESTED_MEMO,
      ProcessResultApprovalStatus.DS_SAVED_FULL_FORM_MEMO,
      ProcessResultApprovalStatus.DS_SUBMITTED_FULL_FORM_MEMO,

      ProcessResultApprovalStatus.DCV_REQUESTED_DESCISION,
      ProcessResultApprovalStatus.DCV_REQUESTED_DESCISION_CCM,
      ProcessResultApprovalStatus.DCV_SENT_MAIL_DESCISION_CCM,
      ProcessResultApprovalStatus.DCV_REQUESTED_RCMS,

      ProcessResultApprovalStatus.DCV_REQUESTED_E_LICENCING,
      ProcessResultApprovalStatus.DCV_UPDATED_E_LICENCING_FAILED,
      ProcessResultApprovalStatus.DCV_UPDATED_E_LICENCING_PASSED,

      ProcessResultApprovalStatus.DCV_UPDATED_AGENT_CODE,
      ProcessResultApprovalStatus.DCV_DROPPED,
    ]
    const findNoEdit = noEditStatuses.includes(
      initProfile?.progressBarStatus as ProcessResultApprovalStatus
    )
    return findNoEdit
  }

  const formData = useSelector(selectCrudFormData)
  const { options } = formData

  return (
    <>
      <WrapperStyle>
        {/* HEADER ACTIONS SECTION */}
        <Stack direction="row" spacing={2} alignSelf="flex-end">
          <LoadingButton
            variant="outlined"
            color="error"
            size="medium"
            disabled={
              !isPermission ||
              _.isNull(initProfile?.contractor) ||
              isNoAction() ||
              _.isEmpty(bgReview?.auditInfo?.area) ||
              _.isEmpty(profileDetails?.paymentStatus) ||
              initProfile?.progressBarStatus ===
                ProcessResultApprovalStatus.DCV_APPROVED_TO_BE_AGENT
            }
            onClick={() => {
              dispatch(
                openCustomDialog({
                  type: DIALOG_TYPE_STRING.CUSTOM,
                  content: <FormRequestDecision startWith="CCM" />,
                  boxSx: { pr: 0, pe: 0 },
                })
              )
            }}
          >
            ส่งพิจารณาเพิ่มเติม
          </LoadingButton>

          <Button
            variant="contained"
            color="success"
            size="medium"
            sx={{ color: theme.palette.common.white }}
            onClick={handleSubmitVerifyBackground}
            disabled={
              !isPermission ||
              _.isNull(initProfile?.contractor) ||
              isNoAction() ||
              _.isEmpty(bgReview?.auditInfo?.area) ||
              _.isEmpty(profileDetails?.paymentStatus) ||
              initProfile?.progressBarStatus ===
                ProcessResultApprovalStatus.DCV_APPROVED_TO_BE_AGENT
            }
          >
            ตรวจสอบประวัติผ่านแล้ว
          </Button>
        </Stack>

        <Stack
          border={`1px solid ${theme.palette.blue.blueBorder}`}
          borderRadius="8px"
          padding={2}
        >
          <Stack gap={1}>
            <Stack direction="row" alignItems="center" gap={2}>
              <Box flex={1}>
                <Typography variant="body2" color="text.gray">
                  รหัสผู้จัดการ
                </Typography>
                <Typography variant="h6">
                  {bgReview?.managerData?.userCode
                    ? formatAgentCode(bgReview?.managerData?.userCode)
                    : '-'}
                </Typography>
              </Box>

              <Box flex={1}>
                <Typography variant="body2" color="text.gray">
                  ชื่อ นามสกุลผู้จัดการ
                </Typography>
                <Typography variant="h6">
                  {bgReview.managerData?.firstNameTH}{' '}
                  {bgReview.managerData?.lastNameTH}
                </Typography>
              </Box>

              <Box flex={1}>
                <Typography variant="body2" color="text.gray">
                  รหัสตัวแทนผู้ชักชวน
                </Typography>
                <Typography variant="h6">
                  {bgReview.inviteCode
                    ? formatAgentCode(bgReview.inviteCode)
                    : '-'}{' '}
                </Typography>
              </Box>
            </Stack>

            <Stack
              direction="row"
              alignItems="top"
              gap={2}
              sx={{
                display: 'grid',
                gridTemplateColumns: 'repeat(4, minmax(0, 1fr))',
              }}
            >
              <Box
                onClick={() =>
                  _.isEmpty(options?.RAM_PROFILE) &&
                  dispatch(handleGetOptions('RAM_PROFILE_ALL'))
                }
              >
                <CustomDropdown
                  label={'RAM'}
                  crud={'ram'}
                  placeholder="RAM"
                  loading
                  defaultValue={formData?.ram?.label}
                  options={convertAnyItemToOption(
                    options?.RAM_PROFILE_ALL ?? [],
                    {
                      label: 'name_alias',
                      value: 'uuid',
                    }
                  )}
                  disabled={!isPermission}
                  fullWidth
                  required
                />
              </Box>

              <CustomTextfield
                label="Area"
                crud="area"
                placeholder="Area"
                disabled={!isPermission}
                fullWidth
                required
              />

              <CustomDropdown
                label="Level"
                crud="level"
                placeholder="Level"
                isValue
                options={levelOptions}
                disabled={!isPermission}
                required
              />

              <CustomTextfield
                label="GA"
                crud="ga"
                placeholder="GA"
                disabled={!isPermission}
                fullWidth
                required
              />
            </Stack>

            <Stack
              direction="row"
              alignItems="top"
              gap={2}
              sx={{
                display: 'grid',
                gridTemplateColumns: 'repeat(4, minmax(0, 1fr))',
              }}
            >
              <CustomDropdown
                label="Role"
                crud="role"
                placeholder="Role"
                options={roleOptions}
                disabled={!isPermission}
                isValue
                required
              />

              <CustomTextfield
                label="T-Unit Name"
                crud="tUnit"
                placeholder="T-Unit"
                disabled={!isPermission}
                required
                fullWidth
              />

              <CustomTextfield
                label="E-Unit Name"
                crud="eUnit"
                placeholder="E-Unit"
                disabled={!isPermission}
                required
                fullWidth
              />
            </Stack>

            <CustomRadioGroup
              label="เคยมีใบอนุญาต หรือไม่"
              name="hasLicense"
              options={hasLicenseOptions}
              defaultValue={_.toString(auditForm.hasLicense)}
              disabled={!isPermission}
              required
            />

            {_.toString(auditForm.hasLicense) === 'true' && (
              <>
                <Stack
                  display="grid"
                  gridTemplateColumns="repeat(3, minmax(0, 1fr))"
                  direction="row"
                  alignItems="top"
                  gap={2}
                >
                  <CustomTextfield
                    label="เลขที่ใบอนุญาต"
                    crud="licenseNumber"
                    placeholder="เลขที่ใบอนุญาต"
                    required
                    disabled={!isPermission}
                    boxSx={{ mb: 0 }}
                    fullWidth
                  />

                  <CustomDateInput
                    required
                    labelName="วันออกใบอนุญาต"
                    fieldName="licenseStartDate"
                    placeholder="วว/ดด/ปปปปป"
                    sxTextError={{ mb: 0 }}
                    disabled={!isPermission}
                  />

                  <CustomDateInput
                    required
                    labelName="วันใบอนุญาตหมดอายุ"
                    fieldName="licenseExpireDate"
                    placeholder="วว/ดด/ปปปปป"
                    sxTextError={{ mb: 0 }}
                    disabled={!isPermission}
                  />
                </Stack>

                <CustomRadioGroup
                  label="เคยเป็นตัวแทนกับบริษัท กรุงไทย-แอกซ่า ประกันชีวิต จํากัด (มหาชน) หรือไม่"
                  name="formerAgentAXA"
                  options={formerAgentAXAOptions}
                  defaultValue={_.toString(auditForm.formerAgentAXA)}
                  disabled={!isPermission}
                  required
                />

                {_.toString(auditForm.formerAgentAXA) === 'true' ? (
                  <CustomInput
                    required
                    labelName="เลขที่ตัวแทนของบริษัท กรุงไทย-แอกซ่า ประกันชีวิต จํากัด (มหาชน) "
                    fieldName="licenseNumberAXA"
                    placeholder="เลขที่ตัวแทน"
                    disabled={!isPermission}
                  />
                ) : (
                  <CustomInput
                    required
                    labelName="เป็นตัวแทนของบริษัทใด"
                    fieldName="insuranceCompany"
                    placeholder="บริษัท"
                    disabled={!isPermission}
                  />
                )}
              </>
            )}

            <CustomLoadingButton
              startIcon={<SaveIcon />}
              loading={!isPermission ? false : isLoading}
              disabled={!isPermission || isEmpty(auditForm)}
              sx={{ maxWidth: 'fit-content', marginLeft: 'auto' }}
              onClick={handleUpdateAuditInfo}
            >
              บันทึก
            </CustomLoadingButton>
          </Stack>
        </Stack>

        {/* List item section */}
        <CustomListItem
          subheader={
            <Stack direction="row" justifyContent="space-between" px={2}>
              <Typography variant="tableHeader" color="text.primary" flex={1}>
                รายการ
              </Typography>
              <Typography variant="tableHeader" color="text.primary" flex={1}>
                ช่องทาง
              </Typography>
              <Typography variant="tableHeader" color="text.primary" flex={1}>
                สถานะการตรวจสอบ
              </Typography>
            </Stack>
          }
          listData={bgReview.variousHistory.map((item) => ({
            label: variousHistoryLabel[item.variousName],
            value: item?.variousChannel ?? '',
          }))}
        />
      </WrapperStyle>

      <Modal
        isOpen={isOpenModal}
        onRequestClose={closeModal}
        contentLabel="Reject applicant modal"
        style={{
          overlay: {
            backgroundColor: `${theme.palette.common.black}BF`,
            zIndex: 1000000,
          },
          content: {
            maxWidth: '678px',
            width: '100%',
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            padding: 0,
            backgroundColor: theme.palette.background.paper,
            borderRadius: '8px',
            boxShadow: `0px 9px 46px 8px ${theme.palette.common.black}1F,
                        0px 24px 38px 3px ${theme.palette.common.black}24,
                        0px 11px 15px -7px ${theme.palette.common.black}33`,
          },
        }}
      >
        <Box padding={2}>
          <Typography variant="h6">ไม่สามารถดำเนินการได้</Typography>
        </Box>

        <Box padding={2}>
          <Typography variant="body1">
            กรุณากรอกข้อมูลและ “บันทึก” ก่อนทำการกดปุ่ม “ตรวจสอบประวัติผ่านแล้ว”
          </Typography>
        </Box>

        <Stack width={1} direction="row" justifyContent="flex-end" padding={2}>
          <Button
            variant="contained"
            sx={{
              color: theme.palette.primary.contrastText,
              marginLeft: 'auto',
            }}
            onClick={closeModal}
          >
            <Typography variant="button">รับทราบ</Typography>
          </Button>
        </Stack>
      </Modal>
    </>
  )
}

type Props = ListProps & {
  listData: {
    label: string
    value: string
  }[]
}

function CustomListItem({ listData, ...other }: Readonly<Props>) {
  const theme = useTheme()

  const dispatch = useDispatch()

  const [user] = useLocalStorage('user')
  const uniqPermissions = _.get(user, 'uniqPermissions', [])
  const isPermission = _.includes(uniqPermissions, permissionItem.DC_ADMIN)

  const initProfile = useSelector(selectInitialProfile)
  const bgReview = useSelector(selectBackgroundReview)
  const formData = useSelector(selectCrudFormData)
  const isLoading = useSelector((state) => state.applicantDetails.isLoading)

  const handleUpdateVariousHistoryStatus = async (
    index: number,
    value: any
  ) => {
    await dispatch(
      callUpdateVariousHistoryStatus({
        fullFormUuid: String(initProfile?.fullformUuid),
        variousName: bgReview.variousHistory?.[index].variousName,
        newStatus: value?.value,
        variousChannel: bgReview.variousHistory?.[index].variousChannel,
      })
    )
  }

  return (
    <ListStyle
      sx={{ width: '100%', bgcolor: theme.palette.common.white }}
      {...other}
    >
      {listData.map((item, index) => (
        <ListItemStyle
          key={item.label}
          secondaryAction={
            <Dropdown
              label=""
              value={formData?.variousHistory?.[index] || null}
              name={`variousHistory.${String(index)}`}
              options={backgroundReviewOptions}
              isOptionEqualToValue={(option, value) =>
                option.value === value.value
              }
              getOptionLabel={(option: (typeof backgroundReviewOptions)[0]) =>
                option.label
              }
              disableClearable
              handleExtraChange={async (_event, value) => {
                await handleUpdateVariousHistoryStatus(index, value)
              }}
              disabled={!isPermission}
              loading={isLoading}
            />
          }
          divider
        >
          <ListItemText primary={item.label} sx={{ flex: 1 }} />
          <ListItemText primary={item.value} sx={{ flex: 1 }} />
        </ListItemStyle>
      ))}
    </ListStyle>
  )
}
