import React, { useState } from 'react'
import { Box } from '@mui/material'

interface CustomSliderWithBoxProps {
  min: number
  max: number
  step: number
  defaultValue: number
  size?: 'small' | 'medium' // Optional size prop for custom styling
  onChange?: (value: number) => void // Optional onChange callback
}

const CustomSliderWithBox: React.FC<CustomSliderWithBoxProps> = ({
  min,
  max,
  step,
  defaultValue,
  size = 'medium',
  onChange,
}) => {
  const [value, setValue] = useState(defaultValue)

  const handleSliderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = Number(event.target.value)
    setValue(newValue)
    if (onChange) {
      onChange(newValue) // Call the onChange prop if provided
    }
  }

  return (
    <Box sx={{ width: '100%' }}>
      <input
        type="range"
        min={min}
        max={max}
        step={step}
        defaultValue={defaultValue}
        value={value}
        onChange={handleSliderChange}
        style={{
          width: '100%',
          height: size === 'small' ? '4px' : '8px', // Custom size for "small" or "medium"
          appearance: 'none',
          backgroundColor: '#a8cbee',
          borderRadius: '5px',
        }}
      />
    </Box>
  )
}

export default CustomSliderWithBox
