import React from 'react'
import {
  DateRangePicker,
  type Range,
  type RangeKeyDict,
} from 'react-date-range'
import useTheme from '@mui/system/useTheme'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import './date-range-style.css'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import { getMonth, getYear } from 'date-fns'
dayjs.extend(utc)
dayjs.extend(timezone)
interface DateRangeProps {
  selectedDates: Range[]
  disableDates?: Date[]
  maxDate?: Date
  minDate?: Date
  onChange?: (ranges: RangeKeyDict) => void
}
const monthsInThai = [
  'มกราคม',
  'กุมภาพันธ์',
  'มีนาคม',
  'เมษายน',
  'พฤษภาคม',
  'มิถุนายน',
  'กรกฎาคม',
  'สิงหาคม',
  'กันยายน',
  'ตุลาคม',
  'พฤศจิกายน',
  'ธันวาคม',
]

const DateRange: React.FC<DateRangeProps> = (props) => {
  const { selectedDates, disableDates, maxDate, minDate, onChange } = props
  const theme = useTheme()

  return (
    <DateRangePicker
      onChange={onChange}
      months={1}
      ranges={selectedDates}
      direction="horizontal"
      className="dateRange"
      maxDate={maxDate}
      minDate={minDate}
      rangeColors={[theme?.palette?.primary?.main]}
      disabledDates={disableDates}
      navigatorRenderer={(currFocusedDate, changeShownDate) => {
        const currentYear = getYear(currFocusedDate)
        const currentMonth = getMonth(currFocusedDate)

        const handleYearChange = (
          event: React.ChangeEvent<HTMLSelectElement>
        ) => {
          const selectedYear = event.currentTarget.value
          changeShownDate(parseInt(selectedYear), 'setYear')
        }
        const handleMonthChange = (
          event: React.ChangeEvent<HTMLSelectElement>
        ) => {
          const selectedMonth = event.currentTarget.value
          changeShownDate(parseInt(selectedMonth), 'setMonth')
        }
        return (
          <div className="rdrMonthAndYearWrapper">
            <KeyboardArrowLeftIcon
              sx={{ cursor: 'pointer' }}
              color="primary"
              onClick={() => changeShownDate(currentMonth - 1, 'setMonth')}
            />

            <select
              className="rdrMonthPicker"
              value={currentMonth}
              onChange={handleMonthChange}
            >
              {monthsInThai.map((month, index) => (
                <option key={index} value={index}>
                  {month}
                </option>
              ))}
            </select>

            <select
              className="rdrYearPicker"
              value={currentYear}
              onChange={handleYearChange}
            >
              {Array.from({ length: 100 }, (_, i) => {
                const year = dayjs().year() - i
                return (
                  <option key={year} value={year}>
                    {year + 543}
                  </option>
                )
              })}
            </select>
            <KeyboardArrowRightIcon
              sx={{ cursor: 'pointer' }}
              color={
                dayjs().year() === currentYear &&
                dayjs().month() === currentMonth
                  ? 'disabled'
                  : 'primary'
              }
              onClick={() => changeShownDate(currentMonth + 1, 'setMonth')}
            />
          </div>
        )
      }}
    />
  )
}

export default DateRange
