import { useSelector } from 'react-redux'
import { UploadMultiReturnPath } from '../../../../components/FullUpload'
import {
  selectCrudFormData,
  selectInitialProfile,
} from '../../../../redux/selector'
import CustomRadioGroup from '../../../../components/Input/CustomRadioGroup'
import { Typography } from '@mui/material'
import { useEffect } from 'react'
import { setFieldValue } from '../../../../components/CRUD/slices'
import { dispatch } from '../../../../redux/store'

export const HistoryDecisionForm = () => {
  const initProfile = useSelector(selectInitialProfile)
  const fullFormUuid = String(initProfile?.fullformUuid)
  const formData = useSelector(selectCrudFormData)

  const { actionForm, actionBy } = formData

  useEffect(() => {
    dispatch(
      setFieldValue({
        key: `formData.decisionResult`,
        value: null,
      })
    )
  }, [])

  return (
    <>
      {actionBy === 'Request' ? (
        <>
          <CustomRadioGroup
            label={`ผลการพิจารณาเพิ่มเติมจาก ${actionForm} *`}
            name={'decisionResult.result'}
            options={[
              {
                label: 'ผ่าน',
                value: 'YES',
              },
              {
                label: 'ไม่ผ่าน',
                value: 'NO',
              },
            ]}
          />
          <UploadMultiReturnPath
            name={'decisionResult.attachments'}
            label={`แนบเอกสารผลการพิจารณาเพิ่มเติมจาก ${actionForm} *`}
            fullFormUuid={fullFormUuid}
            fieldName={`consideration${actionForm}`}
            maxFile={3}
            info={'สูงสุดไม่เกิน 3 รายการ'}
          />
        </>
      ) : (
        <>
          <Typography variant="body1">
            คุณต้องการยืนยันผลพิจารณาเพิ่มเติมใช่หรือไม่
          </Typography>
        </>
      )}
    </>
  )
}
