import React, { Suspense, useEffect, useState } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import RoutePage from './routes/Route'
import { ThemeProvider } from '@mui/material/styles'
import { theme } from './styles/themes/theme'
import { Provider as ReduxProvider } from 'react-redux'
import CssBaseline from '@mui/material/CssBaseline'
import { store, persistor } from './redux/store'
import { PersistGate } from 'redux-persist/integration/react'
import { Box, CircularProgress, Typography } from '@mui/material'
import CustomerAlertDialog from './components/CustomerAlertDialog'
import Keycloak from './keyCloak'
import OneAccount from './OneAccount'
import {
  AuthProvider,
  useAuth,
  type AuthProviderProps,
} from 'react-oidc-context'
import { DialogContextProvider } from './context/dialog-context'
import PopUpDialog from './components/PopUpDialog'
import { useLocalStorage } from '@rehooks/local-storage'
import _ from 'lodash'
import { callAPI } from './utils/callAPI'
import CustomDialog from './components/CustomDialog'
import CustomDrawer from './components/CustomDrawer'
import { handleExceptPath } from './utils'

type CustomUserType = {
  access_token: string
  refresh_token?: string
  token_type: string
}

const App: React.FC = () => {
  const [showOverlay, setShowOverlay] = useState(false)
  const auth = useAuth()

  useEffect(() => {
    if (window.location.pathname === '/' && auth.isAuthenticated === true) {
      // แสดง overlay
      setShowOverlay(true)

      // ตั้งเวลาให้ซ่อน overlay หลังจาก 3 วินาที
      const timer = setTimeout(() => {
        setShowOverlay(false)
      }, 4000)

      // Clear timer เมื่อ component unmounts
      // return () => clearTimeout(timer)
    }
  }, [auth.isAuthenticated])

  if (auth.isLoading || showOverlay) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          position: 'fixed',
          top: '0',
          left: '0',
          width: '100%',
          height: '100%',
          backgroundColor: '#FFFFFF',
          zIndex: '99999',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography sx={{ mt: 2 }}>กรุณารอสักครู่</Typography>
      </Box>
    )
  }

  return <RoutePage />
}

const DialogProviders = () => {
  const auth = useAuth()
  const [user, setUser] = useLocalStorage('user')

  useEffect(() => {
    return auth.events.addUserLoaded(async (authorization: CustomUserType) => {
      if (!_.isEmpty(authorization?.access_token) && _.isEmpty(user)) {
        localStorage.setItem(
          'refresh_token',
          authorization?.refresh_token || ''
        )
        localStorage.setItem(
          'token',
          `${authorization.token_type} ${authorization?.access_token}`
        )
        try {
          // เรียก callAPI โดยใช้ await
          const res = await callAPI({
            url: 'staff-profile',
            method: 'get',
            headers: {
              Authorization: `${authorization.token_type} ${authorization.access_token}`,
              'Content-Type': 'application/json',
            },
          })

          // ตั้งค่า user ด้วยผลลัพธ์ที่ได้จาก callAPI
          setUser(res)
        } catch (error) {
          // จัดการข้อผิดพลาดหาก API ล้มเหลว
          console.log(error)
          alert('Error occurred while fetching staff profile')
        }
      }
    })
  }, [auth.events, user, setUser])

  return (
    <Suspense fallback={<CircularProgress size={30} />}>
      <Router>
        <App />
      </Router>

      <CustomerAlertDialog />
      <PopUpDialog />
      <CustomDialog />
      <CustomDrawer />
    </Suspense>
  )
}

const DialogContextProviders = () => {
  return <DialogProviders />
}

const getKey = (): AuthProviderProps => {
  if (window.__env__.ENV === 'DEV') {
    return Keycloak
  }
  return OneAccount
}

export const Root = () => {
  useEffect(() => {
    if (
      _.isEmpty(window.localStorage.getItem('user')) &&
      !handleExceptPath(window.location)
    ) {
      window.location.href = '/'
    }
  }, [])
  return (
    <AuthProvider {...getKey()}>
      <ReduxProvider store={store}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <PersistGate loading={null} persistor={persistor}>
            <DialogContextProvider>
              <DialogContextProviders />
            </DialogContextProvider>
          </PersistGate>
        </ThemeProvider>
      </ReduxProvider>
    </AuthProvider>
  )
}
export default App
