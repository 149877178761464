import { Box, Typography, IconButton } from '@mui/material'
import { useEffect } from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import { closeDrawer } from '../../../../components/CustomDrawer/slices'
import { DrawerTableState } from '../../../../components/CustomDrawer/slices/model'
import HistoryTable, {
  DataTableCol,
} from '../../../../components/Table/TableHistory'
import { DateFormat } from '../../../../constants/dateFormat'
import { SliceModel } from '../../../../redux/models'
import { dispatch } from '../../../../redux/store'
import { displayDateTime } from '../../../../utils/convertFormatDateTime'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import { loadProspectAssignHistory } from '../handler/loadProspectAssignHistory'

export const ProspectAssignHistory = () => {
  const { table, isLoading, data } = useSelector(
    (state: SliceModel) => ({
      isLoading: state.drawer.isLoading,
      table: state.drawer.table as DrawerTableState,
      data: state.prospect.detail.data,
    }),
    shallowEqual
  )

  const headers: DataTableCol[] = [
    {
      headerName: 'รหัสผู้จัดการ',
      width: '10%',
      field: 'managerCode',
    },
    {
      headerName: 'ชื่อผู้จัดการ',
      width: '20%',
      field: 'managerName',
    },
    {
      headerName: 'มอบหมายไปที่',
      width: '10%',
      field: 'assignTo',
    },
    {
      headerName: 'เงื่อนไขได้รับการมอบหมาย',
      width: '15%',
      field: 'assignCondition',
    },
    {
      headerName: 'วันที่ได้รับการมอบหมาย',
      width: '15%',
      field: 'assignDate',
      renderCell: (row: any) => (
        <>{displayDateTime(row.assignDate, DateFormat.DDMMYYYYHHmm)}</>
      ),
    },
    {
      headerName: 'ช่องทาง',
      width: '8%',
      field: 'channel',
    },
    {
      headerName: 'ระยะเวลานัดหมาย',
      width: '11%',
      field: 'periodTimeAppointment',
    },
    {
      headerName: 'ระยะเวลาสัมภาษณ์',
      width: '11%',
      field: 'periodTimeInterview',
    },
  ]

  useEffect(() => {
    dispatch(loadProspectAssignHistory(String(data?.uuid)))
  }, [])

  return (
    <Box sx={{ p: 3, width: '100%' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
        <Box sx={{ width: '100%' }}>
          <Typography variant="h5">ประวัติการมอบหมาย</Typography>
        </Box>
        <Box>
          <IconButton onClick={() => dispatch(closeDrawer())}>
            <CloseRoundedIcon />
          </IconButton>
        </Box>
      </Box>
      <HistoryTable
        isLoading={isLoading}
        headers={headers}
        rows={table?.items ?? []}
      />
    </Box>
  )
}
