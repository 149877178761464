import { Typography } from '@mui/material'
import CompareTableItem, { CompareTableCol } from './CompareTableItem'
import HeaderActions from '../step/HeaderActions'

export const TableTax = () => {
  const taxRows: CompareTableCol[] = [
    {
      fieldTitle: 'ฐานะการสมรส',
      fieldName: 'tax.taxMaritalStatus',
    },
    {
      fieldTitle: 'มีบุตรหรือบุตรบุญธรรมที่มีสิทธินำมาหักค่าลดหย่อน',
      fieldName: 'tax.totalChildrenNum',
      other: 'haveChild',
    },
    {
      fieldTitle: 'จํานวนบุตรคนแรกและเกิดก่อนปี 2561 รวม',
      fieldName: 'tax.firstChildrenNum',
    },
    {
      fieldTitle:
        'จํานวนบุตรตั้งแต่คนที่2 เป็นต้นไปที่เกิดในหรือหลังปี พ.ศ.2561',
      fieldName: 'tax.otherChildrenNum',
    },
    {
      fieldTitle: 'รวมจำนวน คน',
      fieldName: 'tax.totalChildrenNum',
    },
    {
      fieldTitle:
        'เบี้ยประกันชีวิตที่จ่ายในปี ภาษี (กรมธรรม์มีกําหนดระยะเวลาตั้งแต่ 10 ปี ขึ้นไป)',
      fieldName: 'tax.lifeInsurancePremium',
    },
    {
      fieldTitle:
        'เบี้ยประกันสุขภาพของผู้มีเงินได้ (ตามที่จ่ายจริง แต่ไม่เกิน 15,000 บาท และเมื่อรวมกับเบี้ยประกันชีวิต ต้องไม่เกิน 100,000 บาท)',
      fieldName: 'tax.healthInsurancePremium',
    },
    {
      fieldTitle:
        'ดอกเบี้ยเงินกู้ยืมเพื่อซื้อ เช่าซื้อ หรือสร้างอาคารที่อยู่อาศัย (ตามจริงแต่ไม่เกิน 100,000 บาท)',
      fieldName: 'tax.interestHomeLoans',
    },
    {
      fieldTitle: 'มีผู้กู้ร่วมหรือไม่',
      fieldName: 'tax.coBorrower',
    },
    {
      fieldTitle: 'รูปแบบผู้กู้ร่วม',
      fieldName: 'tax.coBorrowerType',
    },
    {
      fieldTitle: 'รวมจํานวนผู้กู้ร่วมทั้งหมด (รวมตัวผู้มีเงินได้เองด้วย)',
      fieldName: 'tax.totalCoBorrower',
    },
  ]
  const supportRows: CompareTableCol[] = [
    {
      fieldTitle: 'บิดา ของผู้มีเงินได้',
      fieldName: 'tax.fatherSupportAmount',
    },
    {
      fieldTitle: 'มารดา ของผู้มีเงินได้',
      fieldName: 'tax.motherSupportAmount',
    },
    {
      fieldTitle: 'บิดา ของคู่สมรส',
      fieldName: 'tax.spouseFatherSupportAmount',
    },
    {
      fieldTitle: 'มารดา ของคู่สมรส',
      fieldName: 'tax.spouseMotherSupportAmount',
    },
    {
      fieldTitle:
        'เบี้ยประกันสุขภาพของบิดา-มารดา (ตามจำนวนที่จ่ายจริงแต่ไม่เกิน 15,000 บาท)',
      fieldName: 'tax.parentsHealthInsurancePremium',
    },
    {
      fieldTitle:
        'เบี้ยประกันชีวิตของคู่สมรส (กรณีคู่สมรสไม่มีเงินได้และความเป็นสามีภริยามีอยู่ตลอดปีภาษี หักลดหย่อนของคู่ สมรสได้อีกตามจำนวนที่จ่ายจริงแต่ไม่เกิน 10,000 บาท โดยกรมธรรม์ประกันชีวิตต้องมีระยะเวลา 10 ขึ้นไป)',
      fieldName: 'tax.spouseLifeInsurancePremium',
    },
    {
      fieldTitle:
        'ค่าซื้อหน่วยลงทุนในกองทุนรวมเพื่อการเลี้ยงชีพ (RMF) (ลดหย่อนภาษีสูงสุด 30% ของเงินได้พึงประเมินแต่ไม่เกิน 500,000 บาท)',
      fieldName: 'tax.rmfInvestmentAmount',
    },
    {
      fieldTitle:
        'ค่าซื้อกองทุนรวมเพื่อการออม (SSF) (ลดหย่อนภาษีสูงสุด 30% ของเงินได้พึงประเมินแต่ไม่เกิน 200,000 บาท)',
      fieldName: 'tax.ssfInvestmentAmount',
    },
  ]
  return (
    <>
      <HeaderActions titleStep="Tax-Deduction" />
      <Typography variant="h6" mb={2}>
        รายการลดหย่อน
      </Typography>
      <CompareTableItem sx={{ mb: 4 }} rows={taxRows} showCompare />
      <Typography variant="h6" mb={2}>
        ค่าเลี้ยงดูบิดา-มารดา (หักได้คนละ 30,000 บาท)
      </Typography>
      <CompareTableItem sx={{ mb: 4 }} rows={supportRows} hideHeader />
    </>
  )
}
