import * as React from 'react'
import { styled } from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { StyledTableCell } from './Styled'
import _ from 'lodash'
import { CrudForm } from '../CRUD/types/CrudState'
import {
  Box,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
} from '@mui/material'
import { setLineClamp } from '../../utils/lineClamp'
import LinearProgress from '@mui/material/LinearProgress'
const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))

export interface DataTableCol {
  field: string
  headerName: string
  width: number | string
  tooltip?: boolean
  renderCell?: (row: CrudForm) => React.ReactNode
}

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}))

export default function HistoryTable(props: {
  rows: CrudForm[]
  headers: DataTableCol[]
  isLoading?: boolean
}) {
  const { rows = [], headers = [], isLoading } = props
  return (
    <>
      <TableContainer component={Paper}>
        <Table
          sx={{ minWidth: 1180, border: '1px solid rgba(224, 224, 224, 1)' }}
          aria-label="customized table"
        >
          <TableHead>
            <TableRow>
              {headers.map((head, index) => (
                <StyledTableCell key={index} width={head.width}>
                  {head.headerName}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <StyledTableRow key={index}>
                {headers.map((head, index) => (
                  <StyledTableCell key={index}>
                    {head.renderCell ? (
                      head.renderCell(row)
                    ) : (
                      <Box sx={{ display: 'flex' }}>
                        {head.tooltip ? (
                          <LightTooltip
                            title={
                              <Typography variant="body2">
                                {_.get(row, `${head.field}`, '')}
                              </Typography>
                            }
                            placement="top-end"
                          >
                            <Typography variant="body2" sx={setLineClamp(1)}>
                              {_.get(row, `${head.field}`, '')}
                            </Typography>
                          </LightTooltip>
                        ) : (
                          <Typography variant="body2" sx={setLineClamp(1)}>
                            {_.get(row, `${head.field}`, '')}
                          </Typography>
                        )}
                      </Box>
                    )}
                  </StyledTableCell>
                ))}
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {isLoading && <LinearProgress />}
      {!isLoading && _.size(rows) == 0 && (
        <Box sx={{ textAlign: 'center', my: 5 }}>
          <Typography>ไม่มีรายการข้อมูล</Typography>
        </Box>
      )}
    </>
  )
}
