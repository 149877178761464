import _ from 'lodash'
import { AppDispatch } from '../../../redux/store'
import { callInit } from '../services/thunk'
import { getValueNumber } from '../../../utils/getValue'
import { setFormInit, setFormIsLoading } from '../../../components/CRUD/slices'

export const init = () => async (dispatch: AppDispatch) => {
  dispatch(setFormIsLoading(true))
  const response = await dispatch(callInit())
  if (!_.isEmpty(response?.payload)) {
    const formData = {
      COMPANY_LEAD_TYPE: '',
      COMPANY_LEAD_HOURS: '',
      REFERRAL_LEAD_TYPE: '',
      REFERRAL_LEAD_HOURS: '',

      COMPANY_LEAD_TYPE_INTERVIEW: '',
      COMPANY_LEAD_HOURS_INTERVIEW: '',
      REFERRAL_LEAD_TYPE_INTERVIEW: '',
      REFERRAL_LEAD_HOURS_INTERVIEW: '',
    }

    const EXPIRE_LEAD_COMPANY = getValueByKey(
      'EXPIRE_LEAD_COMPANY',
      response?.payload
    )
    const noExpire = _.get(EXPIRE_LEAD_COMPANY, 'noExpire', false)
    if (!noExpire) {
      const expireHours = getValueNumber(EXPIRE_LEAD_COMPANY, 'expireHours')
      formData.COMPANY_LEAD_TYPE = 'EXPIRE'
      formData.COMPANY_LEAD_HOURS = String(expireHours)
    } else {
      formData.COMPANY_LEAD_TYPE = 'NO_EXPIRE'
    }

    const EXPIRE_LEAD_REFERRAL = getValueByKey(
      'EXPIRE_LEAD_REFERRAL',
      response?.payload
    )
    const noExpire2 = _.get(EXPIRE_LEAD_REFERRAL, 'noExpire', false)
    if (!noExpire2) {
      const expireHours = getValueNumber(EXPIRE_LEAD_REFERRAL, 'expireHours')
      formData.REFERRAL_LEAD_TYPE = 'EXPIRE'
      formData.REFERRAL_LEAD_HOURS = String(expireHours)
    } else {
      formData.REFERRAL_LEAD_TYPE = 'NO_EXPIRE'
    }

    const EXPIRE_LEAD_COMPANY_INTERVIEW = getValueByKey(
      'EXPIRE_LEAD_COMPANY_INTERVIEW',
      response?.payload
    )
    const noExpire3 = _.get(EXPIRE_LEAD_COMPANY_INTERVIEW, 'noExpire', false)
    if (!noExpire3) {
      const expireHours = getValueNumber(
        EXPIRE_LEAD_COMPANY_INTERVIEW,
        'expireHours'
      )
      formData.COMPANY_LEAD_TYPE_INTERVIEW = 'EXPIRE'
      formData.COMPANY_LEAD_HOURS_INTERVIEW = String(expireHours)
    } else {
      formData.COMPANY_LEAD_TYPE_INTERVIEW = 'NO_EXPIRE'
    }

    const EXPIRE_LEAD_REFERRAL_INTERVIEW = getValueByKey(
      'EXPIRE_LEAD_REFERRAL_INTERVIEW',
      response?.payload
    )
    const noExpire4 = _.get(EXPIRE_LEAD_REFERRAL_INTERVIEW, 'noExpire', false)
    if (!noExpire4) {
      const expireHours = getValueNumber(
        EXPIRE_LEAD_REFERRAL_INTERVIEW,
        'expireHours'
      )
      formData.REFERRAL_LEAD_TYPE_INTERVIEW = 'EXPIRE'
      formData.REFERRAL_LEAD_HOURS_INTERVIEW = String(expireHours)
    } else {
      formData.REFERRAL_LEAD_TYPE_INTERVIEW = 'NO_EXPIRE'
    }

    dispatch(
      setFormInit({
        formData,
      })
    )
  }
  dispatch(setFormIsLoading(false))
}
const getValueByKey = (key: string, payload: any) => {
  const data = _.find(payload, {
    type: key,
  })
  if (isJsonString(data?.value)) {
    const value = JSON.parse(data.value)
    return value
  } else return null
}
const isJsonString = (str: string) => {
  try {
    JSON.parse(str)
  } catch (e) {
    return false
  }
  return true
}
