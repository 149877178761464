import { useSelector } from 'react-redux'
import { selectShowFile } from '../../../redux/selector'
import FileViewer from '../../../components/Modal/FileViewer'
export type FileViewerProp = {
  name: string
  srcFile: string | ArrayBuffer
}
export const ShowFile = () => {
  const showFile = useSelector(selectShowFile)

  return <FileViewer isOpen={showFile.isOpen} fileData={showFile.file} />
}
