import { Box, Typography } from '@mui/material'
import { useSelector, shallowEqual } from 'react-redux'
import { CustomCard } from '../../../../../../components/Layout/CustomCard'
import { SliceModel } from '../../../../../../redux/models'
import { themeColor } from '../../../../../../styles/themes/themeData'
import {
  getPercentTime,
  showDayOrTime,
  displayDateTime,
} from '../../../../../../utils/convertFormatDateTime'
import UpdateIcon from '@mui/icons-material/Update'
import { setLineClamp } from '../../../../../../utils/lineClamp'
import { CustomCircularProgress } from './CustomCircularProgress'
import { DateFormat } from '../../../../../../constants/dateFormat'

export function TimerInterview() {
  const { data, isLoading } = useSelector(
    (state: SliceModel) => ({
      data: state.prospect.detail.data,
      isLoading: state.prospect.detail.isLoading,
    }),
    shallowEqual
  )
  const present =
    data?.expireType === 'NO_EXPIRE'
      ? 0
      : 100 - getPercentTime(data?.processedAt, data?.expireDateInterview)
  return (
    <CustomCard isLoading={isLoading} sx={{ height: '145px' }}>
      <Box mb={1}>
        <Typography variant="body1b">ระยะเวลาในการสัมภาษณ์</Typography>
      </Box>
      <Box display={'flex'} alignItems={'center'} gap={2}>
        <Box
          sx={{
            width: 'auto',
            p: 1,
            pb: 0,
            borderRadius: 2,
            backgroundColor: themeColor.grey.grey100,
            position: 'relative',
          }}
          alignItems={'center'}
          textAlign={'center'}
        >
          <CustomCircularProgress value={present} />
          <UpdateIcon
            sx={{
              position: 'absolute',
              left: '50%',
              top: '50%',
              transform: 'translate(-50%,-50%)',
            }}
          />
        </Box>
        <Box>
          <Typography
            sx={{ ...setLineClamp(1), color: 'text.gray' }}
            variant="body2"
          >
            เวลาที่เหลือในการสัมภาษณ์
          </Typography>

          {data?.expireType === 'NO_EXPIRE' ? (
            <>-</>
          ) : (
            <>
              <Typography variant="h5">
                {showDayOrTime(data?.expireDateInterview)}
              </Typography>
              <Typography
                sx={{ ...setLineClamp(1), color: 'text.gray' }}
                variant="body2"
              >
                วันที่สิ้นสุด :{' '}
                <span style={{ color: '#000000' }}>
                  {' '}
                  {displayDateTime(data?.expireDateInterview)}
                </span>{' '}
                เวลา :{' '}
                <span style={{ color: '#000000' }}>
                  {displayDateTime(data?.expireDateInterview, DateFormat.HHmm)}
                </span>
              </Typography>
            </>
          )}
        </Box>
      </Box>
    </CustomCard>
  )
}
