import Box from '@mui/material/Box'
import styled from '@mui/system/styled'

export const StyledSubContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column', // จัดเรียงลูกให้เป็นแนวตั้ง
  alignItems: 'center', // จัดกึ่งกลางในแนวขวาง
  justifyContent: 'space-between',
  gap: theme.spacing(1), // ระยะห่างระหว่าง SubBox และข้อความ
  padding: theme.spacing(1), // ปรับระยะห่างด้านใน
}))

export const StyledSubBox = styled(Box)(({ theme }) => ({
  width: 'calc(100% - 8px)',
  height: 66,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(1),
  borderRadius: theme.spacing(2),
  background: theme.palette?.background?.blue,
}))
