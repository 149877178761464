import React, { useState } from 'react'
import Box from '@mui/material/Box'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import _ from 'lodash'
import './datePickter.css'
import dayjs, { Dayjs } from 'dayjs'
import styled from '@mui/system/styled'
import type { SxProps, Theme } from '@mui/material/styles'

interface DatePickerProps {
  boxSx?: SxProps
  labelText?: string
  required?: boolean
  disabled?: boolean
  id?: string
  onChange?: (date: string) => void
  textError?: string
  placeholder?: string
  value?: string
  hideTextError?: boolean
  disabledDate?: Array<{ startDate: string; finishDate: string }>
  disableFuture?: boolean
  hideDisableDateRange?: boolean
  ignoreDateRange?: string
  disablePast?: boolean
  disabledInput?: boolean
  disabledStartDate?: string
  disabledEndDate?: string
  disabledMonthlyPlan?: { year: number; month: number }
  disabledDateRange?: boolean
  isShouldDisableDate?: boolean
  currentDate?: string
  handleOpen?: () => void
  handleClose?: () => void
  sxTextError?: SxProps<Theme>
  onAccept?: (date: Dayjs | null) => void
  name?: string
}

const DatePicker: React.FC<DatePickerProps> = (props) => {
  const {
    boxSx,
    labelText,
    required,
    disabled,
    id,
    onChange,
    textError,
    placeholder = 'เลือกวันที่',
    value,
    hideTextError,
    disabledDate,
    disableFuture = false,
    hideDisableDateRange,
    ignoreDateRange,
    disablePast,
    disabledInput,
    disabledStartDate,
    disabledEndDate,
    disabledMonthlyPlan,
    disabledDateRange,
    isShouldDisableDate,
    currentDate,
    handleOpen = () => {},
    handleClose = () => {},
    sxTextError = {},
    onAccept,
    name,
  } = props

  const [newDate, setNewDate] = useState<Dayjs | null>(null)

  const handleChange = (newValue: Dayjs | null) => {
    if (newValue) {
      onChange?.(dayjs(newValue).format(window.__env__.REACT_APP_DATE_DB!))
    } else {
      onChange?.('')
    }
  }

  const handleAccept = (value: Dayjs | null) => {
    const newYear = newDate ? dayjs(newDate).year() : null
    const year = value ? dayjs(value).year() : null
    const sameYear = newYear === year
    handleChange(sameYear ? value : newDate || value)
    setNewDate(null)
  }

  const disabledList: string[] = []
  if (disabledDate && disabledDate.length > 0) {
    disabledDate.forEach((item) => {
      if (!_.isEqual(ignoreDateRange, item)) {
        let start = dayjs(item.startDate)
        const end = dayjs(item.finishDate)
        while (start <= end) {
          disabledList.push(
            dayjs(start).format(window.__env__.REACT_APP_DATE_DB!)
          )
          start = dayjs(start).add(1, 'day')
        }
      }
    })
  }

  const disableDateRange = (date: Dayjs) => {
    let isDisabled = false
    const newDate = dayjs(date).format(window.__env__.REACT_APP_DATE_DB!)

    if (currentDate && newDate < currentDate) return true

    const concatDate = dayjs(date)
      .hour(0)
      .format(window.__env__.REACT_APP_DATE_DB!)
    const find = disabledList.find((item) => concatDate === item)

    if (find) isDisabled = true
    let checkDateStart = dayjs(disabledStartDate).format(
      window.__env__.REACT_APP_DATE_DB!
    )
    let checkDateEnd = dayjs(disabledEndDate).format(
      window.__env__.REACT_APP_DATE_DB!
    )

    if (!disabledDateRange) {
      if (isShouldDisableDate) {
        checkDateStart = checkDateStart === 'Invalid Date' ? '' : checkDateStart
        checkDateEnd = checkDateEnd === 'Invalid Date' ? '' : checkDateEnd

        if (id === 'startDate') {
          if (newDate > checkDateEnd) {
            isDisabled = true
          }
          if (newDate < checkDateEnd) {
            isDisabled = false
          }

          if (checkDateEnd === '') isDisabled = false
        } else if (id === 'finishDate' || id === 'finishDate2') {
          if (newDate < checkDateStart) {
            isDisabled = true
          }
          if (newDate > checkDateStart) {
            isDisabled = false
          }
          if (checkDateStart === '') isDisabled = false
        } else {
          if (
            checkDateStart === '' &&
            checkDateEnd !== '' &&
            newDate > checkDateEnd
          ) {
            isDisabled = true
          } else if (
            checkDateStart !== '' &&
            checkDateEnd === '' &&
            newDate < checkDateStart
          ) {
            isDisabled = true
          } else if (
            checkDateStart !== '' &&
            checkDateEnd !== '' &&
            (newDate < checkDateStart || newDate > checkDateEnd)
          ) {
            isDisabled = true
          } else if (checkDateStart === '' || checkDateEnd === '') {
            isDisabled = false
          }
        }
      } else {
        if (
          id === 'startDate' &&
          disabledEndDate &&
          dayjs(disabledEndDate).isBefore(newDate)
        ) {
          isDisabled = true
        }

        if (
          (id === 'finishDate' || id === 'finishDate2') &&
          disabledEndDate &&
          !_.isEmpty(disabledEndDate) &&
          dayjs(disabledEndDate).isBefore(newDate)
        ) {
          isDisabled = true
        }
        if (
          (id === 'finishDate' || id === 'finishDate2') &&
          checkDateStart > newDate
        ) {
          isDisabled = true
        }
      }
    }
    if (disabledMonthlyPlan?.year && disabledMonthlyPlan?.year > 0) {
      const getFirstDayOfMonth = new Date(
        disabledMonthlyPlan.year,
        disabledMonthlyPlan.month,
        1
      )
      const getLastDayOfMonth = new Date(
        disabledMonthlyPlan.year,
        disabledMonthlyPlan.month + 1,
        0
      )

      const firstDayOfMonth = dayjs(getFirstDayOfMonth).format(
        window.__env__.REACT_APP_DATE_DB!
      )
      const lastDayOfMonth = dayjs(getLastDayOfMonth).format(
        window.__env__.REACT_APP_DATE_DB!
      )
      if (lastDayOfMonth < newDate) {
        isDisabled = true
      }
      if (firstDayOfMonth > newDate) {
        isDisabled = true
      }
    }

    return isDisabled
  }

  return (
    <Box
      sx={{ display: 'flex', flexDirection: 'column', width: '100%', ...boxSx }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        <Typography
          variant={'body2'}
          color={disabled ? 'text.gray' : 'text.secondary'}
          id={id}
          mb={0.6}
        >
          {labelText}
        </Typography>
        {required && (
          <Typography
            sx={{ lineHeight: 1, ml: 0.5 }}
            color={disabled || disabledInput ? 'text.gray' : 'error'}
          >
            *
          </Typography>
        )}
      </Box>

      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DesktopDatePicker
          format="DD/MM/YYYY"
          value={!_.isEmpty(value) ? dayjs(value) : null}
          shouldDisableDate={
            !hideDisableDateRange ? disableDateRange : undefined
          }
          disablePast={disablePast}
          disableFuture={disableFuture}
          label={''}
          name={name}
          onChange={onChange ? handleChange : () => {}}
          onYearChange={(value) => {
            setNewDate(dayjs(value))
            handleChange(value)
          }}
          slots={{
            textField: (params) => (
              <StyledTextField
                label="test"
                {...params}
                value={
                  params.value !== 'DD/MM/YYYY' ? params.value : placeholder
                }
                textError={textError}
                inputProps={{
                  ...params.inputProps,
                  placeholder: placeholder ? placeholder : 'เลือกวันที่',
                  'data-testid': 'date-picker',
                }}
              />
            ),
          }}
          disabled={disabledInput}
          onOpen={handleOpen}
          onClose={handleClose}
          onAccept={onAccept || handleAccept}
        />
      </LocalizationProvider>
      {!hideTextError && (
        <Box sx={{ mb: 3, ...sxTextError }}>
          {textError && (
            <Typography variant="body2" color="error">
              {textError}
            </Typography>
          )}
        </Box>
      )}
    </Box>
  )
}

export default DatePicker

const StyledTextField = styled(TextField, {
  shouldForwardProp: (prop) => prop !== 'textError',
})<{ textError?: string }>(({ theme, textError }) => ({
  width: '100%',
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor:
      textError && textError !== ''
        ? `${theme?.palette?.error?.main} !important`
        : `${theme?.palette?.text?.silver} !important`,
  },
  '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor:
      textError && textError !== ''
        ? `${theme?.palette?.error?.main} !important`
        : `${theme?.palette?.primary?.main} !important`,
  },
}))
