import { Box, Divider, Typography } from '@mui/material'
import CustomRadioGroup from '../../components/Input/CustomRadioGroup'
import CustomTextfield from '../../components/Input/CustomTextfield'
import { getCrudBy } from '../../components/CRUD/handler/useInputField'

export default function ConfigurationFormInterview() {
  const COMPANY_LEAD_TYPE_INTERVIEW = getCrudBy(
    'formData.COMPANY_LEAD_TYPE_INTERVIEW'
  )
  const REFERRAL_LEAD_TYPE_INTERVIEW = getCrudBy(
    'formData.REFERRAL_LEAD_TYPE_INTERVIEW'
  )

  return (
    <>
      <Typography variant="h6">การตั้งค่าระยะเวลาในการสัมภาษณ์</Typography>
      <CustomRadioGroup
        name={'COMPANY_LEAD_TYPE_INTERVIEW'}
        label={'เลือกรูปแบบวันหมดอายุ Company lead'}
        options={[
          {
            label: 'หมดอายุ',
            value: 'EXPIRE',
          },
          {
            label: 'ไม่หมดอายุ',
            value: 'NO_EXPIRE',
          },
        ]}
        required
      />
      <Box
        sx={{
          maxWidth: '500px',
          display:
            COMPANY_LEAD_TYPE_INTERVIEW === 'NO_EXPIRE' ? 'none' : 'block',
        }}
        mb={2}
      >
        <CustomTextfield
          crud={'COMPANY_LEAD_HOURS_INTERVIEW'}
          label={'ระยะเวลา Company lead (ชั่วโมง)'}
          placeholder="กรุณากรอก ตัวเลข 1-8,784 ชั่วโมง"
          required
          fullWidth
          boxSx={{ mb: 1 }}
          valueType={'number'}
          type="number"
          maxLength={4}
          maxNumber={8784}
          disabled={COMPANY_LEAD_TYPE_INTERVIEW === 'NO_EXPIRE'}
        />
        <Typography variant="body2">
          น้อยสุด 1 ชั่วโมง มากสุด 8,784 ชั่วโมง (4 หลัก)
        </Typography>
      </Box>
      <Divider sx={{ mb: 2 }} />
      <CustomRadioGroup
        name={'REFERRAL_LEAD_TYPE_INTERVIEW'}
        label={'เลือกรูปแบบวันหมดอายุ Referral lead'}
        options={[
          {
            label: 'หมดอายุ',
            value: 'EXPIRE',
          },
          {
            label: 'ไม่หมดอายุ',
            value: 'NO_EXPIRE',
          },
        ]}
        required
      />
      <Box
        sx={{
          maxWidth: '500px',
          display:
            REFERRAL_LEAD_TYPE_INTERVIEW === 'NO_EXPIRE' ? 'none' : 'block',
        }}
      >
        <CustomTextfield
          crud={'REFERRAL_LEAD_HOURS_INTERVIEW'}
          label={'ระยะเวลา Referral lead (ชั่วโมง)'}
          placeholder="กรุณากรอก ตัวเลข 1-8,784 ชั่วโมง"
          required
          fullWidth
          boxSx={{ mb: 1 }}
          valueType={'number'}
          type="number"
          maxLength={4}
          maxNumber={8784}
          disabled={REFERRAL_LEAD_TYPE_INTERVIEW === 'NO_EXPIRE'}
        />
        <Typography variant="body2">
          น้อยสุด 1 ชั่วโมง มากสุด 8,784 ชั่วโมง (4 หลัก)
        </Typography>
      </Box>
    </>
  )
}
