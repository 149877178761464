export function addDashInIdCardNo(
  idCardNo: string | null | undefined
): string | null | undefined {
  if (!idCardNo) {
    return idCardNo
  }
  return idCardNo.replace(
    /(\d{1})(\d{4})(\d{5})(\d{2})(\d{1})/,
    '$1-$2-$3-$4-$5'
  )
}

export function removeDashInIdCardNo(
  idCardNo: string | null | undefined
): string | null | undefined {
  if (!idCardNo) {
    return idCardNo
  }
  return idCardNo.replace(/-/g, '')
}
