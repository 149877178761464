export const sectionNameOptions = [
  {
    label: 'ประวัติส่วนตัว',
    value: 'Personal',
  },
  {
    label: 'ประวัติการศึกษา / ประสบการณ์การทำงาน / รายละเอียดอื่นๆ',
    value: 'Education-Work',
  },
  {
    label: 'รายการลดหย่อนเพื่อการคำนวณภาษีเงินได้บุคคลธรรมดา',
    value: 'Tax-Deduction',
  },
  {
    label: 'นโยบาย',
    value: 'Policy',
  },
  {
    label: 'แนบเอกสาร',
    value: 'Attachment',
  },
  {
    label: 'อื่น ๆ',
    value: 'Other',
  },
]

export const topicOptions = [
  {
    label: 'ค่าธรรมเนียมออกรหัส',
    value: 'ค่าธรรมเนียมออกรหัส',
  },
  {
    label: 'รูปถ่าย',
    value: 'รูปถ่าย',
  },
  {
    label: 'ใบสมัคร',
    value: 'ใบสมัคร',
  },
  {
    label: 'บัตรประชาชน',
    value: 'บัตรประชาชน',
  },
  {
    label: 'ทะเบียนบ้าน',
    value: 'ทะเบียนบ้าน',
  },
  {
    label: 'ใบเปลี่ยนชื่อ',
    value: 'ใบเปลี่ยนชื่อ',
  },
  {
    label: 'แบบแจ้งรายการลดหย่อนภาษี',
    value: 'แบบแจ้งรายการลดหย่อนภาษี',
  },
  {
    label: 'สมุดบัญชี ธนาคาร',
    value: 'สมุดบัญชี ธนาคาร',
  },
  {
    label: 'อื่นๆ',
    value: 'อื่นๆ',
  },
]
