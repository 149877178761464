import { WebStorageStateStore, StateStore } from 'oidc-client-ts'
import { type AuthProviderProps } from 'react-oidc-context'

type ExtendedAuthProviderProps = AuthProviderProps & {
  authority: string
  client_id: string
  client_secret: string
  redirect_uri: string
  post_logout_redirect_uri: string
  userStore: StateStore
  automaticSilentRenew: boolean
  scope: string
}

const Keycloak: ExtendedAuthProviderProps = {
  authority: `${window.__env__.REACT_APP_KEY_URL}/realms/${window.__env__.REACT_APP_KEY_REALM}`,
  client_id: window.__env__.REACT_APP_KEY_CLIENT_ID,
  client_secret: window.__env__.REACT_APP_KEY_CLIENT_SECRET,
  redirect_uri: `${window.location.origin}/`,
  post_logout_redirect_uri: `${window.location.origin}/`,
  userStore: new WebStorageStateStore({
    store: window.localStorage,
  }),
  automaticSilentRenew: false,
  scope: 'openid email profile offline_access',
}
export default Keycloak
