import { type InputBaseProps } from '@mui/material'
import { useDispatch, useSelector } from '../../../redux/store'
import { selectCrudConfig } from '../../../redux/selector'
import { memo, useCallback, useEffect, useState, ChangeEvent } from 'react'
import TextInput from '../../Input/TextInput'
import { useInputField } from '../hooks/useInputField'
import { createDebouncedSetField } from '../handler/createDebouncedSetField'

interface CustomTextAreaProps
  extends Omit<
    InputBaseProps,
    | 'required'
    | 'disabled'
    | 'multiline'
    | 'rows'
    | 'name'
    | 'type'
    | 'id'
    | 'value'
    | 'onChange'
    | 'defaultValue'
    | 'maxRows'
  > {
  required?: boolean
  disabled?: boolean
  fieldName: string
  labelName: string
}

const CustomTextArea: React.FC<CustomTextAreaProps> = memo(
  ({ required = false, disabled, fieldName, labelName, ...other }) => {
    const dispatch = useDispatch()
    const { value, isError, message } = useInputField(fieldName)
    const [inputValue, setInputValue] = useState(value)
    const crudConfig = useSelector(selectCrudConfig)

    const debouncedSetField = useCallback(
      createDebouncedSetField(dispatch, fieldName),
      [dispatch]
    )

    function handleChange(event: ChangeEvent<HTMLInputElement>) {
      setInputValue(event.target.value)
      debouncedSetField(event.target.value)
    }

    useEffect(() => {
      setInputValue(value)
    }, [value])

    return (
      <TextInput
        multiline={true}
        rows={3}
        required={required}
        disabled={disabled}
        name={fieldName}
        type="basic"
        labelText={labelName}
        placeholder={
          other.placeholder ?? `${required ? 'กรุณากรอก' : ''}${labelName}`
        }
        id={`${crudConfig?.moduleSlug}-${fieldName}`}
        value={inputValue ?? ''}
        textError={message}
        isShowTextError={isError}
        onChange={handleChange}
        {...other}
      />
    )
  }
)
CustomTextArea.displayName = 'CustomTextArea'

export { CustomTextArea }
