import MenuList from '@mui/material/MenuList'
import Typography from '@mui/material/Typography'
import { Box, LinearProgress, Link, SxProps } from '@mui/material'
import { shallowEqual, useSelector } from 'react-redux'
import { SliceModel } from '../../redux/models'
import { dispatch } from '../../redux/store'
import { loadNotification } from './handler/loadNotification'
import { changeFilterType } from './handler/changeFilterType'
import ItemView from './ItemView'
import _ from 'lodash'
import InboxRoundedIcon from '@mui/icons-material/InboxRounded'

export default function NotiContainer(props: { menuListSx?: SxProps }) {
  const { items, filterType, isLoading, meta } = useSelector(
    (state: SliceModel) => ({
      items: state.notification.items,
      filterType: state.notification.filterType,
      isLoading: state.notification.isLoading,
      meta: state.notification.meta,
    }),
    shallowEqual
  )

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const target = event.currentTarget
    const { scrollTop, scrollHeight, clientHeight } = target
    const limit = 5
    if (scrollTop + clientHeight >= scrollHeight - 20) {
      if (!isLoading && meta.page < Math.ceil(meta.total / limit)) {
        dispatch(
          loadNotification({
            page: meta.page + 1,
            tab: filterType,
            append: true,
            limit: limit,
          })
        )
      }
    }
  }

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          py: 2,
          px: 3,
          borderBottom: 1,
          borderColor: 'grey.grey200',
          justifyContent: 'space-between',
        }}
      >
        <Typography variant="h5">การแจ้งเตือน</Typography>
        <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
          <Link href={`/notifications`} sx={{ textDecoration: 'none' }}>
            <Typography variant="body2" color={'primary'}>
              ดูทั้งหมด
            </Typography>
          </Link>
        </Box>
      </Box>
      <Box visibility={isLoading ? 'visible' : 'hidden'}>
        <LinearProgress />
      </Box>

      <Box
        sx={{
          display: 'flex',
          py: 2,
          px: 3,
          gap: 2,
          pt: 1,
        }}
      >
        <Typography
          variant={filterType === 'unread' ? 'body2b' : 'body2'}
          sx={{
            borderBottom: 4,
            px: 1,
            borderColor: filterType === 'unread' ? '' : 'transparent',
            cursor: 'pointer',
          }}
          color={'primary'}
          onClick={() => dispatch(changeFilterType('unread'))}
        >
          ยังไม่อ่าน
        </Typography>
        <Typography
          variant={filterType === 'all' ? 'body2b' : 'body2'}
          sx={{
            borderBottom: 4,
            px: 1,
            borderColor: filterType === 'all' ? '' : 'transparent',
            cursor: 'pointer',
          }}
          color={'primary'}
          onClick={() => dispatch(changeFilterType('all'))}
        >
          ทั้งหมด
        </Typography>
      </Box>

      <Box
        onScroll={handleScroll}
        sx={{
          maxHeight: { xs: '65%', sm: '350px' },
          overflow: 'auto',
          scrollbarWidth: '10px',
          zIndex: 10,
          position: 'relative',
          '&::-webkit-scrollbar': {
            width: '10px',
            height: '10px',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#cccccc',
            borderRadius: '5px',
          },
          '&::-webkit-scrollbar-thumb:hover': {
            backgroundColor: '#cccccc',
          },
          '&::-webkit-scrollbar-track': {
            backgroundColor: '#f1f1f1',
          },
        }}
      >
        <MenuList
          sx={{
            float: 'right',
            p: 0,
            m: 0,
            width: 490,
            ...props.menuListSx,
          }}
        >
          {items.map((item, key) => (
            <ItemView key={key} item={item} />
          ))}
        </MenuList>
        {_.size(items) === 0 && (
          <Box sx={{ py: 2, px: 3, textAlign: 'center' }}>
            <InboxRoundedIcon fontSize={'large'} sx={{ color: '#CCCCCC' }} />
            <Typography>ไม่มีรายการ</Typography>
          </Box>
        )}
      </Box>
    </>
  )
}
