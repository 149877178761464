import type { TableCellProps } from '@mui/material/TableCell'

export const auditHistoryHeader = [
  'ลำดับ',
  'ขั้นตอน',
  'เหตุผล',
  'รายละเอียด',
  'ความถูกต้องของข้อมูล',
  'ผู้ตรวจ',
  'วันที่ทำรายการ',
]

export const additionalSubmissionHistoryHeader = [
  'ผู้ตรวจ',
  'ตำแหน่ง',
  'ผลการพิจารณา',
  'เอกสารผลการพิจารณา',
]

export const editHistoryHeader = [
  'หัวข้อ',
  'ข้อมูลที่กรอก',
  'เหตุผล',
  'รายละเอียด',
  'วันเวลาที่ออก Memo',
  'วันเวลาที่ถูกแก้ไข',
]

type MemoHeader = TableCellProps & {
  label: string
}

export const memoHeader: MemoHeader[] = [
  { label: 'ลำดับ', align: 'center' },
  { label: 'รายการ' },
  { label: 'เหตุผล' },
  { label: 'รายละเอียด' },
  { label: 'คำสั่ง', align: 'center' },
]

export enum TitleStepNumber {
  'Personal' = 1,
  'Education-Work' = 2,
  'Tax-Deduction' = 3,
  'Policy' = 4,
  'Attachment' = 5,
}
