export const parseJWT = <T>(token: string | undefined): T | null => {
  try {
    return token ? (JSON.parse(atob(token.split('.')[1])) as T) : null
  } catch (e) {
    console.log(e)

    return null
  }
}

export type JwtPayload = {
  exp: number
  [key: string]: any // Add more properties if needed
}
