import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { initialState, DashboardState } from './model'
import { DashboardDetail } from '../type/types'

const DashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setDashboard: (
      state: DashboardState,
      action: PayloadAction<DashboardDetail>
    ) => {
      state.data = action.payload
    },
    setDashboardIsLoading: (
      state: DashboardState,
      action: PayloadAction<boolean>
    ) => {
      state.isLoading = action.payload
    },
  },
})
export const { setDashboard, setDashboardIsLoading } =
  DashboardSlice.actions

export default DashboardSlice.reducer
