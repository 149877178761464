import get from 'lodash/get'

export function getBodyMutation(data: Record<string, string> = {}) {
  return {
    id: get(data, 'id', ''),
    uuid: get(data, 'uuid', undefined),
    title: get(data, 'title', ''),
    icon: get(data, 'icon', ''),
    status: get(data, 'status', false),
    createdBy: get(data, 'createdBy', ''),
    updatedBy: get(data, 'updatedBy', ''),
    createdAt: get(data, 'createdAt', ''),
    updatedAt: get(data, 'updatedAt', ''),
  }
}
