export interface DecisionBody {
  fullFormUuid: string
  hasSendConsideration: string
  hasSendRam: boolean
  RamUuid: string
  hasSendSRam: boolean
  SRamUuid: string
  hasSendCADTO: boolean
  CADTOUuid: string
  hasSendCDO: boolean
  CDOUuid: string
  detail: string
  metaId: number[]
  ramMetaId: number[]
}

export enum ActionByEnum {
  SRAM = 'SRAM',
  RAM = 'RAM',
  CADTO = 'CADTO',
  CDO = 'CDO',
}
export interface ResultConsiderationBody {
  fullFormUuid: string
  hasSendConsideration?: string
  actionBy?: ActionByEnum
  action?: string // Request , Confirm
  metaId?: number[]
  ramMetaId?: number[]
  result?: boolean // ถ้าเป็น Request คือ ยืนยันผล ต้องมี result
}

export interface CcmSendBody {
  fullFormUuid: string
}
export interface CcmResultBody {
  fullFormUuid: string
  metaId: number[]
  hasSendDecision: string
}
export enum DecisionStatus {
  COMPLETED = 'COMPLETED',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
}
export interface HistoryAuditBody {
  fullFormUuid: string
  limit?: number
  page?: number
  order?: string
  sort?: string
}
