import axios from 'axios'
import isEmpty from 'lodash/isEmpty'

import { ERROR_NETWORK, ERRORS_STATUS } from '../constants/status'
import { handleDispatchException } from '../utils/dispatchError'
import Keycloak from '../keyCloak'
import OneAccount from '../OneAccount'
import qs from 'qs'
import { parseJWT, JwtPayload } from './parseJWT'
import _ from 'lodash'
import { handleDispatch401Exception } from './dispatchError401'

const instanceKeycloak = axios.create({
  baseURL: `${Keycloak.authority}`,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
  timeout: 60000,
})

const instanceOneAccount = axios.create({
  baseURL: `${OneAccount.authority}`,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
  timeout: 10000,
})

const axiosInstance = axios.create({
  baseURL: window.__env__.REACT_APP_API_URL,
  headers: {
    Pragma: 'no-cache',
  },
})

const refreshToken = async () => {
  try {
    const refreshToken = localStorage.getItem('refresh_token')
    const keycloak = window.__env__.ENV === 'DEV' ? Keycloak : OneAccount
    const body = {
      grant_type: 'refresh_token',
      refresh_token: refreshToken,
      scope: 'openid email profile offline_access',
      client_id: keycloak.client_id,
      client_secret: '',
    }

    if (keycloak.client_secret) {
      body.client_secret = keycloak.client_secret
    }

    if (window.__env__.ENV === 'DEV') {
      const getToken = await instanceKeycloak({
        method: 'post',
        url: `/protocol/openid-connect/token`,
        data: qs.stringify(body),
      })
      const { refresh_token, access_token, token_type } = getToken.data
      localStorage.setItem('refresh_token', refresh_token)
      localStorage.setItem('token', `${token_type} ${access_token}`)
      return `${token_type} ${access_token}`
    } else {
      body['scope'] = 'openid email'
      const getToken = await instanceOneAccount({
        method: 'post',
        url: `/as/token.oauth2`,
        data: qs.stringify(body),
      })

      const { refresh_token, access_token, token_type } = getToken.data
      localStorage.setItem('refresh_token', refresh_token)
      localStorage.setItem('token', `${token_type} ${access_token}`)
      return `${token_type} ${access_token}`
    }
  } catch (error) {
    console.log('session expire ->', error)
    return 'expire'
  }
}

axiosInstance.interceptors.request.use(
  (req) => {
    const token = localStorage.getItem('token')
    if (!isEmpty(token)) {
      req.headers.Authorization = token
    }

    if (!req.headers['Content-Type']) {
      req.headers['Content-Type'] = 'application/json'
    }

    return req
  },
  (error) => Promise.reject(new Error(error))
)

axiosInstance.interceptors.response.use(
  async (response) => {
    const getToken = localStorage.getItem('token')

    console.log(getToken, 'check get token')
    const token = getToken?.replace('Bearer ', '')
    const decodedJwt = parseJWT<JwtPayload>(token)

    if (decodedJwt && token) {
      const expire = 60000 * 10
      const expirationTime = decodedJwt?.exp * 1000 - expire
      const dateNow = new Date().getTime()
      if (dateNow >= expirationTime) {
        await refreshToken()
      }
    }
    return response
  },
  async (error) => {
    const { statusText } = error?.response ?? {}

    const status = _.get(error, 'response.status', 500)
    const config = error?.config
    if (status === 401 && !config?.sent && config.expire !== 'expire') {
      config.sent = true
      config.expire = await refreshToken()
      config.headers = { ...config.headers }
      return axiosInstance(config)
    }
    if (status === 401 && config?.expire === 'expire') {
      handleDispatch401Exception()
      return Promise.reject(error)
    }

    if (
      ERRORS_STATUS.includes(error.response?.status) ||
      error.code === ERROR_NETWORK
    ) {
      handleDispatchException()
    }

    return Promise.reject(
      error ?? new Error(statusText) ?? new Error('ระบบขัดข้อง')
    )
  }
)

export default axiosInstance
