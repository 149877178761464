import { type ChangeEvent } from 'react'
import Button, { type ButtonProps } from '@mui/material/Button'
import styled from '@mui/material/styles/styled'
import UploadFileIcon from '@mui/icons-material/UploadFile'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import InputLabel from '@mui/material/InputLabel'
import TextField, { type TextFieldProps } from '@mui/material/TextField'
import type { SxProps, Theme } from '@mui/material/styles'

const InputFileStyle = styled(TextField)({
  display: 'none',
})

const ButtonUploadStyle = styled(Button)<ButtonProps>(({ theme }) => ({
  height: 40,
  borderRadius: 4,
  background: theme.palette.primary.main,
  color: theme.palette.common.white,
  fontSize: 18,
  boxShadow: `0px 4px 12px 1px ${theme.palette.blue.oceanBlue}59`,
}))

const RootStyle = styled(Stack)(({ theme }) => ({
  flexDirection: 'column',
  gap: theme.spacing(1),
}))

const WrapperStyle = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.spacing(6),
  borderRadius: '8px',
}))

interface Props extends Omit<TextFieldProps, 'type' | 'id' | 'inputProps'> {
  handleUploadFile: (e: ChangeEvent<HTMLInputElement>) => void
  textError?: string
  description?: React.ReactElement
  disabled?: boolean
  sx?: SxProps<Theme>
  acceptFiles?: string
  handleClick?: VoidFunction
  textButton?: React.ReactElement | string
}

function BasicUpload(props: Readonly<Props>) {
  const {
    handleUploadFile,
    textError,
    description,
    disabled,
    sx,
    acceptFiles,
    handleClick,
    textButton = 'เลือกไฟล์',
    ...other
  } = props

  return (
    <RootStyle>
      <WrapperStyle sx={sx} onClick={handleClick}>
        {description}

        <ButtonUploadStyle
          variant="contained"
          component="span"
          className="basic-upload"
        >
          {!disabled && (
            <InputLabel htmlFor="input-file">
              <InputFileStyle
                id="input-file"
                type="file"
                inputProps={{ accept: acceptFiles }}
                onChange={handleUploadFile}
                {...other}
              />
            </InputLabel>
          )}
          <Stack
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
          >
            <UploadFileIcon />
            <Typography
              variant="buttonm"
              sx={{ cursor: 'pointer', marginLeft: 1 }}
            >
              {textButton}
            </Typography>
          </Stack>
        </ButtonUploadStyle>
      </WrapperStyle>

      {textError && (
        <Typography variant="body1" color="error">
          {textError}
        </Typography>
      )}
    </RootStyle>
  )
}

export default BasicUpload
