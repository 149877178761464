import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import _ from 'lodash'
import { DrawerState, DrawerTableState, initialState } from './model'

const DrawerSlice = createSlice({
  name: 'drawer',
  initialState,
  reducers: {
    openDrawer: (state: DrawerState, action: PayloadAction<DrawerState>) => {
      const payload = action.payload
      state.isOpen = _.get(payload, 'isOpen', true)
      state.isLoading = _.get(payload, 'isLoading', false)
      state.content = _.get(payload, 'content', null)
    },
    closeDrawer: (state: DrawerState) => {
      state.isOpen = false
      state.isLoading = false
      state.content = null
    },
    setIsLoadingDrawer: (
      state: DrawerState,
      action: PayloadAction<boolean>
    ) => {
      state.isLoading = action.payload
    },
    setDrawerTable: (
      state: DrawerState,
      action: PayloadAction<DrawerTableState>
    ) => {
      state.table = action.payload
    },
  },
})

export const { openDrawer, closeDrawer, setIsLoadingDrawer, setDrawerTable } =
  DrawerSlice.actions

export default DrawerSlice.reducer
