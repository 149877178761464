import * as React from 'react'
import { styled } from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'

import _ from 'lodash'
import {
  Avatar,
  Box,
  SxProps,
  tableCellClasses,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
} from '@mui/material'
import { CrudForm } from '../../../../components/CRUD/types/CrudState'
import { StyledTableCell } from '../../../../components/Table/Styled'
import { useCompare } from './handler/useCompare'
import { displayTextValue } from './handler/displayTextValue'
import MenuVersion from './MenuVersion'
import { dispatch } from '../../../../redux/store'
import { getFileUrl } from '../../../../utils/getFileUrl'
import { setShowFile } from '../../slices/applicant-details'
import { AttachmentMeta } from '../../../../types/full-form'
import PictureAsPdfRoundedIcon from '@mui/icons-material/PictureAsPdfRounded'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))

export interface CompareTableCol {
  fieldName: string
  fieldTitle: string
  headerName?: string
  other?: string
  width?: number | string
  tooltip?: string
  renderCell?: (row: CrudForm) => React.ReactNode
}

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: '#F8FAFC',
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}))

export default function CompareTableItem(props: {
  rows: CompareTableCol[]
  showCompare?: boolean
  hideHeader?: boolean
  sx?: SxProps
  type?: string
}) {
  const {
    rows = [],
    showCompare = false,
    hideHeader = false,
    type = 'text',
  } = props

  const compare = useCompare()
  const firstIndex = compare.firstIndex
  const secondIndex = compare.secondIndex
  const isCompare = compare.isCompare
  const items = compare.items

  const firstFiles = _.get(items, `[${firstIndex}].attachmentMetas`, [])
  const secondFiles = _.get(items, `[${secondIndex}].attachmentMetas`, [])

  const renderCellText = (value: string | number, warning?: boolean) => {
    return (
      <StyledTableCell
        width={isCompare ? '30%' : '50%'}
        sx={{ backgroundColor: warning ? '#fef0de' : '' }}
      >
        <Box sx={{ display: 'flex' }}>
          <Typography variant="body2">{value}</Typography>
        </Box>
      </StyledTableCell>
    )
  }
  const renderCellFile = (files: AttachmentMeta[], warning = false) => {
    return (
      <StyledTableCell
        width={isCompare ? '30%' : '50%'}
        sx={{ backgroundColor: warning ? '#fef0de' : '' }}
      >
        <Box sx={{ display: 'flex' }}>
          {files.map((file, index) => (
            <>
              {file.type.includes('image') ? (
                <>
                  <Avatar
                    key={index}
                    variant={'square'}
                    src={getFileUrl(file.url)}
                    sx={{ mr: 1, borderRadius: 1, cursor: 'pointer' }}
                    onClick={() => {
                      dispatch(setShowFile({ isOpen: true, file: file }))
                    }}
                  />
                </>
              ) : (
                <>
                  <PictureAsPdfRoundedIcon
                    fontSize={'large'}
                    color="error"
                    sx={{ mr: 2, cursor: 'pointer' }}
                    onClick={() => {
                      dispatch(setShowFile({ isOpen: true, file: file }))
                    }}
                  />
                </>
              )}
            </>
          ))}
        </Box>
      </StyledTableCell>
    )
  }
  const renderFile = (row: CompareTableCol) => {
    const arrFirst = firstFiles.filter((f) => f.fieldName === row.fieldName)
    const arrSecond = secondFiles.filter((f) => f.fieldName === row.fieldName)

    if (isCompare)
      return (
        <>
          {renderCellFile(arrFirst)}
          {renderCellFile(arrSecond)}
        </>
      )
    else return renderCellFile(arrFirst)
  }
  const renderText = (row: CompareTableCol) => {
    const firstValue = displayTextValue(
      row.fieldName,
      _.get(items, `[${firstIndex}].${row.fieldName}`, '-'),
      row.other
    )
    const secondValue = displayTextValue(
      row.fieldName,
      _.get(items, `[${secondIndex}].${row.fieldName}`, '-'),
      row.other
    )

    const firstValueText =
      !_.isEmpty(firstValue) || _.isNumber(firstValue) ? firstValue : '-'
    const secondValueText =
      !_.isEmpty(secondValue) || _.isNumber(secondValue) ? secondValue : '-'

    if (isCompare)
      return (
        <>
          {renderCellText(firstValueText, firstValueText !== secondValueText)}
          {renderCellText(secondValueText)}
        </>
      )
    else return renderCellText(firstValueText, false)
  }
  const randerCompare = (row: CompareTableCol) => {
    return row.other === 'file' ? renderFile(row) : renderText(row)
  }

  const titleLabel = () => {
    if (type === 'file') {
      return isCompare ? 'เอกสารแนบครั้งล่าสุด' : 'เอกสารแนบ'
    } else {
      return isCompare ? 'ข้อมูลที่กรอกครั้งล่าสุด' : 'ข้อมูลที่กรอก'
    }
  }
  const titleVLabel = () => {
    if (type === 'file') {
      return 'เอกสารแนบครั้งที่'
    } else {
      return 'ข้อมูลที่กรอกครั้งที่'
    }
  }
  const versions: any[] = []
  items.forEach((row, index) => {
    versions.push({
      label:
        index === 0
          ? titleLabel()
          : `${titleVLabel()} ${_.size(items) - index}`,
      value: index,
    })
  })

  return (
    <>
      <TableContainer sx={props.sx}>
        <Table
          aria-label="customized table"
          sx={{
            [`& .${tableCellClasses.root}`]: {
              borderBottom: 'solid 1px #f1f1f1',
            },
          }}
        >
          {!hideHeader && (
            <TableHead>
              <TableRow>
                <StyledTableCell width={isCompare ? '40%' : '50%'}>
                  {type === 'file' ? 'เอกสาร' : 'หัวข้อ'}
                </StyledTableCell>
                {isCompare ? (
                  <>
                    <StyledTableCell width={'30%'}>
                      <Box display={'flex'} gap={1} alignItems={'center'}>
                        <Typography variant="body2b" sx={{ width: '80%' }}>
                          {_.get(versions, `${firstIndex}.label`, '-')}
                        </Typography>

                        <Box
                          sx={{
                            visibility: showCompare ? 'visible' : 'hidden',
                          }}
                        >
                          <MenuVersion
                            options={versions}
                            currentIndex={firstIndex}
                            position={'first'}
                          />
                        </Box>
                      </Box>
                    </StyledTableCell>
                    <StyledTableCell width={'30%'}>
                      <Box display={'flex'} gap={1} alignItems={'center'}>
                        <Typography variant="body2b" sx={{ width: '80%' }}>
                          {_.get(versions, `${secondIndex}.label`, '-')}
                        </Typography>
                        <Box
                          sx={{
                            visibility: showCompare ? 'visible' : 'hidden',
                          }}
                        >
                          <MenuVersion
                            options={versions}
                            currentIndex={secondIndex}
                            position={'second'}
                          />
                        </Box>
                      </Box>
                    </StyledTableCell>
                  </>
                ) : (
                  <StyledTableCell width={'50%'}>
                    <Box display={'flex'} gap={1} alignItems={'center'}>
                      <Typography variant="body2b" sx={{ width: '80%' }}>
                        {_.get(versions, `${firstIndex}.label`, '-')}
                      </Typography>
                    </Box>
                  </StyledTableCell>
                )}
              </TableRow>
            </TableHead>
          )}

          <TableBody sx={{ position: 'relative' }}>
            {rows.map((row, index) => (
              <StyledTableRow key={index}>
                <StyledTableCell width={isCompare ? '40%' : '50%'}>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <Typography variant="body2">
                      {_.get(row, `fieldTitle`, '-')}{' '}
                    </Typography>
                    {!_.isEmpty(row.tooltip) && (
                      <LightTooltip
                        title={
                          <Typography variant="body2">
                            {row?.tooltip}
                          </Typography>
                        }
                        placement="top-end"
                      >
                        <InfoOutlinedIcon />
                      </LightTooltip>
                    )}
                  </Box>
                </StyledTableCell>
                {randerCompare(row)}
              </StyledTableRow>
            ))}
            {compare.isLoading && (
              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  backgroundColor: 'rgba(255,255,255,.8)',
                }}
              ></Box>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}
