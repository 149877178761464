import _ from 'lodash'
import Button, { ButtonProps } from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'

const getDefSizeSX = (size: string | undefined) => {
  const sizeSx = {
    boxShadow: 'none',
    borderRadius: 1,
  }

  return sizeSx
}
const CustomButton = (props: ButtonProps) => {
  return (
    <Button
      variant="contained"
      color="info"
      {...props}
      sx={{
        minWidth: { xs: '100px' },
        alignItems: 'center',
        verticalAlign: 'middle',
        ':hover': {
          boxShadow: 'none',
        },
        ':active': {
          boxShadow: 'none',
        },
        ...getDefSizeSX(props.size),
        ..._.get(props, 'sx', {}),
      }}
    >
      {props.children}
    </Button>
  )
}
interface CustomLoadingButtonProps extends ButtonProps {
  loading?: boolean
}
export const CustomLoadingButton = (props: CustomLoadingButtonProps) => {
  return (
    <Button
      variant="contained"
      color="info"
      {...props}
      disabled={props.disabled || props.loading}
      endIcon={
        props.loading ? <CircularProgress size={16} color={'inherit'} /> : ''
      }
      sx={{
        minWidth: { xs: '100px' },
        ':hover': {
          boxShadow: 'none',
        },
        ':active': {
          boxShadow: 'none',
        },
        ...getDefSizeSX(props.size),
        ..._.get(props, 'sx', {}),
      }}
    >
      {props.children}
    </Button>
  )
}
export default CustomButton
