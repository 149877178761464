export const headerCells: any = [
  {
    id: 'codeId',
    label: 'หมายเลข',
  },
  {
    id: 'name',
    label: 'ชื่อผู้สมัคร',
    link: '/dc-admin/applicant-details',
  },
  {
    id: 'email',
    label: 'อีเมล',
  },
  {
    id: 'idCardNo',
    label: 'เลขบัตรประชาชน',
  },
  {
    id: 'registerDate',
    label: 'วันที่สมัคร',
  },
  {
    id: 'contractor',
    label: 'ผู้รับงาน',
  },

  {
    id: 'statusTh',
    label: 'สถานะ',
    hideSortIcon: true,
  },
]
