import dayjs from 'dayjs'
import {
  startLoading,
  stopLoading,
} from '../../../../../../components/Table/redux'
import { EXPORT_TYPE_TH } from '../../../../../../constants/dc-admin'
import { dispatch, store } from '../../../../../../redux/store'
import { downloadFile } from '../../../../../../services/utils/downloadFile'
import { dcAdminListFullFormDownload } from '../../../../../../utils/apiPath'
import { mutateFilterPropToBody } from '../mutation/filterPropToBody'

export const handleDownloadFullForm = async () => {
  dispatch(startLoading())

  const { isSelectAll, selected } = store.getState().table.table
  const body = {
    ...mutateFilterPropToBody(),
    isSelectAll,
    list: selected,
  }
  const currentDate = dayjs(new Date()).format('YYYYMMDD')

  dispatch(
    downloadFile({
      url: dcAdminListFullFormDownload,
      body: body,
      fileName: `${EXPORT_TYPE_TH.FULLFORM}-${currentDate}.xlsx`,
    })
  )
  dispatch(stopLoading())
}
