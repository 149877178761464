import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { initialState, CompareState, CompareFullForm } from './model'

const CompareSlice = createSlice({
  name: 'DC-Compare-Table',
  initialState,
  reducers: {
    setCompareItems: (
      state: CompareState,
      action: PayloadAction<CompareFullForm[]>
    ) => {
      state.items = action.payload
    },
    setIsCompareItems: (
      state: CompareState,
      action: PayloadAction<boolean>
    ) => {
      state.isCompare = action.payload
    },
    setCompareFirstIndex: (
      state: CompareState,
      action: PayloadAction<number>
    ) => {
      state.firstIndex = action.payload
    },
    setCompareSecondIndex: (
      state: CompareState,
      action: PayloadAction<number>
    ) => {
      state.secondIndex = action.payload
    },
    setCompareIsLoading: (
      state: CompareState,
      action: PayloadAction<boolean>
    ) => {
      state.isLoading = action.payload
    },
  },
})
export const {
  setCompareItems,
  setIsCompareItems,
  setCompareFirstIndex,
  setCompareSecondIndex,
  setCompareIsLoading,
} = CompareSlice.actions

export default CompareSlice.reducer
