import get from 'lodash/get'

import { store } from '../../../../../../redux/store'
import { enumStatus } from '../../../../../../components/CRUD/enum/status'

export function postBodyMutation(data: Record<string, string>) {
  const status = get(data, 'status', enumStatus.INACTIVE)

  const {
    data: { uploadData },
  } = store.getState().upload

  return {
    uuid: get(data, 'uuid', undefined),
    id: get(data, 'id', ''),
    name: get(data, 'title', ''),
    title: get(data, 'title', ''),
    icon: uploadData,
    status: status && status !== '' ? status : enumStatus.INACTIVE,
  }
}
