export const headerCells: any = [
  {
    id: 'status',
    label: 'status',
    width: '20px',
    hideSortIcon: true,
  },
  {
    id: 'id',
    disablePadding: false,
    label: 'ID',
    width: '90px',
  },
  {
    id: 'name',
    label: 'ชื่อ CDO',
  },
  {
    id: 'email',
    label: 'อีเมล',
  },
  {
    id: 'phoneNumber',
    label: 'เบอร์โทรศัพท์',
  },
  {
    id: 'action',
    disablePadding: false,
    label: 'คำสั่ง',
    width: '90px',
    hideSortIcon: true,
    editPath: '/cdo-profile/form',
    hideDelete: false,
  },
]
