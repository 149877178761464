import { memo, useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import Box from '@mui/material/Box'
import _ from 'lodash'
import { useLocalStorage } from '@rehooks/local-storage'

import LeftMenu from './components/LeftMenu/LeftMenu'
import { leftMenuList } from './components/LeftMenu/LeftMenuData'
import { theme } from './styles/themes/theme'
import Keycloak from './keyCloak'
import OneAccount from './OneAccount'
import qs from 'qs'
import axios from 'axios'
import { handleLogout } from './components/AppBar/Appbar'
import { useAuth } from 'react-oidc-context'
import { parseJWT, JwtPayload } from './utils/parseJWT'
import { useAuthGuard } from './hooks/useAuthGuard'
import { resetTable } from './components/Table/redux/index'
import { dispatch } from './redux/store'

const instanceOneAccount = axios.create({
  baseURL: `${OneAccount.authority}`,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
  timeout: 10000,
})

const instanceKeycloak = axios.create({
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
  timeout: 60000,
})

type Props = {
  children: React.ReactNode
  location?: string
  layout: string
}

// interface KeycloakBody {
//   grant_type: string
//   refresh_token: string | null
//   scope: string
//   client_id?: string
//   client_secret?: string
// }

const Layout = memo((props: Readonly<Props>) => {
  const lastLocation = useLocation()
  const storedUser = localStorage.getItem('user')
  const [leftMenuLS, setLeftMenuLS] = useLocalStorage<boolean[]>('leftMenu2')
  const [menu, setMenu] = useState<ReturnType<typeof leftMenuList>>([])

  const [leftMenuDrawer, setLeftMenuDrawer] = useState(false)

  const leftMenu = leftMenuList()
  const auth = useAuth()
  const [sessionExpire, setSessionExpire] = useState({
    open: false,
    title: 'session หมดอายุ',
    content: 'กรุณาเข้าสู่ระบบใหม่อีกครั้ง',
    variant: 'fail',
    onConfirmClick: () => handleLogout(auth, true),
  })

  const refreshToken = async () => {
    try {
      const refreshToken = localStorage.getItem('refresh_token')
      const keycloak = window.__env__.ENV === 'DEV' ? Keycloak : OneAccount
      const body = {
        grant_type: 'refresh_token',
        refresh_token: refreshToken,
        scope: 'openid email profile offline_access',
        client_id: keycloak.client_id,
        client_secret: '',
      }

      if (keycloak.client_secret) {
        body.client_secret = keycloak.client_secret
      }

      const user = storedUser ? JSON.parse(storedUser) : null

      const uniqPermissionsAccess = [
        ...new Set([
          ...user.permissionGroup.flatMap((group: { permission: any[] }) =>
            group.permission.map((perm: { permission: any }) => perm.permission)
          ),
          ...user.permission.map(
            (perm: { permission: any }) => perm.permission
          ),
        ]),
      ]
      user.uniqPermissions = uniqPermissionsAccess
      localStorage.setItem('user', JSON.stringify(user))

      if (window.__env__.ENV === 'DEV') {
        const getToken = await instanceKeycloak({
          method: 'post',
          url: `/protocol/openid-connect/token`,
          data: qs.stringify(body),
        })
        const { refresh_token, access_token, token_type } = getToken.data
        localStorage.setItem('refresh_token', refresh_token)
        localStorage.setItem('token', `${token_type} ${access_token}`)
        return `${token_type} ${access_token}`
      } else {
        body['scope'] = 'openid email'
        const getToken = await instanceOneAccount({
          method: 'post',
          url: `/as/token.oauth2`,
          data: qs.stringify(body),
        })
        const { refresh_token, access_token, token_type } = getToken.data
        localStorage.setItem('refresh_token', refresh_token)
        localStorage.setItem('token', `${token_type} ${access_token}`)
        return `${token_type} ${access_token}`
      }
    } catch (error) {
      console.log('session expire ->', error)
      return 'expire'
    }
  }

  axios.interceptors.request.use(
    async (req) => {
      const token = localStorage.getItem('token')
      if (!_.isEmpty(token)) {
        req.headers.Authorization = `${token}`
      } else {
        req.headers.Authorization = 'NoToken'
      }
      return req
    },
    (error) => Promise.reject(error)
  )

  axios.interceptors.response.use(
    async (response) => {
      const getToken = localStorage.getItem('token')

      const token = getToken?.replace('Bearer ', '')
      const decodedJwt = parseJWT<JwtPayload>(token)

      if (decodedJwt && token) {
        const expire = 60000 * 10
        const expirationTime = decodedJwt?.exp * 1000 - expire
        const dateNow = new Date().getTime()
        if (dateNow >= expirationTime) {
          await refreshToken()
        }
      }
      return response
    },
    async (error) => {
      const status = _.get(error, 'response.status', 500)
      const config = error?.config
      if (status === 401 && !config?.sent) {
        config.sent = true
        config.expire = await refreshToken()
        config.headers = { ...config.headers }
        return axios(config)
      }
      if (status === 401 && config?.expire === 'expire') {
        setSessionExpire({
          open: true,
          title: sessionExpire.title,
          content: sessionExpire.content,
          variant: sessionExpire.variant,
          onConfirmClick: sessionExpire.onConfirmClick,
        })
        return Promise.reject(error)
      }
    }
  )

  useEffect(() => {
    if (menu.length === 0) {
      setLeftMenuLS(
        leftMenu.map((item) => {
          return item.active
        })
      )
      if (_.isNil(leftMenuLS)) {
        setMenu(leftMenu)
      } else {
        setMenu(
          leftMenu.map((item, i: number) => {
            return { ...item, active: leftMenuLS[i] }
          })
        )
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (props.layout === 'login' && !storedUser) {
    return <>{props.children}</>
  }

  if (storedUser && props.layout === 'login') {
    useAuthGuard(leftMenu)
  }

  if (storedUser === null) {
    const redirectTo = `${lastLocation.search}`
    const inOf = redirectTo.indexOf('?state')
    if (inOf === 0) {
      window.history.replaceState(null, '', lastLocation.pathname)
    }
  }

  useEffect(() => {
    return () => {
      window.scrollTo(0, 0)
      dispatch(resetTable())
    }
  }, [])

  return (
    <Box>
      {storedUser !== null ? (
        <>
          {props.layout !== 'login' && (
            <LeftMenu
              menu={menu}
              setMenu={setMenu}
              open={leftMenuDrawer}
              setOpen={setLeftMenuDrawer}
            />
          )}

          <Box
            sx={{
              mt: 10,
              ml: 0,
              [theme.breakpoints.up('md')]: {
                ml: 5,
              },
            }}
          >
            {props.children}
          </Box>
        </>
      ) : (
        <></>
        // <Redirect to="/" replace state={{ from: props.location }} />
      )}
    </Box>
  )
})

Layout.displayName = 'Layout'

export default Layout
