import { Box, IconButton, Typography } from '@mui/material'
import { CustomLoadingButton } from '../../../../components/Input/CustomButton'
import { closeCustomDialog } from '../../../../components/CustomDialog/slices'
import { dispatch, useSelector } from '../../../../redux/store'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import { FormCADTO } from './FormCADTO'
import { FormView } from './FormView'
import {
  callCreatedResultConsideration,
  callSendCCM,
  callSubmit,
} from './services/thunk'
import _ from 'lodash'
import {
  selectInitialProfile,
  selectCrudFormData,
  selectCrudLoading,
} from '../../../../redux/selector'
import { Complete } from './Complete'
import {
  setFieldValue,
  setFormIsLoading,
} from '../../../../components/CRUD/slices'
import { ApiStatus } from '../../../../constants/status'
import { getProfileDetails } from '../../slices/applicant-details'
import { useEffect } from 'react'
import { getTitleNotOwner } from '../handler/getTitleNotOwner'
import { FormCCM } from './CCMRequest'
import { FormRAM } from './FormRAM'
import { CCMResultDecision } from './CCMResultDecision'
import { CompleteCCM } from './CompleteCCM'
import { setActionStep } from './handler/setActionStep'
import { getValueStr } from '../../../../utils/getValue'
import { loadNotification } from '../../../../components/CustomNotification/handler/loadNotification'

export const FormRequestDecision = (props: { startWith?: string }) => {
  const { startWith = 'CCM' } = props

  const initProfile = useSelector(selectInitialProfile)
  const formData = useSelector(selectCrudFormData)
  const isLoading = useSelector(selectCrudLoading)
  const fullFormUuid = String(initProfile?.fullformUuid)
  const { actionStep, decision, considerationCCM } = formData

  const dialogTitle = getTitleNotOwner(initProfile, 'ยืนยันส่งพิจารณาเพิ่มเติม')
  const ccmMetaIds = _.map(considerationCCM?.attachments ?? [], 'id')
  const ramMetaIds = _.map(decision?.attachments ?? [], 'id')
  const dialogTitle2 = getTitleNotOwner(initProfile, 'ส่งพิจารณาเพิ่มเติม')

  useEffect(() => {
    dispatch(setActionStep(startWith))
  }, [startWith])

  const closeForm = async () => {
    dispatch(setFieldValue({ key: `formData.actionStep`, value: null }))
    dispatch(setFieldValue({ key: `formData.decision`, value: null }))
    dispatch(setFieldValue({ key: `formData.considerationCCM`, value: null }))
    dispatch(closeCustomDialog())
  }

  const handleSubmitSendCCM = async () => {
    if (decision?.hasSendCCM === 'YES') {
      dispatch(setFormIsLoading(true))
      const { payload } = await dispatch(
        callSendCCM({
          fullFormUuid: fullFormUuid,
        })
      )
      dispatch(setFormIsLoading(false))
      if (payload?.status !== ApiStatus.ERROR) {
        dispatch(setActionStep('COMPLETE'))
      }
    } else {
      dispatch(setActionStep('RAM'))
    }
  }
  const handleSubmitDecision = async () => {
    dispatch(setFormIsLoading(true))
    let hasSendConsideration = decision?.hasSendCCM
    if (!_.isEmpty(considerationCCM?.hasSendDecision))
      hasSendConsideration = considerationCCM?.hasSendDecision

    const { payload } = await dispatch(
      callSubmit({
        fullFormUuid: fullFormUuid,
        hasSendConsideration: hasSendConsideration,

        hasSendRam: !_.isEmpty(decision?.RamUuid) ? true : false,
        RamUuid: decision?.RamUuid,

        hasSendSRam: !_.isEmpty(decision?.SRamUuid) ? true : false,
        SRamUuid: decision?.SRamUuid,

        hasSendCADTO: !_.isEmpty(decision?.CADTOUuid) ? true : false,
        CADTOUuid: decision?.CADTOUuid,

        hasSendCDO: !_.isEmpty(decision?.CDOUuid) ? true : false,
        CDOUuid: decision?.CDOUuid,

        detail: decision?.detail,
        metaId: ccmMetaIds,
        ramMetaId: ramMetaIds,
      })
    )
    if (payload?.status !== ApiStatus.ERROR) {
      dispatch(setActionStep('COMPLETE'))
    }
    dispatch(setFormIsLoading(false))
  }
  const handleSubmitResultCCM = async () => {
    if (considerationCCM?.hasSendDecision === 'NO') {
      dispatch(setFormIsLoading(true))
      const { payload } = await dispatch(
        callCreatedResultConsideration({
          fullFormUuid: fullFormUuid,
          metaId: ccmMetaIds,
          ramMetaId: [],
          hasSendConsideration: getValueStr(
            considerationCCM,
            'hasSendDecision'
          ),
        })
      )
      dispatch(setFormIsLoading(false))
      if (payload?.status !== ApiStatus.ERROR) {
        dispatch(setActionStep('COMPLETE_CCM'))
      }
    } else {
      dispatch(setActionStep('RAM'))
    }
  }

  const getButton = () => {
    if (actionStep === 'CADTO') {
      return (
        <>
          <CustomLoadingButton
            sx={{ borderRadius: 1 }}
            variant={'outlined'}
            color="info"
            onClick={() => dispatch(setActionStep('RAM'))}
          >
            ย้อนกลับ
          </CustomLoadingButton>
          <CustomLoadingButton
            sx={{ borderRadius: 1 }}
            variant="contained"
            color="info"
            onClick={() => dispatch(setActionStep('REVIEW'))}
          >
            ถัดไป
          </CustomLoadingButton>
        </>
      )
    } else if (actionStep === 'REVIEW') {
      return (
        <>
          <CustomLoadingButton
            sx={{ borderRadius: 1 }}
            variant={'outlined'}
            color="info"
            onClick={() => dispatch(setActionStep('CADTO'))}
          >
            ย้อนกลับ
          </CustomLoadingButton>
          <CustomLoadingButton
            sx={{ borderRadius: 1 }}
            variant="contained"
            color="info"
            loading={isLoading}
            onClick={() => handleSubmitDecision()}
          >
            ยืนยัน
          </CustomLoadingButton>
        </>
      )
    } else if (actionStep === 'CCM') {
      return (
        <>
          <CustomLoadingButton
            sx={{ borderRadius: 1 }}
            variant={'outlined'}
            color="info"
            onClick={() => closeForm()}
          >
            ย้อนกลับ
          </CustomLoadingButton>
          <CustomLoadingButton
            sx={{ borderRadius: 1 }}
            loading={isLoading}
            variant="contained"
            color="info"
            disabled={_.isEmpty(decision?.hasSendCCM)}
            onClick={() => handleSubmitSendCCM()}
          >
            ยืนยัน
          </CustomLoadingButton>
        </>
      )
    } else if (actionStep === 'CCM_RESULT') {
      return (
        <>
          <CustomLoadingButton
            sx={{ borderRadius: 1 }}
            variant="outlined"
            color="info"
            onClick={() => closeForm()}
          >
            ยกเลิก
          </CustomLoadingButton>
          <CustomLoadingButton
            sx={{ borderRadius: 1 }}
            disabled={
              _.size(ccmMetaIds) == 0 ||
              _.isEmpty(considerationCCM?.hasSendDecision)
            }
            loading={isLoading}
            onClick={handleSubmitResultCCM}
          >
            ยืนยัน
          </CustomLoadingButton>
        </>
      )
    } else if (actionStep === 'COMPLETE' || actionStep === 'COMPLETE_CCM') {
      return (
        <>
          <CustomLoadingButton
            sx={{ borderRadius: 1 }}
            variant="contained"
            color="info"
            onClick={() => {
              dispatch(
                getProfileDetails({ prospectId: String(initProfile?.uuid) })
              )
              dispatch(loadNotification({ tab: 'unread' }))
              closeForm()
            }}
          >
            รับทราบ
          </CustomLoadingButton>
        </>
      )
    } else {
      return (
        <>
          <CustomLoadingButton
            sx={{ borderRadius: 1 }}
            variant={'outlined'}
            color="info"
            onClick={() => {
              if (!_.isEmpty(decision?.hasSendCCM)) {
                dispatch(setActionStep('CCM'))
              } else {
                dispatch(setActionStep('CCM_RESULT'))
              }
            }}
          >
            ย้อนกลับ
          </CustomLoadingButton>
          <CustomLoadingButton
            sx={{ borderRadius: 1 }}
            variant="contained"
            color="info"
            onClick={() => dispatch(setActionStep('CADTO'))}
          >
            ถัดไป
          </CustomLoadingButton>
        </>
      )
    }
  }
  const getContent = () => {
    if (actionStep === 'CADTO') {
      return <FormCADTO />
    } else if (actionStep === 'REVIEW') {
      return <FormView />
    } else if (actionStep === 'COMPLETE') {
      return <Complete />
    } else if (actionStep === 'COMPLETE_CCM') {
      return <CompleteCCM />
    } else if (actionStep === 'RAM') {
      return <FormRAM />
    } else if (actionStep === 'CCM') {
      return <FormCCM />
    } else if (actionStep === 'CCM_RESULT') {
      return <CCMResultDecision />
    } else {
      return <></>
    }
  }
  const getTitle = () => {
    if (actionStep === 'CADTO') {
      return 'เลือกผู้พิจารณาเพิ่มเติม'
    } else if (actionStep === 'REVIEW') {
      return dialogTitle
    } else if (actionStep === 'COMPLETE' || actionStep === 'COMPLETE_CCM') {
      return 'สำเร็จ'
    } else if (actionStep === 'RAM') {
      return 'เลือกผู้พิจารณาเพิ่มเติม'
    } else if (actionStep === 'CCM') {
      return dialogTitle2
    } else if (actionStep === 'CCM_RESULT') {
      return ''
    }
  }

  return (
    <>
      {getTitle() !== '' && (
        <Box
          sx={{
            display: 'flex',
            mb: 2,
            justifyContent: 'space-between',
            alignItems: 'center',
            pr: 2,
          }}
        >
          <Typography variant="h6" sx={{ width: '100%' }}>
            {getTitle()}
          </Typography>
          {actionStep === 'REVIEW' && (
            <IconButton onClick={() => dispatch(setActionStep('CCM_RESULT'))}>
              <EditOutlinedIcon />
            </IconButton>
          )}
        </Box>
      )}

      <Box
        id={'FormRequestDecisionContent'}
        sx={{
          maxHeight: '65vh',
          overflow: 'auto',
          scrollbarWidth: 'thin',
          pr: 3,
        }}
      >
        {getContent()}
      </Box>

      <Box
        sx={{ display: 'flex', justifyContent: 'end', gap: 1, mt: 3, pr: 3 }}
      >
        {getButton()}
      </Box>
    </>
  )
}
