import _ from 'lodash'
import { ApiStatus } from '../../../constants/status'
import { AppDispatch } from '../../../redux/store'
import { callInit } from '../services/thunk'
import {
  setNotificationAppendItems,
  setNotificationIsLoading,
  setNotificationItems,
  setNotificationMeta,
} from '../slices'

export const loadNotification =
  (args: {
    page?: number
    tab?: 'all' | 'unread'
    limit?: number
    append?: boolean
  }) =>
  async (dispatch: AppDispatch) => {
    dispatch(setNotificationIsLoading(true))
    const { page = 1, tab = 'unread', limit = 5, append = false } = args
    const { payload } = await dispatch(
      callInit({
        limit: limit,
        page: page,
        tab: tab,
      })
    )
    if (payload.status === ApiStatus.SUCCESS) {
      const items = _.get(payload.result, 'data', [])
      if (append) dispatch(setNotificationAppendItems(items))
      else dispatch(setNotificationItems(items))

      dispatch(setNotificationMeta(_.get(payload.result, 'meta')))
    }
    dispatch(setNotificationIsLoading(false))
  }
