import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Image from '../../../../components/Image/Image'
import { dispatch, useSelector } from '../../../../redux/store'
import {
  selectInitialProfile,
  selectProgressStatus,
} from '../../../../redux/selector'
import { applicationStatusStep } from '../../slices/applicant-details'
import { CardContentStyle, CardStyle } from '../styled'
import { Button, Link } from '@mui/material'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { openCustomDialog } from '../../../../components/CustomDialog/slices'
import { DIALOG_TYPE_STRING } from '../../../../components/dialog/constants'
import { HistoryDecision } from '../FormRequestDecision/HistoryDecision'
import { displayDateTime } from '../../../../utils/convertFormatDateTime'

export default function ReviewApprovedStatus() {
  const initProfile = useSelector(selectInitialProfile)
  const applicationStatus = useSelector(selectProgressStatus)

  return (
    <CardStyle>
      <CardContentStyle>
        <Stack direction="row" gap={2}>
          <Image
            src="/images/illustrate_approved.svg"
            alt="Review approved process"
            effect="opacity"
            sizes="56"
            sx={{
              '& .MuiBox-root': {
                verticalAlign: 'middle',
                objectFit: 'contain',
              },
            }}
          />

          <Stack flex={1} justifyContent="center" gap={0.5}>
            <Typography variant="h5" color="primary.main">
              {applicationStatus && applicationStatusStep[applicationStatus]}
            </Typography>
            <Typography variant="body2" color="text.gray">
              วันที่: {displayDateTime(initProfile?.statsuUpdateDate)}
            </Typography>
          </Stack>

          <Stack justifyContent="center">
            <Link href="/application-verification/list?status=OIC">
              <Button
                variant="outlined"
                size="small"
                fullWidth
                endIcon={<ArrowForwardIcon fontSize="small" />}
                sx={{ maxWidth: 'fit-content' }}
              >
                ไปหน้าคปภ.
              </Button>
            </Link>
            <Button
              variant="outlined"
              size="small"
              sx={{ mt: 1 }}
              onClick={() => {
                dispatch(
                  openCustomDialog({
                    type: DIALOG_TYPE_STRING.CUSTOM,
                    content: <HistoryDecision isEdit={true} />,
                    boxSx: { pr: 0, pe: 0 },
                  })
                )
              }}
            >
              ผลการตรวจสอบ
            </Button>
          </Stack>
        </Stack>
      </CardContentStyle>
    </CardStyle>
  )
}
