import { memo } from 'react'
import { useInputField } from '../hooks/useInputField'
import { setFieldValue } from '../../CRUD/slices'
import { useDispatch, useSelector } from '../../../redux/store'
import { selectCrudConfig, selectFormDataStatus } from '../../../redux/selector'
import DatePicker from '../../Input/DatePicker'
import dayjs from 'dayjs'
import { type SxProps, type Theme } from '@mui/material/styles'

interface CustomDateInputProps {
  boxSx?: SxProps // Replace 'any' with appropriate types for boxSx if possible
  disabled?: boolean
  fieldName: string
  labelName: string
  handleExtraChange?: (event: any) => void // Replace 'any' with appropriate event type
  placeholder?: string
  required?: boolean
  sxTextError?: SxProps<Theme>
}

const CustomDateInput = memo<CustomDateInputProps>(
  ({
    boxSx,
    disabled,
    fieldName,
    labelName,
    handleExtraChange,
    placeholder,
    required = true,
    sxTextError,
  }) => {
    const dispatch = useDispatch()
    const { value, message } = useInputField(fieldName)

    const crudConfig = useSelector(selectCrudConfig)
    const formDataStatus = useSelector(selectFormDataStatus)

    function handleValueChange(event: any) {
      // Replace 'any' with appropriate event type
      dispatch(setFieldValue({ key: `formData.${fieldName}`, value: event }))
      if (handleExtraChange) {
        handleExtraChange(event)
      }
    }

    const isDisabled = formDataStatus === 'DELETED' || disabled

    return (
      <DatePicker
        boxSx={boxSx}
        id={`${crudConfig?.moduleSlug}-${fieldName}`}
        labelText={labelName}
        name={`${fieldName}`}
        required={required}
        placeholder={placeholder ?? 'เลือกวันที่'} // Default placeholder text
        hideTextError={false}
        disabledStartDate={'1900-01-01'}
        disabledEndDate={dayjs(new Date()).format(
          window.__env__.REACT_APP_DATE_DB
        )}
        hideDisableDateRange={true}
        value={value ?? null}
        onChange={handleValueChange}
        textError={message}
        disabledInput={isDisabled}
        sxTextError={sxTextError}
      />
    )
  }
)

CustomDateInput.displayName = 'CustomDateInputComponent'

export { CustomDateInput }
