import { CRUDListFilterPath } from '../../../utils/apiPath'
import { headerCells } from './features/Listing/model/headerCellsList'

export const crudConfig = {
  moduleId: 'SIGNATURE_DOCUMENT',
  moduleSlug: 'signature-document',
  moduleTitleFull: 'รายการลายมือชื่อบนเอกสาร',
  moduleTitleSecond: 'รายการลายมือชื่อบนเอกสาร',
  modulesTitleShort: 'ลายมือชื่อ',
  modulePath: '/signature-document',
  filterPath: `${CRUDListFilterPath}`,
  headerCells,
}
