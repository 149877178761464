import { Box, Typography } from '@mui/material'
import { UploadMultiReturnPath } from '../../../../components/FullUpload'

import CustomTextfield from '../../../../components/Input/CustomTextfield'

import { useSelector } from 'react-redux'
import {
  selectCrudFormData,
  selectInitialProfile,
} from '../../../../redux/selector'
import CustomDropdown, {
  convertAnyItemToOption,
} from '../../../../components/Input/CustomDropdown'
import { dispatch } from '../../../../redux/store'
import { handleGetOptions } from './handler/handleGetSRAM'
import _ from 'lodash'

export const FormRAM = () => {
  const initProfile = useSelector(selectInitialProfile)
  const fullFormUuid = String(initProfile?.fullformUuid)

  const formData = useSelector(selectCrudFormData)
  const { options } = formData

  const scrollToBottom = () => {
    const div = document.getElementById('FormRequestDecisionContent')
    if (div) {
      div.scrollTop = div.scrollHeight
    }
  }

  return (
    <>
      <Typography sx={{ mb: 1 }}>เลือกผู้ตรวจ</Typography>
      <Box
        onClick={() =>
          _.isEmpty(options?.RAM_PROFILE) &&
          dispatch(handleGetOptions('RAM_PROFILE'))
        }
      >
        <CustomDropdown
          label={'RAM'}
          crud={'decision.RamUuid'}
          placeholder="กรุณาเลือกผู้ตรวจ"
          isValue
          loading
          options={convertAnyItemToOption(options?.RAM_PROFILE ?? [], {
            label: 'name_alias',
            value: 'uuid',
          })}
          fullWidth
        />
      </Box>
      <Box
        onClick={() =>
          _.isEmpty(options?.SRAM_PROFILE) &&
          dispatch(handleGetOptions('SRAM_PROFILE'))
        }
      >
        <CustomDropdown
          label={'SRAM'}
          crud={'decision.SRamUuid'}
          placeholder="กรุณาเลือกผู้ตรวจ"
          isValue
          loading
          options={convertAnyItemToOption(options?.SRAM_PROFILE ?? [], {
            label: 'name_alias',
            value: 'uuid',
          })}
          fullWidth
        />
      </Box>

      <CustomTextfield
        crud={'decision.detail'}
        rows={2}
        multiline
        label={'รายละเอียดสำหรับแนบทางอีเมล'}
        maxLength={500}
        fullWidth
      />
      <UploadMultiReturnPath
        name={'decision.attachments'}
        label={'แนบเอกสาร'}
        fullFormUuid={fullFormUuid}
        fieldName={'consideration'}
        maxFile={3}
        info={'สูงสุดไม่เกิน 3 รายการ'}
        onUploadComplete={() => {
          scrollToBottom()
        }}
      />
    </>
  )
}
