import { store } from '../../../../../../redux/store'
import _ from 'lodash'

export const mutateFilterPropToBody = () => {
  const { filterProp } = store.getState().table.filter

  return {
    name: _.get(filterProp, 'name', ''),
    status: _.get(filterProp, 'status', []),
    send: _.get(filterProp, 'send', []),
  }
}
