import {
  setFieldValue,
  setFormIsLoading,
} from '../../../../../components/CRUD/slices'
import { ApiStatus } from '../../../../../constants/status'
import { AppDispatch } from '../../../../../redux/store'
import { callHistory } from '../services/thunk'

export const getHistory =
  (fullFormUuid: string) => async (dispatch: AppDispatch) => {
    dispatch(setFormIsLoading(true))
    const { payload } = await dispatch(callHistory(fullFormUuid))
    if (payload?.status !== ApiStatus.ERROR) {
      dispatch(
        setFieldValue({
          key: `formData.historyDecision`,
          value: payload.result,
        })
      )
    }
    dispatch(setFormIsLoading(false))
  }
