import { useState } from 'react'
import IconButton from '@mui/material/IconButton'
import MenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'
import _ from 'lodash'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import Menu, { MenuProps } from '@mui/material/Menu'
import styled from '@mui/system/styled'
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded'
import { dispatch } from '../../../../redux/store'
import {
  setCompareFirstIndex,
  setCompareIsLoading,
  setCompareSecondIndex,
} from './slices'
import { useDelay } from '../../../../utils/useDelay'
export const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={8}
    MenuListProps={{ 'aria-labelledby': 'button' }}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    minWidth: 200,
    '& .MuiMenuItem-root': {
      display: 'flex',
      gap: theme.spacing(1),
      '& .MuiSvgIcon-root': { fontSize: 24 },
      '&:active': {
        color: theme.palette?.primary?.main,
        backgroundColor: theme.palette?.primary?.outlinedHoverBackground,
      },
    },
  },
}))

export const StyledButton = styled(Button)(({ theme }) => ({
  padding: `${theme.spacing(1)} 22px`,
}))

export const StyledDivider = styled((props) => (
  <Divider orientation="vertical" flexItem {...props} />
))(({ theme }) => ({
  background: theme.palette?.secondary?.containedHoverBackground,
}))

const MenuVersion = ({
  options = [],
  disabled = false,
  position = 'second',
  currentIndex = 0,
}: any) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleSelect = async (num: number) => {
    dispatch(setCompareIsLoading(true))

    await useDelay(300)
    if (position === 'second') dispatch(setCompareSecondIndex(num))
    else dispatch(setCompareFirstIndex(num))

    dispatch(setCompareIsLoading(false))
    setAnchorEl(null)
  }
  return (
    <div>
      <IconButton
        data-testid="btn-menu"
        disabled={disabled}
        onClick={handleClick}
        sx={{ borderRadius: 1, border: 1 }}
        color="primary"
        size={'small'}
      >
        <KeyboardArrowDownRoundedIcon />
      </IconButton>
      <StyledMenu
        id="menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {options.map((option: any, index: number) => (
          <MenuItem
            key={index}
            onClick={() => handleSelect(Number(option.value))}
          >
            <Typography
              color={currentIndex === Number(option.value) ? 'primary' : 'dark'}
            >
              {_.get(option, 'label', '')}
            </Typography>
          </MenuItem>
        ))}
      </StyledMenu>
    </div>
  )
}

export default MenuVersion
