import { createAsyncThunk } from '@reduxjs/toolkit'
import { API_URL } from '../../../constants/envConfig'
import { callAPI } from '../../../utils/callAPI'
import { ConfigBody } from '../types'

export const callInit = createAsyncThunk(
  'configuration/call-load-init',
  async (_, { rejectWithValue }) => {
    try {
      const response = await callAPI({
        url: `${API_URL}/configuration/get/expire-lead`,
        method: 'get',
      })
      return response
    } catch (err) {
      console.log(err)
      return rejectWithValue(null)
    }
  }
)
export const callSubmitForm = createAsyncThunk(
  'configuration/call-submit-form',
  async (data: ConfigBody, { rejectWithValue }) => {
    try {
      const response = await callAPI({
        url: `${API_URL}/configuration/update/expire-lead`,
        method: 'post',
        body: data,
      })
      return response
    } catch (err) {
      console.log(err)
      return rejectWithValue(null)
    }
  }
)

export const callHistoryConfig = createAsyncThunk(
  'configuration/call-history-config',
  async (body: { page: number; limit: number }, { rejectWithValue }) => {
    try {
      const response = await callAPI({
        url: `${API_URL}/configuration/get/expire-lead/history`,
        method: 'post',
        body: {
          limit: body.limit,
          page: body.page,
          order: 'DESC',
          sort: 'createdAt',
        },
      })
      return response
    } catch (err) {
      console.log(err)
      return rejectWithValue(null)
    }
  }
)
