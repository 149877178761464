import { AppDispatch } from '../../../redux/store'
import {
  setDrawerTable,
  setIsLoadingDrawer,
} from '../../../components/CustomDrawer/slices'
import { callHistoryConfig } from '../services/thunk'
import _ from 'lodash'
import { DrawerTableState } from '../../../components/CustomDrawer/slices/model'
import { getValueNumber } from '../../../utils/getValue'

export const loadHistoryConfig =
  (table: DrawerTableState, page: number) => async (dispatch: AppDispatch) => {
    dispatch(setIsLoadingDrawer(true))

    const { payload } = await dispatch(
      callHistoryConfig({
        limit: table.limit,
        page: page,
      })
    )

    const items = _.get(payload, 'result', [])
    dispatch(
      setDrawerTable({
        items: [..._.get(table, 'items', []), ...items],
        total: getValueNumber(payload, 'meta.total'),
        page: getValueNumber(payload, 'meta.page'),
        limit: getValueNumber(payload, 'meta.limit'),
      })
    )
    dispatch(setIsLoadingDrawer(false))
  }
