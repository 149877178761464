import type { RootState } from './store'
import { createSelector } from 'reselect'

// table slice
export const selectPaginationTable = createSelector(
  [(state: RootState) => state.table],
  (table) => ({
    page: table.table.page,
    sort: table.table.sort,
    order: table.table.order,
    limit: table.table.limit,
    search: table.search.searchText,
  })
)

export const selectTable = createSelector(
  [(state: RootState) => state.table],
  (table) => table.table
)

export const selectTableRows = createSelector(
  [(state: RootState) => state.table],
  (table) => table.table.rows
)

export const selectEnhancedTable = createSelector(
  [(state: RootState) => state.table],
  (table) => ({
    table: table.table,
    customStyle: table.customStyle,
    onDownload: table.onDownload,
    handleSearch: table.handleSearch,
    filter: table.filter,
    subActions: table.subActions,
    optionDownload: table.optionDownload,
  })
)

export const selectTableFilterTotal = createSelector(
  [(state: RootState) => state.table],
  (table) => ({
    table: table.table,
    filterTotal: table.filter.filterTotal,
    onDownload: table.onDownload,
    handleSearch: table.handleSearch,
    customToolbar: table.customToolbar,
    moreMenu: table.moreMenu,
  })
)

export const selectQuickFilter = createSelector(
  [(state: RootState) => state.table],
  (table) => ({
    quickFilter: table.filter.quickFilter,
    onQuickFilter: table.onQuickFilter,
  })
)

export const selectTableDelete = createSelector(
  [(state: RootState) => state.table],
  (table) => table.onDelete
)

export const selectTableSearch = createSelector(
  [(state: RootState) => state.table],
  (table) => ({
    search: table.search,
    handleSearch: table.handleSearch,
  })
)

export const selectFilterTable = createSelector(
  [(state: RootState) => state.table],
  (table) => ({
    open: table.filter.isFilterDrawer,
    filterState: table.filterState,
    filterStateMemo: table.filterStateMemo,
  })
)

export const selectFilterProp = createSelector(
  [(state: RootState) => state.table],
  (table) => table.filter.filterProp
)

export const selectTableLoading = createSelector(
  [(state: RootState) => state.table],
  (table) => table.table.isLoading
)

export const selectTableSelected = createSelector(
  [(state: RootState) => state.table],
  (table) => table.table.selected
)

// crud slice
export const selectCrudConfig = createSelector(
  [(state: RootState) => state.crud],
  (crud) => crud.crudConfig
)

export const selectFormDataStatus = createSelector(
  (state: RootState) => state.crud,
  (crud) => crud.formData.status
)

export const selectCrudMasterData = createSelector(
  [(state: RootState) => state.crud],
  (crud) => crud.masterData
)

export const selectCrudLoading = createSelector(
  [(state: RootState) => state.crud],
  (crud) => crud.isLoading
)

export const selectOpenHistoryDrawer = createSelector(
  [(state: RootState) => state.crud],
  (crud) => crud.isShowHistoryDrawer
)

export const selectCrudFormData = createSelector(
  [(state: RootState) => state.crud],
  (crud) => crud.formData
)

export const selectCrudFormError = createSelector(
  [(state: RootState) => state.crud],
  (crud) => crud.formData.formErrors
)

// dialog slice
export const selectDialog = createSelector(
  [(state: RootState) => state.dialog],
  (dialog) => ({
    isOpen: dialog.isOpen,
    title: dialog.title,
    message: dialog.message,
    type: dialog.type,
    onCancelClick: dialog.handleCancel,
    handleConfirm: dialog.handleConfirm,
    isLoading: dialog.isLoading,
    sxActions: dialog.sxActions,
  })
)

export const selectDialogLoading = createSelector(
  [(state: RootState) => state.dialog],
  (dialog) => dialog.isLoading
)

// applicantDetails slice
export const selectCheckListStatus = createSelector(
  [(state: RootState) => state.applicantDetails],
  (applicantDetails) => applicantDetails.initProfile?.progressBarStatus
)

export const selectInitialProfile = createSelector(
  [(state: RootState) => state.applicantDetails],
  (applicantDetails) => applicantDetails.initProfile
)

export const selectProfileDetails = createSelector(
  [(state: RootState) => state.applicantDetails],
  (applicantDetails) => applicantDetails.profileDetails
)

export const selectReceivePayment = createSelector(
  [(state: RootState) => state.dcAdmin],
  (dcAdmin) => dcAdmin.receivePayment
)

export const selectProgressStatus = createSelector(
  [(state: RootState) => state.applicantDetails],
  (applicantDetails) => applicantDetails.initProfile?.progressBarStatus
)

export const selectMemoList = createSelector(
  [(state: RootState) => state.applicantDetails],
  (applicantDetails) => applicantDetails.memoList
)

export const selectRemarks = createSelector(
  [(state: RootState) => state.applicantDetails],
  (applicantDetails) => applicantDetails.notes
)

export const selectBackgroundReview = createSelector(
  [(state: RootState) => state.applicantDetails],
  (applicantDetails) => applicantDetails.backgroundReview
)

export const selectDataLicense = createSelector(
  [(state: RootState) => state.applicantDetails],
  (applicantDetails) => {
    if (applicantDetails.profileDetails?.main) {
      const { agentCode, startLicenseDate, licenseNumber, expireLicenseDate } =
        applicantDetails.profileDetails.main

      return {
        agentCode,
        startLicenseDate,
        expireLicenseDate,
        licenseNumber,
      }
    }
  }
)
export const selectFixedFileBox = createSelector(
  [(state: RootState) => state.applicantDetails],
  (applicantDetails) => applicantDetails.fixedFileBox
)
export const selectShowFile = createSelector(
  [(state: RootState) => state.applicantDetails],
  (applicantDetails) => applicantDetails.showFile
)
