import { Theme } from '@mui/material/styles'

export const CustomModalStyle = (theme: Theme) => ({
  overlay: {
    backgroundColor: `${theme.palette.common.black}BF`,
    zIndex: 1200,
  },
  content: {
    maxWidth: 678,
    width: '100%',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: 0,
    backgroundColor: theme.palette.background.paper,
    borderRadius: '8px',
    boxShadow: `0px 9px 46px 8px ${theme.palette.common.black}1F,
                        0px 24px 38px 3px ${theme.palette.common.black}24,
                        0px 11px 15px -7px ${theme.palette.common.black}33`,
  },
})
