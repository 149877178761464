import { createAsyncThunk } from '@reduxjs/toolkit'
import { API_URL } from '../../../../constants/envConfig'
import { callAPI } from '../../../../utils/callAPI'
import {
  ConsiderationBody,
  CreateNoteBody,
  DeleteNoteBody,
  LicenseAgentBody,
  RejectFullFormBody,
  RequestMemoBody,
  UpdateAuditInfoBody,
  UpdateContractorBody,
  UpdateVariousHistoryStatusBody,
} from '../types/considerationBody'

export const callFullFormUpdateConsideration = createAsyncThunk(
  'dc/fullform-update-consideration',
  async (body: ConsiderationBody, { rejectWithValue }) => {
    try {
      const response = await callAPI({
        url: `${API_URL}/fullform/update/consideration`,
        method: 'post',
        body: body,
      })
      return response
    } catch (err) {
      console.log(err)
      return rejectWithValue(null)
    }
  }
)
export const callFullFormReject = createAsyncThunk(
  'dc/fullform-reject',
  async (body: RejectFullFormBody, { rejectWithValue }) => {
    try {
      const response = await callAPI({
        url: `${API_URL}/fullform/rejected`,
        method: 'post',
        body: body,
      })
      return response
    } catch (err) {
      console.log(err)
      return rejectWithValue(null)
    }
  }
)

export const callUpdateContractor = createAsyncThunk(
  'dc/fullform-add-contractor',
  async (body: UpdateContractorBody, { rejectWithValue }) => {
    try {
      const response = await callAPI({
        url: `${API_URL}/fullform/add-contractor`,
        method: 'post',
        body: body,
      })
      return response
    } catch (err) {
      console.log(err)
      return rejectWithValue(null)
    }
  }
)
export const callRequestMemo = createAsyncThunk(
  'dc/fullform-request-memo',
  async (body: RequestMemoBody, { rejectWithValue }) => {
    try {
      const response = await callAPI({
        url: `${API_URL}/fullform/request-memo`,
        method: 'post',
        body: body,
      })
      return response
    } catch (err) {
      console.log(err)
      return rejectWithValue(null)
    }
  }
)
export const callUpdateVariousHistoryStatus = createAsyncThunk(
  'dc/fullform-status-various',
  async (body: UpdateVariousHistoryStatusBody, { rejectWithValue }) => {
    try {
      const response = await callAPI({
        url: `${API_URL}/fullform/status/various`,
        method: 'post',
        body: body,
      })
      return response
    } catch (err) {
      console.log(err)
      return rejectWithValue(null)
    }
  }
)
export const callUpdateAuditInfo = createAsyncThunk(
  'dc/fullform-update-audit-info',
  async (body: UpdateAuditInfoBody, { rejectWithValue }) => {
    try {
      const response = await callAPI({
        url: `${API_URL}/fullform/update/audit-info`,
        method: 'post',
        body: body,
      })
      return response
    } catch (err) {
      console.log(err)
      return rejectWithValue(null)
    }
  }
)
export const callCreateNote = createAsyncThunk(
  'dc/fullform-created-noted',
  async (body: CreateNoteBody, { rejectWithValue }) => {
    try {
      const response = await callAPI({
        url: `${API_URL}/fullform/created/noted`,
        method: 'post',
        body: body,
      })
      return response
    } catch (err) {
      console.log(err)
      return rejectWithValue(null)
    }
  }
)
export const callDeleteNote = createAsyncThunk(
  'dc/fullform-delete-noted',
  async (body: DeleteNoteBody, { rejectWithValue }) => {
    try {
      const response = await callAPI({
        url: `${API_URL}/fullform/deleted/noted`,
        method: 'post',
        body: body,
      })
      return response
    } catch (err) {
      console.log(err)
      return rejectWithValue(null)
    }
  }
)
export const callVerifyBackgroundByDC = createAsyncThunk(
  'dc/fullform-update-background-status',
  async (fullFormUuid: string, { rejectWithValue }) => {
    try {
      const response = await callAPI({
        url: `${API_URL}/fullform/update/background-status`,
        method: 'post',
        body: {
          fullFormUuid: fullFormUuid,
        },
      })
      return response
    } catch (err) {
      console.log(err)
      return rejectWithValue(null)
    }
  }
)
export const callLicenseAgent = createAsyncThunk(
  'dc/fullform-submit-data-license-agent',
  async (body: LicenseAgentBody, { rejectWithValue }) => {
    try {
      const response = await callAPI({
        url: `${API_URL}/fullform/submit/data-license-agent`,
        method: 'post',
        body: body,
      })
      return response
    } catch (err) {
      console.log(err)
      return rejectWithValue(null)
    }
  }
)

export const callEditLicenseAgent = createAsyncThunk(
  'dc/fullform-edit-data-license-agent',
  async (body: LicenseAgentBody, { rejectWithValue }) => {
    try {
      const response = await callAPI({
        url: `${API_URL}/fullform/edit/data-license-agent`,
        method: 'post',
        body: body,
      })
      return response
    } catch (err) {
      console.log(err)
      return rejectWithValue(null)
    }
  }
)
