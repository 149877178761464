import { useState } from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import Image from '../../../../components/Image/Image'
import { applicationStatusStep } from '../../slices/applicant-details'
import { useSelector } from '../../../../redux/store'
import {
  selectInitialProfile,
  selectProfileDetails,
  selectProgressStatus,
} from '../../../../redux/selector'
import { CardContentStyle, CardStyle } from '../styled'
import AdditionalInfoDialog from './AdditionalInfoDialog'
import { Link } from '@mui/material'
import { displayDateTime } from '../../../../utils/convertFormatDateTime'

export default function AwaitingRCMSCodeStatus() {
  const initProfile = useSelector(selectInitialProfile)
  const applicationStatus = useSelector(selectProgressStatus)
  const profileDetails = useSelector(selectProfileDetails)

  const [isOpenModal, setIsOpenModal] = useState(false)

  const onSeeMore = () => {
    setIsOpenModal(true)

    document.body.style.overflow = 'hidden'
  }

  const closeModal = () => {
    setIsOpenModal(false)

    document.body.style.overflow = 'auto'
  }

  return (
    <>
      <CardStyle>
        <CardContentStyle>
          <Stack direction="row" gap={2}>
            <Image
              src="/images/illustrate_awaiting_rcms_code.svg"
              alt="Awaiting RMCS code process"
              effect="opacity"
              sizes="56"
              sx={{
                '& .MuiBox-root': {
                  verticalAlign: 'middle',
                  objectFit: 'contain',
                },
              }}
            />

            <Stack flex={1} justifyContent="center" gap={0.5}>
              <Typography variant="h5" color="primary.main">
                {applicationStatus && applicationStatusStep[applicationStatus]}
              </Typography>
              <Typography variant="body2" color="text.gray">
                วันที่: {displayDateTime(initProfile?.statsuUpdateDate)}
              </Typography>
            </Stack>

            <Stack justifyContent="center">
              <Link href="/application-verification/list?status=RCMS">
                <Button
                  variant="outlined"
                  size="small"
                  fullWidth
                  endIcon={<ArrowForwardIcon fontSize="small" />}
                  sx={{ maxWidth: 'fit-content' }}
                >
                  ไปหน้า RCMS
                </Button>
              </Link>
            </Stack>
          </Stack>
        </CardContentStyle>
      </CardStyle>
      <CardStyle>
        <CardContentStyle>
          <Stack direction="row" alignItems="center" gap={2}>
            <Box flex={1}>
              <Typography variant="body2" color="text.gray">
                เลขที่ใบอนุญาต
              </Typography>
              <Typography variant="h6">
                {profileDetails?.main?.licenseNumber}
              </Typography>
            </Box>

            <Box flex={1}>
              <Typography variant="body2" color="text.gray">
                รหัสตัวแทน
              </Typography>
              <Typography variant="h6">-</Typography>
            </Box>

            <Button
              variant="outlined"
              size="small"
              endIcon={<ArrowForwardIcon fontSize="small" />}
              onClick={onSeeMore}
            >
              ดูเพิ่มเติม
            </Button>
          </Stack>
        </CardContentStyle>
      </CardStyle>

      {/* ADDITIONAL INFORMATION DIALOG */}
      <AdditionalInfoDialog isOpenModal={isOpenModal} closeModal={closeModal} />
    </>
  )
}
