import _ from 'lodash'
import { FormData } from '../../../components/CRUD/types/CrudState'
import { AppDispatch } from '../../../redux/store'
import { callSubmitForm } from '../services/thunk'
import { getValueNumber } from '../../../utils/getValue'
import { openDialog } from '../../../components/dialog/slices'
import {
  setAddFormErrors,
  setFormIsLoading,
  setRemoveFieldError,
} from '../../../components/CRUD/slices'

export const submitForm =
  (data: FormData | null) => async (dispatch: AppDispatch) => {
    const COMPANY_LEAD_HOURS = getValueNumber(data, 'COMPANY_LEAD_HOURS')
    const REFERRAL_LEAD_HOURS = getValueNumber(data, 'REFERRAL_LEAD_HOURS')

    const COMPANY_LEAD_HOURS_INTERVIEW = getValueNumber(
      data,
      'COMPANY_LEAD_HOURS_INTERVIEW'
    )
    const REFERRAL_LEAD_HOURS_INTERVIEW = getValueNumber(
      data,
      'REFERRAL_LEAD_HOURS_INTERVIEW'
    )

    const errors: string[] = []
    if (data?.COMPANY_LEAD_TYPE === 'EXPIRE' && COMPANY_LEAD_HOURS < 1) {
      dispatch(
        setAddFormErrors({
          path: 'COMPANY_LEAD_HOURS',
          message: '',
        })
      )
      errors.push('COMPANY_LEAD_HOURS')
    } else {
      dispatch(
        setRemoveFieldError({
          path: 'COMPANY_LEAD_HOURS',
        })
      )
    }
    if (data?.REFERRAL_LEAD_TYPE === 'EXPIRE' && REFERRAL_LEAD_HOURS < 1) {
      dispatch(
        setAddFormErrors({
          path: 'REFERRAL_LEAD_HOURS',
          message: '',
        })
      )
      errors.push('REFERRAL_LEAD_HOURS')
    } else {
      dispatch(
        setRemoveFieldError({
          path: 'REFERRAL_LEAD_HOURS',
        })
      )
    }

    if (
      data?.COMPANY_LEAD_TYPE_INTERVIEW === 'EXPIRE' &&
      COMPANY_LEAD_HOURS_INTERVIEW < 1
    ) {
      dispatch(
        setAddFormErrors({
          path: 'COMPANY_LEAD_HOURS_INTERVIEW',
          message: '',
        })
      )
      errors.push('COMPANY_LEAD_HOURS_INTERVIEW')
    } else {
      dispatch(
        setRemoveFieldError({
          path: 'COMPANY_LEAD_HOURS_INTERVIEW',
        })
      )
    }
    if (
      data?.REFERRAL_LEAD_TYPE_INTERVIEW === 'EXPIRE' &&
      REFERRAL_LEAD_HOURS_INTERVIEW < 1
    ) {
      dispatch(
        setAddFormErrors({
          path: 'REFERRAL_LEAD_HOURS_INTERVIEW',
          message: '',
        })
      )
      errors.push('REFERRAL_LEAD_HOURS_INTERVIEW')
    } else {
      dispatch(
        setRemoveFieldError({
          path: 'REFERRAL_LEAD_HOURS_INTERVIEW',
        })
      )
    }

    if (_.size(errors) > 0) {
      return
    }
    if (!_.isEmpty(data)) {
      dispatch(setFormIsLoading(true))

      await dispatch(
        callSubmitForm({
          leadType: 'COMPANY',
          status: 'ACTIVE',
          noExpire: data?.COMPANY_LEAD_TYPE === 'EXPIRE' ? false : true,
          expireHours: COMPANY_LEAD_HOURS === 0 ? 1 : COMPANY_LEAD_HOURS,
        })
      )

      await dispatch(
        callSubmitForm({
          leadType: 'REFERRAL',
          status: 'ACTIVE',
          noExpire: data?.REFERRAL_LEAD_TYPE === 'EXPIRE' ? false : true,
          expireHours: REFERRAL_LEAD_HOURS === 0 ? 1 : REFERRAL_LEAD_HOURS,
        })
      )

      await dispatch(
        callSubmitForm({
          leadType: 'COMPANY_INTERVIEW',
          status: 'ACTIVE',
          noExpire:
            data?.COMPANY_LEAD_TYPE_INTERVIEW === 'EXPIRE' ? false : true,
          expireHours:
            COMPANY_LEAD_HOURS_INTERVIEW === 0
              ? 1
              : COMPANY_LEAD_HOURS_INTERVIEW,
        })
      )

      await dispatch(
        callSubmitForm({
          leadType: 'REFERRAL_INTERVIEW',
          status: 'ACTIVE',
          noExpire:
            data?.REFERRAL_LEAD_TYPE_INTERVIEW === 'EXPIRE' ? false : true,
          expireHours:
            REFERRAL_LEAD_HOURS_INTERVIEW === 0
              ? 1
              : REFERRAL_LEAD_HOURS_INTERVIEW,
        })
      )

      dispatch(setFormIsLoading(false))
      dispatch(
        openDialog({
          type: 'success',
          title: 'สำเร็จ',
          message: 'บันทึกสำเร็จ',
          isCloseDialog: false,
          handleConfirm: () => window.location.reload(),
        })
      )
    }
  }
