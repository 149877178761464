import MenuList from '@mui/material/MenuList'
import Typography from '@mui/material/Typography'
import { useEffect } from 'react'
import { Box, LinearProgress } from '@mui/material'
import { shallowEqual, useSelector } from 'react-redux'
import { SliceModel } from '../../redux/models'
import { dispatch } from '../../redux/store'
import { loadNotification } from './handler/loadNotification'
import { changeFilterType } from './handler/changeFilterType'
import ItemView from './ItemView'
import { CustomCard } from '../Layout/CustomCard'
import InboxRoundedIcon from '@mui/icons-material/InboxRounded'
import _ from 'lodash'

export default function CustomNotificationView() {
  const { items, filterType, isLoading, meta } = useSelector(
    (state: SliceModel) => ({
      items: state.notification.items,
      filterType: state.notification.filterType,
      isLoading: state.notification.isLoading,
      meta: state.notification.meta,
    }),
    shallowEqual
  )

  const handleScroll = () => {
    const { scrollTop, scrollHeight, clientHeight } = document.documentElement
    const limit = 10

    if (scrollTop + clientHeight >= scrollHeight) {
      if (!isLoading && meta.page < Math.ceil(meta.total / limit)) {
        dispatch(
          loadNotification({
            page: meta.page + 1,
            tab: filterType,
            limit: limit,
            append: true,
          })
        )
      }
    }
  }
  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <CustomCard sx={{ p: 0 }}>
      <Box
        sx={{
          display: 'flex',
          p: 3,
          gap: 2,
          backgroundColor: '#FFFFFF',
          borderBottom: 1,
          borderColor: 'grey.grey200',
        }}
      >
        <Typography variant="h5">การแจ้งเตือน</Typography>
      </Box>
      <Box visibility={isLoading ? 'visible' : 'hidden'}>
        <LinearProgress />
      </Box>
      <Box
        sx={{
          display: 'flex',
          py: 2,
          px: 3,
          gap: 2,
          backgroundColor: '#FFFFFF',
        }}
      >
        <Typography
          variant={filterType === 'unread' ? 'body2b' : 'body2'}
          sx={{
            borderBottom: 4,
            px: 1,
            borderColor: filterType === 'unread' ? '' : 'transparent',
            cursor: 'pointer',
          }}
          color={'primary'}
          onClick={() => dispatch(changeFilterType('unread'))}
        >
          ยังไม่อ่าน
        </Typography>
        <Typography
          variant={filterType === 'all' ? 'body2b' : 'body2'}
          sx={{
            borderBottom: 4,
            px: 1,
            borderColor: filterType === 'all' ? '' : 'transparent',
            cursor: 'pointer',
          }}
          color={'primary'}
          onClick={() => dispatch(changeFilterType('all'))}
        >
          ทั้งหมด
        </Typography>
      </Box>
      <Box>
        <MenuList
          sx={{
            float: 'right',
            p: 0,
            m: 0,
            width: '100%',
            backgroundColor: '#FFFFFF',
          }}
        >
          {items.map((item, key) => (
            <ItemView key={key} item={item} />
          ))}
        </MenuList>
        {_.size(items) === 0 && (
          <Box sx={{ py: 2, px: 3, textAlign: 'center' }}>
            <InboxRoundedIcon fontSize={'large'} sx={{ color: '#CCCCCC' }} />
            <Typography>ไม่มีรายการ</Typography>
          </Box>
        )}
      </Box>
    </CustomCard>
  )
}
