import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from '../../utils/axios'
import { API_URL } from '../../constants/envConfig'
import _ from 'lodash'

interface CallAPIParams {
  url: string
  body?: Record<string, any>
  fileName?: string
  headers?: Record<string, string>
}

export const downloadFile = createAsyncThunk(
  'downloadFile',
  async (
    { url, body, fileName, headers }: CallAPIParams,
    { rejectWithValue }: any
  ) => {
    try {
      await axios
        .post(url, body, { responseType: 'blob', headers, baseURL: API_URL })
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', fileName ?? 'downloadedFile')
          document.body.appendChild(link)
          link.click()
        })

      return []
    } catch (err: any) {
      console.error('Download file error:', err)
      return rejectWithValue([], err)
    }
  }
)

export const downloadFileManager = createAsyncThunk(
  'downloadFileManager',
  async (
    { url, fileName, headers }: CallAPIParams,
    { rejectWithValue }: any
  ) => {
    try {
      await axios
        .get(url, { responseType: 'blob', headers, baseURL: API_URL }) // เปลี่ยนจาก post เป็น get และลบ body
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', fileName ?? 'downloadedFile')
          document.body.appendChild(link)
          link.click()
        })

      return []
    } catch (err: any) {
      console.error('Download file error:', err)
      return rejectWithValue([], err)
    }
  }
)

export const downloadFileCandidate = createAsyncThunk(
  'downloadFileCandidate',
  async (
    { url, fileName, headers }: CallAPIParams,
    { rejectWithValue }: any
  ) => {
    try {
      await axios
        .get(url, { responseType: 'blob', headers, baseURL: API_URL }) // เปลี่ยนจาก post เป็น get และลบ body
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', fileName ?? 'downloadedFile')
          document.body.appendChild(link)
          link.click()
        })

      return []
    } catch (err: any) {
      console.error('Download file error:', err)
      return rejectWithValue([], err)
    }
  }
)

export const downloadImageFromFile = async (file: File, fileName = '') => {
  const fileExtension = _.toLower(file?.name?.split('.').pop())
  if (!['jpeg', 'jpg', 'png'].includes(fileExtension)) {
    console.error('The provided file is not an image.')
    return
  }
  const fileURL = URL.createObjectURL(file)
  const link = document.createElement('a')
  link.href = fileURL

  if (fileName !== '') link.download = `${fileName}.${fileExtension}`
  else link.download = file.name

  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
  URL.revokeObjectURL(fileURL)
}
export const downloadPdf = async (pdfUrl: string, pdfName: string) => {
  try {
    const response = await fetch(pdfUrl)
    const blob = await response.blob()
    const link = document.createElement('a')
    link.href = URL.createObjectURL(blob)
    link.download = pdfName + `.pdf`
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    URL.revokeObjectURL(link.href)
  } catch (error) {
    console.error('Error downloading PDF:', error)
  }
}
