import {
  DeleteTwoTone,
  FileDownloadOutlined,
  FilterListOutlined,
  HowToRegOutlined,
} from '@mui/icons-material'
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined'
import {
  Box,
  Button,
  Table,
  TableCell,
  TableCellProps,
  TableRow,
  TableSortLabel,
  Toolbar,
  styled,
  ButtonProps,
} from '@mui/material'

export const StyledFullWidth = styled(Box)(() => ({
  width: '100%',
}))

interface CustomButtonProps {
  'data-testid': string
  disabled: boolean
  onClick: () => void
  children: React.ReactNode
}

export const StyledSendEmailButton = styled((props: ButtonProps) => (
  <Button
    data-testid={'btn-send-email'}
    variant="text"
    startIcon={
      <img src="../../assets/icons/ic_megaphone_primary.svg" alt="send email" />
    }
    {...props}
  />
))<CustomButtonProps>(({ theme }) => ({
  height: 40,
  padding: theme.spacing(1),
}))

interface StyledTableCellProps extends TableCellProps {
  width?: string | number
  minWidth?: string | number
}

export const StyledTableCell = styled(TableCell, {
  shouldForwardProp: (prop) => prop !== 'minWidth',
})<StyledTableCellProps>(({ width, minWidth }) => ({
  width: width ?? 'unset',
  minWidth: minWidth ?? 'unset',
}))

interface HeadCell {
  marginLabel?: string | number
  hideSortIcon?: boolean
}

interface StyledTableSortLabelProps {
  headcell: HeadCell
}

export const StyledTableSortLabel = styled(
  TableSortLabel
)<StyledTableSortLabelProps>(({ headcell }) => ({
  margin: headcell.marginLabel ?? 0,
  cursor: !headcell.hideSortIcon ? 'pointer' : 'unset',
}))

export const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  flexDirection: 'column',
  padding: `20px 20px 24px 16px !important`,
  [theme.breakpoints.down('sm')]: {
    padding: '16px !important',
  },
}))

export const StyledToolbarButtonBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
  gap: theme.spacing(2),
  margin: 0,
}))

export const StyledTableHeadText = styled(TableCell)(({ theme }) => ({
  padding: `${theme.spacing(3)} ${theme.spacing(3)} ${theme.spacing(1)}`,
  minWidth: 600,
  borderBottom: '0px',
}))

interface StyledTableProps {
  sx?: any
}

export const StyledTable = styled(Table)<StyledTableProps>(() => ({}))

export const StyledBox = styled(Box)(({ theme }) => ({
  justifyContent: 'space-between',
  display: 'flex',
  width: '100%',
  flexDirection: 'row',
  alignItems: 'unset',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(2),
  },
}))

export const StyledCheckBoxCell = styled(TableCell)(() => ({
  padding: '0 13px !important',
  width: 24,
  '& .MuiCheckbox-root': { padding: 0 },
}))

export const StyledDeletedButton = styled((props: ButtonProps) => (
  <Button
    data-testid={'btn-delete'}
    variant="text"
    startIcon={<DeleteTwoTone />}
    {...props}
  />
))<CustomButtonProps>(({ theme }) => ({
  height: 40,
  padding: theme.spacing(1),
}))

export const StyledDownloadButton = styled((props: ButtonProps) => (
  <Button
    data-testid={'btn-download'}
    variant="text"
    startIcon={<FileDownloadOutlined />}
    {...props}
  />
))<CustomButtonProps>(({ theme }) => ({
  height: 40,
  padding: theme.spacing(1),
}))

export const StyledEligibleExamButton = styled((props: ButtonProps) => (
  <Button
    data-testid={'btn-on-eligible-examiner'}
    variant="text"
    startIcon={<ClearOutlinedIcon />}
    {...props}
  />
))<CustomButtonProps>(({ theme }) => ({
  height: 40,
  padding: theme.spacing(1),
}))

export const StyledChangeStatusButton = styled((props: ButtonProps) => (
  <Button
    data-testid={'btn-on-change-status'}
    variant="text"
    startIcon={<HowToRegOutlined />}
    {...props}
  />
))<CustomButtonProps>(({ theme }) => ({
  height: 40,
  padding: theme.spacing(1),
}))

interface StyledTableRowProps {
  children?: React.ReactNode
  key: number
  selected?: boolean
  status?: string
}

export const StyledTableRow = styled((props: StyledTableRowProps) => (
  <TableRow
    {...props}
    hover
    tabIndex={-1}
    role="checkbox"
    selected={props?.selected}
  />
))(({ theme, status }) => ({
  // ตรวจสอบสถานะและกำหนดสีพื้นหลัง
  backgroundColor:
    status === 'INACTIVE'
      ? 'lightgray'
      : status === 'DELETED'
        ? 'lightgray'
        : 'inherit',

  '&.Mui-selected:hover': { background: 'rgba(73, 118, 186, 0.12)' },
  '&.Mui-selected': {
    background: 'rgba(73, 118, 186, 0.08)',
  },
  '& .MuiTableCell-root': {
    padding: `0 ${theme.spacing(2)}`,
    height: 52,
    borderColor: theme?.palette?.action?.disabledBackground,
  },
}))

interface CustomFilterButtonProps {
  'data-testid': string
  onClick: () => void
  children: React.ReactNode
}

export const StyledFilterButton = styled((props: ButtonProps) => (
  <Button
    data-testid={'btn-filter'}
    variant="text"
    startIcon={<FilterListOutlined />}
    {...props}
  />
))<CustomFilterButtonProps>(({ theme }) => ({
  height: 40,
  padding: theme.spacing(1),
}))

interface StyledTableBodyCellProps {
  children?: React.ReactNode
  colSpan?: number
  align?: 'inherit' | 'left' | 'center' | 'right' | 'justify'
}

export const StyledTableBodyCell = styled((props: StyledTableBodyCellProps) => (
  <TableCell key="no-data" align="center" {...props} />
))(() => ({
  '& > .MuiBox-root': {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    '& .MuiSvgIcon-root': {
      color: '#C0C0C0',
      width: '27px',
      height: '27px',
    },
  },
}))
