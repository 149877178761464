import { useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import useLocalStorage from '@rehooks/local-storage'
import _ from 'lodash'
import { useFormik } from 'formik'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import CircularProgress from '@mui/material/CircularProgress'
import { useParams } from 'react-router-dom'

import Breadcrumbs from '../../components/Breadcrumbs'
import Form from './Form'
import { validationSchema } from './FormSchema'
import { handleFetchData, handleSubmitForm } from './events'
import useHistory from '../../utils/useHistory'
import { AppDispatch } from '../../redux/store'

const PermissionForm = ({ isEditForm }: any) => {
  const { id: uuid } = useParams()
  const dispatch = useDispatch<AppDispatch>()
  const history: any = useHistory()
  const [onEdit, setOnEdit] = useLocalStorage<any>('onEdit')
  const titleText = isEditForm ? 'แก้ไขกลุ่ม' : 'เพิ่มกลุ่ม'
  const breadcrumbList = [
    { title: 'Manage', link: '/', pointer: false },
    {
      title: 'สิทธิ์การใช้งาน',
      link: '/manage/permission',
      pointer: true,
    },
    { title: titleText, link: '/', pointer: false },
  ]

  const { data, isPageLoading } = useSelector(
    (state: any) => state.permission,
    shallowEqual
  )

  const groupName = _.get(data, 'title', '')

  useEffect(() => {
    window.onbeforeunload = () => {
      window.localStorage.removeItem('onEdit')
    }
    window.onpopstate = () => {
      window.localStorage.removeItem('onEdit')
    }

    history.listen(() => {
      window.localStorage.removeItem('onEdit')
    })
  }, [])

  useEffect(() => {
    dispatch(handleFetchData({ onEdit, setOnEdit, isEditForm, uuid }))
  }, [isEditForm])

  const formik = useFormik({
    initialValues: data,
    validationSchema: validationSchema,
    validateOnBlur: false,
    onSubmit: (values) => dispatch(handleSubmitForm(values, isEditForm)),
  })

  return (
    <Box sx={{ pb: 2 }}>
      <Box
        sx={{
          opacity: isPageLoading ? 0.4 : 'unset',
          pointerEvents: isPageLoading ? 'none' : 'unset',
        }}
      >
        <Box
          sx={{
            mx: 3,
            justifyContent: 'space-between',
            display: 'flex',
          }}
        >
          <Typography variant="h4">
            {titleText}
            {isEditForm && ` : ${groupName}`}
          </Typography>
        </Box>
        <Breadcrumbs sx={{ mx: 3, mb: 3 }} menuList={breadcrumbList} />
        <Form isEditForm={isEditForm} formik={formik} />
      </Box>
      <CircularProgress
        disableShrink
        sx={{
          position: 'absolute',
          display: isPageLoading ? 'unset' : 'none',
          top: '50%',
          left: '50%',
          color: 'primary.main',
        }}
      />
    </Box>
  )
}
export default PermissionForm
