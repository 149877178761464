import _ from 'lodash'
import { setFieldValue } from '../../../../../components/CRUD/slices'
import { AppDispatch } from '../../../../../redux/store'
import { callCrud } from '../services/thunk'

export const handleGetOptions =
  (path: string) => async (dispatch: AppDispatch) => {
    const { payload } = await dispatch(callCrud(path))
    dispatch(
      setFieldValue({
        key: `formData.options.${path}`,
        value: _.get(payload, 'result', []),
      })
    )
  }
