export const formatAgentCode = (str: string) => {
  const stStr = String(str).trim()
  if (stStr.length !== 14) return stStr
  return `${stStr.slice(0, 6)}-${stStr.slice(6, 8)}-${String(str).slice(8)}`
}

export const formatAgentCodeRevert = (str: string) => {
  const getArr = String(str).split('-')
  if (getArr.length === 1) return str
  return `${getArr[0]}${getArr[1]}${getArr[2]}`
}
