import { createAsyncThunk } from '@reduxjs/toolkit'
import { UploadBody } from '../types/upload'
import { callAPI } from '../../../utils/callAPI'
import { API_URL } from '../../../constants/envConfig'

export const callUploadFileBase64 = createAsyncThunk(
  'dc-manage/upload/file-base64',
  async (body: UploadBody, { rejectWithValue }) => {
    try {
      const response = await callAPI({
        url: `${API_URL}/file-storage/upload/attachment`,
        method: 'post',
        body: body,
      })
      return response
    } catch (err) {
      console.log(err)
      return rejectWithValue(null)
    }
  }
)
export const callUploadFile = createAsyncThunk(
  'dc-manage/upload/file',
  async (body: UploadBody, { rejectWithValue }) => {
    try {
      const formData = new FormData()

      if (body.file) formData.append('file', body.file)

      formData.append('fullFormUuid', body.fullFormUuid)
      formData.append('path', body.path)
      formData.append(
        'data',
        JSON.stringify({
          fieldName: body.data.fieldName,
        })
      )

      const response = await callAPI({
        url: `${API_URL}/file-storage/upload/attachment`,
        method: 'post',
        body: body,
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      return response
    } catch (err) {
      console.log(err)
      return rejectWithValue(null)
    }
  }
)
