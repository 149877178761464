import _ from 'lodash'
import { DateFormat } from '../../../../../constants/dateFormat'
import {
  FULLFORM,
  getLabelFullFormArrText,
} from '../../../../../constants/dc-admin'
import { displayDateTime } from '../../../../../utils/convertFormatDateTime'
import { handleCalAge } from '../../../../../utils/getFormatDate'
import { addDashInIdCardNo } from '../../../../../utils/formatIdCard'

export const displayTextValue = (
  field: string,
  value: string,
  other?: string
) => {
  if (other === 'age') {
    return handleCalAge(value)
  } else if (other === 'haveChild') {
    return Number(value) > 0 ? 'มี' : 'ไม่มี'
  } else if (other === 'date') {
    return displayDateTime(value)
  } else if (other === 'year') {
    return displayDateTime(value, DateFormat.YYYY)
  } else if (other === 'idCard') {
    return addDashInIdCardNo(value)
  } else if (other === 'isAddressCurrent') {
    return value === 'YES'
      ? 'ตรงกับที่อยู่ปัจจุบัน'
      : 'ไม่ตรงกับที่อยู่ปัจจุบัน'
  }
  switch (field) {
    case 'personal.title':
    case 'personalSpouse.title':
      return getLabelFullFormArrText(FULLFORM.title, value)
    case 'personal.gender':
      return getLabelFullFormArrText(FULLFORM.gender, value)
    case 'personal.maritalStatus':
      return getLabelFullFormArrText(FULLFORM.maritalStatus, value)
    case 'personal.nationality':
      return getLabelFullFormArrText(FULLFORM.nationality, value)
    case 'personal.ethnicity':
      return value === 'YES' ? 'ไทย' : '-'
    case 'personal.highestEducation':
      return getLabelFullFormArrText(FULLFORM.education, value)
    case 'personal.channel':
      return getLabelFullFormArrText(FULLFORM.channel, value)
    case 'personalAddress.addressType':
      return getLabelFullFormArrText(FULLFORM.addressType, value)
    case 'work.experienceSaleInsurance':
      return getLabelFullFormArrText(FULLFORM.experienceSaleInsurance, value)
    case 'tax.taxMaritalStatus':
      return getLabelFullFormArrText(FULLFORM.taxMaritalStatus, value)
    case 'tax.coBorrower':
    case 'personalAssets.agentRelative':
    case 'personalAssets.insuranceKTAXA':
    case 'personalAssets.insuranceOther':
    case 'personalAssets.assetEtc':
    case 'personalAssets.vehicle':
    case 'personalAssets.insuranceVehicle':
      return getLabelFullFormArrText(FULLFORM.YesNo, value)
    case 'tax.coBorrowerType':
      // eslint-disable-next-line no-case-declarations
      const arr = `${value}`.split(',')
      // eslint-disable-next-line no-case-declarations
      let text = ''
      if (_.size(arr) === 0) return text

      for (const t of arr) {
        text += getLabelFullFormArrText(FULLFORM.coBorrowerType, t) + ', '
      }

      return text.slice(0, -2)
    case 'policy.conflictOfInterest':
      return getLabelFullFormArrText(FULLFORM.conflictOfInterest, value)
    case 'policy.bankrupt':
    case 'policy.criminalCase':
      return getLabelFullFormArrText(FULLFORM.YesNo2, value)
    case 'policy.authorization':
    case 'policy.insuranceIntermediary':
    case 'policy.majorBusinessPartners':
      return getLabelFullFormArrText(FULLFORM.NoYes, value)
    case 'work.occupation':
      return getLabelFullFormArrText(FULLFORM.occupation, value)
    case 'work.salary':
      return getLabelFullFormArrText(FULLFORM.salary, value)

    default:
      break
  }
  return value
}
