export const handleReadFile = (
  file: File
): Promise<string | ArrayBuffer | null> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.addEventListener('load', () => resolve(reader.result), false)
    reader.addEventListener('error', () => reject(reader.error), false)
    reader.readAsDataURL(file)
  })
}
export const convertToMB = (size: number) => {
  return (size / (1024 * 1024)).toFixed(3) + ' MB'
}
