import { CContainer } from '../../../../../components/CRUD/components/Container'
import { CHeader } from '../../../../../components/CRUD/components/Header'
import { crudConfig as initialCrudConfig } from '../../crudConfig'
import { useEffect, useState } from 'react'
import CFilterDrawer from '../../../../../components/CRUD/components/FilterDrawer'
import { filterItems } from './model/filterItems'
import { defaultFilter } from './model/defaultFilter'
import { breadcrumb } from './model/breadcrumb'
import { handleChange } from '../../../../../components/CRUD/handler/handleChange'
import { handleFiltering } from '../../../../../components/CRUD/handler/handleFiltering'
import { initialConfig } from '../../../../../components/CRUD/handler/initialConfig'
import _ from 'lodash'
import { mutateFilterSateToProp } from './mutation/filterStateToProp'
import { dispatch, store, useSelector } from '../../../../../redux/store'
import {
  selectPaginationTable,
  selectCrudConfig,
  selectFilterProp,
  selectFilterTable,
} from '../../../../../redux/selector'
import { getQueryParams } from '../../../../../utils/getQueryParams'
import ChipFilterContent from './components/ChipFilterContent'
import { handleFetchData } from './handler/handleFetchData'
import {
  clearFilter,
  resetTable,
  setAddOptionDownload,
  setHandleChange,
  setOrder,
  setSelected,
  setSubActions,
} from '../../../../../components/Table/redux'
import { Box, Button, Chip, Typography } from '@mui/material'
import { DC_STATUS } from '../../../../../constants/dc-admin'
import { handleSendToOIC } from './handler/handleSendToOIC'
import { handleSendToRCMS } from './handler/handleSendToRCMS'
import { ProcessResultApprovalStatus } from '../../../../../constants/status'
import { handleSendToCCM } from './handler/handleSendToCCM'
import { useDelay } from '../../../../../utils/useDelay'
import useLocalStorage from '@rehooks/local-storage'
import { permissionItem } from '../../../../../constants/permission'

const ApplicationVerificationList = () => {
  const { page, sort, order, limit } = useSelector(selectPaginationTable)
  const crudConfig = useSelector(selectCrudConfig)
  const filterProp = useSelector(selectFilterProp)
  const status = getQueryParams('status')

  const [user] = useLocalStorage('user')
  const uniqPermissions = _.get(user, 'uniqPermissions', [])
  const isPermission = _.includes(uniqPermissions, permissionItem.DC_ADMIN)

  useEffect(() => {
    dispatch(resetTable())
    dispatch(clearFilter())
    initialConfig(initialCrudConfig)
    if (status === 'REQUEST_DESCISION') {
      dispatch(setAddOptionDownload(<OptionDownload actiontype="SEND_CCM" />))
    } else if (status === 'RCMS') {
      dispatch(setAddOptionDownload(<OptionDownload actiontype="SEND_RCMS" />))
    } else if (status === 'OIC') {
      dispatch(setAddOptionDownload(<OptionDownload actiontype="SEND_OIC" />))
    } else {
      dispatch(setAddOptionDownload(null))
    }
    const _initLoad = async () => {
      await useDelay(500)
      dispatch(setSubActions(<SubMenuBar />))
    }
    _initLoad()
    dispatch(setOrder({ order: 'ASC', sort: 'registerDate' }))
    dispatch(setSelected([]))
  }, [status])

  useEffect(() => {
    if (!_.isUndefined(crudConfig)) {
      handleFetchData(isPermission)
    }
  }, [limit, order, page, sort, crudConfig, filterProp])

  if (_.isUndefined(crudConfig)) {
    return <div />
  }

  return (
    <>
      <CContainer
        drawer={
          <CFilterDrawer
            handleChange={handleChange}
            handleFiltering={() => handleFiltering(mutateFilterSateToProp)}
            filterItems={filterItems}
            defaultFilter={defaultFilter}
          />
        }
        header={
          <CHeader
            title={crudConfig.moduleTitleFull}
            breadcrumbs={breadcrumb(status)}
          />
        }
        headerContent={<ChipFilterContent />}
      />
    </>
  )
}
export const SubMenuBar = () => {
  const { filterState } = useSelector(selectFilterTable)

  const status = getQueryParams('status')
  const menu = _.find(DC_STATUS, { name: status })
  const subStatus: string[] = filterState?.subStatus || []
  const [xSubStatus, setXSubStatus] = useState(subStatus)

  const handleClick = (arrStatus: string[]) => {
    const arr: string[] = subStatus || []
    //const newArr = [...arr, ...arrStatus]
    const newArr = arrStatus
    let uArr: string[] = []
    if (arr.includes(arrStatus[0])) {
      uArr = _.uniq(newArr.filter((e) => !arrStatus.includes(e)))
    } else {
      uArr = _.uniq(newArr)
    }
    setXSubStatus(uArr)
    const newFilter = { ...filterState, subStatus: uArr }
    dispatch(
      setHandleChange({
        key: 'filterState',
        value: newFilter,
      })
    )
    dispatch(handleFiltering(mutateFilterSateToProp))

    const url = new URL(window.location.href)
    const searchParams = new URLSearchParams(url.search)
    searchParams.set('subStatus', uArr.join(','))

    let newSearch = searchParams.toString()
    newSearch = newSearch.replace(/%2C/g, ',')
    url.search = newSearch
    window.history.pushState({}, '', url)
  }

  return (
    <Box sx={{ px: 2 }}>
      <Box sx={{ display: 'flex', gap: 1 }}>
        {menu?.subStatus?.map((menu, key) => (
          <Chip
            key={key}
            variant={
              xSubStatus.includes(menu.status[0]) ? 'filled' : 'outlined'
            }
            sx={{
              backgroundColor: xSubStatus.includes(menu.status[0])
                ? '#CCCCCC'
                : 'initial',
            }}
            label={<Typography variant="body2">{menu.label}</Typography>}
            onClick={() => handleClick(menu.status)}
          />
        ))}
      </Box>
    </Box>
  )
}

const OptionDownload = (props: { actiontype: string }) => {
  const { filterState } = useSelector(selectFilterTable)
  const { selected } = store.getState().table.table
  const subStatus: string[] = filterState?.subStatus || []
  const { actiontype } = props

  const [user] = useLocalStorage('user')
  const uniqPermissions = _.get(user, 'uniqPermissions', [])
  const isPermission = _.includes(uniqPermissions, permissionItem.DC_ADMIN)

  const getOicDic = () => {
    if (_.size(selected) === 0) return true
    if (
      _.size(subStatus) == 2 &&
      subStatus.includes(ProcessResultApprovalStatus.DCV_UPDATED_DESCISION) &&
      subStatus.includes(ProcessResultApprovalStatus.DCV_APPROVED_TO_BE_AGENT)
    )
      return false
    else return true
  }

  switch (actiontype) {
    case 'SEND_CCM':
      return (
        <Button
          disabled={
            !isPermission ||
            _.size(subStatus) > 1 ||
            !subStatus.includes(
              ProcessResultApprovalStatus.DCV_REQUESTED_DESCISION_CCM
            ) ||
            _.size(selected) === 0
          }
          variant={'contained'}
          onClick={() => handleSendToCCM()}
        >
          ส่งอีเมล CCM
        </Button>
      )
    case 'SEND_OIC':
      return (
        <Button
          disabled={!isPermission || getOicDic()}
          variant={'contained'}
          onClick={() => handleSendToOIC()}
        >
          ส่ง คปภ.
        </Button>
      )
    case 'SEND_RCMS':
      return (
        <>
          <Button
            disabled={
              !isPermission ||
              _.size(selected) === 0 ||
              _.size(subStatus) !== 1 ||
              !subStatus.includes(
                ProcessResultApprovalStatus.DCV_UPDATED_E_LICENCING_PASSED
              )
            }
            variant={'contained'}
            onClick={() => handleSendToRCMS()}
          >
            ส่ง RCMS
          </Button>
        </>
      )
    default:
      return null
  }
}
export default ApplicationVerificationList
