import { store } from '../../../../../../redux/store'
import { STATUS } from '../model/status'

export const mutateFilterSateToProp = () => {
  const { filterState }: any = store.getState().table
  let status = []
  let send = []

  if (filterState?.statusChecked) {
    if (filterState?.status?.activeChecked) {
      status.push(STATUS.ACTIVE)
    }
    if (filterState?.status?.inActiveChecked) {
      status.push(STATUS.INACTIVE)
    }
    if (filterState?.status?.deletedChecked) {
      status.push(STATUS.DELETED)
    }
  } else {
    status = []
  }

  if (filterState?.sendChecked) {
    if (filterState?.send?.sendActiveChecked) {
      send.push(STATUS.ACTIVE)
    }
    if (filterState?.send?.sendInActiveChecked) {
      send.push(STATUS.INACTIVE)
    }
  } else {
    send = []
  }

  return {
    name: filterState.nameChecked ? filterState.name : '',
    status: status,
    send: send,
  }
}
