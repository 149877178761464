import { hasPermission } from './hasPermission'
import { hasPermissionPIC } from './hasPermissionPIC'
import _ from 'lodash'

interface ValidatePermissionParams {
  user?: any | null
  moduleType?: string
  permission?: any
  data?: any
}

export const validatePermission = ({
  user,
  moduleType,
  permission,
  data,
}: ValidatePermissionParams): boolean => {
  if (_.isNil(user)) {
    return false
  }
  return hasPermission({
    userPermissions: user.permission,
    permissionList: [
      {
        module: moduleType,
        permission: permission,
        owner: 'PIC',
        isValidatePIC: hasPermissionPIC({
          user,
          data: data,
          moduleType: moduleType,
          permission: permission,
        }),
      },
      {
        module: moduleType,
        permission: permission,
        owner: 'ALL',
      },
    ],
  })
}

export function getUserPermissions(users: any): string[] {
  return users.permission.map((u: { permission: any; module: any }) => {
    return { permission: u.permission, module: u.module }
  })
}
