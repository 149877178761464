/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'
import '@react-pdf-viewer/core/lib/styles/index.css'
import '@react-pdf-viewer/default-layout/lib/styles/index.css'

import { Worker } from '@react-pdf-viewer/core'
import { Viewer } from '@react-pdf-viewer/core'
import {
  defaultLayoutPlugin,
  ToolbarProps,
} from '@react-pdf-viewer/default-layout'
import { PUBLIC_URL } from '../../constants/webMeta'
import Box from '@mui/material/Box'

interface PDFViewerProps {
  fileUrl: string
  height?: string
  disabledDownload?: boolean
}

const PDFViewer: React.FC<PDFViewerProps> = ({
  fileUrl,
  height = '500px',
  disabledDownload = false,
}) => {
  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    sidebarTabs: () => [], // Remove sidebar
    renderToolbar: (Toolbar: (props: ToolbarProps) => React.ReactElement) => (
      <Toolbar>
        {(slots: any) => (
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              px: 0.5,
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {slots.GoToPreviousPage()}
              <Box
                sx={{
                  border: 'solid 1px #CCCCCC',
                  px: 1,
                  borderRadius: 1,
                  background: '#FFFFFF',
                }}
              >
                {slots.CurrentPageLabel()}
              </Box>
              {slots.GoToNextPage()}
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {slots.ZoomOut()}
              {slots.CurrentScale()}
              {slots.ZoomIn()}
            </Box>
            <Box>{!disabledDownload && slots.Download()}</Box>
          </Box>
        )}
      </Toolbar>
    ),
  })
  return (
    <Box style={{ height: height }}>
      <Worker workerUrl={`${PUBLIC_URL}/js/pdf.worker.min.js`}>
        <Viewer fileUrl={fileUrl} plugins={[defaultLayoutPluginInstance]} />
      </Worker>
    </Box>
  )
}

export default PDFViewer
