import { useDispatch } from 'react-redux'
import { setReceiveDate } from '../../../slices/receive-payment'
import { CustomDate, DayjsTZ } from '../../../../../components/Input/CustomDate'

export default function ReceiveDate() {
  const dispatch = useDispatch()

  const handleDateChange = (newValue: DayjsTZ) => {
    dispatch(setReceiveDate(newValue))
  }

  return (
    <div style={{ width: '100%' }}>
      <CustomDate
        label="วันที่รับเงิน"
        name="receiveDate"
        placeholder={'วว/ดด/ปปปป'}
        handleChange={handleDateChange}
        required
      />
    </div>
  )
}
