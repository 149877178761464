import styled from '@mui/material/styles/styled'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import Button, { type ButtonProps } from '@mui/material/Button'

export const BoxHeader = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexBasis: '100%',
  gap: theme.spacing(4),
}))

export const BoxButton = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  gap: theme.spacing(2),
  [theme.breakpoints.down('md')]: {
    position: 'relative',
    width: '100%',
    '& > .MuiIconButton-root': {
      position: 'absolute',
      right: 0,
      top: 0,
    },
  },
  [theme.breakpoints.down('sm')]: {
    gap: theme.spacing(2),
  },
}))

export const ButtonStyle = styled(Button, {
  shouldForwardProp: (props) => props !== 'currentTop',
})<ButtonProps & { currentTop: number }>(({ currentTop }) => ({
  ...(currentTop < 40 &&
    currentTop !== 0 && {
      position: 'fixed',
      right: 24,
      bottom: 48,
      zIndex: 1000,
    }),
}))
