import Dialog from '@mui/material/Dialog'
import { useSelector, shallowEqual } from 'react-redux'
import { SliceModel } from '../../redux/models'
import { DIALOG_TYPE_STRING } from '../dialog/constants'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import _ from 'lodash'
import { CustomLoadingButton } from '../Input/CustomButton'
import { dispatch } from '../../redux/store'
import { closeCustomDialog } from './slices'

export default function CustomDialog() {
  const { dialog } = useSelector(
    (state: SliceModel) => ({
      dialog: state.customDialog,
    }),
    shallowEqual
  )
  const {
    type = DIALOG_TYPE_STRING.DEFAULT,
    isOpen = false,
    content,
    isLoading,
    maxWidth,
    title,
    message,
    textCancelButton,
    textConfirmButton,
    textOKButton,
    disabledCancel,
    handleCancel,
    handleConfirm,
    handleOK,
    boxSx,
  } = dialog

  return (
    <Dialog
      fullWidth
      sx={{ width: 'calc(100%)', margin: '0px' }}
      open={isOpen}
      maxWidth={maxWidth}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      onClose={() => dispatch(closeCustomDialog())}
    >
      {type !== DIALOG_TYPE_STRING.CLOSE && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            padding: 3,
            margin: 0,
            width: '100%',
            opacity: isLoading ? 0.4 : 'unset',
            pointerEvents: isLoading ? 'none' : 'unset',
            ...boxSx,
          }}
        >
          {!_.isEmpty(title) && (
            <Typography
              sx={{
                mb: 2,
                textAlign: 'left',
              }}
              variant="h6"
            >
              {title}
            </Typography>
          )}
          <Box sx={{ textAlign: 'left' }}>
            {content ? content : <Typography>{message}</Typography>}
          </Box>

          {![DIALOG_TYPE_STRING.CUSTOM].includes(type) && (
            <>
              <Box sx={{ display: 'flex', gap: 1, justifyContent: 'right' }}>
                {[DIALOG_TYPE_STRING.CONFIRM].includes(type) && (
                  <CustomLoadingButton
                    data-testid={'btn-cancel'}
                    disabled={disabledCancel}
                    sx={{ borderRadius: 1 }}
                    onClick={() => {
                      if (handleCancel) {
                        handleCancel()
                      } else {
                        dispatch(closeCustomDialog())
                      }
                    }}
                    color="info"
                    variant={
                      type === DIALOG_TYPE_STRING.CONFIRM
                        ? 'outlined'
                        : 'contained'
                    }
                  >
                    {textCancelButton}
                  </CustomLoadingButton>
                )}
                {[
                  DIALOG_TYPE_STRING.CONFIRM,
                  DIALOG_TYPE_STRING.SUCCESS,
                  DIALOG_TYPE_STRING.COMPLETE,
                  DIALOG_TYPE_STRING.ERROR,
                  DIALOG_TYPE_STRING.WARNING,
                ].includes(type) && (
                  <CustomLoadingButton
                    loading={isLoading}
                    sx={{ borderRadius: 1 }}
                    variant="contained"
                    color="info"
                    onClick={() => {
                      if (handleConfirm) {
                        handleConfirm()
                      } else if (handleOK) {
                        handleOK()
                      } else {
                        dispatch(closeCustomDialog())
                      }
                    }}
                  >
                    {[DIALOG_TYPE_STRING.CONFIRM].includes(type)
                      ? textConfirmButton
                      : textOKButton}
                  </CustomLoadingButton>
                )}
              </Box>
            </>
          )}
        </Box>
      )}
    </Dialog>
  )
}
