import Box from '@mui/material/Box'
import styled from '@mui/system/styled'

export const StyledBoxIcon = styled(Box)(() => ({
    width: 56,
    height: 56,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '100%',
}))