import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  HistoryAuditData,
  HistoryProcessData,
  HistoryState,
  initialState,
} from './model'
const HistoryAuditSlice = createSlice({
  name: 'DC-listing',
  initialState,
  reducers: {
    setHistoryAudit: (
      state: HistoryState,
      action: PayloadAction<HistoryAuditData[]>
    ) => {
      state.audit.items = action.payload
    },
    setHistoryAuditIsLoading: (
      state: HistoryState,
      action: PayloadAction<boolean>
    ) => {
      state.audit.isLoading = action.payload
    },
    setHistoryProcess: (
      state: HistoryState,
      action: PayloadAction<HistoryProcessData[]>
    ) => {
      state.process.items = action.payload
    },
    setHistoryProcessIsLoading: (
      state: HistoryState,
      action: PayloadAction<boolean>
    ) => {
      state.process.isLoading = action.payload
    },
  },
})
export const {
  setHistoryAudit,
  setHistoryAuditIsLoading,
  setHistoryProcess,
  setHistoryProcessIsLoading,
} = HistoryAuditSlice.actions

export default HistoryAuditSlice.reducer
