import { Typography } from '@mui/material'
import CompareTableItem, { CompareTableCol } from './CompareTableItem'
import HeaderActions from '../step/HeaderActions'

export const TableAttachment = () => {
  const attachmentRows: CompareTableCol[] = [
    {
      fieldTitle: '-- หลักฐานการชำระเงินค่าธรรมเนียมออกใบอนุญาต',
      fieldName: 'slipPayment',
      other: 'file',
      tooltip:
        'ชำระเงินค่าธรรมเนียมออกใบอนุญาต 300 บาท พร้อมแนบหลักฐานการชำระเงิน',
    },
    {
      fieldTitle: '1.1 รูปถ่ายสีขนาด 1 นิ้ว',
      fieldName: 'photo',
      other: 'file',
      tooltip:
        'รูปหน้าตรง ไม่สวมแว่นตาดำ ฉากหลังภาพสีพื้น ถ่ายไว้ไม่เกิน 6 เดือน',
    },
    {
      fieldTitle: '1.2 สำเนาบัตรประจำตัว ประชาชน หรือ บัตรข้าราชการ',
      fieldName: 'copyOfIdCard',
      other: 'file',
      tooltip:
        'ตัวแทนเซ็นชื่อรับรองสําเนาถูกต้อง หรือถ่ายรูปจากบัตรตัวจริงที่ยังมีผลบังคับ, เห็นรูปหน้าชัดเจน หน้าไม่ดํา',
    },
    {
      fieldTitle: '1.3 สําเนาทะเบียนบ้าน ',
      fieldName: 'copyOfHouseRegistration',
      other: 'file',
      tooltip:
        'ทะเบียนบ้านแบบใหม่ให้ส่งสําเนาที่มีเลขที่บ้านและหน้าที่มีชื่อตัวแทนควบคู่เสมอ,ทะเบียนบ้านแบบเก่าให้ถ่ายสําเนาชัดเจน/เต็มแผ่น, ไม่มีการย้ายออกกรณีมีการขีดฆ่าแก้ไขชื่อ/นามสกุลของตัวแทน และไม่มีใบเปลี่ยนชื่อ/นามสกุล ให้ถ่ายสําเนาด้านหลังทะเบียนบ้านที่ระบุว่ามีการแก้ไขชื่อ/นามสกุล, ตัวแทนเซ็นชื่อรับรองสําเนาถูกต้อง หรือถ่ายรูปจากทะเบียนบ้านตัวจริง',
    },
    {
      fieldTitle: '1.4 สําเนาหน้าบัญชีสมุดคู่ฝากออมทรัพย์ธนาคารกรุงไทยทุกสาขา ',
      fieldName: 'copyOfSavingsBook',
      other: 'file',
    },
    {
      fieldTitle: '1.5 ทะเบียนสมรสกรณีผู้สมัครอายุต่ำกว่า 20 ปีบริบูรณ์',
      fieldName: 'copyOfMarriageCertificate',
      other: 'file',
    },
  ]

  const attachmentOtherRows: CompareTableCol[] = [
    {
      fieldTitle:
        '2.1 ในกรณีที่มีการเปลี่ยน ชื่อ-นามสกุลใช้สําเนาหนังสือสําคัญการเปลี่ยนชื่อ-นามสกุล',
      fieldName: 'copyOfNameChange',
      other: 'file',
      tooltip: 'ตัวแทนเซ็นชื่อรับรองสําเนาถูกต้อง หรือถ่ายรูปจากเอกสารตัวจริง',
    },
    {
      fieldTitle:
        '2.2 บัตรประจําตัวสอบ หรือ แจ้งวันเดือนปีที่สอบ, สถานที่สอบ, เลขที่สอบที่ตัวแทนสอบผ่าน',
      fieldName: 'copyOfExaminationIdCard',
      other: 'file',
    },
    {
      fieldTitle:
        '2.3 สําเนาหนังสือรับรองการอบรมหลักสูตรการขอรับใบอนุญาตเป็นตัวแทนประกันชีวิต',
      fieldName: 'copyOfTrainingCertificate',
      other: 'file',
      tooltip: 'ตัวแทนเซ็นชื่อรับรองสําเนาถูกต้อง หรือถ่ายรูปจากเอกสารตัวจริง',
    },
  ]
  return (
    <>
      <HeaderActions titleStep="Attachment" isLastStep />
      <Typography variant="h6" mb={2}>
        1. เอกสารที่จำเป็นต้องแนบ
      </Typography>
      <CompareTableItem
        sx={{ mb: 4 }}
        rows={attachmentRows}
        type="file"
        showCompare
      />

      <Typography variant="h6" mb={2}>
        2. เอกสารเพิ่มเติม (ถ้ามี)
      </Typography>
      <CompareTableItem
        sx={{ mb: 4 }}
        type="file"
        rows={attachmentOtherRows}
        hideHeader
      />
    </>
  )
}
