import { Box, Typography } from '@mui/material'
import { shallowEqual, useSelector } from 'react-redux'
import { CustomCard } from '../../../../../../components/Layout/CustomCard'
import { SliceModel } from '../../../../../../redux/models'
import { themeColor } from '../../../../../../styles/themes/themeData'
import * as _ from 'lodash'
import {
  arrRequired,
  arrRequiredAttachment,
  subRequired,
} from '../../../handler/validationFullForm'
import { ageCalculator } from '../../../../../../utils/convertFormatDateTime'
import { FormData } from '../../../../../../components/CRUD/types/CrudState'
import BoxText from '../../../../../../components/Layout/BoxText'
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded'
import { dispatch } from '../../../../../../redux/store'
import { FullFormMemo } from '../../../slices/model'
import { getValueStr } from '../../../../../../utils/getValue'
import { openAlertMemoContent } from '../../AlertMemoContent'

export function FullFormStat() {
  const { isLoading, data, fullForm } = useSelector(
    (state: SliceModel) => ({
      isLoading: state.prospect.detail.isLoading,
      data: state.prospect.detail.data,
      fullForm: state.prospect.detail.fullForm,
    }),
    shallowEqual
  )

  const getPercent = (section: number) => {
    if (section === 5) {
      let max = _.size(arrRequiredAttachment)
      let num = 0
      for (const field of arrRequiredAttachment) {
        const find = _.find(fullForm?.attachments, { fieldName: field })
        if (find) {
          num++
        }
      }

      const age = ageCalculator(fullForm?.personal?.dateOfBirth)
      if (age < 20) {
        max++
        const find = _.find(fullForm?.attachments, {
          fieldName: 'copyOfMarriageCertificate',
        })
        if (find) {
          num++
        }
      }
      console.log('num>>', num)
      return (num * 100) / max
    } else {
      const requiredList = arrRequired[section]
      if (!requiredList) return 0

      let max = _.size(requiredList)
      let num = 0
      for (const field of requiredList) {
        const value = _.get(fullForm, `${field}`, null)
        if (!_.isEmpty(value)) {
          num++
        }
        const arrSub = _.find(subRequired, { field: field, value: value })
        if (arrSub && arrSub.arrRequired) {
          max += _.size(arrSub.arrRequired)
          for (const subField of arrSub.arrRequired) {
            const subValue = _.get(fullForm, `${subField}`, null)
            if (!_.isEmpty(subValue)) {
              num++
            }
          }
        }
      }
      return (num * 100) / max
    }
  }

  const fullFormProcess = [
    {
      section: 1,
      processPercent: getPercent(1),
    },
    {
      section: 2,
      processPercent: getPercent(2),
    },
    {
      section: 3,
      processPercent: getPercent(3),
    },
    {
      section: 4,
      processPercent: getPercent(4),
    },
    {
      section: 5,
      processPercent: getPercent(5),
    },
  ]

  const getSumPercent = (allProcess: FormData[]) => {
    let sum = 0
    for (const p of allProcess) {
      sum += p.processPercent
    }
    return ((sum * 100) / 500).toFixed(2)
  }

  return (
    <CustomCard isLoading={isLoading} sx={{ height: '145px' }}>
      <Box mb={1}>
        <Typography variant="body1b">ความคืบหน้าใบสมัคร</Typography>
      </Box>
      <Box display={'flex'} gap={1}>
        <Box>
          <BoxText sx={{ display: 'flex', gap: 2 }}>
            {fullFormProcess.map((item, index) => (
              <Box
                key={index}
                sx={{
                  height: '50px',
                  width: '8px',
                  borderRadius: '50px',
                  backgroundColor: '#f1f1f1',
                  position: 'relative',
                }}
              >
                <Box
                  sx={{
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    height: item.processPercent + '%',
                    width: '100%',
                    borderRadius: '50px',
                    backgroundColor: themeColor.primary.main,
                  }}
                ></Box>
              </Box>
            ))}
          </BoxText>
        </Box>

        <Box sx={{ display: { xs: 'block', md: 'flex' }, gap: 1 }}>
          <BoxText
            sx={{
              display: { xs: 'flex', md: 'block' },
              mb: 1,
              minWidth: '65px',
            }}
          >
            <Typography
              sx={{ pr: 1, pb: { xs: 0, md: 1 } }}
              variant="body2"
              color={'text.gray'}
            >
              คืบหน้า
            </Typography>
            <Typography variant="h6">{`${getSumPercent(fullFormProcess)}%`}</Typography>
          </BoxText>
          <BoxText sx={{ display: { xs: 'flex', md: 'block' }, mb: 1 }}>
            <Typography
              sx={{ pr: 1, pb: { xs: 0, md: 1 } }}
              variant="body2"
              color={'text.gray'}
            >
              คำร้องแก้ไข
            </Typography>
            <Box
              sx={{
                display: 'flex',
                color: _.size(data?.memo) ? 'text.error' : 'text.gray',
                cursor: 'pointer',
              }}
              onClick={() => {
                if (_.size(data?.memo) === 0) return

                const dataMemo: FullFormMemo = {
                  uuid: '',
                  managerUuid: '',
                  prospectUuid: getValueStr(data, 'uuid'),
                  status: getValueStr(data, 'status'),
                  personalInfo: {
                    uuid: getValueStr(data, 'uuid'),
                    title: '',
                    firstName: getValueStr(data, 'name'),
                    lastName: '',
                    gender: getValueStr(fullForm, 'personal.gender'),
                    dateOfBirth: getValueStr(data, 'dateOfBirth'),
                    mobileNo: getValueStr(data, 'mobileNo'),
                  },
                  memo: data?.memo || [],
                }
                dispatch(openAlertMemoContent(dataMemo))
              }}
            >
              <Typography
                sx={{ justifyItems: 'center' }}
                color={_.size(data?.memo) ? 'text.error' : 'text.gray'}
                variant="h6"
              >
                {_.size(data?.memo)}
              </Typography>
              <Typography sx={{ pl: 1, display: { xs: 'none', md: 'block' } }}>
                รายการ
              </Typography>
              <KeyboardArrowRightRoundedIcon
                sx={{ position: 'relative', top: '-1px' }}
              />
            </Box>
          </BoxText>
        </Box>
      </Box>
    </CustomCard>
  )
}
