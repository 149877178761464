export const defaultFilter = {
  codeIdChecked: false,
  codeId: '',
  nameChecked: false,
  name: '',
  idCardNoChecked: false,
  idCardNo: '',
  dateChecked: false,
  startDate: '',
  endDate: '',
  subStatus: [],
}
