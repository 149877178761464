import React, { useState } from 'react'
import { dispatch, store, useSelector } from '../../../redux/store'
import { selectTableSearch } from '../../../redux/selector'
import { BoxSearch, SearchInput } from './styled'
import { handleSearchTable } from '../../CRUD/handler/handleSeaerchTable'
import SearchIcon from '@mui/icons-material/Search'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import Box from '@mui/material/Box'
import { IconButton } from '@mui/material'
const SearchCustom: React.FC = () => {
  const { table } = store.getState().table
  const { search } = useSelector(selectTableSearch)
  const [searchText, setSearchText] = useState('')

  const handleSearch = () => {
    dispatch(handleSearchTable(table, searchText))
  }

  return (
    <>
      <BoxSearch sx={{ position: 'relative' }}>
        <SearchInput
          disabled={table.isLoading}
          data-testid="search-input"
          placeholder={search.placeholder}
          onChange={(e) => setSearchText(e.target.value)}
          value={searchText}
          inputProps={{
            maxLength: search.maxLength,
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleSearch()
            }
          }}
        />
        <Box sx={{ position: 'absolute', right: 0, top: 0 }}>
          <IconButton disabled={table.isLoading} onClick={handleSearch}>
            {table.isLoading ? (
              <MoreHorizIcon />
            ) : (
              <SearchIcon color="primary" />
            )}
          </IconButton>
        </Box>
      </BoxSearch>
    </>
  )
}

export default SearchCustom
