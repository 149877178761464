import _ from 'lodash'

export const SIZE_TYPE_VALUE: Record<string, number> = {
  B: 1,
  KB: 1000,
  MB: 1000000,
  GB: 1000000000,
}

type SizeType = keyof typeof SIZE_TYPE_VALUE

export interface MaxFileSize {
  size: number
  sizeType: SizeType
}

export const handleMaxFileSize = (maxFileSize: MaxFileSize): number => {
  const size = _.get(maxFileSize, 'size', 0)
  const sizeType = _.get(maxFileSize, 'sizeType', 'B') as SizeType
  return size * SIZE_TYPE_VALUE[sizeType]
}
