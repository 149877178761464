import { setAddFormErrors } from '../../../../../../../components/CRUD/slices'
import { closeDialog } from '../../../../../../../components/dialog/slices'
import { ApiStatus } from '../../../../../../../constants/status'
import { AppDispatch } from '../../../../../../../redux/store'
import { callAssignManager } from '../service/thunk'

import _ from 'lodash'
import { Manager } from '../../../../type/types'
import {
  openCustomDialog,
  setCustomDialogIsLoading,
} from '../../../../../../../components/CustomDialog/slices'
import { DIALOG_TYPE_STRING } from '../../../../../../../components/dialog/constants'

export const handleSubmitFormAssign =
  (params: Manager) => async (dispatch: AppDispatch) => {
    if (_.get(params, 'uuid', '') === '') {
      dispatch(
        setAddFormErrors({
          path: 'uuid',
          message: 'กรุณาเลือก Manager',
        })
      )
      return
    }

    dispatch(setCustomDialogIsLoading(true))
    const { payload } = await dispatch(callAssignManager(params))
    if (payload && payload.status === ApiStatus.SUCCESS) {
      dispatch(
        openCustomDialog({
          type: DIALOG_TYPE_STRING.COMPLETE,
          title: 'สำเร็จ',
          message: 'ข้อมูลถูกบันทึกเรียบร้อยแล้ว',
          handleConfirm: () => {
            dispatch(closeDialog())
            window.location.reload()
          },
        })
      )
    } else {
      dispatch(
        openCustomDialog({
          type: DIALOG_TYPE_STRING.COMPLETE,
          title: 'ไม่สำเร็จ',
          message: 'ไม่สามารถบันทึกข้อมูลได้',
          handleConfirm: () => {
            dispatch(closeDialog())
            window.location.reload()
          },
        })
      )
    }
    dispatch(setCustomDialogIsLoading(false))
  }
