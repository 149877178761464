// /* eslint-disable @typescript-eslint/no-explicit-any */

import Box from '@mui/material/Box'
import { useTheme } from '@mui/material'
import { dashboardItem } from '../../model/dashboardItem'
import CardBox from '../CardBox'
import PendingBox from '../PendingBox'
import SuccessBox from '../SuccessBox'
import RCMSBox from '../RCMSBox'
import WaitForApproveBox from '../WaitForApproveBox'
import { useSelector } from '../../../../../../../redux/store'
import _ from 'lodash'

const DashboardContent = () => {
  return (
    <Box sx={{ display: 'flex', columnGap: 3, rowGap: 4, flexWrap: 'wrap' }}>
      <PendingBox />
      <CardBoxItem field="requestEdit" />
      <SuccessBox />
      <CardBoxItem field="inProgress" />
      <CardBoxItem field="waitingForLicense" />
      <RCMSBox/>
      <WaitForApproveBox />
      <Box sx={{ position: 'relative', minWidth: 'calc(33% - 8px)' }}>
        <img
          src="/images/dashboard/person-dashboard.svg"
          style={{
            position: 'absolute',
            bottom: -133,
            right: '50%',
            transform: 'translateX(50%)',
            zIndex: -1,
          }}
        />
      </Box>
    </Box>
  )
}

export default DashboardContent

type Props = { field: string }

export const CardBoxItem: React.FC<Props> = ({ field }) => {
  const data = useSelector((state) => state.dcAdmin.dashboard.data)
  const theme = useTheme()
  const item = dashboardItem(theme)
  return <CardBox item={_.get(item, field, {})} total={_.get(data, field, 0)} />
}
