import { Typography } from '@mui/material'
import { selectCrudFormData } from '../../../../redux/selector'
import { useSelector } from 'react-redux'
import _ from 'lodash'

export const Complete = () => {
  const formData = useSelector(selectCrudFormData)
  const { decision } = formData

  const arrText = []

  if (!_.isEmpty(decision.RamUuid)) {
    arrText.push('RAM')
  }
  if (!_.isEmpty(decision.SRamUuid)) {
    arrText.push('SRAM')
  }
  if (!_.isEmpty(decision.CADTOUuid)) {
    arrText.push('CADTO')
  }
  if (!_.isEmpty(decision.CDOUuid)) {
    arrText.push('CDO')
  }

  const getText = () => {
    if (decision?.hasSendCCM === 'YES') {
      return `อยู่ในรายการรอส่งอีเมลหา CCM แล้ว`
    } else {
      return `ส่งพิจารณาสำเร็จแล้ว`
    }
  }
  return (
    <>
      <Typography>{getText()}</Typography>
    </>
  )
}
