import { Typography, Box } from '@mui/material'
import { breadcrumb } from './model/breadcrumb'
import Breadcrumbs from '../../../../../components/Breadcrumbs'
import { BoxHeader } from './styled'

const Header = () => {
  return (
    <BoxHeader>
      <Box>
        <Typography variant="h4">ตรวจสอบใบสมัคร</Typography>
        <Breadcrumbs menuList={breadcrumb} />
      </Box>
    </BoxHeader>
  )
}

export default Header
