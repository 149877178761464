import _ from "lodash"
import { checkPermissionOwner } from "./checkPermissionOwner"

export const checkPermission = ({ module, permission, data }: any) => {
    const strUser = window.localStorage.getItem('user') || ''
    const user = JSON.parse(strUser)
    const creator = _.get(data, 'createdBy', undefined)
    const assignees = _.get(
      data,
      'assignees',
      _.get(data, 'setting.assignees', []),
    )
  
    if (_.isNil(user)) {
      return false
    }
  
    let hasPermission = false
    for (const obj of user.permission) {
      if (hasPermission) break
      if (obj.module === module) {
        if (obj.permission === permission) {
          hasPermission = checkPermissionOwner({
            owner: obj.owner,
            user,
            creator,
            assignees,
          })
        }
      }
    }
  
    return hasPermission
  }
  