export interface BodyNotification {
  limit: number
  page: number
  tab: 'all' | 'unread'
}
export interface BodyIsRead {
  uuid: string
}
export enum NotificationType {
  PROSPECT = 'PROSPECT',
  MA = 'MA',
  DC = 'DC',
  ADMIN = 'ADMIN',
}

export enum NotificationAction {
  ASSIGN_RULE = 'ASSIGN_RULE',
  REFERRAL_LINK = 'REFERRAL_LINK',
  ACCEPTED_INTERVIEW = 'ACCEPTED_INTERVIEW',
  DECLINED_INTERVIEW = 'DECLINED_INTERVIEW',
  PASSED_INTERVIEW = 'PASSED_INTERVIEW',
  FAILED_INTERVIEW = 'FAILED_INTERVIEW',
  PROSPECT_SENT_FULLFORM_MA_REVIEW = 'PROSPECT_SENT_FULLFORM_MA_REVIEW',
  AFTER_MA_APPROVED_FULL_FORM = 'AFTER_MA_APPROVED_FULL_FORM',
  AFTER_MA_APPROVED_REQUEST_MEMO = 'AFTER_MA_APPROVED_REQUEST_MEMO',
  AFTER_ADMIN_MANAGER_OR_SRAM_APPROVED = 'AFTER_ADMIN_MANAGER_OR_SRAM_APPROVED',
  CONDITIONS_NOT_PASSED = 'CONDITIONS_NOT_PASSED',
  RECEIVE_PROSPECT = 'RECEIVE_PROSPECT',
  INTERVIEW_PROSPECT = 'INTERVIEW_PROSPECT',
  REQUEST_RAM = 'REQUEST_RAM',
  REQUEST_SRAM = 'REQUEST_SRAM',
  REQUEST_CADTO = 'REQUEST_CADTO',
  AFRER_SRAM_APPROVED_BY_LINK_ON_EMAIL = 'AFRER_SRAM_APPROVED_BY_LINK_ON_EMAIL',

  DC_REQUEST_MEMO = 'DC_REQUEST_MEMO',
  APPROVED_TO_BE_AGENT = 'APPROVED_TO_BE_AGENT',
  ASSIGN_RULE_NOT_FOUND = 'ASSIGN_RULE_NOT_FOUND',
  I_FAILED_OUT_OF_LIMIT = 'I_FAILED_OUT_OF_LIMIT',
  UPDATE_RESULT_RAM_SRAM_CADTO_CDO = 'UPDATE_RESULT_RAM_SRAM_CADTO_CDO',
}
