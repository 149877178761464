import React from 'react'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import Menu, { MenuProps } from '@mui/material/Menu'
import styled from '@mui/system/styled'

export const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={8}
    MenuListProps={{ 'aria-labelledby': 'button' }}
    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    minWidth: 200,
    '& .MuiMenuItem-root': {
      display: 'flex',
      gap: theme.spacing(1),
      '& .MuiSvgIcon-root': { fontSize: 24 },
      '&:active': {
        color: theme.palette?.primary?.main,
        backgroundColor: theme.palette?.primary?.outlinedHoverBackground,
      },
    },
  },
}))

export const StyledButton = styled(Button)(({ theme }) => ({
  padding: `${theme.spacing(1)} 22px`,
}))

export const StyledDivider = styled((props) => (
  <Divider orientation="vertical" flexItem {...props} />
))(({ theme }) => ({
  background: theme.palette?.secondary?.containedHoverBackground,
}))
