import type { TableCellProps } from '@mui/material/TableCell'

type NotesTableHeader = Omit<TableCellProps, 'sx'> & {
  label: string
}

export const notesTableHeader: NotesTableHeader[] = [
  { label: 'หัวข้อ' },
  { label: 'รายละเอียดเพิ่มเติม' },
  { label: 'เอกสารแนบ', align: 'center' },
  { label: 'คำสั่ง', align: 'center' },
]
