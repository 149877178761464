export type CheckHistoryStatus = 'Pending' | 'Completed' | 'Rejected'

export const backgroundReviewOptions = [
  {
    label: 'รอตรวจสอบ',
    value: 'Pending',
  },
  {
    label: 'ผ่าน',
    value: 'Completed',
  },
  {
    label: 'ไม่ผ่าน',
    value: 'Rejected',
  },
]

export type AuditInfoForm = {
  ram: { label: string; value: string }
  area: string
  level: string
  ga: string
  role: string
  tUnit: string
  eUnit: string
  hasLicense: string
  licenseNumber: string
  licenseStartDate: string
  licenseExpireDate: string
  formerAgentAXA: string
  licenseNumberAXA: string
  insuranceCompany: string
}

export const variousHistoryLabel: Record<string, string> = {
  CODEISSUANCE: 'ตรวจสอบประวัติการออกรหัสกับบริษัท',
  BLACKLIST: 'ตรวจสอบแบล็กลิสต์',
  INSURANCELICENSE: 'ตรวจสอบประวัติใบอนุญาต',
  BANKRUP: 'ตรวจสอบ BL system, Bankrup',
  AMLO: 'ตรวจสอบ ปปง.',
  SEC: 'ตรวจสอบ กลต.',
  FRAUD: 'ตรวจสอบฉ้อฉล',
}

export const levelOptions = [
  { label: 'IC', value: 'IC' },
  { label: 'SFC', value: 'SFC' },
  { label: 'BE', value: 'BE' },
  { label: 'SBE', value: 'SBE' },
  { label: 'CBE', value: 'CBE' },
]

export const roleOptions = [
  {
    label: 'Agent',
    value: 'Agent',
  },
  {
    label: 'Manager',
    value: 'Manager',
  },
]

export const hasLicenseOptions = [
  {
    label: 'เคย',
    value: true,
  },
  {
    label: 'ไม่เคย',
    value: false,
  },
]

export const formerAgentAXAOptions = [
  {
    label: 'เคย',
    value: true,
  },
  {
    label: 'ไม่เคย',
    value: false,
  },
]
