export const historyProcessText = {
  add_contractor: 'เริ่มต้นตรวจสอบใบสมัคร',
  request_consideration: 'ส่งคำขอพิารณาเพิ่มเติม',
  request_memo: 'ส่งคำขอแก้ไขเอกสาร',
  updated_agent_code: 'ออกชุดตัวแทนสำเร็จ',
  updated_e_licencing_passed: 'รอออกรหัส RCMS',
  sent_mail_descision_ccm: 'ส่งข้อมูลไปให้ CCM',
  reqeusted_e_licencing: 'ส่งข้อมูลไปให้ OIC',
  reqeusted_rcms: 'ส่งข้อมูลไปให้ RCMS',
  verify_background_by_dc: 'ยืนยันการตรวจสอบประวัติ',
  personal: 'ยืนยันข้อมูล ประวัติส่วนตัว ถูกต้อง ',
  'education-work': 'ยืนยันข้อมูล การศึกษา ถูกต้อง ',
  'tax-deduction': 'ยืนยันข้อมูล นโยบาย ถูกต้อง ',
  policy: 'ยืนยันข้อมูล นโยบาย ถูกต้อง ',
  attachment: 'ยืนยันข้อมูล เอกสารแนบ ถูกต้อง ',
  payment_status_paid: 'ยืนยันข้อมูล การชำระเงิน ถูกต้อง ',
  request_e_licencing_failed: 'OIC ไม่อนุมัติ',
  dropped_prospect: 'ปฏิเสธ รายการใบสมัครนี้',
  request_result_consideration_ccm: 'บันทึกผล พิจารณาโดย CCM',
  request_result_consideration_ram: 'บันทึกผล พิจารณาโดย RAM',
  request_result_consideration_sram: 'บันทึกผล พิจารณาโดย SRAM',
  request_result_consideration_cadto: 'บันทึกผล พิจารณาโดย CADTO',
  request_result_consideration_cdo: 'บันทึกผล พิจารณาโดย CDO',
  request_confirm_consideration_ccm: 'ยืนยันผล พิจารณาโดย CCM',
  request_confirm_consideration_ram: 'ยืนยันผล พิจารณาโดย RAM',
  request_confirm_consideration_sram: 'ยืนยันผล พิจารณาโดย SRAM',
  request_confirm_consideration_cadto: 'ยืนยันผล พิจารณาโดย CADTO',
  request_confirm_consideration_cdo: 'ยืนยันผล พิจารณาโดย CDO',
}
