import _ from 'lodash'
import { ProcessResultApprovalStatus } from '../../../../constants/status'
import { InitProfile } from '../../../../types/full-form'

export const disabledBeforeReview = (initProfile: InitProfile | null) => {
  return _.isNull(initProfile?.contractor)
}
export const disabledConsiderationAll = (initProfile: InitProfile | null) => {
  const noEditStatuses = [
    ProcessResultApprovalStatus.DCV_REQUESTED_MEMO,
    ProcessResultApprovalStatus.DS_SAVED_FULL_FORM_MEMO,
    ProcessResultApprovalStatus.DS_SUBMITTED_FULL_FORM_MEMO,

    ProcessResultApprovalStatus.DCV_REQUESTED_DESCISION,
    ProcessResultApprovalStatus.DCV_REQUESTED_DESCISION_CCM,
    ProcessResultApprovalStatus.DCV_SENT_MAIL_DESCISION_CCM,
    ProcessResultApprovalStatus.DCV_REQUESTED_RCMS,

    ProcessResultApprovalStatus.DCV_UPDATED_AGENT_CODE,
    ProcessResultApprovalStatus.DCV_DROPPED,
  ]
  const findNoEdit = noEditStatuses.includes(
    initProfile?.progressBarStatus as ProcessResultApprovalStatus
  )
  return findNoEdit || disabledBeforeReview(initProfile)
}
export const disabledBySection = (memoLists: any[], titleStep: string) => {
  const find = memoLists.filter((e) => e.titleStep === titleStep)
  return _.size(find) > 0
}
