import { dispatch } from '../redux/store'
import { closeDialog, openErrorDialog } from '../components/dialog/slices'
import { themeColor } from '../styles/themes/themeData'

export function handleDispatch401Exception() {
  const message = 'กรุณาเข้าสู่ระบบใหม่อีกครั้ง'
  dispatch(
    openErrorDialog({
      title: 'session หมดอายุ',
      message,
      handleConfirm() {
        dispatch(closeDialog())
      },
      isCloseDialog: false,
      agreeText: 'ตกลง',
      sxActions: {
        '& .MuiButtonBase-root': {
          bgcolor: themeColor.error.main,
          boxShadow: `0px 4px 12px 1px ${themeColor.text.error}59`,

          ':hover': {
            background: themeColor.error.containedHoverBackground,
          },
        },
      },
    })
  )
}
