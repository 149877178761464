import React, { useState } from 'react'
import MoreVert from '@mui/icons-material/MoreVert'
import IconButton from '@mui/material/IconButton'
import MenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'
import { StyledMenu } from './Styled'
import _ from 'lodash'

const DropdownMoreButton = ({ options = [], disabled = false }: any) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div>
      <IconButton
        data-testid="btn-menu"
        disabled={disabled}
        onClick={handleClick}
      >
        <MoreVert />
      </IconButton>
      <StyledMenu
        id="menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {options.map((option: any, index: number) => (
          <MenuItem
            key={index}
            data-testid={`menu-create-${index}`}
            onClick={() => {
              const onClick = _.get(option, 'onClick', () => {})
              onClick()
              handleClose()
            }}
          >
            {_.get(option, 'icon', undefined)}
            <Typography>{_.get(option, 'label', '')}</Typography>
          </MenuItem>
        ))}
      </StyledMenu>
    </div>
  )
}

export default DropdownMoreButton
