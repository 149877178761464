import _ from 'lodash'
export const getOptionDataByUuid = (
  options: any[],
  value: any,
  field = 'value'
) => {
  const data = _.find(options, { uuid: `${field}` })
  return data
}
export const getOptionLabelByUuid = (
  options: any[] = [],
  value: any,
  field = 'label'
) => {
  const data = _.find(options, { uuid: value })
  return _.get(data, `${field}`, '-')
}
