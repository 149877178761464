import dayjs from 'dayjs'
import {
  startLoading,
  stopLoading,
} from '../../../../../../components/Table/redux'
import { dispatch, store } from '../../../../../../redux/store'
import { downloadFile } from '../../../../../../services/utils/downloadFile'
import { mutateFilterPropToBody } from '../mutation/filterPropToBody'
import { dcAdminListManpowerDownload } from '../../../../../../utils/apiPath'
import { EXPORT_TYPE_TH } from '../../../../../../constants/dc-admin'

export const handleExportManpower = async () => {
  dispatch(startLoading())

  const filter = mutateFilterPropToBody()

  const { isSelectAll, selected } = store.getState().table.table
  const body = {
    list: isSelectAll ? [] : selected,
    filter: filter,
    type: isSelectAll ? 'ALL' : 'SELECTED',
    hasSortedColumns: false,
    translation: false,
  }
  const currentDate = dayjs(new Date()).format('YYYYMMDD')

  dispatch(
    downloadFile({
      url: dcAdminListManpowerDownload,
      body: body,
      fileName: `${EXPORT_TYPE_TH.MANPOWER}-${currentDate}.xlsx`,
    })
  )
  dispatch(stopLoading())
}
