import { DashboardDetail } from "../../type/types"

export interface DashboardState {
  isLoading: boolean
  data: DashboardDetail | null
}

export const initialState: DashboardState = {
  isLoading: false,
  data: null,
}
