import Radio from '@mui/material/Radio'
import RadioGroup, { type RadioGroupProps } from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import { type FormLabelProps } from '@mui/material/FormLabel'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { useDispatch, useSelector } from '../../../redux/store'
import { setFieldValue } from '../../CRUD/slices/'
import {
  selectCrudFormData,
  selectCrudFormError,
} from '../../../redux/selector'
import { Box, FormHelperText, Typography } from '@mui/material'
import _ from 'lodash'

interface Props extends RadioGroupProps {
  label: React.ReactNode
  name: string
  options: {
    label: string
    value: string | boolean | number
  }[]
  formLabelProps?: FormLabelProps
  defaultValue?: unknown
  disabled?: boolean
  required?: boolean
}

export default function CustomRadioGroup({
  label,
  name,
  options,
  defaultValue,
  disabled,
  required,
  ...other
}: Readonly<Props>) {
  const dispatch = useDispatch()

  const formData = useSelector(selectCrudFormData)
  const formError = useSelector(selectCrudFormError)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      setFieldValue({
        key: `formData.${name}`,
        value: (event.target as HTMLInputElement).value,
      })
    )
  }

  return (
    <FormControl>
      <Box display={'flex'} alignItems={'center'}>
        <Typography variant="body2">{label}</Typography>
        {required && (
          <Typography sx={{ lineHeight: 1, ml: 1 }} color={'error'}>
            *
          </Typography>
        )}
      </Box>
      <RadioGroup
        aria-labelledby="controlled-radio-buttons-group"
        name={name}
        defaultValue={defaultValue}
        value={_.get(formData, `${name}`, '')}
        onChange={handleChange}
        row
        {...other}
      >
        {options.map((option) => (
          <FormControlLabel
            sx={{ pr: 5 }}
            key={option.label}
            value={option.value}
            control={<Radio checkedIcon={<CheckCircleIcon />} />}
            disabled={disabled}
            label={option.label}
          />
        ))}
      </RadioGroup>

      {!!formError && (
        <FormHelperText error sx={{ px: 2 }}>
          {formError}
        </FormHelperText>
      )}
    </FormControl>
  )
}
