import React, { useEffect } from 'react'
import { useDispatch, useSelector, store } from '../../../../redux/store'
import { selectFilterTable } from '../../../../redux/selector'
import Close from '@mui/icons-material/Close'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import Divider from '@mui/material/Divider'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup'
import IconButton from '@mui/material/IconButton'
import InputBase from '@mui/material/InputBase'
import Typography from '@mui/material/Typography'
import { CustomDatePicker } from '../CustomDatePicker'

import { setHandleChange, setToggleFilterDrawer } from '../../../Table/redux'
import {
  StyledDrawer,
  StyledFooter,
  StyledGroupBox,
  StyledHeadLabel,
  StyledList,
} from '../../../FilterDrawer/Styled'
import isEmpty from 'lodash/isEmpty'
import { setMinDate } from '../../../DateRangeInput/slices'

interface CFilterDrawerProps {
  handleChange: any
  handleFiltering: any
  filterItems: any
  defaultFilter: any
}

const CFilterDrawer: React.FC<CFilterDrawerProps> = ({
  handleChange,
  handleFiltering,
  filterItems,
  defaultFilter,
}) => {
  const { open, filterState, filterStateMemo } = useSelector(selectFilterTable)
  const dispatch = useDispatch()

  const handleClose = () => {
    if (!isEmpty(filterStateMemo)) {
      dispatch(setHandleChange({ key: 'filterState', value: filterStateMemo }))
    }
    dispatch(setToggleFilterDrawer(false))
  }

  useEffect(() => {
    if (filterStateMemo) {
      return
    }
    dispatch(setHandleChange({ key: 'filterState', value: defaultFilter }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  return (
    <StyledDrawer open={open} onClose={handleClose}>
      <StyledHeadLabel>
        <Box>
          <Typography variant="h5">ตัวกรอง</Typography>
          <IconButton
            data-testid="btn-close-drawer"
            color="primary"
            component="span"
            onClick={handleClose}
          >
            <Close />
          </IconButton>
        </Box>
        <Divider />
      </StyledHeadLabel>

      <ListCheckbox listArray={filterItems} handleChange={handleChange} />
      <StyledFooter>
        <Button
          data-testid="btn-clear"
          variant="outlined"
          onClick={() => {
            dispatch(
              setHandleChange({ key: 'filterState', value: defaultFilter })
            )
            dispatch(setMinDate())
          }}
        >
          ล้าง
        </Button>
        <Button
          data-testid="btn-submit"
          onClick={() => {
            dispatch(handleFiltering())
            dispatch(
              setHandleChange({ key: 'filterStateMemo', value: filterState })
            )
          }}
          variant="contained"
        >
          ยืนยัน
        </Button>
      </StyledFooter>
    </StyledDrawer>
  )
}
export default CFilterDrawer

export const ListCheckbox: React.FC<{ listArray: any; handleChange: any }> = ({
  listArray,
  handleChange,
}) => {
  const { filterState }: any = store.getState().table
  const { filterList }: any = store.getState().crud
  return listArray.map((item: any) => {
    let list = item?.list ?? []
    if (filterList?.[item.nameInput]) {
      list = filterList?.[item.nameInput]
    }

    return (
      <StyledGroupBox key={item.nameCheckBox}>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                name={item.nameCheckBox}
                checked={filterState[item.nameCheckBox]}
                onChange={(e) => {
                  handleChange({
                    value: e.target.checked,
                    key: e.target.name,
                    filter: filterState,
                  })
                }}
              />
            }
            label={item.label}
            sx={!filterState[item.nameCheckBox] ? { marginBottom: -1 } : {}}
          />

          {filterState[item.nameCheckBox] && (
            <>
              {item.type === 'text' && (
                <InputBase
                  sx={{ borderRadius: 0 }}
                  type="basic"
                  inputProps={{ 'data-testid': `input-${item.nameInput}` }}
                  name={item.nameInput}
                  disabled={!filterState[item.nameCheckBox]}
                  placeholder={item.placeholder}
                  value={filterState[item.nameInput]}
                  onChange={(e) => {
                    handleChange({
                      key: e.target.name,
                      value: e.target.value,
                      filter: filterState,
                      filterState,
                    })
                  }}
                />
              )}
              {item.type === 'checkbox' && (
                <StyledList>
                  <ListSubCheckbox
                    list={list}
                    listKey={item.nameInput}
                    handleChange={handleChange}
                  />
                </StyledList>
              )}
              {item.type === 'daterange' && (
                <CustomDatePicker filterState={filterState} item={item} />
              )}
            </>
          )}
        </FormGroup>
      </StyledGroupBox>
    )
  })
}

export const ListSubCheckbox: React.FC<{
  list: any
  listKey: any
  handleChange: any
}> = ({ list, listKey, handleChange }) => {
  const { filterState } = useSelector(selectFilterTable)

  return list.map((item: any, index: number) => (
    <FormControlLabel
      key={index}
      label={item.label}
      control={
        <Checkbox
          name={item.name}
          checked={filterState[listKey][item.name]}
          onChange={(e) => {
            handleChange({
              key: e.target.name,
              value: e.target.checked,
              listKey,
              filter: filterState,
            })
          }}
        />
      }
    />
  ))
}
