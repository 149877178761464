import { Box, Typography } from '@mui/material'
import { useSelector } from '../../../../redux/store'
import { UploadMultiReturnPath } from '../../../../components/FullUpload'
import {
  selectCrudFormData,
  selectInitialProfile,
} from '../../../../redux/selector'
import { getTitleNotOwner } from '../handler/getTitleNotOwner'
import CustomRadioGroup from '../../../../components/Input/CustomRadioGroup'

export const CCMResultDecision = () => {
  const initProfile = useSelector(selectInitialProfile)
  const fullFormUuid = String(initProfile?.fullformUuid)
  const formData = useSelector(selectCrudFormData)
  const { considerationCCM } = formData
  const title = getTitleNotOwner(initProfile, 'ยืนยันผลการตรวจสอบ')
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          mb: 2,
          justifyContent: 'space-between',
          alignItems: 'center',
          pr: 2,
        }}
      >
        <Typography variant="h6" sx={{ width: '100%' }}>
          {title}
        </Typography>
      </Box>

      <Box
        sx={{
          maxHeight: '65vh',
          overflow: 'auto',
          scrollbarWidth: 'thin',
          pr: 3,
        }}
      >
        <UploadMultiReturnPath
          name={'considerationCCM.attachments'}
          label={'แนบเอกสารผลการตรวจสอบจาก CCM *'}
          fullFormUuid={fullFormUuid}
          fieldName={'considerationCCM'}
          maxFile={3}
          info={'สูงสุดไม่เกิน 3 รายการ'}
        />
      </Box>
      <CustomRadioGroup
        label={'ต้องการส่งพิจารณาเพิ่มเติมหรือไม่ *'}
        name={'considerationCCM.hasSendDecision'}
        defaultValue={considerationCCM?.hasSendDecision}
        options={[
          {
            label: 'ส่ง',
            value: 'YES',
          },
          {
            label: 'ไม่ส่ง',
            value: 'NO',
          },
        ]}
      />
    </>
  )
}
