import { createAsyncThunk } from '@reduxjs/toolkit'
import { API_URL } from '../../../../../constants/envConfig'
import { callAPI } from '../../../../../utils/callAPI'
import {
  CcmResultBody,
  CcmSendBody,
  DecisionBody,
  HistoryAuditBody,
  ResultConsiderationBody,
} from '../types/formBody'

export const callCrud = createAsyncThunk(
  'dc/call-load-crud',
  async (xType: string, { rejectWithValue }) => {
    try {
      const response = await callAPI({
        url: `${API_URL}/crud`,
        method: 'get',
        headers: { 'x-type': xType },
      })
      return response
    } catch (err) {
      console.log(err)
      return rejectWithValue(null)
    }
  }
)
export const callSubmit = createAsyncThunk(
  'dc/call-submit-decision',
  async (body: DecisionBody, { rejectWithValue }) => {
    try {
      const response = await callAPI({
        url: `${API_URL}/fullform/created/request-consideration`,
        method: 'post',
        body: body,
      })
      return response
    } catch (err) {
      console.log(err)
      return rejectWithValue(null)
    }
  }
)
export const callHistory = createAsyncThunk(
  'dc/call-history',
  async (fullFormUuid: string, { rejectWithValue }) => {
    try {
      const response = await callAPI({
        url: `${API_URL}/fullform/dc/history-consideration/${fullFormUuid}`,
        method: 'get',
      })
      return response
    } catch (err) {
      console.log(err)
      return rejectWithValue(null)
    }
  }
)
export const callResultConsideration = createAsyncThunk(
  'dc/call-result-consideration',
  async (body: ResultConsiderationBody, { rejectWithValue }) => {
    try {
      const response = await callAPI({
        url: `${API_URL}/fullform/update/result-consideration`,
        method: 'post',
        body: body,
      })
      return response
    } catch (err) {
      console.log(err)
      return rejectWithValue(null)
    }
  }
)
export const callCreatedResultConsideration = createAsyncThunk(
  'dc/call-created-result-consideration',
  async (body: ResultConsiderationBody, { rejectWithValue }) => {
    try {
      const response = await callAPI({
        url: `${API_URL}/fullform/created/request-consideration`,
        method: 'post',
        body: body,
      })
      return response
    } catch (err) {
      console.log(err)
      return rejectWithValue(null)
    }
  }
)
export const callSendCCM = createAsyncThunk(
  'dc/call-send-ccm-consideration',
  async (body: CcmSendBody, { rejectWithValue }) => {
    try {
      const response = await callAPI({
        url: `${API_URL}/fullform/update/send-ccm-consideration`,
        method: 'post',
        body: body,
      })
      return response
    } catch (err) {
      console.log(err)
      return rejectWithValue(null)
    }
  }
)
export const callCCMResult = createAsyncThunk(
  'dc/call-ccm-result',
  async (body: CcmResultBody, { rejectWithValue }) => {
    try {
      const response = await callAPI({
        url: `${API_URL}/fullform/update-ccm-result`,
        method: 'post',
        body: body,
      })
      return response
    } catch (err) {
      console.log(err)
      return rejectWithValue(null)
    }
  }
)
export const callHistoryAudit = createAsyncThunk(
  'dc/fullform-history-audit-filter',
  async (body: HistoryAuditBody, { rejectWithValue }) => {
    try {
      const response = await callAPI({
        url: `${API_URL}/fullform/history/audit/filter`,
        method: 'post',
        body: body,
      })
      return response
    } catch (err) {
      console.log(err)
      return rejectWithValue(null)
    }
  }
)
export const callHistoryProcess = createAsyncThunk(
  'dc/fullform-history-process-filter',
  async (body: HistoryAuditBody, { rejectWithValue }) => {
    try {
      const response = await callAPI({
        url: `${API_URL}/fullform/history/process/filter`,
        method: 'post',
        body: body,
      })
      return response
    } catch (err) {
      console.log(err)
      return rejectWithValue(null)
    }
  }
)
