// /* eslint-disable @typescript-eslint/no-explicit-any */

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { StyledCard } from '../../../../../../../components/Styled'
import { selectTableLoading } from '../../../../../../../redux/selector'
import { useSelector } from '../../../../../../../redux/store'
import Table from '../../../../../../../components/Table'

const DCAdminTable = () => {
  const isLoading = useSelector(selectTableLoading)
  return (
    <Box
      sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 2 }}
    >
      <Typography variant="body1b">จำนวนงานของ DC Admin</Typography>
      <StyledCard id="list-table" isLoading={isLoading} sx={{ mt: 0 }}>
        <Table />
      </StyledCard>
    </Box>
  )
}

export default DCAdminTable
