import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { TimePicker } from '@mui/x-date-pickers/TimePicker'
import { useDispatch } from 'react-redux'
import { setReceiveTime } from '../../../slices/receive-payment'
import { DayjsTZ } from '../../../../../components/Input/CustomDate'
import dayjs from 'dayjs'

export default function ReceiveTime() {
  const dispatch = useDispatch()

  const handleTimeChange = (e: DayjsTZ) => {
    const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
    const newValue = dayjs(e).tz(localTimeZone).format()
    dispatch(setReceiveTime(newValue))
  }
  return (
    <div style={{ width: '100%' }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', mb: 1 }}>
        <Typography variant={'body2'} color={'text.secondary'}>
          เวลาที่รับเงิน
        </Typography>
        <Typography sx={{ lineHeight: 1, ml: 0.5 }} color={'error'}>
          *
        </Typography>
      </Box>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <TimePicker
          onChange={handleTimeChange}
          views={['hours', 'minutes']}
          slotProps={{
            textField: { placeholder: 'เลือกเวลา', sx: { width: '100%' } },
          }}
          format="HH:mm"
          ampm={false}
        />
      </LocalizationProvider>
    </div>
  )
}
