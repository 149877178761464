import * as yup from 'yup'

export const formValidationSchema = yup.object({
  text: yup
    .string()
    .nullable()
    .required('กรุณาระบุเหตุผล')
    .max(100, 'กรุณาระบุเหตุผลไม่เกิน 100 ตัวอักษร'),
  sectionID: yup
    .object()
    .shape({
      label: yup.string(),
      value: yup.string(),
    })
    .required(),
  reasonForMA: yup.string().required(),
  reasonForProspect: yup.string().required(),
})
