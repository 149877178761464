import { WebStorageStateStore,StateStore } from 'oidc-client-ts'
import { AuthProviderProps } from 'react-oidc-context'

type ExtendedAuthProviderProps = AuthProviderProps & {
  authority: string
  client_id: string
  client_secret:string
  redirect_uri:string
  post_logout_redirect_uri:string
  userStore: StateStore
  automaticSilentRenew: boolean;
  scope: string;
}

const OneAccount: ExtendedAuthProviderProps = {
  authority: `${window.__env__.ONE_URL}`,
  client_id: window.__env__.ONE_CLIENT_ID,
  client_secret: window.__env__.ONE_CLIENT_SECRET,
  redirect_uri: `${window.location.origin}/`,
  post_logout_redirect_uri: `${window.location.origin}/`,
  userStore: new WebStorageStateStore({
    store: window.localStorage,
  }),
  automaticSilentRenew: false,
  scope: 'openid email',
}
export default OneAccount
