export const permissionItem = {
  CANDIDATE: 'CANDIDATE',
  ASSIGN_RULE: 'ASSIGN_RULE',
  DC_ADMIN: 'DC_ADMIN',
  DASHBOARD: 'DASHBOARD',
  DASHBOARD_WORKLOAD: 'DASHBOARD_WORKLOAD',
  MASTER_DATA: 'MASTER_DATA',
  PERMISSION: 'PERMISSION',
  ATTACHMENT_DOWNLOAD_AND_REVIEW_UPLOAD:
    'ATTACHMENT_DOWNLOAD_AND_REVIEW_UPLOAD',
}

export const permissionAgencyAdmin = [
  {
    module: 'AGENCY_ADMIN',
    owner: 'ALL',
    permission: ['CANDIDATE'],
    remark: 'สิทธิ์การเข้าถึงฟังชั่นการทำงานของ Agency Admin',
  },
  {
    module: 'AGENCY_ADMIN',
    owner: 'ALL',
    permission: ['ASSIGN_RULE'],
    remark: 'สิทธิ์การเข้าถึงฟังชั่นการทำงานของ Assign Rule',
  },
]

export const permissionSuperAdmin = [
  {
    module: 'SUPER_ADMIN',
    owner: 'ALL',
    permission: ['MASTER_DATA'],
    remark: 'สิทธิ์การเข้าถึงฟังชั่นการทำงานของ Master Data',
  },
  {
    module: 'SUPER_ADMIN',
    owner: 'ALL',
    permission: ['PERMISSION'],
    remark: 'สิทธิ์การเข้าถึงฟังชั่นการทำงานของ Permission',
  },
]

export const permissionManpowerAdmin = [
  {
    module: 'MANPOWER_ADMIN',
    owner: 'ALL',
    permission: ['ATTACHMENT_DOWNLOAD_AND_REVIEW_UPLOAD'],
    remark:
      'สิทธิ์การเข้าถึงฟังชั่นการทำงานของการอัปโหลดผลพิจารณาเพิ่มเติม จาก RAM / SRAM / CADTO / CDO',
  },
]

export const permissionDCAdmin = [
  {
    module: 'DC_ADMIN',
    owner: 'ALL',
    permission: ['DC_ADMIN'],
    remark: 'สิทธิ์การเข้าถึงฟังชั่นการทำงานของ DC',
  },
  {
    module: 'DC_ADMIN',
    owner: 'PIC',
    permission: ['DASHBOARD'],
    remark: 'สิทธิ์การ View ภาพรวม',
  },
  {
    module: 'DC_ADMIN',
    owner: 'ALL',
    permission: ['DASHBOARD_WORKLOAD'],
    remark: 'สิทธิ์การ View รายการ',
  },
  ...permissionManpowerAdmin,
]
