export const FULLFORM = {
  gender: [
    {
      label: 'ชาย',
      value: 'M',
    },
    {
      label: 'หญิง',
      value: 'F',
    },
  ],
  title: [
    {
      label: 'นาย',
      value: 'MR',
    },
    {
      label: 'นางสาว',
      value: 'MS',
    },
    {
      label: 'นาง',
      value: 'MRS',
    },
  ],
  maritalStatus: [
    {
      label: 'สมรสแล้ว',
      value: 'M',
    },
    {
      label: 'โสด',
      value: 'S',
    },
    {
      label: 'หม้าย',
      value: 'W',
    },
    {
      label: 'หย่า',
      value: 'D',
    },
  ],
  experienceSaleInsurance: [
    {
      label: 'ไม่มีประสบการณ์',
      value: '0',
    },
    {
      label: 'น้อยกว่า 1 ปี',
      value: '1',
    },
    {
      label: '1-2 ปี',
      value: '2',
    },
    {
      label: '3-5 ปี',
      value: '5',
    },
    {
      label: '5-8 ปี',
      value: '8',
    },
  ],
  workTypeInsurance: [
    {
      label: 'เต็มเวลา',
      value: 'FT',
    },
    {
      label: 'บางเวลา',
      value: 'PT',
    },
  ],
  taxMaritalStatus: [
    {
      label: 'โสด',
      value: 'M',
    },
    {
      label: 'หม้าย',
      value: 'S',
    },
    {
      label: 'คู่สมรสไม่มีเงินได้',
      value: 'MN',
    },
    {
      label: 'คู่สมรสมีเงินได้ แยกยื่นหรือรวมยื่น',
      value: 'MC',
    },
    {
      label: 'คู่สมรสมีเงินได้ จดทะเบียนระหว่างปี',
      value: 'MY',
    },
    {
      label: 'คู่สมรสมีเงินได้ หย่าหรือตายระหว่างปี',
      value: 'MD',
    },
  ],
  nationality: [
    {
      label: 'สัญชาติไทย',
      value: 'T',
    },
    {
      label: 'มีถิ่นที่อยู่ในไทย',
      value: 'N',
    },
  ],
  education: [
    {
      label: 'ประถมศึกษา',
      value: 'US',
    },
    {
      label: 'มัธยมศึกษา',
      value: 'PC',
    },
    {
      label: 'มัธยมศึกษาตอนปลาย',
      value: 'PS',
    },
    {
      label: 'อาชีวะ',
      value: 'DP',
    },
    {
      label: 'ปริญญาตรี/วิชาชีพเฉพาะหรือสูงกว่า',
      value: 'GR',
    },
  ],
  occupation: [
    {
      label: 'พนักงานขาย',
      value: 'S',
    },
    {
      label: 'วิชาชีพเฉพาะ',
      value: 'T',
    },
    {
      label: 'ครู',
      value: 'P',
    },
    {
      label: 'นักธุรกิจ',
      value: 'B',
    },
    {
      label: 'เสมียนพนักงาน',
      value: 'C',
    },
    {
      label: 'รับราชการ',
      value: 'G',
    },
    {
      label: 'ยังไม่มีงานทำ',
      value: 'P',
    },
    {
      label: 'อื่น ๆ',
      value: 'O',
    },
  ],
  channel: [
    {
      label: 'ประกาศรับสมัครงาน',
      value: 'A',
    },
    {
      label: 'สมัครด้วยตนเอง',
      value: 'B',
    },
    {
      label: 'ได้รับการแนะนำจาก ตัวแทน/ผู้บริหาร',
      value: 'C',
    },
    {
      label: 'ได้รับการแนะนำจาก พนักงาน',
      value: 'D',
    },
    {
      label: 'โอนมาจากพนักงาน',
      value: 'E',
    },
    {
      label: 'ช่องทางออนไลน์',
      value: 'F',
    },
  ],
  addressType: [
    {
      label: 'อาศัยกับครอบครัว',
      value: 'H',
    },
    {
      label: 'เช่าอยู่',
      value: 'R',
    },
    {
      label: 'ผ่อน',
      value: 'M',
    },
    {
      label: 'เป็นของตัวเอง',
      value: 'O',
    },
  ],
  salary: [
    {
      label: 'ต่ำกว่า 5,000',
      value: 'A',
    },
    {
      label: 'ระหว่าง 5,001 - 10,000',
      value: 'B',
    },
    {
      label: 'ระหว่าง 10,001 - 20,000',
      value: 'C',
    },
    {
      label: 'มากกว่า 20,000',
      value: 'D',
    },
  ],
  YesNo: [
    {
      label: 'มี',
      value: 'YES',
    },
    {
      label: 'ไม่มี',
      value: 'NO',
    },
  ],
  YesNo2: [
    {
      label: 'เคย',
      value: 'YES',
    },
    {
      label: 'ไม่เคย',
      value: 'NO',
    },
  ],
  NoYes: [
    {
      label: 'ไม่ใช่',
      value: 'NO',
    },
    {
      label: 'ใช่',
      value: 'YES',
    },
  ],
  NoYes2: [
    {
      label: 'ไม่เคย',
      value: 'NO',
    },
    {
      label: 'เคย',
      value: 'YES',
    },
  ],
}
