import dayjs from 'dayjs'
import _ from 'lodash'
import {
  openDialog,
  closeDialog,
} from '../../../../../../components/dialog/slices'
import {
  startLoading,
  stopLoading,
} from '../../../../../../components/Table/redux'
import { dispatch, store } from '../../../../../../redux/store'
import { downloadFile } from '../../../../../../services/utils/downloadFile'
import { mutateFilterPropToBody } from '../mutation/filterPropToBody'
import { dcAdminListRcmsDownload } from '../../../../../../utils/apiPath'

export const handleExportRcms = async () => {
  dispatch(startLoading())

  const filter = mutateFilterPropToBody()

  const subStatus = _.get(filter, 'subStatus', '')
  if (subStatus && subStatus.length > 0) {
    const { isSelectAll, selected } = store.getState().table.table
    const body = {
      list: isSelectAll ? [] : selected,
      filter: filter,
      type: isSelectAll ? 'ALL' : 'SELECTED',
      hasSortedColumns: false,
      translation: false,
    }
    const currentDate = dayjs(new Date()).format('YYYYMMDD')

    dispatch(
      downloadFile({
        url: dcAdminListRcmsDownload,
        body: body,
        fileName: `RCMS-list-${currentDate}.xlsx`,
      })
    )
  } else {
    dispatch(
      openDialog({
        type: 'fail',
        title: 'ไม่สามารถส่งออกข้อมูลได้',
        message:
          'กรุณาเลือกเมนูย่อยรอออกรหัสตัวแทนกับ RCMS \nก่อนทำการส่งออกข้อมูล',
        agreeText: 'ตกลง',
        isCloseDialog: false,
        handleConfirm: () => {
          dispatch(closeDialog())
          window.location.reload()
        },
      })
    )
  }

  dispatch(stopLoading())
}
