import { CustomInput } from '../../../../../../components/CRUD/components/CustomInput'
import CustomSwitchInLine from '../../../../../../components/CRUD/components/CustomSwitchInLine'
import Divider from '@mui/material/Divider'

export const BasicInfo = () => {
  return (
    <>
      <CustomInput
        required
        labelName="ชื่อ SRAM"
        fieldName="name"
        placeholder="ระบุชื่อ SRAM"
      />
      <CustomInput
        required
        labelName="อีเมล"
        fieldName="email"
        placeholder="อีเมล"
      />
        <CustomInput
            labelName="เบอร์โทรศัพท์"
            fieldName="phoneNumber"
            placeholder="เบอร์โทรศัพท์"
        />
        <CustomInput
            labelName="เลขาชื่อไทย"
            fieldName="secretaryNameTh"
            placeholder="เลขาชื่อไทย"
        />
        <CustomInput
            labelName="เลขาชื่ออังกฤษ"
            fieldName="secretaryNameEn"
            placeholder="เลขชื่ออังกฤษ"
        />
        <CustomInput
            labelName="เบอร์โทรศัพท์เลขา"
            fieldName="secretaryPhoneNumber"
            placeholder="เบอร์โทรศัพท์เลขา"
        />
        <CustomInput
            labelName="อีเมลเลขา"
            fieldName="secretaryEmail"
            placeholder="อีเมลเลขา"
        />
        <CustomInput
            labelName="รหัส"
            fieldName="code"
            placeholder="รหัส"
        />
      <CustomSwitchInLine fieldName="status" title="สถานะ" />
      <Divider />
    </>
  )
}
