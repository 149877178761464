import Box from '@mui/material/Box'

export default function CustomNotificationMobile(props: {
  children: React.ReactNode
}) {
  return (
    <>
      <Box
        sx={{
          position: 'fixed',
          display: { xs: 'block', sm: 'none' },
          right: 0,
          width: '100%',
          top: '79px',
          height: '100%',
          bottom: 0,
          backgroundColor: '#FFFFFF',
          zIndex: 10,
          borderTop: 'solid 1px #F1F1F1',
          color: '#333333',
          textAlign: 'left',
        }}
      >
        {props.children}
      </Box>
    </>
  )
}
