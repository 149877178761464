import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CustomDialogState, initialState } from './model'
import _ from 'lodash'
import { DIALOG_TYPE_STRING } from '../../dialog/constants'

const CustomDialogSlice = createSlice({
  name: 'custom-dialog',
  initialState,
  reducers: {
    openCustomDialog: (
      state: CustomDialogState,
      action: PayloadAction<CustomDialogState>
    ) => {
      const payload = action.payload
      state.isOpen = true
      state.type = _.get(payload, 'type', DIALOG_TYPE_STRING.DEFAULT)
      state.isLoading = _.get(payload, 'isLoading', false)
      state.title = _.get(payload, 'title', '')
      state.message = _.get(payload, 'message', '')
      state.content = _.get(payload, 'content', null)
      state.textConfirmButton = _.get(payload, 'textConfirmButton', 'ยืนยัน')
      state.textCancelButton = _.get(payload, 'textCancelButton', 'ยกเลิก')
      state.textOKButton = _.get(payload, 'textOKButton', 'รับทราบ')
      state.handleConfirm = _.get(payload, 'handleConfirm', null)
      state.handleCancel = _.get(payload, 'handleCancel', null)
      state.handleOK = _.get(payload, 'handleOK', null)
      state.showButton = _.get(payload, 'showButton', true)
      state.disabledButton = _.get(payload, 'disabledButton', false)
      state.disabledCancel = _.get(payload, 'disabledCancel', false)
      state.boxSx = _.get(payload, 'boxSx', {})
    },
    closeCustomDialog: (state: CustomDialogState) => {
      state.isOpen = false
      state.isLoading = false
    },
    setCustomDialogIsLoading: (
      state: CustomDialogState,
      action: PayloadAction<boolean>
    ) => {
      state.isLoading = action.payload
    },
  },
})

export const { openCustomDialog, closeCustomDialog, setCustomDialogIsLoading } =
  CustomDialogSlice.actions

export default CustomDialogSlice.reducer
