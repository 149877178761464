import { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
// mui
import Divider from '@mui/material/Divider'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import useTheme from '@mui/material/styles/useTheme'
// hook
import { useLocalStorage } from '@rehooks/local-storage'
// redux
import {
  useSelector,
  dispatch as appDispatch,
} from '../../../../../../redux/store'
import {
  getUploadSingleFile,
  hasError,
} from '../../../../../../components/Upload/slices'
// constant
import { UPLOAD_ICON } from '../../../../../../constants/master-data'
// component
import { CustomInput } from '../../../../../../components/CRUD/components/CustomInput'
import CustomerSwitchInLine from '../../../../../../components/CRUD/components/CustomSwitchInLine'
import UploadSingleFile from '../../../../../../components/Upload/UploadSingleFile'
import BasicUpload from '../../../../../../components/Upload/BasicUpload'
import Image from '../../../../../../components/Image/Image'
// utils
import { handleUploadFile } from '../../../../../../utils/dispatchError'
import { base64toFile } from '../../../../../../utils/base64'
//
import { acceptFiles, acceptFilesProps } from '../model/upload'
import { selectCrudFormData } from '../../../../../../redux/selector'

export default function BasicInfo() {
  const { error, data } = useSelector((state) => state.upload)
  const formData = useSelector(selectCrudFormData)

  const { uuid } = useParams()

  const [uploadImage, setUploadImage] = useState('')

  const [uploadIcon, setUploadIcon] = useLocalStorage<{
    filename: string
    data: string
  }>(UPLOAD_ICON)

  const theme = useTheme()

  const renderUploadIcon = useCallback(() => {
    const fileMetadata = {
      filename: uploadIcon?.filename ?? 'upload-icon.png',
      data: data?.uploadData,
    }

    // * is edit
    if (uuid) {
      // * if data?.uploadData => image from upload file, formData?.icon => file from APIs
      fileMetadata.data = data?.uploadData ?? formData?.icon
    }

    const file = base64toFile(fileMetadata.data, fileMetadata.filename)

    if (file) {
      const imageUrl = URL.createObjectURL(file)

      setUploadImage(imageUrl)

      return () => {
        if (imageUrl) URL.revokeObjectURL(imageUrl)
      }
    }
  }, [data?.uploadData, formData?.icon, uploadIcon?.filename, uuid])

  useEffect(() => {
    setUploadIcon(null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    renderUploadIcon()
  }, [renderUploadIcon])

  return (
    <>
      <CustomInput
        required
        labelName="ชื่อเจ้าของลายมือ"
        fieldName="title"
        placeholder="ระบุชื่อชื่อเจ้าของลายมือ"
      />
      {/* UPLOAD ICON */}
      <UploadSingleFile
        imgPath="/images/manager/amico.svg"
        description={<UploadDragAndDrop />}
        accept={acceptFilesProps}
        textError={typeof error === 'string' ? error : error?.message}
        onDrop={(file) => handleUploadFile(file, acceptFiles, getUploadFile)}
        sx={{
          width: 1,
          marginBottom: theme.spacing(2),
          borderRadius: '8px',
          border: `2px dashed ${theme.palette.common.black}1F`,
          padding: theme.spacing(0, 4),
          cursor: 'pointer',

          [theme.breakpoints.up('md')]: {
            marginBottom: theme.spacing(0),
          },
        }}
      />

      {uploadImage && (
        <Image
          src={uploadImage}
          sx={{
            maxWidth: 150,
            height: 'auto',
            marginX: 'auto',
            '& .MuiBox-root': {
              verticalAlign: 'middle',
              padding: 0,
            },
          }}
          wrapperProps={{
            style: {
              display: 'block',
              border: `1px solid ${theme.palette.common.black}33`,
              boxShadow: `0px 0px 4px 0px ${theme.palette.common.black}33`,
              borderRadius: '8px',
              padding: 8,
            },
          }}
          padding={1}
        />
      )}

      <CustomerSwitchInLine fieldName="status" title="สถานะ" />

      <Divider />
    </>
  )
}

function UploadDragAndDrop() {
  const theme = useTheme()

  return (
    <BasicUpload
      handleUploadFile={(file: any) =>
        handleUploadFile(file, acceptFiles, getUploadFile)
      }
      sx={{
        flexWrap: { xs: 'wrap', md: 'nowrap' },
        gap: { xs: 2, md: 6 },
        py: { xs: 4, md: 0 },
        cursor: 'pointer',
      }}
      acceptFiles={acceptFilesProps['image/jpeg'].join(' ')}
      textButton="อัปโหลดรูป"
      description={
        <>
          <Stack justifyContent="center" alignItems="center">
            <Typography
              align="center"
              variant="body1b"
              color={theme.palette.text.primary}
              fontWeight={700}
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: theme.spacing(0, 0.5),
              }}
            >
              วางไฟล์ที่นี่{' '}
              <Typography variant="body1">
                (จำกัด 1 ไฟล์/รูป เท่านั้น)
              </Typography>
            </Typography>
            <Typography variant="body1" color={theme.palette.text.secondary}>
              .jpeg / .jpg / .png
            </Typography>
            <Typography variant="body1" color={theme.palette.text.secondary}>
              ขนาดแนะนำ ไม่เกิน 2 MB
            </Typography>
          </Stack>

          <Typography
            variant="body1b"
            color={theme.palette.text.primary}
            fontWeight={700}
          >
            หรือ
          </Typography>
        </>
      }
    />
  )
}

function getUploadFile(file: File | undefined) {
  if (file) {
    appDispatch(hasError(null))
    appDispatch(getUploadSingleFile(file))
  }
}
