import { useSelector, shallowEqual } from 'react-redux'
import { CustomDateRangeState } from '../slices/model'
import { SliceModel } from '../../../redux/models'

export const useDateRange = () => {
  const { dateRange } = useSelector(
    (state: SliceModel) => ({
      dateRange: state.dateRange,
    }),
    shallowEqual
  )
  const entryData: CustomDateRangeState = dateRange
  return entryData
}
