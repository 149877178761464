import Typography from '@mui/material/Typography'

import Box from '@mui/material/Box'

import { IconButton } from '@mui/material'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'

import { closeDrawer } from '../../components/CustomDrawer/slices'
import HistoryTable, { DataTableCol } from '../../components/Table/TableHistory'
import { DateFormat } from '../../constants/dateFormat'

import { dispatch, useSelector } from '../../redux/store'
import { displayDateTime } from '../../utils/convertFormatDateTime'

import { useEffect } from 'react'
import { loadHistoryConfig } from './handler/loadHistoryConfig'
import { shallowEqual } from 'react-redux'
import { SliceModel } from '../../redux/models'
import {
  DrawerTableState,
  initialState,
} from '../../components/CustomDrawer/slices/model'

export const HistoryConfig = () => {
  const { table, isLoading } = useSelector(
    (state: SliceModel) => ({
      isLoading: state.drawer.isLoading,
      table: state.drawer.table as DrawerTableState,
    }),
    shallowEqual
  )

  const headers: DataTableCol[] = [
    {
      headerName: 'การทำนัดหมาย Company lead',
      width: '17.5%',
      field: 'companyLead',
    },
    {
      headerName: 'การทำนัดหมาย Referral lead',
      width: '16.5%',
      field: 'referralLead',
    },
    {
      headerName: 'การสัมภาษณ์ Company lead',
      width: '16.5%',
      field: 'companyInterviewLead',
    },
    {
      headerName: 'การสัมภาษณ์ Referral lead',
      width: '16.5%',
      field: 'referralInterviewLead',
    },
    {
      headerName: 'ผู้บันทึก',
      width: '20%',
      field: 'createdBy',
      tooltip: true,
    },
    {
      headerName: 'วันที่',
      width: '12.5%',
      field: 'createdAt',
      renderCell: (row: any) => (
        <>{displayDateTime(row.createdAt, DateFormat.DDMMYYYYHHmm)}</>
      ),
    },
  ]

  useEffect(() => {
    dispatch(loadHistoryConfig(initialState.table as DrawerTableState, 1))
  }, [])
  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const target = event.currentTarget
    const { scrollTop, scrollHeight, clientHeight } = target
    if (scrollTop + clientHeight >= scrollHeight - 10 && !isLoading) {
      if (!isLoading && table.page < Math.ceil(table.total / table.limit)) {
        dispatch(loadHistoryConfig(table, table.page + 1))
      }
    }
  }
  return (
    <Box sx={{ p: 3, width: '100%' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
        <Box sx={{ width: '100%' }}>
          <Typography variant="h5">ประวัติ</Typography>
        </Box>
        <Box>
          <IconButton onClick={() => dispatch(closeDrawer())}>
            <CloseRoundedIcon />
          </IconButton>
        </Box>
      </Box>
      <Box
        onScroll={handleScroll}
        sx={{ height: '85vh', width: '100%', overflow: 'auto' }}
      >
        <HistoryTable
          isLoading={isLoading}
          headers={headers}
          rows={table?.items ?? []}
        />
      </Box>
    </Box>
  )
}
