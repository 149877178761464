import _ from 'lodash'
import { APPLICATION_STATUS_TH } from '../../../../../../constants/dc-admin'

export const breadcrumb = (status: string) => [
  { title: 'ตรวจสอบใบสมัคร', link: '/', pointer: false },
  {
    title: _.get(APPLICATION_STATUS_TH, status, APPLICATION_STATUS_TH.ALL),
    link: '/',
    pointer: false,
  },
]
