import _ from 'lodash'
import {
  openCustomDialog,
  setCustomDialogIsLoading,
} from '../../../../../../components/CustomDialog/slices'
import { DIALOG_TYPE_STRING } from '../../../../../../components/dialog/constants'
import { dispatch, store } from '../../../../../../redux/store'
import { sendToCCM } from '../../../../../../utils/apiPath'
import { callAPI } from '../../../../../../utils/callAPI'

export const handleSendToCCM = async () => {
  const { selected } = store.getState().table.table
  dispatch(
    openCustomDialog({
      type: DIALOG_TYPE_STRING.CONFIRM,
      title: 'ยืนยัน',
      message: `ต้องการนำส่ง CCM จำนวน ${_.size(selected)} รายการใช่หรือไม่`,
      handleConfirm: async () => {
        const body = {
          list: selected,
        }
        dispatch(setCustomDialogIsLoading(true))
        await callAPI({
          method: 'post',
          url: sendToCCM,
          body: body,
          onSuccess: () => {
            dispatch(
              openCustomDialog({
                type: DIALOG_TYPE_STRING.SUCCESS,
                title: 'สำเร็จ',
                message: 'นำส่งCCM เรียบร้อยแล้ว',
                handleOK: () => {
                  window.location.reload()
                },
              })
            )
          },
          onError: (error) => {
            console.error(error)
            alert('Error: Cannot send data to OIC')
          },
        })
        dispatch(setCustomDialogIsLoading(false))
      },
    })
  )
}
