import { createAsyncThunk } from '@reduxjs/toolkit'
import { API_URL } from '../../../../../constants/envConfig'
import { callAPI } from '../../../../../utils/callAPI'

export const callCompareLoad = createAsyncThunk(
  'dc/call-compare-load',
  async (fullFormUuid: string, { rejectWithValue }) => {
    try {
      const response = await callAPI({
        url: `${API_URL}/fullform/history/edit/filter/${fullFormUuid}`,
        method: 'get',
      })
      return response
    } catch (err) {
      return rejectWithValue(null)
    }
  }
)
