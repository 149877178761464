import { ApiStatus } from '../../../constants/status'
import { AppDispatch } from '../../../redux/store'
import { getValueNumber } from '../../../utils/getValue'
import { callIsRead } from '../services/thunk'
import { setIsReadToItems, setUnReadTotal } from '../slices'

export const submitIsRead = (uuid: string) => async (dispatch: AppDispatch) => {
  const { payload } = await dispatch(callIsRead({ uuid: uuid }))
  if (payload.status === ApiStatus.SUCCESS) {
    dispatch(setIsReadToItems(uuid))
    dispatch(setUnReadTotal(getValueNumber(payload, 'result.unreadTotal')))
  }
}
