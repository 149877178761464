export enum fullformStatus {
  DELETED = 'DELETED',
  CREATED = 'CREATED',
  DRAFT = 'DRAFT',
  PENDING = 'PENDING',
  INPROGRESS = 'INPROGRESS',
  REJECT = 'REJECT',
  MEMO = 'MEMO',
  COMPLETE = 'COMPLETE',
}
export enum MemoStatus {
  ACTIVE = 'ACTIVE',
  INPROGRESS = 'INPROGRESS',
  COMPLETE = 'COMPLETE',
}
