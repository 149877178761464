export type FilterType = 'all' | 'unread'

export interface NotificationItem {
  uuid: string
  title: string
  isRead: boolean
  action: string
  refId: string
  prospectUuid: string
  fullformUuid: string
  userType: string
  createdAt: string
}
export interface ResponseMeta {
  total: number
  unReadTotal?: number
  limit: number
  page: number
}

export interface NotificationState {
  isOpen: boolean
  isLoading: boolean
  filterType: FilterType
  readTotal: number
  items: NotificationItem[]
  meta: ResponseMeta
}

export const initialState: NotificationState = {
  isOpen: false,
  isLoading: false,
  filterType: 'unread',
  readTotal: 0,
  items: [],
  meta: {
    total: 7,
    limit: 5,
    page: 1,
  },
}
