import {
  Link as RouterLink,
  type LinkProps as RouterLinkProps,
} from 'react-router-dom'
import Accordion from '@mui/material/Accordion'
import List from '@mui/material/List'
import Stack from '@mui/material/Stack'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Link, { type LinkProps } from '@mui/material/Link'
import styled from '@mui/material/styles/styled'

export const ListStyle = styled(List)(({ theme }) => ({
  width: '100%',
  backgroundColor: theme.palette.common.white,
  padding: 0,

  '&.MuiList-subheader': {
    paddingTop: theme.spacing(2),
  },

  '& .MuiListItem-root:nth-of-type(odd)': {
    backgroundColor: theme.palette.background.blue,
  },

  '& .MuiListItem-divider': {
    borderColor: theme.palette.blue.blueBorder,
  },
}))

export const CardApproveStyle = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: theme.spacing(4),
  backgroundColor: theme.palette.background.paper,
  border: `1px solid ${theme.palette.blue.blueBorder}`,
  borderRadius: '8px',
  padding: theme.spacing(2),
}))

export const AccordionStyle = styled(Accordion)(({ theme }) => ({
  '&.MuiAccordion-root': {
    borderRadius: '8px',
    border: `1px solid ${theme.palette.blue.blueBorder}`,
    boxShadow: 'none',
  },

  '&.MuiAccordion-root::before': {
    display: 'none',
  },

  '& .MuiAccordionDetails-root': {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },

  '& .MuiAccordionSummary-root': {
    alignItems: 'flex-start',
  },

  '& .MuiAccordionSummary-expandIconWrapper': {
    marginTop: theme.spacing(1.5),
  },

  '& .MuiAccordionSummary-content.Mui-expanded': {
    '& .MuiAccordionSummary-divider, .MuiAccordionSummary-actions': {
      display: 'none',
    },
  },

  '& .MuiAccordionSummary-divider': {
    width: 'calc(100% + 24px)',
  },
}))

export const AccordionSummaryWrapper = styled(Stack)(({ theme }) => ({
  width: '100%',
  flexDirection: 'row',
  alignContent: 'center',
  gap: theme.spacing(4),
}))

export const CardStyle = styled(Card)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  alignSelf: 'stretch',
  flexBasis: '33.33%',
  padding: theme.spacing(2, 3),
  borderRadius: 8,
  border: `1px solid ${theme.palette.blue.blueBorder}`,
  boxShadow: `0px 12px 40px 0px  ${theme.palette.blue.oceanBlue}1A`,
}))

export const CardContentStyle = styled(CardContent)({
  width: '100%',
  padding: 0,

  '&.MuiCardContent-root:last-child': {
    paddingBottom: 0,
  },
})

export const LinkStyle = styled((props: LinkProps & RouterLinkProps) => (
  <Link component={RouterLink} {...props} />
))(({ theme }) => ({
  display: 'block',
  marginBottom: theme.spacing(1),
  textDecoration: 'none',
  border: `1px solid ${theme.palette.primary.main}`,
  padding: theme.spacing(0.5, 1.25),
  borderRadius: '4px',
}))
