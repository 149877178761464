import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ListingData, initialState, ListingState } from './model'

const DashboardSlice = createSlice({
  name: 'DC-listing',
  initialState,
  reducers: {
    setDCListing: (
      state: ListingState,
      action: PayloadAction<ListingData[]>
    ) => {
      state.items = action.payload
    },
    setDCListingIsLoading: (
      state: ListingState,
      action: PayloadAction<boolean>
    ) => {
      state.isLoading = action.payload
    },
  },
})
export const { setDCListing, setDCListingIsLoading } = DashboardSlice.actions

export default DashboardSlice.reducer
