import React, { useEffect } from 'react'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import { Routes, Route, useLocation } from 'react-router-dom'
import routes from './routeList'
import Layout from '../Layout'
import _ from 'lodash'
import useLocalStorage from '@rehooks/local-storage'
import { hasPermission } from '../utils'

let previousPath = ''
let currentPath = ''

const withLayout = (WL: any, permissionCheck: boolean) => {
  const Comp = (props: any) => {
    if (permissionCheck) {
      return (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            position: 'absolute',
            top: '45%',
            left: '45%',
          }}
        >
          <Typography variant="h4">403 Forbidden</Typography>
          <Button
            sx={{ alignSelf: 'center' }}
            variant="contained"
            onClick={() => window.history.back()}
          >
            Back
          </Button>
        </Box>
      )
    }
    return (
      <Layout layout={WL.templateLayout}>
        <WL {...props} />
      </Layout>
    )
  }
  Comp.displayName = 'withLayout'
  return Comp
}

const AppRoutes: React.FC = () => {
  const [user] = useLocalStorage('user')
  const location = useLocation()
  useEffect(() => {
    if (!previousPath && !currentPath) {
      previousPath = location.pathname
      currentPath = location.pathname
    } else {
      previousPath = currentPath
      currentPath = location.pathname
    }
  }, [location.pathname])

  return (
    <Routes>
      {routes.map((item) => {
        //check user permission can access
        const permissionCheck =
          !_.isNil(item.permission) &&
          !_.isNil(user) &&
          item.permission.length > 0 &&
          !hasPermission({
            userPermissions: _.get(user, 'permission', []),
            permissionList: item.permission,
          })
        return (
          <Route
            key={item.path}
            path={item.path}
            Component={withLayout(item.component, permissionCheck)}
          />
        )
      })}
    </Routes>
  )
}

export default AppRoutes
