export enum TabFeature {
  ApplicationReview = 0,
  BackgroundReview = 1,
  Notes = 2,
}

export enum TabApplicationReview {
  'Personal' = 'ประวัติส่วนตัว',
  'Education-Work' = 'ประวัติการศึกษา / ประสบการณ์\nการทำงาน / รายละเอียดอื่นๆ',
  'Tax-Deduction' = 'รายการลดหย่อนเพื่อการคำนวณ\nภาษีเงินได้บุคคลธรรมดา',
  'Policy' = 'นโยบาย',
  'Attachment' = 'แนบเอกสาร',
  'Other' = 'อื่นๆ',
}

export const tabNames = {
  PERSONAL: 'PERSONAL',
  EDUCATIONWORK: 'EDUCATIONWORK',
  TAXDEDUCTION: 'TAXDEDUCTION',
  POLICY: 'POLICY',
  ATTACHMENT: 'ATTACHMENT',
} as const
