import _ from 'lodash'
import { ApiStatus } from '../../../../../constants/status'
import { AppDispatch } from '../../../../../redux/store'
import { callCompareLoad } from '../services/thunk'
import { setCompareIsLoading, setCompareItems } from '../slices'

export const compareLoad =
  (fullFormUuid: string) => async (dispatch: AppDispatch) => {
    dispatch(setCompareIsLoading(true))
    const { payload } = await dispatch(callCompareLoad(fullFormUuid))
    if (payload?.status !== ApiStatus.ERROR) {
      dispatch(setCompareItems(_.get(payload, 'result.fullFormData', [])))
    }
    dispatch(setCompareIsLoading(false))
  }
