// Override types of mui
declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    upload: true
  }
}

declare module '@mui/material/styles/createPalette' {
  interface PaletteColor {
    containedHoverBackground?: string
    outlinedHoverBackground?: string
    outlinedResting?: string
    content?: string
    background?: string
  }

  interface Palette {
    primary: PaletteColor
    secondary: PaletteColor
    success: PaletteColor
  }
}

// Update the Typography's Variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    h6Reg: true
    body1b: true
    body2b: true
    body3: true
    body3b: true
    buttons: true
    buttonm: true
    buttonl: true
    buttonsDisabled: true
    avatarletter: true
    inputlabel: true
    helpertext: true
    inputtext: true
    tooltip: true
    toltipb: true
    chip: true
    tableHeader: true
  }
}

declare module '@mui/material/styles' {
  interface TypographyVariants {
    h6Reg: React.CSSProperties
    body1b: React.CSSProperties
    body2b: React.CSSProperties
    body3: React.CSSProperties
    body3b: React.CSSProperties
    buttons: React.CSSProperties
    buttonm: React.CSSProperties
    buttonl: React.CSSProperties
    buttonsDisabled: React.CSSProperties
    avatarletter: React.CSSProperties
    inputlabel: true
    helpertext: React.CSSProperties
    inputtext: React.CSSProperties
    tooltip: React.CSSProperties
    toltipb: React.CSSProperties
    chip: React.CSSProperties
    tableHeader: React.CSSProperties
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    h6Reg: React.CSSProperties
    body1b: React.CSSProperties
    body2b: React.CSSProperties
    body2: React.CSSProperties
    body3: React.CSSProperties
    body3b: React.CSSProperties
    buttons: React.CSSProperties
    buttonm: React.CSSProperties
    buttonl: React.CSSProperties
    buttonsDisabled: React.CSSProperties
    avatarletter: React.CSSProperties
    inputlabel: true
    helpertext: React.CSSProperties
    inputtext: React.CSSProperties
    tooltip: React.CSSProperties
    toltipb: React.CSSProperties
    chip: React.CSSProperties
    tableHeader: React.CSSProperties
  }

  interface TypeBackground {
    default: string
    paper: string
    blue: string
    basic: string
    blur: string
  }

  interface TypeText {
    primary: string
    secondary: string
    gray: string
    lightGray: string
    silver: string
    mercury: string
    wildSand: string
    alabaster: string
    white: string
    disabled: string
    lightBlue: string
    success: string
    error: string
    warning: string
  }

  interface Palette {
    color: PaletteOptions['common'] & {
      gray: string
      light: string
      silver: string
      mercury: string
      wildSand: string
      alabaster: string
    }
    blue: {
      deepDapphire: string
      stTropaz: string
      azure: string
      darkIndigo: string
      indigo: string
      oceanBlue: string
      blueLine: string
      blueBorder: string
    }
    other: {
      divider: string
      outlineBorder: string
      standardInputLine: string
      backdropOverlay: string
      ratingActive: string
      ratingBackground: string
      ratingActiveChip: string
      snackbarBackground: string
      filledInputBackground: string
      learnerHeader: string
      milestoneGrey: string
      greenCard: string
      greenBorder: string
      blueCard: string
      blueBorder: string
    }
    text: {
      primary: string
      secondary: string
      gray: string
      lightGray: string
      silver: string
      mercury: string
      wildSand: string
      alabaster: string
      white: string
      disabled: string
      lightBlue: string
      success: string
      error: string
      warning: string
    }
    silver: {
      primary: string
    }
    background: {
      paper: string
      default: string
      blue: string
      basic: string
      blur: string
    }
    shadow: {
      main: string
      bottom: string
    }
    boxShadow: {
      main: string
      menu: string
    }
    axa: {
      teal: string
    }
  }

  interface PaletteOptions {
    blue: {
      deepDapphire: string
      stTropaz: string
      azure: string
      darkIndigo: string
      indigo: string
      oceanBlue: string
      blueLine: string
      blueBorder: string
    }
    other: {
      divider: string
      outlineBorder: string
      standardInputLine: string
      backdropOverlay: string
      ratingActive: string
      ratingActiveChip: string
      snackbarBackground: string
      filledInputBackground: string
      learnerHeader: string
      milestoneGrey: string
      greenCard: string
      greenBorder: string
      blueCard: string
      blueBorder: string
    }
    silver: {
      primary: string
    }
    shadow: {
      main: string
      bottom: string
    }
    boxShadow: {
      main: string
      menu: string
    }
    axa: {
      teal: string
    }
  }
}

export const themeColor = {
  primary: {
    light: '#3032C1',
    main: '#494DF4',
    dark: '#00008F',
    contrast: '#FFFFFF',
    outlinedRestingBorder: 'rgba(73, 77, 244, 0.5)',
    outlinedHoverBackground: 'rgba(73, 118, 186, 0.08)',
    containedHoverBackground:
      'linear-gradient(0deg, #494DF4, #494DF4), linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2))',
  },
  secondary: {
    light: '#F49889',
    main: '#F07662',
    dark: '#C05E4E',
    contrast: '#FFFFFF',
    containedHoverBackground:
      'linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #F07662',
    outlinedHoverBackground: 'rgba(240, 118, 98, 0.08)',
    outlinedResting: 'rgba(240, 118, 98, 0.5)',
  },
  success: {
    light: '#55D37A',
    main: '#1CC54E',
    dark: '#169E3E',
    contrast: '#FFFFFF',
    containedHoverBackground:
      'linear-gradient(0deg, #4CAF50, #4CAF50), linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3))',
    outlinedHoverBackground: 'rgba(28, 197, 78, 0.08)',
    outlinedResting: 'rgba(28, 197, 78, 0.5)',
    content:
      'linear-gradient(0deg, #1CC54E, #1CC54E), linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6))',
    background:
      'linear-gradient(0deg, #1CC54E, #1CC54E), linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9))',
  },
  warning: {
    light: '#FFB547',
    main: '#ED6C02',
    dark: '#C77700',
    contrast: '#FFFFFF',
    containedHoverBackground:
      'linear-gradient(0deg, #ED6C02, #ED6C02), linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3))',
    outlinedHoverBackground: 'rgba(237, 108, 2, 0.08)',
    outlinedResting: 'rgba(237, 108, 2, 0.5)',
    content:
      'linear-gradient(0deg, #ED6C02, #ED6C02), linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6))',
    background:
      'linear-gradient(0deg, #ED6C02, #ED6C02), linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9))',
  },
  error: {
    light: '#D64F65',
    main: '#C91432',
    dark: '#A11028',
    contrast: '#FFFFFF',
    containedHoverBackground:
      'linear-gradient(0deg, #F44336, #F44336), linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3))',
    outlinedHoverBackground: 'rgba(201, 20, 50, 0.08)',
    outlinedResting: 'rgba(201, 20, 50, 0.5)',
    content:
      'linear-gradient(0deg, #C91432, #C91432), linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6))',
    background:
      'linear-gradient(0deg, #C91432, #C91432), linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9))',
  },
  info: {
    light: '#777AF7;',
    main: '#494DF4',
    dark: '#3A3EC3',
    contrast: '#FFFFFF',
    containedHoverBackground:
      'linear-gradient(0deg, #494DF4, #494DF4), linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2))',
    outlinedHoverBackground: 'rgba(73, 77, 244, 0.08)',
    outlinedResting: 'rgba(73, 77, 244, 0.5)',
    content:
      'linear-gradient(0deg, #494DF4, #494DF4), linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6))',
    background:
      'linear-gradient(0deg, #4976BA, #4976BA), linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9))',
  },
  white: { main: '#FFFFFF', light: '#FAFAFA' },
  dark: { main: '#000000', light: '#666666' },
  black: { main: '#333333', light: '#666666', dark: '#000000' },
  action: {
    active: 'rgba(0, 0, 0, 0.54)',
    hover: 'rgba(0, 0, 0, 0.04)',
    selected: 'rgba(0, 0, 0, 0.08)',
    disabled: 'rgba(0, 0, 0, 0.26)',
    disabledBackground: '#F5F5F5',
    focus: 'rgba(0, 0, 0, 0.12)',
  },
  other: {
    divider: 'rgba(0, 0, 0, 0.07)',
    outlineBorder: 'rgba(0, 0, 0, 0.23)',
    standardInputLine: 'rgba(0, 0, 0, 0.42)',
    backdropOverlay: 'rgba(0, 0, 0, 0.3)',
    ratingActive: '#FFB400',
    ratingBackground: 'rgba(255, 180, 0, 0.15)',
    ratingActiveChip: 'rgba(255, 180, 0, 0.15)',
    snackbarBackground: '#323232',
    filledInputBackground: '#FAFAFA',
    learnerHeader: 'linear-gradient(180deg, #00008F 0%, #000072 100%)',
    milestoneGrey: '#C4C4C4',
    greenCard: 'rgba(2, 113, 128, 0.2)',
    greenBorder: '#027180',
    blueCard: 'rgba(73, 77, 244, 0.2)',
    blueBorder: '#494DF4',
  },
  text: {
    primary: '#333333',
    secondary: '#5F5F5F',
    gray: '#7F7F7F',
    lightGray: '#999999',
    silver: '#CCCCCC',
    mercury: '#E5E5E5',
    wildSand: '#F5F5F5',
    alabaster: '#FAFAFA',
    white: '#FFFFFF',
    disabled: 'rgba(0, 0, 0, 0.38)',
    lightBlue: 'rgba(219, 228, 241, 0.6)',
    success: '#1CC54E',
    error: '#C91432',
    warning: '#ED6C02',
  },
  silver: {
    primary: '#CCCCCC',
  },
  blue: {
    deepDapphire: '#00005B',
    stTropaz: '#2425AA',
    azure: '#3032C1',
    darkIndigo: '#3B3FD8',
    indigo: '#494DF4',
    oceanBlue: '#4976BA',
    blueLine: 'rgba(219, 228, 241, 0.6)',
    blueBorder: '#DBE4F1',
  },
  shadow: {
    main: 'inset -1px 0px 0px rgba(219, 228, 241, 0.6)',
    bottom: 'inset 0px -1px 0px rgba(219, 228, 241, 0.6)',
  },
  boxShadow: {
    main: '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)',
    menu: '0px 4px 4px rgba(204, 204, 204, 0.1)',
  },
  background: {
    paper: '#FFFFFF',
    default: '#FAFAFA',
    blue: '#F8FAFC',
    basic: '#F2F4FA',
    blur: 'rgb(255,255,255,0.6)',
  },
  common: {
    white: '#FFFFFF',
    black: '#000000',
  },
  monthlyPlan: {
    holiday: '#F9FFF4',
    academyActivity: '#CCEFFF',
    training: '#FFC3B9',
    holidayBorder: '#70AD47',
    annualLeaveBorder: '#9BC2E6',
    sickLeaveBorder: '#FF9A58',
    familyCareLeaveBorder: '#F691FF',
    birthdayLeaveBorder: '#7030A0',
    personalLeaveBorder: '#FFC000',
    otherLeaveBorder: '#9FBEAF',
    dayOffBorder: '#07C4B6',
    successBackground:
      'linear-gradient(180deg, rgba(28, 197, 78, 0.08) 0%, rgba(28, 197, 78, 0) 100%)',
    errorBackground:
      'linear-gradient(180deg, rgba(201, 20, 50, 0.08) 0%, rgba(201, 20, 50, 0) 100%)',
    infoBackground:
      'linear-gradient(180deg, rgba(73, 118, 186, 0.08) 0%, rgba(73, 118, 186, 0) 100%)',
    waitingApproveBackground:
      'linear-gradient(180deg, rgba(237, 108, 2, 0.08) 0%, rgba(237, 108, 2, 0) 100%)',
  },
  grey: {
    grey50: '#FAFAFA',
    grey100: '#F5F5F5',
    grey200: '#EEEEEE',
    grey300: '#E0E0E0',
    grey400: '#BDBDBD',
    grey500: '#9E9E9E',
    grey600: '#757575',
    grey700: '#616161',
    grey800: '#424242',
    grey900: '#212121',
    greyA100: '#D5D5D5',
    greyA200: '#AAAAAA',
    greyA400: '#616161',
    greyA700: '#303030',
  },
  rating: {
    theBest: '#B2DFDB',
    great: '#C8E6C9',
    veryGood: '#DCEDC8',
    good: '#F0F4C3',
    ok: '#FFF9C4',
    notBad: '#FFECB3',
    notOk: '#FFE0B2',
    bad: '#FFCCBC',
    veryBad: '#FFCDD2',
    worst: '#F8BBD0',
  },
  axa: { teal: '#027180' },
  manageClass: {
    blueStatus:
      'linear-gradient(90.67deg, #FFFFFF 0%, #EDF1F8 100%) padding-box, linear-gradient(90deg, rgba(73, 118, 186, 0.2) 0%, rgba(73, 118, 186, 0.25) 100%) border-box',
    greenStatus:
      'linear-gradient(90.67deg, #FFFFFF 0%, #F7FDF9 100%) padding-box, linear-gradient(90deg, rgba(73, 118, 186, 0.2) 0%,rgba(73, 118, 186, 0.25) 100%) border-box',
    redStatus:
      'linear-gradient(90.67deg, #FFFFFF 0%, #FEF6F7 100%) padding-box, linear-gradient(90deg, rgba(73, 118, 186, 0.2) 0%,rgba(73, 118, 186, 0.25) 100%) border-box',
    grayStatus:
      'linear-gradient(90.67deg, #FFFFFF 0%, #F1F1F1 100%) padding-box, linear-gradient(90deg, rgba(187, 187, 187, 0.2) 0%,rgba(163, 163, 163, 0.25) 100%) border-box',
    learner:
      'linear-gradient(274.82deg, #E8F5E9 0%, rgba(255, 255, 255, 0) 100%);',
    userCheck:
      'linear-gradient(291.59deg, #FFF8E1 0%, rgba(255, 255, 255, 0) 100%);',
    document:
      'linear-gradient(274.82deg, #EDE7F6 0%, rgba(255, 255, 255, 0) 100%)',
    courseDetail:
      'linear-gradient(274.82deg, #E3F2FD 0%, rgba(255, 255, 255, 0) 100%)',
    classDetail:
      'linear-gradient(275.3deg, #E0F7FA 0%, rgba(255, 255, 255, 0) 100%)',
  },
}

export const btnSize = { s: 30, m: 40, l: 50 }

export const fontStyle = {
  h1: {
    fontSize: 100,
    lineHeight: '112px',
    letterSpacing: '-1.5px',
    fontWeight: 700,
    fontStyle: 'normal',
  },
  h2: {
    fontSize: 64,
    lineHeight: 1,
    letterSpacing: '-0.5',
    fontWeight: 700,
    fontStyle: 'normal',
  },
  h3: {
    fontSize: 52,
    lineHeight: '56px',
    fontWeight: 700,
    fontStyle: 'normal',
  },
  h4: {
    fontSize: 38,
    lineHeight: '42px',
    fontWeight: 700,
    fontStyle: 'normal',
  },
  h5: {
    fontSize: 28,
    lineHeight: '32px',
    fontWeight: 700,
    fontStyle: 'normal',
  },
  h6: {
    fontSize: 24,
    lineHeight: '32px',
    fontWeight: 700,
    fontStyle: 'normal',
  },
  h6Reg: {
    fontSize: 20,
    lineHeight: '20px',
  },
  subtitle1: {
    fontSize: 20,
    lineHeight: '24px',
    fontWeight: 400,
    fontStyle: 'normal',
  },
  subtitle2: {
    fontSize: 18,
    lineHeight: '20px',
    fontWeight: 500,
    fontStyle: 'normal',
  },
  body1: {
    fontSize: 20,
    lineHeight: '24px',
    letterSpacing: '0.15px',
    fontWeight: 400,
    fontStyle: 'normal',
  },
  body1b: {
    fontSize: 20,
    lineHeight: '24px',
    letterSpacing: '0.15px',
    fontWeight: 700,
    fontStyle: 'normal',
  },
  body2: {
    fontSize: 18,
    lineHeight: '20px',
    letterSpacing: '0.15px',
    fontWeight: 400,
    fontStyle: 'normal',
  },
  body2b: {
    fontSize: 18,
    lineHeight: '20px',
    letterSpacing: '0.15px',
    fontWeight: 700,
    fontStyle: 'normal',
  },
  body3: { fontSize: 14, fontWeight: 400, fontStyle: 'normal' },
  body3b: { fontSize: 14, fontWeight: 700, fontStyle: 'normal' },
  button: { fontSize: 18, fontWeight: 500, fontStyle: 'normal' },
  buttons: {
    fontSize: 17,
    lineHeight: '22px',
    fontWeight: 500,
    fontStyle: 'normal',
  },
  buttonm: {
    fontSize: 18,
    lineHeight: '24px',
    letterSpacing: '0.4px',
    fontWeight: 500,
    fontStyle: 'normal',
  },
  buttonl: {
    fontSize: 19,
    lineHeight: '26px',
    letterSpacing: '0.46px',
    fontWeight: 500,
    fontStyle: 'normal',
  },
  buttonsDisabled: {
    fontSize: 17,
    fontWeight: 500,
    fontStyle: 'normal',
    color: 'rgba(0, 0, 0, 0.26)',
  },
  caption: { fontSize: 16, fontWeight: 400, fontStyle: 'normal' },
  overline: {
    fontSize: 16,
    fontWeight: 400,
    fontStyle: 'normal',
    letterSpacing: '1px',
  },
  avatarletter: { fontSize: 24, fontWeight: 400, fontStyle: 'normal' },
  inputlabel: { fontSize: 16, fontWeight: 400, fontStyle: 'normal' },
  helpertext: { fontSize: 16, fontWeight: 400, fontStyle: 'normal' },
  inputtext: { fontSize: 20, fontWeight: 400, fontStyle: 'normal' },
  tooltip: {
    fontSize: 14,
    fontWeight: 500,
    fontStyle: 'normal',
    lineHeight: '14px',
  },
  tooltipb: { fontSize: 14, fontWeight: 500, fontStyle: 'normal' },
  chip: { fontSize: 17, fontWeight: 400, fontStyle: 'normal' },
  coverText: {
    fontSize: 52,
    fontWeight: 500,
    fontStyle: 'normal',
    WebkitTextStroke: '2px #fff',
    textShadow: '0px 4px 8px rgba(0, 0, 0, 0.25)',
    position: 'absolute',
    width: '100%',
    textAlign: 'center',
    padding: '32px 12px',
    top: '50%',
    transform: 'translateY(-50%)',
    background:
      'linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.8) 50%, rgba(255, 255, 255, 0) 100%)',
  },
  alertTitle: {
    fontSize: 20,
    fontWeight: 500,
    fontStyle: 'normal',
  },
  badgeLabel: {
    fontSize: 16,
    fontWeight: 400,
    fontStyle: 'normal',
  },
  tableHeader: {
    fontSize: 18,
    fontWeight: 500,
    fontStyle: 'normal',
  },
}
