import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { combineReducers } from '@reduxjs/toolkit'

import table from '../components/Table/redux'
import dialog from '../components/dialog/slices'
import customDialog from '../components/CustomDialog/slices'
import drawer from '../components/CustomDrawer/slices'
import crud from '../components/CRUD/slices'
import upload from '../components/Upload/slices'
import prospect from '../modules/Prospect/slices/reducer'
import managerLookup from '../components/Lookup/ManagerLookup/slices/index'
import province from '../components/Lookup/ManagerLookup/slices/provinceSlice'
import permission from '../components/Permission/slices'
import dcAdmin from '../modules/DCAdmin/slices/reducer'
import applicantDetails from '../modules/DCAdmin/slices/applicant-details'
import compare from '../modules/DCAdmin/applicant-details/CompareTable/slices'
import dateRange from '../components/DateRangeInput/slices'
import notification from '../components/CustomNotification/slices'

const applicantDetailsPersistConfig = {
  key: 'applicantDetails',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['memoList'],
}

const reducer = combineReducers({
  table,
  dialog,
  customDialog,
  drawer,
  crud,
  upload,
  prospect,
  managerLookup,
  province,
  permission,
  applicantDetails: persistReducer(
    applicantDetailsPersistConfig,
    applicantDetails
  ),
  dcAdmin,
  notification,
  compare,
  dateRange,
})

export default reducer
