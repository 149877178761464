import { Typography } from '@mui/material'
import CompareTableItem, { CompareTableCol } from './CompareTableItem'
import HeaderActions from '../step/HeaderActions'

export const TablePersonal = () => {
  const managerRows: CompareTableCol[] = [
    {
      fieldTitle: 'รหัสผู้จัดการ',
      fieldName: 'managerData.managerCode',
    },
    {
      fieldTitle: 'ชื่อ ผู้จัดการ',
      fieldName: 'managerData.firstNameTH',
    },
    {
      fieldTitle: 'นามสกุล ผู้จัดการ',
      fieldName: 'managerData.lastNameTH',
    },
    {
      fieldTitle: 'รหัสตัวแทนผู้ชักชวน',
      fieldName: 'main.invitedCode',
    },
  ]
  const personalRows: CompareTableCol[] = [
    {
      fieldTitle: 'คำนำหน้า',
      fieldName: 'personal.title',
    },
    {
      fieldTitle: 'ชื่อ',
      fieldName: 'personal.firstName',
    },
    {
      fieldTitle: 'นามสกุล',
      fieldName: 'personal.lastName',
    },
    {
      fieldTitle: 'ชื่อภาษาอังกฤษ',
      fieldName: 'personal.firstNameEn',
    },
    {
      fieldTitle: 'นามสกุลภาษาอังกฤษ',
      fieldName: 'personal.lastNameEn',
    },
    {
      fieldTitle: 'เพศ',
      fieldName: 'personal.gender',
    },
    {
      fieldTitle: 'วัน/เดือน/ปี เกิด',
      fieldName: 'personal.dateOfBirth',
      other: 'date',
    },
    {
      fieldTitle: 'อายุ',
      fieldName: 'personal.dateOfBirth',
      other: 'age',
    },
    {
      fieldTitle: 'ฐานะการสมรส',
      fieldName: 'personal.maritalStatus',
    },
    {
      fieldTitle: 'คำนำหน้า คู่สมรส',
      fieldName: 'personalSpouse.title',
    },
    {
      fieldTitle: 'ชื่อ นามสกุล คู่สมรส',
      fieldName: 'personalSpouse.fullName',
    },
    {
      fieldTitle: 'วัน/เดือน/ปี เกิด คู่สมรส',
      fieldName: 'personalSpouse.dateOfBirth',
      other: 'date',
    },
    {
      fieldTitle: 'เลขบัตรประชาชน คู่สมรส',
      fieldName: 'personalSpouse.idCard',
      other: 'idCard',
    },
    {
      fieldTitle: 'สัญชาติ',
      fieldName: 'personal.nationality',
    },
    {
      fieldTitle: 'สัญชาติอื่น ๆ',
      fieldName: 'personal.nationalityOther',
    },
    {
      fieldTitle: 'เชื้อชาติ',
      fieldName: 'personal.ethnicity',
    },
  ]
  const addressRows: CompareTableCol[] = [
    {
      fieldTitle: 'บ้านเลขที่',
      fieldName: 'personalAddress.houseNo',
    },
    {
      fieldTitle: 'หมู่ที่',
      fieldName: 'personalAddress.village',
    },
    {
      fieldTitle: 'ตรอก/ซอย',
      fieldName: 'personalAddress.soi',
    },
    {
      fieldTitle: 'ถนน',
      fieldName: 'personalAddress.road',
    },
    {
      fieldTitle: 'จังหวัด',
      fieldName: 'personalAddress.provinceUuidData',
    },
    {
      fieldTitle: 'เขต/อำเภอ',
      fieldName: 'personalAddress.amphurUuidData',
    },
    {
      fieldTitle: 'แขวง/ตำบล',
      fieldName: 'personalAddress.tambonUuidData',
    },
    {
      fieldTitle: 'รหัสไปรษณีย์',
      fieldName: 'personalAddress.zipcode',
    },
    {
      fieldTitle: 'ประเภทที่อยู่อาศัย',
      fieldName: 'personalAddress.addressType',
    },
  ]
  const regAddressRows: CompareTableCol[] = [
    {
      fieldTitle: 'ที่อยู่ตามทะเบียนบ้าน',
      fieldName: 'personalAddress.isAddressCurrent',
      other: 'isAddressCurrent',
    },
    {
      fieldTitle: 'บ้านเลขที่',
      fieldName: 'personalAddress.regHouseNo',
    },
    {
      fieldTitle: 'หมู่ที่',
      fieldName: 'personalAddress.regVillage',
    },
    {
      fieldTitle: 'ตรอก/ซอย',
      fieldName: 'personalAddress.regSoi',
    },
    {
      fieldTitle: 'ถนน',
      fieldName: 'personalAddress.regRoad',
    },
    {
      fieldTitle: 'จังหวัด',
      fieldName: 'personalAddress.regProvinceUuidData',
    },
    {
      fieldTitle: 'เขต/อำเภอ',
      fieldName: 'personalAddress.regAmphurUuidData',
    },
    {
      fieldTitle: 'แขวง/ตำบล',
      fieldName: 'personalAddress.regTambonUuidData',
    },
    {
      fieldTitle: 'รหัสไปรษณีย์',
      fieldName: 'personalAddress.regZipcode',
    },
  ]
  const contactRows: CompareTableCol[] = [
    {
      fieldTitle: 'โทรศัพท์มือถือ',
      fieldName: 'personal.mobileNo',
    },
    {
      fieldTitle: 'อีเมล',
      fieldName: 'personal.email',
    },
    {
      fieldTitle: 'เบอร์โทรศัพท์ บ้าน/สำนักงาน',
      fieldName: 'personal.homePhone',
    },
  ]
  const idCardRows: CompareTableCol[] = [
    {
      fieldTitle: 'เลขประจำตัวประชาชน',
      fieldName: 'personal.idCard',
      other: 'idCard',
    },

    {
      fieldTitle: 'บัตรอื่น ๆ',
      fieldName: 'personal.otherCardName',
    },
    {
      fieldTitle: 'เลขบัตร',
      fieldName: 'personal.otherIdCard',
    },
    {
      fieldTitle:
        'เลขบัญชีธนาคารกรุงไทยเท่านั้น (เพื่อใช้รับเงินจากบริษัท กรุงไทย แอกซ่า)',
      fieldName: 'personal.bookbankKT',
    },
  ]
  return (
    <>
      <HeaderActions titleStep="Personal" />
      <Typography variant="h6" mb={2}>
        รหัสผู้จัดการ
      </Typography>
      <CompareTableItem sx={{ mb: 4 }} rows={managerRows} showCompare />

      <Typography variant="h6" mb={2}>
        ข้อมูลส่วนบุคคล
      </Typography>
      <CompareTableItem sx={{ mb: 4 }} rows={personalRows} hideHeader />

      <Typography variant="h6" mb={2}>
        ที่อยู่ปัจุบัน
      </Typography>
      <CompareTableItem sx={{ mb: 4 }} rows={addressRows} hideHeader />

      <Typography variant="body2b">ที่อยู่ตามทะเบียนบ้าน</Typography>
      <CompareTableItem
        sx={{ mb: 4, mt: 2 }}
        rows={regAddressRows}
        hideHeader
      />

      <Typography variant="h6" mb={2}>
        เบอร์โทรศัพท์ / อีเมล
      </Typography>
      <CompareTableItem sx={{ mb: 4 }} rows={contactRows} hideHeader />

      <Typography variant="h6" mb={2}>
        บัตรประจำตัวประชาชน / บัตรอื่น ๆ
      </Typography>
      <CompareTableItem sx={{ mb: 4 }} rows={idCardRows} hideHeader />
    </>
  )
}
