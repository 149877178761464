import isEmpty from 'lodash/isEmpty'
import Modal from 'react-modal'
import { useCallback, useState } from 'react'
import useTheme from '@mui/material/styles/useTheme'
import LoadingButton from '@mui/lab/LoadingButton'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined'
import BasicUpload from '../../../../components/Upload/BasicUpload'
import Image from '../../../../components/Image/Image'
import UploadSingleFile from '../../../../components/Upload/UploadSingleFile'
import { getUploadSingleFile } from '../../../../components/Upload/slices'
import { useDispatch, useSelector } from '../../../../redux/store'
import {
  selectProgressStatus,
  selectInitialProfile,
} from '../../../../redux/selector'
import {
  applicationStatusStep,
  submitUploadResult,
} from '../../slices/applicant-details'
import { handleUploadFile } from '../../../../utils/dispatchError'
import { CardContentStyle, CardStyle } from '../styled'
import { UploadFieldName } from '../model/upload'
import { openCustomDialog } from '../../../../components/CustomDialog/slices'
import { DIALOG_TYPE_STRING } from '../../../../components/dialog/constants'
import { FormRequestDecision } from '../FormRequestDecision'
import { displayDateTime } from '../../../../utils/convertFormatDateTime'
import _ from 'lodash'
import useLocalStorage from '@rehooks/local-storage'
import { permissionItem } from '../../../../constants/permission'

Modal.setAppElement('#root')

export default function AwaitingCCMCheckStatus() {
  const [isOpenModal, setIsOpenModal] = useState(false)

  const [user] = useLocalStorage('user')
  const uniqPermissions = _.get(user, 'uniqPermissions', [])
  const isPermission = _.includes(uniqPermissions, permissionItem.DC_ADMIN)

  const { error, data } = useSelector((state) => state.upload)
  const initProfile = useSelector(selectInitialProfile)
  const applicationStatus = useSelector(selectProgressStatus)
  const { isLoading } = useSelector((state) => state.applicantDetails)

  const dispatch = useDispatch()

  const theme = useTheme()

  const closeModal = () => {
    setIsOpenModal(false)

    document.body.style.overflow = 'auto'
  }

  const handleLicenseNo = useCallback(() => {
    dispatch(
      submitUploadResult({
        fieldName: UploadFieldName.ConsiderationCCM,
        base64: data?.uploadData,
      })
    )

    closeModal()
  }, [data?.uploadData, dispatch])

  const getUploadFile = (file: File | undefined) => {
    if (file) {
      dispatch(getUploadSingleFile(file))
    }
  }

  return (
    <>
      <CardStyle>
        <CardContentStyle>
          <Stack direction="row" gap={2}>
            <Image
              src="/images/illustrate_awaiting_ccm_check.svg"
              alt="Awaiting CCM check"
              effect="opacity"
              sizes="56"
              sx={{
                '& .MuiBox-root': {
                  verticalAlign: 'middle',
                  objectFit: 'contain',
                },
              }}
            />

            <Stack flex={1} justifyContent="center" gap={0.5}>
              <Typography variant="h5" color="primary.main" whiteSpace="nowrap">
                {applicationStatus && applicationStatusStep[applicationStatus]}
              </Typography>
              <Typography variant="body2" color="text.gray">
                วันที่: {displayDateTime(initProfile?.statsuUpdateDate)}
              </Typography>
            </Stack>

            <Stack justifyContent="center" gap={1}>
              <Button
                variant="outlined"
                size="small"
                sx={{ marginButton: 1 }}
                onClick={() => {
                  dispatch(
                    openCustomDialog({
                      type: DIALOG_TYPE_STRING.CUSTOM,
                      content: <FormRequestDecision startWith="CCM_RESULT" />,
                      boxSx: { pr: 0, pe: 0 },
                    })
                  )
                }}
                disabled={!isPermission}
              >
                ผลการตรวจสอบ
              </Button>
            </Stack>
          </Stack>
        </CardContentStyle>
      </CardStyle>

      {/* MODAL */}
      <Modal
        isOpen={isOpenModal}
        onRequestClose={closeModal}
        contentLabel="Awaiting CCM check"
        style={{
          overlay: {
            backgroundColor: `${theme.palette.common.black}BF`,
            zIndex: 1200, // For ensuring overlay is above other elements
          },
          content: {
            maxWidth: '678px',
            width: '100%',
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            padding: 0,
            backgroundColor: theme.palette.background.paper,
            borderRadius: '8px',
            boxShadow: `0px 9px 46px 8px ${theme.palette.common.black}1F,
                          0px 24px 38px 3px ${theme.palette.common.black}24,
                          0px 11px 15px -7px ${theme.palette.common.black}33`,
          },
        }}
      >
        <Box padding={2}>
          <Typography variant="h6">ผลการตรวจสอบ</Typography>
        </Box>
        <Stack paddingX={2} gap={0.5}>
          <Typography variant="body2">
            แนบเอกสารผลการตรวจสอบจาก CCM *
          </Typography>
          <UploadSingleFile
            imgPath="/images/illustrate_upload-dc-admin.svg"
            description={
              <BasicUpload
                handleUploadFile={(file) =>
                  handleUploadFile(
                    file,
                    ['jpg', 'jpeg', 'png', 'pdf'],
                    getUploadFile
                  )
                }
                required
                sx={{
                  flexWrap: { xs: 'wrap', md: 'nowrap' },
                  gap: { xs: 2, md: 6 },
                  py: { xs: 4, md: 0 },
                  cursor: 'pointer',
                }}
                acceptFiles="image/jpeg, image/png, application/pdf'"
                description={
                  <>
                    <Stack justifyContent="center" alignItems="center">
                      <Typography
                        variant="body1"
                        color={theme.palette.primary.main}
                        fontWeight={700}
                      >
                        วางไฟล์ที่นี่
                      </Typography>
                      <Typography
                        variant="body2"
                        color={theme.palette.grey[700]}
                      >
                        JPG, JPEG, PNG, PDF
                      </Typography>
                      <Typography
                        variant="body2"
                        color={theme.palette.grey[700]}
                      >
                        ที่มีขนาดไฟล์ไม่เกิน 5 MB
                      </Typography>
                    </Stack>

                    <Typography variant="body1b">หรือ</Typography>
                  </>
                }
              />
            }
            accept={{
              'image/jpeg': [],
              'image/png': [],
              'application/pdf': [],
            }}
            onDrop={(file) =>
              handleUploadFile(
                file,
                ['png', 'jpeg', 'jpg', 'pdf'],
                getUploadFile
              )
            }
            sx={{
              mb: { xs: 2, md: 0 },
              width: 1,
              cursor: 'pointer',
              borderRadius: '8px',
              border: `2px dashed ${theme.palette.common.black}1F`,
              padding: theme.spacing(0, 4),

              ...(!isEmpty(error) && {
                backgroundColor: `${theme.palette.text.error}14 !important`,
                borderColor: theme.palette.error.main,
              }),
            }}
            textError={typeof error === 'string' ? error : error?.message}
          />
          <Stack direction="row" alignItems="center" spacing={1.5}>
            <ErrorOutlineOutlinedIcon color="primary" />
            <Typography variant="body2" color="primary.main">
              สูงสุดไม่เกิน 3 รายการ
            </Typography>
          </Stack>
        </Stack>

        <Stack
          direction="row"
          justifyContent="flex-end"
          gap={1.5}
          padding={2}
          pt={1}
        >
          <Button variant="outlined" onClick={closeModal}>
            <Typography variant="buttonm">ยกเลิก</Typography>
          </Button>
          <LoadingButton
            variant="contained"
            loading={isLoading}
            disabled={isLoading || isEmpty(data)}
            loadingPosition="end"
            endIcon={<></>}
            onClick={handleLicenseNo}
          >
            ยืนยัน
          </LoadingButton>
        </Stack>
      </Modal>
    </>
  )
}
