import {
  EXPORT_TYPE,
  EXPORT_TYPE_TH,
} from '../../../../../../constants/dc-admin'

export const moreMenu = {
  LIST: [
    {
      label: EXPORT_TYPE_TH.FULLFORM,
      value: EXPORT_TYPE.FULLFORM,
    },
  ],
  PENDING: [
    {
      label: EXPORT_TYPE_TH.FULLFORM,
      value: EXPORT_TYPE.FULLFORM,
    },
  ],
  PROCESS: [
    {
      label: EXPORT_TYPE_TH.FULLFORM,
      value: EXPORT_TYPE.FULLFORM,
    },
  ],
  MEMO: [
    {
      label: EXPORT_TYPE_TH.MANPOWER,
      value: EXPORT_TYPE.MAN_POWER,
    },
    {
      label: EXPORT_TYPE_TH.FULLFORM,
      value: EXPORT_TYPE.FULLFORM,
    },
  ],
  REQUEST_DESCISION: [
    {
      label: EXPORT_TYPE_TH.MANPOWER,
      value: EXPORT_TYPE.MAN_POWER,
    },
    {
      label: EXPORT_TYPE_TH.FULLFORM,
      value: EXPORT_TYPE.FULLFORM,
    },
  ],
  OIC: [
    { label: EXPORT_TYPE_TH.APPLICANT, value: EXPORT_TYPE.APPLICANT },
    { label: EXPORT_TYPE_TH.RECEIPT, value: EXPORT_TYPE.RECEIPT },
    {
      label: EXPORT_TYPE_TH.WITHDRAWAL_SLIP,
      value: EXPORT_TYPE.WITHDRAWAL_SLIP,
    },
    {
      label: EXPORT_TYPE_TH.MANPOWER,
      value: EXPORT_TYPE.MAN_POWER,
    },
    {
      label: EXPORT_TYPE_TH.FULLFORM,
      value: EXPORT_TYPE.FULLFORM,
    },
  ],
  RCMS: [
    { label: EXPORT_TYPE_TH.RCMS, value: EXPORT_TYPE.RCMS },
    {
      label: EXPORT_TYPE_TH.MANPOWER,
      value: EXPORT_TYPE.MAN_POWER,
    },
    {
      label: EXPORT_TYPE_TH.FULLFORM,
      value: EXPORT_TYPE.FULLFORM,
    },
  ],
  SUCCESS: [
    { label: EXPORT_TYPE_TH.REPORT, value: EXPORT_TYPE.REPORT },
    { label: EXPORT_TYPE_TH.VERIFY_FAILED, value: EXPORT_TYPE.VERIFY_FAILED },
    {
      label: EXPORT_TYPE_TH.MANPOWER,
      value: EXPORT_TYPE.MAN_POWER,
    },
    {
      label: EXPORT_TYPE_TH.FULLFORM,
      value: EXPORT_TYPE.FULLFORM,
    },
  ],
}
