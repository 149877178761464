import { Typography } from '@mui/material'
import { CustomCard } from '../../../../components/Layout/CustomCard'
import { useSelector } from 'react-redux'
import { selectCrudFormData } from '../../../../redux/selector'
import { getOptionLabelByUuid } from './handler/getOptionData'
import { UploadMultiReturnPath } from '../../../../components/FullUpload'
import _ from 'lodash'

export const FormView = () => {
  const formData = useSelector(selectCrudFormData)
  const { decision, options } = formData

  return (
    <>
      {_.isEmpty(decision?.hasSendCCM) && (
        <CustomCard sx={{ p: 1, mb: 2 }}>
          <Typography variant={'body2'} mb={1} color={'text.gray'}>
            ผลการตรวจสอบจาก CCM
          </Typography>
          <UploadMultiReturnPath
            name={'considerationCCM.attachments'}
            fullFormUuid={''}
            fieldName={'consideration'}
            isView
          />
        </CustomCard>
      )}

      <CustomCard sx={{ p: 1, mb: 1 }}>
        <Typography variant={'body2'} color={'text.gray'}>
          RAM
        </Typography>
        <Typography>
          {getOptionLabelByUuid(
            options?.RAM_PROFILE,
            decision?.RamUuid,
            'name_alias'
          )}
        </Typography>
      </CustomCard>
      <CustomCard sx={{ p: 1, mb: 1 }}>
        <Typography variant={'body2'} color={'text.gray'}>
          SRAM
        </Typography>
        <Typography>
          {getOptionLabelByUuid(
            options?.SRAM_PROFILE,
            decision?.SRamUuid,
            'name_alias'
          )}
        </Typography>
      </CustomCard>
      <CustomCard sx={{ p: 1, mb: 1 }}>
        <Typography variant={'body2'} color={'text.gray'}>
          รายละเอียด
        </Typography>
        <Typography>{decision?.detail ?? '-'}</Typography>
      </CustomCard>
      <CustomCard sx={{ p: 1, mb: 1 }}>
        <Typography variant={'body2'} mb={1} color={'text.gray'}>
          เอกสารแนบ
        </Typography>

        <UploadMultiReturnPath
          name={'decision.attachments'}
          fullFormUuid={''}
          fieldName={'consideration'}
          isView
        />
      </CustomCard>
      <CustomCard sx={{ p: 1, mb: 1 }}>
        <Typography variant={'body2'} color={'text.gray'}>
          CADTO
        </Typography>
        <Typography>
          {getOptionLabelByUuid(
            options?.CADTO_PROFILE,
            decision?.CADTOUuid,
            'name'
          )}
        </Typography>
      </CustomCard>
      <CustomCard sx={{ p: 1, mb: 1 }}>
        <Typography variant={'body2'} color={'text.gray'}>
          CDO
        </Typography>
        <Typography>
          {getOptionLabelByUuid(
            options?.CDO_PROFILE,
            decision?.CDOUuid,
            'name'
          )}
        </Typography>
      </CustomCard>
    </>
  )
}
