import { useSelector, shallowEqual } from 'react-redux'
import { CustomCard } from '../../../../../../components/Layout/CustomCard'
import { SliceModel } from '../../../../../../redux/models'

export function TimerBlank() {
  const { isLoading } = useSelector(
    (state: SliceModel) => ({
      isLoading: state.prospect.detail.isLoading,
    }),
    shallowEqual
  )
  return (
    <CustomCard
      isLoading={isLoading}
      sx={{ height: '145px', alignItems: 'center', display: 'flex' }}
    ></CustomCard>
  )
}
