export const defaultFilter = {
  nameChecked: false,
  name: '',
  managerCodeChecked: false,
  managerCode: '',
  managerNameChecked: false,
  managerName: '',
  channelChecked: false,
  channel: {
    directChecked: false,
    referralChecked: false,
  },
  createdAtChecked: false,
  createdAt: '',
  dateChecked: false,
  date: '',
  prospectStatusChecked: false,
  prospectStatus: {
    waitingForReAssignChecked: false,
    submittiedShortFormChecked: false,
    managerAcceptedInterviewChecked: false,
    submittiedShortFormInactiveChecked: false,
  },
  loginStatusChecked: false,
  loginStatus: {
    statusActiveChecked: false,
    statusInactiveChecked: false,
  },
  userStatusChecked: false,
  userStatus: {
    statusActiveChecked: false,
    statusInactiveChecked: false,
    statusDeletedChecked: false,
  },
}
