import { PUBLIC_URL } from '../../../constants/webMeta'
import { NotificationItem } from '../slices/model'
import { NotificationAction } from '../types'

export const getActionLink = (item: NotificationItem) => {
  switch (item.action) {
    case NotificationAction.ASSIGN_RULE_NOT_FOUND:
    case NotificationAction.I_FAILED_OUT_OF_LIMIT:
      return `${PUBLIC_URL}/prospect/detail/${item.prospectUuid}`

    default:
      return `${PUBLIC_URL}/dc-admin/applicant-details/${item.prospectUuid}`
  }
}
