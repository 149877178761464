import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material'
import CardBox from '../CardBox'
import { dashboardItem } from '../../model/dashboardItem'
import _ from 'lodash'
import { useSelector } from '../../../../../../../redux/store'
import { StyledSubBox, StyledSubContainer } from './styled'
import dayjs from 'dayjs'

const SuccessBox = () => {
  const field = 'success'
  const data = useSelector((state) =>
    _.get(state.dcAdmin.dashboard.data, field, 0)
  )
  const theme = useTheme()
  const item = dashboardItem(theme)[field]

  const startDate =
    dayjs()
      .startOf('month') // เริ่มต้นเดือน
      .format('DD/MM') + `/${dayjs().year() + 543}`

  const endDate =
    dayjs()
      .endOf('day') // สิ้นสุดวัน
      .format('DD/MM') + `/${dayjs().year() + 543}`

  return (
    <CardBox item={item} total={_.get(data, 'total', 0)}>
      <StyledSubContainer>
        <SubBoxSuccessItem
          label="ผ่าน"
          unit="คน"
          value={_.get(data, 'approved', 0)}
        />
        <SubBoxSuccessItem
          label="ถูกปฏิเสธ"
          unit="คน"
          value={_.get(data, 'rejected', 0)}
        />
        <Typography
          variant="caption"
          color="text.secondary"
          sx={{
            marginTop: theme.spacing(1),
            fontSize: '0.75rem',
            width: '100%', // ให้ข้อความครอบคลุมความกว้างของกล่อง
            textAlign: 'center',
          }}
        >
          ข้อมูล ณ วันที่ {startDate} ถึง {endDate}
        </Typography>
      </StyledSubContainer>
    </CardBox>
  )
}

export default SuccessBox

export const SubBoxSuccessItem = ({ label, unit, value }: any) => {
  return (
    <StyledSubBox>
      <Typography color="text.gray">{label}</Typography>
      <Typography variant="body1b" color="text.gray">
        {value}
        <span style={{ marginLeft: 12 }}>{unit}</span>
      </Typography>
    </StyledSubBox>
  )
}
