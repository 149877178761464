import { Typography } from '@mui/material'
import CompareTableItem, { CompareTableCol } from './CompareTableItem'
import HeaderActions from '../step/HeaderActions'

export const TablePolicy = () => {
  const policyRows: CompareTableCol[] = [
    {
      fieldTitle:
        'คุณมีความขัดแย้ง ทางผลประโยชน์ หรือความขัดแย้ง ในการปฏิบัติหน้าที่ ที่ประสงค์จะเปิดเผยหรือไม่?',
      fieldName: 'policy.conflictOfInterest',
    },
    {
      fieldTitle: 'โปรดให้รายละเอียด',
      fieldName: 'policy.conflictOfInterestInfo',
    },
    {
      fieldTitle: 'ท่านเคยถูกศาลพิพากษาล้มละลายมาก่อนหรือไม่?',
      fieldName: 'policy.bankrupt',
    },
    {
      fieldTitle: 'โปรดให้รายละเอียด',
      fieldName: 'policy.bankruptInfo',
    },
    {
      fieldTitle:
        'ท่านเคยถูกดำเนินคดีอาญา และ/หรือ ถูก ศาลพิพากษาลงโทษในคดีอาญามาก่อนหรือไม่?',
      fieldName: 'policy.criminalCase',
    },
    {
      fieldTitle: 'โปรดให้รายละเอียด',
      fieldName: 'policy.criminalCaseInfo',
    },
    {
      fieldTitle:
        'ท่านได้รับการมอบอำนาจให้เป็นตัวแทนหรือนายหน้าที่ทำหน้าที่พิจารณารับประกันภัย และพิจารณาสินไหมในนามบริษัท หรือไม่?',
      fieldName: 'policy.authorization',
    },
    {
      fieldTitle:
        'ท่านเป็นคนกลางประกันภัย ทำหน้าที่เป็นผู้แนะนำธุรกิจให้บริษัทฯ โดยไม่มีใบอนุญาตตัวแทน/นายหน้า และต้องการค่าแนะนำธุรกิจหรือไม่?',
      fieldName: 'policy.insuranceIntermediary',
    },
    {
      fieldTitle:
        'ท่านเป็นคู่ค้าทางธุรกิจรายใหญ่/พันธมิตรทางธุรกิจรายใหญ่ของบริษัท เช่น ธนาคารที่เป็นนายหน้าให้แก่บริษัท เช่น KTB หรือไม่?',
      fieldName: 'policy.majorBusinessPartners',
    },
  ]
  return (
    <>
      <HeaderActions titleStep="Policy" />
      <Typography variant="h6" mb={2}>
        นโยบาย
      </Typography>
      <CompareTableItem sx={{ mb: 4 }} rows={policyRows} showCompare />
    </>
  )
}
