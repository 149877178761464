import Modal from 'react-modal'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
// components
import Image from '../../../../components/Image/Image'
// redux
import { useSelector } from '../../../../redux/store'
import {
  selectProgressStatus,
  selectInitialProfile,
} from '../../../../redux/selector'
import { applicationStatusStep } from '../../slices/applicant-details'
// utils
import { getFormatDate } from '../../../../utils/getFormatDate'
//
import { CardContentStyle, CardStyle } from '../styled'
import { Link } from '@mui/material'
import { PUBLIC_URL } from '../../../../constants/webMeta'

Modal.setAppElement('#root')

export default function AwaitingCCMSendStatus() {
  const initProfile = useSelector(selectInitialProfile)
  const applicationStatus = useSelector(selectProgressStatus)

  return (
    <CardStyle>
      <CardContentStyle>
        <Stack direction="row" gap={2}>
          <Image
            src="/images/illustrate_awaiting_ccm_check.svg"
            alt="Awaiting CCM check"
            effect="opacity"
            sizes="56"
            sx={{
              '& .MuiBox-root': {
                verticalAlign: 'middle',
                objectFit: 'contain',
              },
            }}
          />

          <Stack flex={1} justifyContent="center" gap={0.5}>
            <Typography variant="h5" color="primary.main" whiteSpace="nowrap">
              {applicationStatus && applicationStatusStep[applicationStatus]}
            </Typography>
            <Typography variant="body2" color="text.gray">
              วันที่: {getFormatDate(String(initProfile?.statsuUpdateDate))}
            </Typography>
          </Stack>

          <Stack justifyContent="center" gap={1}>
            <Link
              href={`${PUBLIC_URL}/application-verification/list?status=REQUEST_DESCISION`}
            >
              <Button
                variant="outlined"
                size={'small'}
                endIcon={<ArrowForwardIcon fontSize="small" />}
                sx={{ marginButton: 1, fontSize: '1rem' }}
              >
                ไปหน้า ส่งพิจารณาเพิ่มเติม
              </Button>
            </Link>
          </Stack>
        </Stack>
      </CardContentStyle>
    </CardStyle>
  )
}
