import { CrudForm } from '../../../CRUD/types/CrudState'

export interface DrawerState {
  isOpen?: boolean
  content: React.ReactNode
  isLoading?: boolean
  table?: DrawerTableState
}
export interface DrawerTableState {
  items: CrudForm[]
  page: number
  total: number
  limit: number
}
export const initialState: DrawerState = {
  isOpen: false,
  content: null,
  isLoading: false,
  table: {
    items: [],
    page: 1,
    total: 0,
    limit: 20,
  },
}
