import Drawer from '@mui/material/Drawer'
import { shallowEqual, useSelector } from 'react-redux'
import { SliceModel } from '../../redux/models'
import { closeDrawer } from './slices'
import { useDispatch } from 'react-redux'
import React from 'react'
import { AppDispatch } from '../../redux/store'
import { SxProps } from '@mui/material'
export default function CustomDrawer(props: { sx?: SxProps }) {
  const dispath: AppDispatch = useDispatch()
  const { drawer } = useSelector(
    (state: SliceModel) => ({
      drawer: state.drawer,
    }),
    shallowEqual
  )
  React.useEffect(() => {
    if (drawer.isOpen) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
  }, [drawer.isOpen])

  return (
    <Drawer
      anchor={'right'}
      open={drawer.isOpen}
      onClose={() => dispath(closeDrawer())}
      sx={props.sx}
    >
      {drawer.content}
    </Drawer>
  )
}
