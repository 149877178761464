
export const headerCells: any = [
    {
        id: 'status',
        label: 'status',
        width: '20px',
        hideSortIcon: true,
    },
    {
        id: 'id',
        disablePadding: false,
        label: 'ID',
        width: '90px',
    },
    {
        id: 'name',
        label: 'ชื่อ Ram',
    },
    {
        id: 'email',
        label: 'อีเมล',
    },
    {
        label: 'เบอร์โทรศัพท์',
        id: 'phoneNumber'
    },
    {
        label: 'เลขาชื่อไทย',
        id: 'secretaryNameTh'
    },
    {
        label: 'เลขาชื่ออังกฤษ',
        id: 'secretaryNameEn'
    },
    {
        label: 'เบอร์โทรศัพท์เลขา',
        id: 'secretaryPhoneNumber'
    },
    {
        label: 'อีเมลเลขา',
        id: 'secretaryEmail'
    },
    {
        label: 'รหัส',
        id: 'code'
    },
    {
        id: 'action',
        disablePadding: false,
        label: 'คำสั่ง',
        width: '90px',
        hideSortIcon: true,
        editPath: '/ram-profile/form',
        hideDelete: false,
    },
]
