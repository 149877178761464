import { API_URL } from '../constants/envConfig'
import { fileStorage } from './apiPath'

export async function handlePreviewImage({
  imageUrl,
  fileName = 'preview-image.png',
  fileType,
  cb,
}: {
  imageUrl: string
  fileName: string
  fileType: string
  cb: (file: { srcFile: string | ArrayBuffer; name: string }) => void
}) {
  const url = `${API_URL}${fileStorage}${imageUrl}`

  const response = await fetch(url)

  const blob = await response.blob()

  // Create a File object from the Blob
  const selectedFile = new File([blob], fileName, {
    type: blob.type,
  })

  if (fileType.startsWith('image/')) {
    return selectedFile
  }

  const reader = new FileReader()

  reader.readAsDataURL(selectedFile)

  reader.onloadend = (e) => {
    const file = {
      srcFile: e?.target?.result || '',
      name: fileName,
    }

    cb(file)
  }
}
