import _ from 'lodash'
import { enumStatus } from '../../../../../../components/CRUD/enum/status'

export const getBodyMutation = (data: any = {}) => {
  return {
    id: _.get(data, 'id', ''),
    uuid: _.get(data, 'uuid', undefined),
    name: _.get(data, 'name', ''),
    email: _.get(data, 'email', ''),
    phoneNumber: _.get(data, 'phoneNumber', ''),
    secretaryNameTh: _.get(data, 'secretaryNameTh', ''),
    secretaryNameEn: _.get(data, 'secretaryNameEn', ''),
    secretaryPhoneNumber: _.get(data, 'secretaryPhoneNumber', ''),
    secretaryEmail: _.get(data, 'secretaryEmail', ''),
    code: _.get(data, 'code', ''),
    status: _.get(data, 'status', enumStatus.INACTIVE),
    createdBy: _.get(data, 'createdBy', ''),
    updatedBy: _.get(data, 'updatedBy', ''),
    createdAt: _.get(data, 'createdAt', ''),
    updatedAt: _.get(data, 'updatedAt', ''),
  }
}
