import { Box, Typography } from '@mui/material'

import { breadcrumb } from './model/breadcrumb'
import { BoxButton, BoxHeader } from './styled'
import Breadcrumbs from '../../components/Breadcrumbs'
import {
  getCrudBy,
  getFormIsLoading,
} from '../../components/CRUD/handler/useInputField'
import { dispatch } from '../../redux/store'
import { submitForm } from './handler/submitForm'
import CustomButton, {
  CustomLoadingButton,
} from '../../components/Input/CustomButton'
import RestoreIcon from '@mui/icons-material/Restore'
import { openDrawer } from '../../components/CustomDrawer/slices'
import { HistoryConfig } from './HistoryConfig'
function Header() {
  const formData = getCrudBy('formData')
  const isLoading = getFormIsLoading()
  return (
    <>
      <BoxHeader>
        <Box>
          <Typography variant="h4">การตั้งค่า</Typography>
          <Breadcrumbs menuList={breadcrumb} />
        </Box>

        <BoxButton>
          <CustomButton
            variant="outlined"
            startIcon={<RestoreIcon />}
            onClick={() =>
              dispatch(
                openDrawer({
                  content: <HistoryConfig />,
                })
              )
            }
          >
            ประวัติ
          </CustomButton>
          <CustomLoadingButton
            loading={isLoading}
            onClick={() => dispatch(submitForm(formData))}
          >
            บันทึกและเผยแพร่
          </CustomLoadingButton>
        </BoxButton>
      </BoxHeader>
    </>
  )
}

export default Header
