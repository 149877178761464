import { CRUDListFilterPath } from '../../../utils/apiPath'
import { headerCells } from './features/Listing/model/headerCellsList'

export const crudConfig = {
  moduleId: 'DC_ADMIN',
  moduleSlug: 'dc-admin',
  moduleTitleFull: 'ตรวจสอบใบสมัคร',
  moduleTitleSecond: 'ตรวจสอบใบสมัคร',
  modulesTitleShort: 'ตรวจสอบใบสมัคร',
  modulePath: '/application-verification/list',
  filterPath: CRUDListFilterPath,
  headerCells: headerCells,
}
