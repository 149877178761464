export interface CompareState {
  isLoading: boolean
  isCompare: boolean
  firstIndex: number
  secondIndex: number
  items: CompareFullForm[]
}

export const initialState: CompareState = {
  isLoading: false,
  isCompare: false,
  firstIndex: 0,
  secondIndex: 0,
  items: [],
}
export interface CompareFullForm {
  fullFormUuid: string
  main: {
    invitedCode: string
    status: string
  }
  personal: PersonalInfo
  personalSpouse?: object
  personalAddress: AddressInfo
  personalAssets?: object
  personContact?: object
  work: WorkInfo
  tax: TaxInfo
  policy: PolicyInfo
  educations: any[]
  agentRelatives: any[]
  status: string
  attachmentMetas: AttachmentMeta[]
  managerUuid: string
  createdAt: string
  updatedAt: string
}
interface TaxInfo {
  reduceTax: number
  taxMaritalStatus: string
  firstChildrenNum: number
  otherChildrenNum: number
  totalChildrenNum: number
  lifeInsurancePremium: number
  healthInsurancePremium: number
  interestHomeLoans: number
  coBorrower: string
  coBorrowerType: string
  totalCoBorrower: number
  fatherSupportAmount: number
  motherSupportAmount: number
  spouseFatherSupportAmount: number
  spouseMotherSupportAmount: number
  parentsHealthInsurancePremium: number
  spouseLifeInsurancePremium: number
  rmfInvestmentAmount: number
  ssfInvestmentAmount: number
}

interface PolicyInfo {
  conflictOfInterest: string
  conflictOfInterestInfo: string
  bankrupt: string
  bankruptInfo: string
  criminalCase: string
  criminalCaseInfo: string
  authorization: string
  insuranceIntermediary: string
  majorBusinessPartners: string
}

interface WorkInfo {
  experienceSaleInsurance: string
  workTypeInsurance: string
  companyName: string
  startYear: string
  endYear: string
  occupation: string
  salary: string
}

interface PersonalInfo {
  title: string
  firstName: string
  lastName: string
  firstNameEn: string
  lastNameEn: string
  gender: string
  dateOfBirth: string
  placeOfBirth: string
  maritalStatus: string
  nationality: string
  ethnicity: string
  nationalityOther: string
  idCard: string
  otherCardName: string
  otherIdCard: string
  bookbankKT: string
  language1: string
  language2: string
  language3: string
  mobileNo: string
  email: string
  homePhone: string
  highestEducation: string
  contactPersonName: string
  contactPersonAddress: string
  contactPersonMobile: string
  channel: string
}

interface AddressInfo {
  houseNo: string
  village: string
  soi: string
  road: string
  provinceUuid: string
  amphurUuid: string
  tambonUuid: string
  zipcode: string
  isAddressCurrent: string
  regHouseNo: string
  regVillage: string
  regSoi: string
  regRoad: string
  regProvinceUuid: string
  regAmphurUuid: string
  regTambonUuid: string
  regZipcode: string
  addressType: string
}

interface AttachmentMeta {
  id: number
  createdAt: string
  createdBy: string
  updatedAt: string
  updatedBy: string
  uuid: string
  fullformUuid: string
  fieldName: string
  originalFileName: string
  fileName: string
  size: number
  type: string
  url: string
  status: string
}
