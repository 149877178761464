import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Breadcrumbs from '../../../Breadcrumbs'
import Grid from '@mui/material/Grid'
import _ from 'lodash'
import React from 'react'

export type Breadcrumb = {
  title: string
  link: string
  pointer: boolean
}

export const CHeader: React.FC<{
  title: string
  breadcrumbs: Breadcrumb[]
  action?: React.ReactNode
}> = ({ title, breadcrumbs, action }) => {
  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      gap={3}
    >
      <Grid item style={{ flexGrow: 1 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          <Box>
            <Typography variant="h4">{title}</Typography>
          </Box>
          <Breadcrumbs menuList={breadcrumbs} />
        </Box>
      </Grid>
      <Grid item>{!_.isUndefined(action) && action}</Grid>
    </Grid>
  )
}
