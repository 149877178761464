export const memoListTopic = [
  {
    name: 'ค่าธรรมเนียมออกรหัส',
  },
  {
    name: 'รูปถ่าย',
  },
  {
    name: 'ใบสมัคร',
  },
  {
    name: 'บัตรประชาชน',
  },
  {
    name: 'ทะเบียนบ้าน',
  },
  {
    name: 'ใบเปลี่ยนชื่อ',
  },
  {
    name: 'แบบแจ้งรายการลดหย่อนภาษี',
  },
  {
    name: 'สมุดบัญชี ธนาคาร',
  },
  {
    name: 'อื่นๆ',
  },
]
